import {
    GET_DT_TEST_TYPES,
    GET_DT_TEST_TYPES_SUCCESS,
    GET_DT_TEST_TYPES_FAIL,

    GET_DT_RESULTS,
    GET_DT_RESULTS_SUCCESS,
    GET_DT_RESULTS_FAIL,

    RESET,
    GET_DT_RESULT_DETAILS,
    GET_DT_RESULT_DETAILS_SUCCESS,
    GET_DT_RESULT_DETAILS_FAIL,
    GET_DT_TYPE_OF_COLLECTION,
    GET_DT_TYPE_OF_COLLECTION_SUCCESS,
    GET_DT_TYPE_OF_COLLECTION_FAIL,
    GET_DT_NATURE_OF_ACTIONS,
    GET_DT_NATURE_OF_ACTIONS_SUCCESS,
    GET_DT_NATURE_OF_ACTIONS_FAIL,
    GET_DT_RELEASED_TO,
    GET_DT_RELEASED_TO_SUCCESS,
    GET_DT_RELEASED_TO_FAIL,
    GET_DT_FUNDING_STATUSES,
    GET_DT_FUNDING_STATUSES_SUCCESS,
    GET_DT_FUNDING_STATUSES_FAIL,
    GET_DT_RANDOM_REASONS,
    GET_DT_RANDOM_REASONS_SUCCESS,
    GET_DT_RANDOM_REASONS_FAIL,
    GET_TDP_TYPES,
    GET_TDP_TYPES_SUCCESS,
    GET_TDP_TYPES_FAIL,
    GET_QUARTER_REPORT_DATES,
    GET_QUARTER_REPORT_DATES_SUCCESS,
    GET_QUARTER_REPORT_DATES_FAIL,
    GET_VENDOR_LOG_STATUS_LISTS,
    GET_VENDOR_LOG_STATUS_LISTS_FAIL,
    GET_VENDOR_LOG_STATUS_LISTS_SUCCESS,
} from "./actionsTypes";

const initialState = {
    DTTestTypeList: [],
    loadingDTTestTypeList: false,
    errorDTTestTypeList: {
        message: "",
    },

    DTResultsList: [],
    loadingDTResultsList: false,
    errorDTResultsList: {
        message: "",
    },

    DTResultDetailsList: [],
    loadingDTResultDetailsList: false,

    DTTypeOfCollectionList: [],
    loadingDTTypeOfCollectionList: false,

    DTNatureOfActions: [],
    loadingDTNatureOfActions: false,
    errorDTNatureOfActions: {
        message: "",
    },

    DTReleasedToList: [],
    loadingDTReleasedToList: false,
    errorDTReleasedToList: {
        message: "",
    },

    DTFundingStatusList: [],
    DTloadingFundingStatusList: false,
    errorDTFundingStatusList: {
        message: "",
    },

    DTRandomReasonsList: [],
    loadingDTRandomReasonsList: false,
    errorDTRandomReasonsList: {
        message: "",
    },

    TDPTypesList: [],
    loadingTDPTypesList: false,
    errorTDPTypesList: {
        message: "",
    },

    quarterReportDatesList: [],
    loadingQuarterReportDatesList: false,
    errorQuarterReportDatesList: {
        message: "",
    },

    vendorLogStatusLists: [],
    loadingVendorLogStatusLists: false,
    errorVendorLogStatusLists: {
        message: "",
    },
};

const DTTestTypeReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        //DT TEST TYPES
        case GET_DT_TEST_TYPES:
            state = { ...state, loadingDTTestTypeList: true, DTTestTypeList: [] };
            break;
        case GET_DT_TEST_TYPES_SUCCESS:
            state = { ...state, DTTestTypeList: action.payload, loadingDTTestTypeList: false };
            break;
        case GET_DT_TEST_TYPES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTTestTypeList: false,
            };
            break;

        //DT RESULTS
        case GET_DT_RESULTS:
            state = { ...state, loadingDTResultsList: true, DTResultsList: [] };
            break;
        case GET_DT_RESULTS_SUCCESS:
            state = { ...state, DTResultsList: action.payload, loadingDTResultsList: false };
            break;
        case GET_DT_RESULTS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTResultsList: false,
            };
            break;

        //DT RESULTS DETAILS
        case GET_DT_RESULT_DETAILS:
            state = { ...state, loadingDTResultDetailsList: true, DTResultDetailsList: [] };
            break;
        case GET_DT_RESULT_DETAILS_SUCCESS:
            state = { ...state, DTResultDetailsList: action.payload, loadingDTResultDetailsList: false };
            break;
        case GET_DT_RESULT_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTResultDetailsList: false,
            };
            break;

        //DT TYPE OF COLLECTION
        case GET_DT_TYPE_OF_COLLECTION:
            state = { ...state, loadingDTTypeOfCollectionList: true, DTTypeOfCollectionList: [] };
            break;
        case GET_DT_TYPE_OF_COLLECTION_SUCCESS:
            state = { ...state, DTTypeOfCollectionList: action.payload, loadingDTTypeOfCollectionList: false };
            break;
        case GET_DT_TYPE_OF_COLLECTION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTTypeOfCollectionList: false,
            };
            break;

        //DT NATURE OF ACTION
        case GET_DT_NATURE_OF_ACTIONS:
            state = { ...state, loadingDTNatureOfActions: true, DTNatureOfActions: [] };
            break;
        case GET_DT_NATURE_OF_ACTIONS_SUCCESS:
            state = { ...state, DTNatureOfActions: action.payload, loadingDTNatureOfActions: false };
            break;
        case GET_DT_NATURE_OF_ACTIONS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTNatureOfActions: false,
            };
            break;

        //DT RELEASED TO
        case GET_DT_RELEASED_TO:
            state = { ...state, loadingDTReleasedToList: true, DTReleasedToList: [] };
            break;
        case GET_DT_RELEASED_TO_SUCCESS:
            state = { ...state, DTReleasedToList: action.payload, loadingDTReleasedToList: false };
            break;
        case GET_DT_RELEASED_TO_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTReleasedToList: false,
            };
            break;

        //DT FUNDING STATUS
        case GET_DT_FUNDING_STATUSES:
            state = { ...state, loadingDTFundingStatusList: true, DTFundingStatusList: [] };
            break;
        case GET_DT_FUNDING_STATUSES_SUCCESS:
            state = { ...state, DTFundingStatusList: action.payload, loadingDTFundingStatusList: false };
            break;
        case GET_DT_FUNDING_STATUSES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingDTFundingStatusList: false,
            };
            break;

        //DT RANDOM REASONS
        case GET_DT_RANDOM_REASONS:
            state = { ...state, loadingDTRandomReasonsList: true, DTRandomReasonsList: [] };
            break;
        case GET_DT_RANDOM_REASONS_SUCCESS:
            state = { ...state, DTRandomReasonsList: action.payload, loadingDTRandomReasonsList: false };
            break;
        case GET_DT_RANDOM_REASONS_FAIL:
            state = {
                ...state,
                errorDTRandomReasonsList: {
                    message: "Error",
                },
                loadingDTRandomReasonsList: false,
            };
            break;

        //TDP TYPES
        case GET_TDP_TYPES:
            state = { ...state, loadingTDPTypesList: true, TDPTypesList: [] };
            break;
        case GET_TDP_TYPES_SUCCESS:
            state = { ...state, TDPTypesList: action.payload, loadingTDPTypesList: false };
            break;
        case GET_TDP_TYPES_FAIL:
            state = {
                ...state,
                errorTDPTypesList: {
                    message: "Error",
                },
                loadingTDPTypesList: false,
            };
            break;

        //QUARTER REPORT DATES
        case GET_QUARTER_REPORT_DATES:
            state = { ...state, loadingQuarterReportDatesList: true, quarterReportDatesList: [] };
            break;
        case GET_QUARTER_REPORT_DATES_SUCCESS:
            state = { ...state, quarterReportDatesList: action.payload, loadingQuarterReportDatesList: false };
            break;
        case GET_QUARTER_REPORT_DATES_FAIL:
            state = {
                ...state,
                errorQuarterReportDatesList: {
                    message: "Error",
                },
                loadingQuarterReportDatesList: false,
            };
            break;

        //VENDOR LOG STATUS
        case GET_VENDOR_LOG_STATUS_LISTS:
            state = { ...state, loadingVendorLogStatusLists: true, vendorLogStatusLists: [] };
            break;
        case GET_VENDOR_LOG_STATUS_LISTS_SUCCESS:
            state = { ...state, vendorLogStatusLists: action.payload, loadingVendorLogStatusLists: false };
            break;
        case GET_VENDOR_LOG_STATUS_LISTS_FAIL:
            state = {
                ...state,
                errorVendorLogStatusLists: {
                    message: "Error",
                },
                loadingVendorLogStatusLists: false,
            };
            break;


        //RESET WHOLE STATE
        case RESET:
            state = { ...initialState };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DTTestTypeReducer;