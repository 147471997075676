export const RANDOM_DT_LOG_CREATE = "RANDOM_DT_LOG_CREATE";
export const RANDOM_DT_LOG_CREATE_SUCCESS = "RANDOM_DT_LOG_CREATE_SUCCESS";
export const RANDOM_DT_LOG_CREATE_FAIL = "RANDOM_DT_LOG_CREATE_FAIL";

export const GET_RANDOM_DT_LOG_DETAILS = "GET_RANDOM_DT_LOG_DETAILS";
export const GET_RANDOM_DT_LOG_DETAILS_SUCCESS = "GET_RANDOM_DT_LOG_DETAILS_SUCCESS";
export const GET_RANDOM_DT_LOG_DETAILS_FAIL = "GET_RANDOM_DT_LOG_DETAILS_FAIL";

export const UPDATE_RANDOM_DT_LOG = "UPDATE_RANDOM_DT_LOG";
export const UPDATE_RANDOM_DT_LOG_SUCCESS = "UPDATE_RANDOM_DT_LOG_SUCCESS";
export const UPDATE_RANDOM_DT_LOG_FAIL = "UPDATE_RANDOM_DT_LOG_FAIL";

export const DELETE_RANDOM_DT_LOG = "DELETE_RANDOM_DT_LOG";
export const DELETE_RANDOM_DT_LOG_SUCCESS = "DELETE_RANDOM_DT_LOG_SUCCESS";
export const DELETE_RANDOM_DT_LOG_FAIL = "DELETE_RANDOM_DT_LOG_FAIL";

export const RESET = "RESET";
export const RESET_RANDOM_DT_LOG_CREATE = "RESET_RANDOM_DT_LOG_CREATE";
export const RESET_RANDOM_DT_LOG_DETAILS = "RESET_RANDOM_DT_LOG_DETAILS";