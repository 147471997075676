import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createGrievanceAppealStatus = (payload) => post(baseURL + url.GRIEVANCE_APPEAL_STATUS, payload);
export const getGrievanceAppealStatusDetails = (id) => get(`${baseURL + url.GRIEVANCE_APPEAL_STATUS}/${id}`);
export const getGrievanceAppealStatuses = () => get(`${baseURL + url.GRIEVANCE_APPEAL_STATUS}`);
export const updateGrievanceAppealStatus = (id, payload) => put(`${baseURL + url.GRIEVANCE_APPEAL_STATUS}/${id}`, payload);
export const deleteGrievanceAppealStatus = (id) => remove(`${baseURL + url.GRIEVANCE_APPEAL_STATUS}/${id}`);
