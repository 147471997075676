import {
	GET_ETHICGROUPS, GET_ETHICGROUPS_SUCCESS, GET_ETHICGROUPS_FAIL,
	GET_ETHICGROUPS_DETAILS, GET_ETHICGROUPS_DETAILS_SUCCESS, GET_ETHICGROUPS_DETAILS_FAIL,
	ETHICGROUP_CREATE,ETHICGROUP_CREATE_FAIL,ETHICGROUP_CREATE_SUCCESS,
	ETHICGROUP_DELETE,ETHICGROUP_DELETE_FAIL,ETHICGROUP_DELETE_SUCCESS,
	ETHICGROUP_UPDATE,ETHICGROUP_UPDATE_FAIL,ETHICGROUP_UPDATE_SUCCESS,RESET, GET_ETHICGROUPS_SETTING_FAIL, GET_ETHICGROUPS_SETTING_SUCCESS, SAVE_ETHICGROUPS_SETTING_FAIL, SAVE_ETHICGROUPS_SETTING_SUCCESS, SAVE_ETHICGROUPS_SETTING, GET_ETHICGROUPS_SETTING, ETHICGROUP_COPY, ETHICGROUP_COPY_SUCCESS, ETHICGROUP_COPY_FAIL, ETHICGROUP_STATUS_CREATE, ETHICGROUP_STATUS_CREATE_SUCCESS, ETHICGROUP_STATUS_CREATE_FAIL, ETHICGROUP_STATUS_UPDATE, ETHICGROUP_STATUS_UPDATE_SUCCESS, ETHICGROUP_STATUS_UPDATE_FAIL, ETHICGROUP_STATUS_DELETE, ETHICGROUP_STATUS_DELETE_SUCCESS, ETHICGROUP_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getEthicGroups = (payload) => {
	return {
	  type: GET_ETHICGROUPS,
	  payload: payload
	};
  };
  
  export const getEthicGroupsSuccess = (posts) => {
	return {
	  type: GET_ETHICGROUPS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getEthicGroupsFail = (error) => {
	return {
	  type: GET_ETHICGROUPS_FAIL,
	  payload: error,
	};
  };
  
  export const getEthicGroupsDetails = (id) => {
	return {
	  type: GET_ETHICGROUPS_DETAILS,
	  payload: id,
	};
  };
  
  export const getEthicGroupsDetailsSuccess = (post) => {
	return {
	  type: GET_ETHICGROUPS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getEthicGroupsDetailsFail = (error) => {
	return {
	  type: GET_ETHICGROUPS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createEthicGroup =(payload)=>{
	return {
		type: ETHICGROUP_CREATE,
		payload: payload
	}
  }
  export const createEthicGroupSuccess =(response)=>{
	return {
		type: ETHICGROUP_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createEthicGroupFail =(error)=>{
	return {
		type: ETHICGROUP_CREATE_FAIL,
		payload: error
	}
  }


  export const updateEthicGroup =(payload)=>{
	
	return {
		type: ETHICGROUP_UPDATE,
		payload: payload
	}
  }
  export const updateEthicGroupSuccess =(response)=>{
	return {
		type: ETHICGROUP_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateEthicGroupFail =(error)=>{
	return {
		type: ETHICGROUP_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteEthicGroup =(payload)=>{
	return {
		type: ETHICGROUP_DELETE,
		payload: payload
	}
  }
  export const deleteEthicGroupSuccess =(response)=>{
	return {
		type: ETHICGROUP_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteEthicGroupFail =(error)=>{
	return {
		type: ETHICGROUP_DELETE_FAIL,
		payload: error
	}
  };
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

