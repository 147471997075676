import {
    TYPE_OF_SME_CREATE, TYPE_OF_SME_CREATE_FAIL, TYPE_OF_SME_CREATE_SUCCESS,
    DELETE_TYPE_OF_SME,
    DELETE_TYPE_OF_SME_FAIL,
    DELETE_TYPE_OF_SME_SUCCESS,
    GET_TYPE_OF_SMEES,
    GET_TYPE_OF_SMEES_FAIL,
    GET_TYPE_OF_SMEES_SUCCESS,
    GET_TYPE_OF_SME_DETAILS,
    GET_TYPE_OF_SME_DETAILS_FAIL,
    GET_TYPE_OF_SME_DETAILS_SUCCESS,
    RESET,
    RESET_TYPE_OF_SME_CREATE,
    RESET_TYPE_OF_SME_DELETE,
    RESET_TYPE_OF_SME_DETAILS,
    UPDATE_TYPE_OF_SME,
    UPDATE_TYPE_OF_SME_FAIL,
    UPDATE_TYPE_OF_SME_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createTypesOfSME = (payload) => {
    return {
        type: TYPE_OF_SME_CREATE,
        payload: payload
    }
};
export const createTypesOfSMESuccess = (response) => {
    return {
        type: TYPE_OF_SME_CREATE_SUCCESS,
        payload: response
    }
};
export const createTypesOfSMEFail = (error) => {
    return {
        type: TYPE_OF_SME_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTypesOfSMEDetails = (id) => {
    return {
        type: GET_TYPE_OF_SME_DETAILS,
        payload: id,
    };
};
export const getTypesOfSMEDetailsSuccess = (data) => {
    return {
        type: GET_TYPE_OF_SME_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTypesOfSMEDetailsFail = (error) => {
    return {
        type: GET_TYPE_OF_SME_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getTypesOfSMEes = () => {
    return {
        type: GET_TYPE_OF_SMEES,
    };
};
export const getTypesOfSMEesSuccess = (data) => {
    return {
        type: GET_TYPE_OF_SMEES_SUCCESS,
        payload: data,
    };
};
export const getTypesOfSMEesFail = (error) => {
    return {
        type: GET_TYPE_OF_SMEES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTypesOfSME = (payload) => {

    return {
        type: UPDATE_TYPE_OF_SME,
        payload: payload
    }
};
export const updateTypesOfSMESuccess = (response) => {
    return {
        type: UPDATE_TYPE_OF_SME_SUCCESS,
        payload: response
    }
};
export const updateTypesOfSMEFail = (error) => {
    return {
        type: UPDATE_TYPE_OF_SME_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTypesOfSME = (id) => {
    return {
        type: DELETE_TYPE_OF_SME,
        payload: id
    }
};
export const deleteTypesOfSMESuccess = (response) => {
    return {
        type: DELETE_TYPE_OF_SME_SUCCESS,
        payload: response
    }
};
export const deleteTypesOfSMEFail = (error) => {
    return {
        type: DELETE_TYPE_OF_SME_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateTypesOfSME = () => {
    return {
        type: RESET_TYPE_OF_SME_CREATE
    }
};

export const resetTypesOfSMEDetails = () => {
    return {
        type: RESET_TYPE_OF_SME_DETAILS
    }
};

export const resetTypesOfSMEDeleteData = () => {
    return {
        type: RESET_TYPE_OF_SME_DELETE
    }
};