export const CBAR_LOG_CREATE = "CBAR_LOG_CREATE";
export const CBAR_LOG_CREATE_SUCCESS = "CBAR_LOG_CREATE_SUCCESS";
export const CBAR_LOG_CREATE_FAIL = "CBAR_LOG_CREATE_FAIL";

export const GET_CBAR_LOG_DETAILS = "GET_CBAR_LOG_DETAILS";
export const GET_CBAR_LOG_DETAILS_SUCCESS = "GET_CBAR_LOG_DETAILS_SUCCESS";
export const GET_CBAR_LOG_DETAILS_FAIL = "GET_CBAR_LOG_DETAILS_FAIL";

export const UPDATE_CBAR_LOG = "UPDATE_CBAR_LOG";
export const UPDATE_CBAR_LOG_SUCCESS = "UPDATE_CBAR_LOG_SUCCESS";
export const UPDATE_CBAR_LOG_FAIL = "UPDATE_CBAR_LOG_FAIL";

export const DELETE_CBAR_LOG = "DELETE_CBAR_LOG";
export const DELETE_CBAR_LOG_SUCCESS = "DELETE_CBAR_LOG_SUCCESS";
export const DELETE_CBAR_LOG_FAIL = "DELETE_CBAR_LOG_FAIL";

export const RESET = "RESET";
export const RESET_CBAR_LOG_CREATE = "RESET_CBAR_LOG_CREATE";
export const RESET_CBAR_LOG_DETAILS = "RESET_CBAR_LOG_DETAILS";
export const RESET_CBAR_LOG_DELETE = "RESET_CBAR_LOG_DELETE";