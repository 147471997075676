export const GRIEVANCE_APPEAL_STATUS_CREATE = "GRIEVANCE_APPEAL_STATUS_CREATE";
export const GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS = "GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS";
export const GRIEVANCE_APPEAL_STATUS_CREATE_FAIL = "GRIEVANCE_APPEAL_STATUS_CREATE_FAIL";

export const GET_GRIEVANCE_APPEAL_STATUS_DETAILS = "GET_GRIEVANCE_APPEAL_STATUS_DETAILS";
export const GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS = "GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS";
export const GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL = "GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL";

export const GET_GRIEVANCE_APPEAL_STATUSES = "GET_GRIEVANCE_APPEAL_STATUSES";
export const GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS = "GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS";
export const GET_GRIEVANCE_APPEAL_STATUSES_FAIL = "GET_GRIEVANCE_APPEAL_STATUSES_FAIL";

export const UPDATE_GRIEVANCE_APPEAL_STATUS = "UPDATE_GRIEVANCE_APPEAL_STATUS";
export const UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS = "UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS";
export const UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL = "UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL";

export const DELETE_GRIEVANCE_APPEAL_STATUS = "DELETE_GRIEVANCE_APPEAL_STATUS";
export const DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS = "DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS";
export const DELETE_GRIEVANCE_APPEAL_STATUS_FAIL = "DELETE_GRIEVANCE_APPEAL_STATUS_FAIL";

export const RESET = "RESET";
export const RESET_GRIEVANCE_APPEAL_STATUS_CREATE = "RESET_GRIEVANCE_APPEAL_STATUS_CREATE";
export const RESET_GRIEVANCE_APPEAL_STATUS_DETAILS = "RESET_GRIEVANCE_APPEAL_STATUS_DETAILS";
export const RESET_GRIEVANCE_APPEAL_STATUS_DELETE = "RESET_GRIEVANCE_APPEAL_STATUS_DELETE";