export const GET_HISTORYS = "GET_HISTORYS";
export const GET_HISTORYS_SUCCESS = "GET_HISTORYS_SUCCESS";
export const GET_HISTORYS_FAIL = "GET_HISTORYS_FAIL";

export const GET_HISTORYS_DETAILS = "GET_HISTORYS_DETAILS";
export const GET_HISTORYS_DETAILS_SUCCESS = "GET_HISTORYS_DETAILS_SUCCESS";
export const GET_HISTORYS_DETAILS_FAIL = "GET_HISTORYS_DETAILS_FAIL";

export const HISTORY_UPDATE = "HISTORY_UPDATE";
export const HISTORY_UPDATE_SUCCESS = "HISTORY_UPDATE_SUCCESS";
export const HISTORY_UPDATE_FAIL = "HISTORY_UPDATE_FAIL";

export const HISTORY_CREATE = "HISTORY_CREATE";
export const HISTORY_CREATE_SUCCESS = "HISTORY_CREATE_SUCCESS";
export const HISTORY_CREATE_FAIL = "HISTORY_CREATE_FAIL";

export const HISTORY_DELETE = "HISTORY_DELETE";
export const HISTORY_DELETE_SUCCESS = "HISTORY_DELETE_SUCCESS";
export const HISTORY_DELETE_FAIL = "HISTORY_DELETE_FAIL";

export const RESET = "RESET";
