export const GET_DEDUCTIONPLANS = "GET_DEDUCTIONPLANS";
export const GET_DEDUCTIONPLANS_SUCCESS = "GET_DEDUCTIONPLANS_SUCCESS";
export const GET_DEDUCTIONPLANS_FAIL = "GET_DEDUCTIONPLANS_FAIL";

export const GET_DEDUCTIONPLANS_DETAILS = "GET_DEDUCTIONPLANS_DETAILS";
export const GET_DEDUCTIONPLANS_DETAILS_SUCCESS = "GET_DEDUCTIONPLANS_DETAILS_SUCCESS";
export const GET_DEDUCTIONPLANS_DETAILS_FAIL = "GET_DEDUCTIONPLANS_DETAILS_FAIL";

export const DEDUCTIONPLAN_UPDATE= "DEDUCTIONPLAN_UPDATE";
export const DEDUCTIONPLAN_UPDATE_SUCCESS = "DEDUCTIONPLAN_UPDATE_SUCCESS";
export const DEDUCTIONPLAN_UPDATE_FAIL = "DEDUCTIONPLAN_UPDATE_FAIL";

export const DEDUCTIONPLAN_CREATE= "DEDUCTIONPLAN_CREATE";
export const DEDUCTIONPLAN_CREATE_SUCCESS = "DEDUCTIONPLAN_CREATE_SUCCESS";
export const DEDUCTIONPLAN_CREATE_FAIL = "DEDUCTIONPLAN_CREATE_FAIL";

export const DEDUCTIONPLAN_DELETE= "DEDUCTIONPLAN_DELETE";
export const DEDUCTIONPLAN_DELETE_SUCCESS = "DEDUCTIONPLAN_DELETE_SUCCESS";
export const DEDUCTIONPLAN_DELETE_FAIL = "DEDUCTIONPLAN_DELETE_FAIL";
export const RESET = "RESET";

