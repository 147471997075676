export const SET_HEADER_FILTER = "SET HEADER FILTER";
export const CHANGE_HEADER_FILTER = "CHANGE HEADER FILTER";
export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const UPDATE_PERFORMANCE_EVALUATION_DETAIL = "UPDATE_PERFORMANCE_EVALUATION_DETAIL";
export const ADVANCE_SEARCH = "ADVANCE_SEARCH_PERFORMANCE_EVALUTION";
export const ADVANCE_SEARCH_PERFORMANCE_EVALUTION_SUCCESS =
  "ADVANCE_SEARCHPERFORMANCE_EVALUTION_SUCCESS";
  export const ADVANCE_SEARCH_PERFORMANCE_EVALUTION_FAIL =
    "ADVANCE_SEARCHPERFORMANCE_EVALUTION_FAIL";


