import {
    GET_BILLING_INVOICES, GET_BILLING_INVOICES_SUCCESS, GET_BILLING_INVOICES_FAIL,
    GET_BILLING_INVOICES_DETAILS, GET_BILLING_INVOICES_DETAILS_SUCCESS, GET_BILLING_INVOICES_DETAILS_FAIL,
    BILLING_INVOICE_CREATE, BILLING_INVOICE_CREATE_FAIL, BILLING_INVOICE_CREATE_SUCCESS,
    BILLING_INVOICE_DELETE, BILLING_INVOICE_DELETE_FAIL, BILLING_INVOICE_DELETE_SUCCESS,
    BILLING_INVOICE_UPDATE, BILLING_INVOICE_UPDATE_FAIL, BILLING_INVOICE_UPDATE_SUCCESS, RESET, GET_BILLING_INVOICE_STATUS_LISTS, GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS, GET_BILLING_INVOICE_STATUS_LISTS_FAIL, RESET_BILLING_INVOICE_CREATE, RESET_BILLING_INVOICES_DETAILS,
} from "./actionsTypes";

//Get All Actions
export const getBillingInvoices = (payload) => {
    return {
        type: GET_BILLING_INVOICES,
        payload: payload
    };
};
export const getBillingInvoicesSuccess = (posts) => {
    return {
        type: GET_BILLING_INVOICES_SUCCESS,
        payload: posts,
    };
};
export const getBillingInvoicesFail = (error) => {
    return {
        type: GET_BILLING_INVOICES_FAIL,
        payload: error,
    };
};

//Get Details Actions
export const getBillingInvoicesDetails = (id) => {
    return {
        type: GET_BILLING_INVOICES_DETAILS,
        payload: id,
    };
};
export const getBillingInvoicesDetailsSuccess = (data) => {
    return {
        type: GET_BILLING_INVOICES_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getBillingInvoicesDetailsFail = (error) => {
    return {
        type: GET_BILLING_INVOICES_DETAILS_FAIL,
        payload: error,
    };
};


//Create Actions
export const createBillingInvoice = (payload) => {
    return {
        type: BILLING_INVOICE_CREATE,
        payload: payload
    }
};
export const createBillingInvoiceSuccess = (response) => {
    return {
        type: BILLING_INVOICE_CREATE_SUCCESS,
        payload: response
    }
};
export const createBillingInvoiceFail = (error) => {
    return {
        type: BILLING_INVOICE_CREATE_FAIL,
        payload: error
    }
};
export const resetCreateBillingInvoice = () => {
    return {
        type: RESET_BILLING_INVOICE_CREATE
    }
};


//Update Actions
export const updateBillingInvoice = (payload) => {

    return {
        type: BILLING_INVOICE_UPDATE,
        payload: payload
    }
};
export const updateBillingInvoiceSuccess = (response) => {
    return {
        type: BILLING_INVOICE_UPDATE_SUCCESS,
        payload: response
    }
};
export const updateBillingInvoiceFail = (error) => {
    return {
        type: BILLING_INVOICE_UPDATE_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteBillingInvoice = (id) => {
    return {
        type: BILLING_INVOICE_DELETE,
        payload: id
    }
};
export const deleteBillingInvoiceSuccess = (response) => {
    return {
        type: BILLING_INVOICE_DELETE_SUCCESS,
        payload: response
    }
};
export const deleteBillingInvoiceFail = (error) => {
    return {
        type: BILLING_INVOICE_DELETE_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,

    };
};
export const resetBillingInvoicesDetails = () => {
    return {
        type: RESET_BILLING_INVOICES_DETAILS,
    };
};

//Billing Invoice Status Lists Actions
export const getBillingInvoiceStatusList = (payload) => {
    return {
        type: GET_BILLING_INVOICE_STATUS_LISTS,
        payload: payload
    };
};
export const getBillingInvoiceStatusListSuccess = (posts) => {
    return {
        type: GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS,
        payload: posts,
    };
};
export const getBillingInvoiceStatusListFail = (error) => {
    return {
        type: GET_BILLING_INVOICE_STATUS_LISTS_FAIL,
        payload: error,
    };
};