import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createTypesOfSME = (payload) => post(baseURL + url.TYPE_OF_SME, payload);
export const getTypesOfSMEDetails = (id) => get(`${baseURL + url.TYPE_OF_SME}/${id}`);
export const getTypesOfSMEes = () => get(`${baseURL + url.TYPE_OF_SME}`);
export const updateTypesOfSME = (id, payload) => put(`${baseURL + url.TYPE_OF_SME}/${id}`, payload);
export const deleteTypesOfSME = (id) => remove(`${baseURL + url.TYPE_OF_SME}/${id}`);
