import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createTrainingLog = (payload) => post(baseURL + url.TRAINING_LOG, payload);
export const getTrainingLogDetails = (id) => get(`${baseURL + url.TRAINING_LOG}/${id}`);
export const updateTrainingLog = (id, payload) => put(`${baseURL + url.TRAINING_LOG}/${id}`, payload);
export const deleteTrainingLog = (id) => remove(`${baseURL + url.TRAINING_LOG}/${id}`);

export const getTrainingLog__AuditLogs = (payload) => post(`${baseURL + url.ADVANCE_COMMON_SEARCH}/commonSearch`, payload);