import {
	GET_HISTORYS,
	GET_HISTORYS_SUCCESS,
	GET_HISTORYS_FAIL,
	GET_HISTORYS_DETAILS,
	GET_HISTORYS_DETAILS_SUCCESS,
	GET_HISTORYS_DETAILS_FAIL,
	HISTORY_CREATE,
	HISTORY_CREATE_FAIL,
	HISTORY_CREATE_SUCCESS,
	HISTORY_DELETE,
	HISTORY_DELETE_FAIL,
	HISTORY_DELETE_SUCCESS,
	HISTORY_UPDATE,
	HISTORY_UPDATE_FAIL,
	HISTORY_UPDATE_SUCCESS,
	RESET,
  } from "./actionTypes";
  
  const initialState = {
	historyList: [],
	history: {},
	postingResult: {},
	loading: false,
	loadingHistoryDetails: false,
	historySetting: {},
	loadinghistorySetting: false,
	loading: false,
	error: {
	  message: "",
	},
  };
  
  const HistoryReducer = (state = initialState, action) => {
	if (!action) return state;
	switch (action.type) {
	  case GET_HISTORYS:
		state = { ...state, loading: true, historyList: [] };
		break;
	  case GET_HISTORYS_SUCCESS:
		state = { ...state, historyList: action.payload, loading: false };
		break;
	  case GET_HISTORYS_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loading: false,
		};
		break;
	  case GET_HISTORYS_DETAILS:
		state = { ...state, loadingHistoryDetails: true, history: {} };
		break;
	  case GET_HISTORYS_DETAILS_SUCCESS:
		state = { ...state, history: action.payload, loadingHistoryDetails: false };
		break;
	  case GET_HISTORYS_DETAILS_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingHistoryDetails: false,
		};
		break;
	  case HISTORY_CREATE:
		state = { ...state, postingResult: {}, loading: true, history: {} };
		break;
	  case HISTORY_CREATE_SUCCESS:
		state = { ...state, postingResult: action.payload, loading: false };
		break;
	  case HISTORY_CREATE_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  postingResult: {},
		  loading: false,
		};
		break;
		
		case HISTORY_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case HISTORY_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case HISTORY_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case HISTORY_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case HISTORY_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case HISTORY_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
	  case RESET:
		state = { ...state, history: {} };
		break;
	  default:
		state = { ...state };
		break;
	}
	return state;
  };
  
  export default HistoryReducer;