// import * as Actions from "../action/ggAction";

import { uniqueId } from "react-bootstrap-typeahead/types/utils";


const initialState = {
  offset: 9,
  currentCount: 9,
  total: 0,
  appliedList: [],
  ListDetail: [
    {
      id: 0,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 1,
      announcement: 101,
      position: "Bus Driver",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 2,
      announcement: 102,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 3,
      announcement: 103,
      position: "Bus Driver",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 4,
      announcement: 104,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 5,
      announcement: 105,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 6,
      announcement: 106,
      position: "Bus Driver",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 7,
      announcement: 107,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 8,
      announcement: 108,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 9,
      announcement: 109,
      position: "Bus Driver",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 10,
      announcement: 110,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 11,
      announcement: 111,
      position: "Bus Driver",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 12,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 13,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 14,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 15,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 16,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 17,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 18,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 19,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 20,
      announcement: 100,
      position: "Clerk",
      due_date: "22/02/2023",
      type: "open",
      department: "Accounts",
    },
    {
      id: 21,
      announcement: 100,
      position: "Supervisior",
      due_date: "22/02/2023",
      type: "open",
      department: "HR",
    },
  ],
  editData: {},
  filterBy: {
    announcement: "",
    position: "",
    due_date: null,
    type: "",
    department: "",
  },
};

const OnlineRecruitment = (state = initialState, action) => {
	if(!action)
		return state;
  switch (action.type) {
    case 'CHANGE_HEADER_FILTER':
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          [action.payload.data?.target.name]: action.payload.data?.target.value,
        },
      };
    case 'SAVE_DATA':
      const list = state.ListDetail.find((data, index)=> data.id === action.payload.rowIndex);
      const finalAppliedList = {...list, ...action.payload.data, status: action.payload.status,
                                id:uniqueId()}
      return {
        ...state,
        appliedList:[...state.appliedList, finalAppliedList]
      }

    case 'EDIT_DATA':
      return {
        ...state,
        editData: action.payload.data
      }

    case 'UPDATE_DATA':
      const newData = {...state.appliedList.find((app, i)=> app.id === action.payload.rowIndex),
      ...action.payload.data, status: action.payload.status}
      const index = state.appliedList.findIndex((app, i)=> app.id === action.payload.rowIndex)
      state.appliedList.splice(index, 1, newData)
      return {
        ...state,
        editData: {},
      }
    case 'RESET_EDIT_DATA':
      return {
        ...state,
        editData: {}
      }
    case 'GET_MORE_DATA':
      return {
        ...state,
        currentCount: action.payload.currentCount,
        ListDetail: [
           ...state.ListDetail,
           ...initialState.ListDetail.slice(action.payload.start, action.payload.currentCount)]
      }
    default: {
      // return state;
      return {
        ...state,
        // editData: {},
        offset: 9,
        currentCount: 9,
        total: initialState.ListDetail.length,
        ListDetail: initialState.ListDetail.slice(0,initialState.offset)
      }
    }
  }
};

export default OnlineRecruitment;
