import {
	GET_EMPLOYEETYPES, GET_EMPLOYEETYPES_SUCCESS, GET_EMPLOYEETYPES_FAIL,
	GET_EMPLOYEETYPES_DETAILS, GET_EMPLOYEETYPES_DETAILS_SUCCESS, GET_EMPLOYEETYPES_DETAILS_FAIL,
	EMPLOYEETYPE_CREATE,EMPLOYEETYPE_CREATE_FAIL,EMPLOYEETYPE_CREATE_SUCCESS,
	EMPLOYEETYPE_DELETE,EMPLOYEETYPE_DELETE_FAIL,EMPLOYEETYPE_DELETE_SUCCESS,
	EMPLOYEETYPE_UPDATE,EMPLOYEETYPE_UPDATE_FAIL,EMPLOYEETYPE_UPDATE_SUCCESS,RESET, GET_EMPLOYEETYPES_SETTING_FAIL, GET_EMPLOYEETYPES_SETTING_SUCCESS, SAVE_EMPLOYEETYPES_SETTING_FAIL, SAVE_EMPLOYEETYPES_SETTING_SUCCESS, SAVE_EMPLOYEETYPES_SETTING, GET_EMPLOYEETYPES_SETTING, EMPLOYEETYPE_COPY, EMPLOYEETYPE_COPY_SUCCESS, EMPLOYEETYPE_COPY_FAIL, EMPLOYEETYPE_STATUS_CREATE, EMPLOYEETYPE_STATUS_CREATE_SUCCESS, EMPLOYEETYPE_STATUS_CREATE_FAIL, EMPLOYEETYPE_STATUS_UPDATE, EMPLOYEETYPE_STATUS_UPDATE_SUCCESS, EMPLOYEETYPE_STATUS_UPDATE_FAIL, EMPLOYEETYPE_STATUS_DELETE, EMPLOYEETYPE_STATUS_DELETE_SUCCESS, EMPLOYEETYPE_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getEmployeeTypes = (payload) => {
	return {
	  type: GET_EMPLOYEETYPES,
	  payload: payload
	};
  };
  
  export const getEmployeeTypesSuccess = (posts) => {
	return {
	  type: GET_EMPLOYEETYPES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getEmployeeTypesFail = (error) => {
	return {
	  type: GET_EMPLOYEETYPES_FAIL,
	  payload: error,
	};
  };
  
  export const getEmployeeTypesDetails = (id) => {
	return {
	  type: GET_EMPLOYEETYPES_DETAILS,
	  payload: id,
	};
  };
  
  export const getEmployeeTypesDetailsSuccess = (post) => {
	return {
	  type: GET_EMPLOYEETYPES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getEmployeeTypesDetailsFail = (error) => {
	return {
	  type: GET_EMPLOYEETYPES_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createEmployeeType =(payload)=>{
	return {
		type: EMPLOYEETYPE_CREATE,
		payload: payload
	}
  }
  export const createEmployeeTypeSuccess =(response)=>{
	return {
		type: EMPLOYEETYPE_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createEmployeeTypeFail =(error)=>{
	return {
		type: EMPLOYEETYPE_CREATE_FAIL,
		payload: error
	}
  }


  export const updateEmployeeType =(payload)=>{
	
	return {
		type: EMPLOYEETYPE_UPDATE,
		payload: payload
	}
  }
  export const updateEmployeeTypeSuccess =(response)=>{
	return {
		type: EMPLOYEETYPE_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateEmployeeTypeFail =(error)=>{
	return {
		type: EMPLOYEETYPE_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteEmployeeType =(payload)=>{
	return {
		type: EMPLOYEETYPE_DELETE,
		payload: payload
	}
  }
  export const deleteEmployeeTypeSuccess =(response)=>{
	return {
		type: EMPLOYEETYPE_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteEmployeeTypeFail =(error)=>{
	return {
		type: EMPLOYEETYPE_DELETE_FAIL,
		payload: error
	}
  };

  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

