export const TYPE_OF_SME_CREATE = "TYPE_OF_SME_CREATE";
export const TYPE_OF_SME_CREATE_SUCCESS = "TYPE_OF_SME_CREATE_SUCCESS";
export const TYPE_OF_SME_CREATE_FAIL = "TYPE_OF_SME_CREATE_FAIL";

export const GET_TYPE_OF_SME_DETAILS = "GET_TYPE_OF_SME_DETAILS";
export const GET_TYPE_OF_SME_DETAILS_SUCCESS = "GET_TYPE_OF_SME_DETAILS_SUCCESS";
export const GET_TYPE_OF_SME_DETAILS_FAIL = "GET_TYPE_OF_SME_DETAILS_FAIL";

export const GET_TYPE_OF_SMEES = "GET_TYPE_OF_SMEES";
export const GET_TYPE_OF_SMEES_SUCCESS = "GET_TYPE_OF_SMEES_SUCCESS";
export const GET_TYPE_OF_SMEES_FAIL = "GET_TYPE_OF_SMEES_FAIL";

export const UPDATE_TYPE_OF_SME = "UPDATE_TYPE_OF_SME";
export const UPDATE_TYPE_OF_SME_SUCCESS = "UPDATE_TYPE_OF_SME_SUCCESS";
export const UPDATE_TYPE_OF_SME_FAIL = "UPDATE_TYPE_OF_SME_FAIL";

export const DELETE_TYPE_OF_SME = "DELETE_TYPE_OF_SME";
export const DELETE_TYPE_OF_SME_SUCCESS = "DELETE_TYPE_OF_SME_SUCCESS";
export const DELETE_TYPE_OF_SME_FAIL = "DELETE_TYPE_OF_SME_FAIL";

export const RESET = "RESET";
export const RESET_TYPE_OF_SME_CREATE = "RESET_TYPE_OF_SME_CREATE";
export const RESET_TYPE_OF_SME_DETAILS = "RESET_TYPE_OF_SME_DETAILS";
export const RESET_TYPE_OF_SME_DELETE = "RESET_TYPE_OF_SME_DELETE";