import React, { useContext, Fragment, useState, useEffect } from "react";
import { Input } from "reactstrap";
import { Btn, H6, UL } from "../../../../AbstractElements";
import { useSelector } from "react-redux";
import ConfigDB from "../../../../Config/ThemeConfig";
import { Apply, UnlimitedColor } from "../../../../Constant";
import CustomizerContext from "../../../../_helper/Customizer";

const ColorsComponent = () => {
  const { storedTheme, StoredUserConfiguration } = useSelector(
    (state) => state?.ThemeCustomizerReducer
  );
  const { addColor } = useContext(CustomizerContext);
  const [colorBackground1, setColorBackground1] = useState(null);
  const [colorBackground2, setColorBackground2] = useState(null);

  useEffect(() => {
    if (
      (StoredUserConfiguration !== null &&
        Object.keys(StoredUserConfiguration).length > 0) ||
      (storedTheme !== null && Object.keys(storedTheme).length > 0)
    ) {
      const defaultColor1 =
        StoredUserConfiguration?.layoutSetting?.color?.primary_color ||
        storedTheme?.color?.primary_color;
      const defaultColor2 =
        StoredUserConfiguration?.layoutSetting?.color?.secondary_color ||
        storedTheme?.color?.secondary_color;
      setColorBackground1(defaultColor1);
      setColorBackground2(defaultColor2);
    }
  }, [StoredUserConfiguration, storedTheme]);
  useEffect(() => {
    if (colorBackground1) {
      document.documentElement.style.setProperty(
        "--theme-default",
        colorBackground1
      );
      // ConfigDB.data.color.primary_color = colorBackground1;
    }
    if (colorBackground2) {
      document.documentElement.style.setProperty(
        "--theme-secondary",
        colorBackground2
      );
      // ConfigDB.data.color.secondary_color = colorBackground2;
    }
  }, [colorBackground1, colorBackground2]);
  const handleUnlimatedColor1Change = (e) => {
    const { value } = e.target;
    setColorBackground1(value);
    // ConfigDB.data.color.primary_color = value;
  };
  const handleUnlimatedColor2Change = (e) => {
    const { value } = e.target;
    setColorBackground2(value);
    // ConfigDB.data.color.secondary_color = value;
  };
  const OnUnlimatedColorClick = () => {
    addColor(colorBackground1, colorBackground2);
    document.documentElement.style.setProperty(
      "--theme-default",
      colorBackground1
    );
    document.documentElement.style.setProperty(
      "--theme-secondary",
      colorBackground2
    );
    // window.location.reload();
  };

  return (
    <Fragment>
      <H6>{UnlimitedColor}</H6>
      <UL
        attrUL={{
          className: "simple-list flex-row layout-grid unlimited-color-layout",
        }}
      >
        <Input
          type="color"
          name="Color-Background1"
          value={colorBackground1}
          onChange={(e) => handleUnlimatedColor1Change(e)}
        />
        <Input
          type="color"
          name="Color-Background2"
          value={colorBackground2}
          onChange={(e) => handleUnlimatedColor2Change(e)}
        />
        <Btn
          attrBtn={{
            color: "primary",
            className: "color-apply-btn color-apply-btn",
            onClick: OnUnlimatedColorClick,
          }}
        >
          {" "}
          {Apply}
        </Btn>
      </UL>
    </Fragment>
  );
};

export default ColorsComponent;
