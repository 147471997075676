import {
    WAITING_PERIOD_CREATE, WAITING_PERIOD_CREATE_FAIL, WAITING_PERIOD_CREATE_SUCCESS,
    DELETE_WAITING_PERIOD,
    DELETE_WAITING_PERIOD_FAIL,
    DELETE_WAITING_PERIOD_SUCCESS,
    GET_WAITING_PERIOD_DETAILS,
    GET_WAITING_PERIOD_DETAILS_FAIL,
    GET_WAITING_PERIOD_DETAILS_SUCCESS,
    RESET,
    RESET_WAITING_PERIOD_CREATE,
    RESET_WAITING_PERIOD_DELETE,
    RESET_WAITING_PERIOD_DETAILS,
    UPDATE_WAITING_PERIOD,
    UPDATE_WAITING_PERIOD_FAIL,
    UPDATE_WAITING_PERIOD_SUCCESS,
    GET_WAITING_PERIODS,
    GET_WAITING_PERIODS_SUCCESS,
    GET_WAITING_PERIODS_FAIL,
} from "./actionsTypes";

//Create Actions
export const createWaitingPeriod = (payload) => {
    return {
        type: WAITING_PERIOD_CREATE,
        payload: payload
    }
};
export const createWaitingPeriodSuccess = (response) => {
    return {
        type: WAITING_PERIOD_CREATE_SUCCESS,
        payload: response
    }
};
export const createWaitingPeriodFail = (error) => {
    return {
        type: WAITING_PERIOD_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getWaitingPeriodDetails = (id) => {
    return {
        type: GET_WAITING_PERIOD_DETAILS,
        payload: id,
    };
};
export const getWaitingPeriodDetailsSuccess = (data) => {
    return {
        type: GET_WAITING_PERIOD_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getWaitingPeriodDetailsFail = (error) => {
    return {
        type: GET_WAITING_PERIOD_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getWaitingPeriods = () => {
    return {
        type: GET_WAITING_PERIODS,
    };
};
export const getWaitingPeriodsSuccess = (data) => {
    return {
        type: GET_WAITING_PERIODS_SUCCESS,
        payload: data,
    };
};
export const getWaitingPeriodsFail = (error) => {
    return {
        type: GET_WAITING_PERIODS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateWaitingPeriod = (payload) => {

    return {
        type: UPDATE_WAITING_PERIOD,
        payload: payload
    }
};
export const updateWaitingPeriodSuccess = (response) => {
    return {
        type: UPDATE_WAITING_PERIOD_SUCCESS,
        payload: response
    }
};
export const updateWaitingPeriodFail = (error) => {
    return {
        type: UPDATE_WAITING_PERIOD_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteWaitingPeriod = (id) => {
    return {
        type: DELETE_WAITING_PERIOD,
        payload: id
    }
};
export const deleteWaitingPeriodSuccess = (response) => {
    return {
        type: DELETE_WAITING_PERIOD_SUCCESS,
        payload: response
    }
};
export const deleteWaitingPeriodFail = (error) => {
    return {
        type: DELETE_WAITING_PERIOD_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateWaitingPeriod = () => {
    return {
        type: RESET_WAITING_PERIOD_CREATE
    }
};

export const resetWaitingPeriodDetails = () => {
    return {
        type: RESET_WAITING_PERIOD_DETAILS
    }
};

export const resetWaitingPeriodDeleteData = () => {
    return {
        type: RESET_WAITING_PERIOD_DELETE
    }
};