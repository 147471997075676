import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createNatureOfGrievance = (payload) => post(baseURL + url.NATURE_OF_GRIEVANCE, payload);
export const getNatureOfGrievanceDetails = (id) => get(`${baseURL + url.NATURE_OF_GRIEVANCE}/${id}`);
export const getNatureOfGrievancees = () => get(`${baseURL + url.NATURE_OF_GRIEVANCE}`);
export const updateNatureOfGrievance = (id, payload) => put(`${baseURL + url.NATURE_OF_GRIEVANCE}/${id}`, payload);
export const deleteNatureOfGrievance = (id) => remove(`${baseURL + url.NATURE_OF_GRIEVANCE}/${id}`);
