import React from "react";
const CustomLegand = () => {
  const chipData = [
    { key: 0, label: "System Generated", color: "#c2dcff" },
    { key: 1, label: "User Input", color: "#b7dfbb" },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "right",
        marginRight: "5px",
      }}
    >
      {chipData.map((data) => {
        return (
          <div
            key={data.key}
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginLeft: "7px",
            }}
          >
            <div
              style={{
                backgroundColor: data.color,
                width: "15px",
                height: "15px",
                borderRadius: "3px",
              }}
            />
            <div style={{ margin: "3px" }}>{data.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomLegand;
