export const GRIEVANCE_REQUEST_CREATE = "GRIEVANCE_REQUEST_CREATE";
export const GRIEVANCE_REQUEST_CREATE_SUCCESS = "GRIEVANCE_REQUEST_CREATE_SUCCESS";
export const GRIEVANCE_REQUEST_CREATE_FAIL = "GRIEVANCE_REQUEST_CREATE_FAIL";

export const GET_GRIEVANCE_REQUEST_DETAILS = "GET_GRIEVANCE_REQUEST_DETAILS";
export const GET_GRIEVANCE_REQUEST_DETAILS_SUCCESS = "GET_GRIEVANCE_REQUEST_DETAILS_SUCCESS";
export const GET_GRIEVANCE_REQUEST_DETAILS_FAIL = "GET_GRIEVANCE_REQUEST_DETAILS_FAIL";

export const UPDATE_GRIEVANCE_REQUEST = "UPDATE_GRIEVANCE_REQUEST";
export const UPDATE_GRIEVANCE_REQUEST_SUCCESS = "UPDATE_GRIEVANCE_REQUEST_SUCCESS";
export const UPDATE_GRIEVANCE_REQUEST_FAIL = "UPDATE_GRIEVANCE_REQUEST_FAIL";

export const DELETE_GRIEVANCE_REQUEST = "DELETE_GRIEVANCE_REQUEST";
export const DELETE_GRIEVANCE_REQUEST_SUCCESS = "DELETE_GRIEVANCE_REQUEST_SUCCESS";
export const DELETE_GRIEVANCE_REQUEST_FAIL = "DELETE_GRIEVANCE_REQUEST_FAIL";

export const RESET = "RESET";
export const RESET_GRIEVANCE_REQUEST_CREATE = "RESET_GRIEVANCE_REQUEST_CREATE";
export const RESET_GRIEVANCE_REQUEST_DETAILS = "RESET_GRIEVANCE_REQUEST_DETAILS";
export const RESET_GRIEVANCE_REQUEST_DELETE = "RESET_GRIEVANCE_REQUEST_DELETE";