import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { TYPE_OF_SME_CREATE, DELETE_TYPE_OF_SME, GET_TYPE_OF_SMEES, GET_TYPE_OF_SME_DETAILS, RESET, RESET_TYPE_OF_SME_CREATE, UPDATE_TYPE_OF_SME } from "./actionsTypes";

import {
    createTypesOfSMESuccess,
    createTypesOfSMEFail,
    reset,
    resetCreateTypesOfSME,
    getTypesOfSMEDetailsSuccess,
    getTypesOfSMEDetailsFail,
    updateTypesOfSMESuccess,
    deleteTypesOfSMESuccess,
    deleteTypesOfSMEFail,
    updateTypesOfSMEFail,
    getTypesOfSMEesSuccess,
    getTypesOfSMEesFail,
} from "./action";

import {
    createTypesOfSME, deleteTypesOfSME, getTypesOfSMEDetails, getTypesOfSMEes, updateTypesOfSME
} from "../../../../_helper/services/EMR/SMEList";


function* onCreateTypesOfSME({ payload }) {
    try {
        const response = yield call(createTypesOfSME, payload);
        yield put(createTypesOfSMESuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTypesOfSMEFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTypesOfSME());
    }
}

function* onGetTypesOfSMEDetails({ payload: id }) {
    try {
        const response = yield call(getTypesOfSMEDetails, id);
        yield put(getTypesOfSMEDetailsSuccess(response));
    } catch (error) {
        yield put(getTypesOfSMEDetailsFail(error.response));
    }
}

function* onGetTypesOfSMEes() {
    try {
        const response = yield call(getTypesOfSMEes);
        yield put(getTypesOfSMEesSuccess(response));
    } catch (error) {
        yield put(getTypesOfSMEesFail(error.response));
    }
}

function* onUpdateTypesOfSME({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTypesOfSME, id, payload);
        yield put(updateTypesOfSMESuccess(response));
        yield put(getTypesOfSMEDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTypesOfSMEFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTypesOfSME({ payload: id }) {
    try {
        const response = yield call(deleteTypesOfSME, id);
        yield put(deleteTypesOfSMESuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTypesOfSMEFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TypesOfSMESaga() {
    yield takeLatest(TYPE_OF_SME_CREATE, onCreateTypesOfSME);
    yield takeLatest(GET_TYPE_OF_SME_DETAILS, onGetTypesOfSMEDetails);
    yield takeLatest(GET_TYPE_OF_SMEES, onGetTypesOfSMEes);
    yield takeLatest(UPDATE_TYPE_OF_SME, onUpdateTypesOfSME);
    yield takeLatest(DELETE_TYPE_OF_SME, onDeleteTypesOfSME);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_TYPE_OF_SME_CREATE, resetCreateTypesOfSME);
}

export default TypesOfSMESaga;