import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { CBA_STATUS_CREATE, DELETE_CBA_STATUS, GET_CBA_STATUSES, GET_CBA_STATUS_DETAILS, RESET, RESET_CBA_STATUS_CREATE, UPDATE_CBA_STATUS } from "./actionsTypes";

import {
    createCBAStatusSuccess,
    createCBAStatusFail,
    reset,
    resetCreateCBAStatus,
    getCBAStatusDetailsSuccess,
    getCBAStatusDetailsFail,
    updateCBAStatusSuccess,
    deleteCBAStatusSuccess,
    deleteCBAStatusFail,
    updateCBAStatusFail,
    getCBAStatusesSuccess,
    getCBAStatusesFail,
} from "./action";

import {
    createCBAStatus, deleteCBAStatus, getCBAStatusDetails, getCBAStatuses, updateCBAStatus
} from "../../../../_helper/services/EMR/CBAStatus";


function* onCreateCBAStatus({ payload }) {
    try {
        const response = yield call(createCBAStatus, payload);
        yield put(createCBAStatusSuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createCBAStatusFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateCBAStatus());
    }
}

function* onGetCBAStatusDetails({ payload: id }) {
    try {
        const response = yield call(getCBAStatusDetails, id);
        yield put(getCBAStatusDetailsSuccess(response));
    } catch (error) {
        yield put(getCBAStatusDetailsFail(error.response));
    }
}

function* onGetCBAStatuses() {
    try {
        const response = yield call(getCBAStatuses);
        yield put(getCBAStatusesSuccess(response));
    } catch (error) {
        yield put(getCBAStatusesFail(error.response));
    }
}

function* onUpdateCBAStatus({ payload: { id }, payload }) {
    try {
        const response = yield call(updateCBAStatus, id, payload);
        yield put(updateCBAStatusSuccess(response));
        yield put(getCBAStatusDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateCBAStatusFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteCBAStatus({ payload: id }) {
    try {
        const response = yield call(deleteCBAStatus, id);
        yield put(deleteCBAStatusSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteCBAStatusFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* CBAStatusSaga() {
    yield takeLatest(CBA_STATUS_CREATE, onCreateCBAStatus);
    yield takeLatest(GET_CBA_STATUS_DETAILS, onGetCBAStatusDetails);
    yield takeLatest(GET_CBA_STATUSES, onGetCBAStatuses);
    yield takeLatest(UPDATE_CBA_STATUS, onUpdateCBAStatus);
    yield takeLatest(DELETE_CBA_STATUS, onDeleteCBAStatus);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_CBA_STATUS_CREATE, resetCreateCBAStatus);
}

export default CBAStatusSaga;