import {
	GET_EMPLOYEETYPES, GET_EMPLOYEETYPES_SUCCESS, GET_EMPLOYEETYPES_FAIL,
	GET_EMPLOYEETYPES_DETAILS, GET_EMPLOYEETYPES_DETAILS_SUCCESS, GET_EMPLOYEETYPES_DETAILS_FAIL,
	EMPLOYEETYPE_CREATE, EMPLOYEETYPE_CREATE_FAIL, EMPLOYEETYPE_CREATE_SUCCESS,
	EMPLOYEETYPE_DELETE, EMPLOYEETYPE_DELETE_FAIL, EMPLOYEETYPE_DELETE_SUCCESS,
	EMPLOYEETYPE_UPDATE, EMPLOYEETYPE_UPDATE_FAIL, EMPLOYEETYPE_UPDATE_SUCCESS,RESET
} from "./actionTypes";

const initialState = {
	employeeTypeList: [],
	employeeType: {},
	postingResult: {},
	loading: false,
	loadingEmployeeTypeDetails: false,
	employeeTypeSetting:{},
	loadingemployeeTypeSetting: false,
	loading: false,
	error: {
		message: "",
	},
	
};

const EmployeeTypeReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_EMPLOYEETYPES:
			state = { ...state, loading: false };
			break;
		case GET_EMPLOYEETYPES_SUCCESS:
			state = { ...state, employeeTypeList: action.payload, loading: false };
			break;
		case GET_EMPLOYEETYPES_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_EMPLOYEETYPES_DETAILS:
			state = { ...state, loadingEmployeeTypeDetails: true };
			break;
		case GET_EMPLOYEETYPES_DETAILS_SUCCESS:
			state = { ...state, employeeType: action.payload, loadingEmployeeTypeDetails: false };
			break;
		case GET_EMPLOYEETYPES_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingEmployeeTypeDetails: false,
			};
			break;
		case EMPLOYEETYPE_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case EMPLOYEETYPE_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false,  };
			break;
		case EMPLOYEETYPE_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case EMPLOYEETYPE_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case EMPLOYEETYPE_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case EMPLOYEETYPE_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case EMPLOYEETYPE_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case EMPLOYEETYPE_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case EMPLOYEETYPE_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:
		state = { ...state, employeeType: {} };
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default EmployeeTypeReducer;