import {
    PE_RATING_CREATE,
    PE_RATING_CREATE_FAIL,
    PE_RATING_CREATE_SUCCESS,
    DELETE_PE_RATING,
    DELETE_PE_RATING_FAIL,
    DELETE_PE_RATING_SUCCESS,
    GET_PE_RATINGES,
    GET_PE_RATINGES_FAIL,
    GET_PE_RATINGES_SUCCESS,
    GET_PE_RATING_DETAILS,
    GET_PE_RATING_DETAILS_FAIL,
    GET_PE_RATING_DETAILS_SUCCESS,
    RESET,
    RESET_PE_RATING_CREATE,
    RESET_PE_RATING_DELETE,
    RESET_PE_RATING_DETAILS,
    UPDATE_PE_RATING,
    UPDATE_PE_RATING_FAIL,
    UPDATE_PE_RATING_SUCCESS,
} from "./actionsTypes";

const initialState = {
    PERatingList: [],
    PERatingDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const PERatingReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case PE_RATING_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case PE_RATING_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case PE_RATING_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_PE_RATINGES:
            state = { ...state, loading: true };
            break;
        case GET_PE_RATINGES_SUCCESS:
            state = { ...state, PERatingList: action.payload, loading: false };
            break;
        case GET_PE_RATINGES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_PE_RATING_DETAILS:
            state = { ...state, detailsLoading: true, PERatingDetails: {} };
            break;
        case GET_PE_RATING_DETAILS_SUCCESS:
            state = { ...state, PERatingDetails: action.payload, detailsLoading: false };
            break;
        case GET_PE_RATING_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_PE_RATING:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_PE_RATING_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_PE_RATING_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_PE_RATING:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_PE_RATING_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_PE_RATING_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_PE_RATING_DETAILS:
            state = { ...state, PERatingDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, PERatingList: [], createResult: {} };
            break;
        case RESET_PE_RATING_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_PE_RATING_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default PERatingReducer;