import {
	GET_POSITIONS, GET_POSITIONS_SUCCESS, GET_POSITIONS_FAIL,
	GET_POSITIONS_DETAILS, GET_POSITIONS_DETAILS_SUCCESS, GET_POSITIONS_DETAILS_FAIL,
	POSITION_CREATE,POSITION_CREATE_FAIL,POSITION_CREATE_SUCCESS,
	POSITION_DELETE,POSITION_DELETE_FAIL,POSITION_DELETE_SUCCESS,
	POSITION_UPDATE,POSITION_UPDATE_FAIL,POSITION_UPDATE_SUCCESS,RESET, GET_POSITIONS_SETTING_FAIL, GET_POSITIONS_SETTING_SUCCESS, SAVE_POSITIONS_SETTING_FAIL, SAVE_POSITIONS_SETTING_SUCCESS, SAVE_POSITIONS_SETTING, GET_POSITIONS_SETTING, POSITION_COPY, POSITION_COPY_SUCCESS, POSITION_COPY_FAIL, POSITION_STATUS_CREATE, POSITION_STATUS_CREATE_SUCCESS, POSITION_STATUS_CREATE_FAIL, POSITION_STATUS_UPDATE, POSITION_STATUS_UPDATE_SUCCESS, POSITION_STATUS_UPDATE_FAIL, POSITION_STATUS_DELETE, POSITION_STATUS_DELETE_SUCCESS, POSITION_STATUS_DELETE_FAIL, GET_LOOKUP_POSITIONS, GET_LOOKUP_POSITIONS_SUCCESS, GET_LOOKUP_POSITIONS_FAIL
  } from "./actionTypes";

  export const getPositions = (payload) => {
	return {
	  type: GET_POSITIONS,
	  payload: payload
	};
  };
  
  export const getPositionsSuccess = (posts) => {
	return {
	  type: GET_POSITIONS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPositionsFail = (error) => {
	return {
	  type: GET_POSITIONS_FAIL,
	  payload: error,
	};
  };

  export const getLookupPositions = () => {
	return {
	  type: GET_LOOKUP_POSITIONS
	};
  };
  
  export const getLookupPositionsSuccess = (posts) => {
	return {
	  type: GET_LOOKUP_POSITIONS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getLookupPositionsFail = (error) => {
	return {
	  type: GET_LOOKUP_POSITIONS_FAIL,
	  payload: error,
	};
  };
  
  export const getPositionsDetails = (id) => {
	return {
	  type: GET_POSITIONS_DETAILS,
	  payload: id,
	};
  };
  
  export const getPositionsDetailsSuccess = (post) => {
	return {
	  type: GET_POSITIONS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPositionsDetailsFail = (error) => {
	return {
	  type: GET_POSITIONS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPosition =(payload)=>{
	return {
		type: POSITION_CREATE,
		payload: payload
	}
  }
  export const createPositionSuccess =(response)=>{
	return {
		type: POSITION_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPositionFail =(error)=>{
	return {
		type: POSITION_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePosition =(payload)=>{
	
	return {
		type: POSITION_UPDATE,
		payload: payload
	}
  }
  export const updatePositionSuccess =(response)=>{
	return {
		type: POSITION_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePositionFail =(error)=>{
	return {
		type: POSITION_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePosition =(payload)=>{
	return {
		type: POSITION_DELETE,
		payload: payload
	}
  }
  export const deletePositionSuccess =(response)=>{
	return {
		type: POSITION_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePositionFail =(error)=>{
	return {
		type: POSITION_DELETE_FAIL,
		payload: error
	}
  }

  export const reset=()=>{
	return {
		type:RESET,
	}
  }

