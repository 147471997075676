export const WAITING_PERIOD_CREATE = "WAITING_PERIOD_CREATE";
export const WAITING_PERIOD_CREATE_SUCCESS = "WAITING_PERIOD_CREATE_SUCCESS";
export const WAITING_PERIOD_CREATE_FAIL = "WAITING_PERIOD_CREATE_FAIL";

export const GET_WAITING_PERIOD_DETAILS = "GET_WAITING_PERIOD_DETAILS";
export const GET_WAITING_PERIOD_DETAILS_SUCCESS = "GET_WAITING_PERIOD_DETAILS_SUCCESS";
export const GET_WAITING_PERIOD_DETAILS_FAIL = "GET_WAITING_PERIOD_DETAILS_FAIL";

export const GET_WAITING_PERIODS = "GET_WAITING_PERIODS";
export const GET_WAITING_PERIODS_SUCCESS = "GET_WAITING_PERIODS_SUCCESS";
export const GET_WAITING_PERIODS_FAIL = "GET_WAITING_PERIODS_FAIL";

export const UPDATE_WAITING_PERIOD = "UPDATE_WAITING_PERIOD";
export const UPDATE_WAITING_PERIOD_SUCCESS = "UPDATE_WAITING_PERIOD_SUCCESS";
export const UPDATE_WAITING_PERIOD_FAIL = "UPDATE_WAITING_PERIOD_FAIL";

export const DELETE_WAITING_PERIOD = "DELETE_WAITING_PERIOD";
export const DELETE_WAITING_PERIOD_SUCCESS = "DELETE_WAITING_PERIOD_SUCCESS";
export const DELETE_WAITING_PERIOD_FAIL = "DELETE_WAITING_PERIOD_FAIL";

export const RESET = "RESET";
export const RESET_WAITING_PERIOD_CREATE = "RESET_WAITING_PERIOD_CREATE";
export const RESET_WAITING_PERIOD_DETAILS = "RESET_WAITING_PERIOD_DETAILS";
export const RESET_WAITING_PERIOD_DELETE = "RESET_WAITING_PERIOD_DELETE";