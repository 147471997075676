export const GET_DT_TEST_TYPES = "GET_DT_TEST_TYPES";
export const GET_DT_TEST_TYPES_SUCCESS = "GET_DT_TEST_TYPES_SUCCESS";
export const GET_DT_TEST_TYPES_FAIL = "GET_DT_TEST_TYPES_FAIL";

export const GET_DT_RESULTS = "GET_DT_RESULTS";
export const GET_DT_RESULTS_SUCCESS = "GET_DT_RESULTS_SUCCESS";
export const GET_DT_RESULTS_FAIL = "GET_DT_RESULTS_FAIL";

export const GET_DT_RESULT_DETAILS = "GET_DT_RESULT_DETAILS";
export const GET_DT_RESULT_DETAILS_SUCCESS = "GET_DT_RESULT_DETAILS_SUCCESS";
export const GET_DT_RESULT_DETAILS_FAIL = "GET_DT_RESULT_DETAILS_FAIL";

export const GET_DT_TYPE_OF_COLLECTION = "GET_DT_TYPE_OF_COLLECTION";
export const GET_DT_TYPE_OF_COLLECTION_SUCCESS = "GET_DT_TYPE_OF_COLLECTION_SUCCESS";
export const GET_DT_TYPE_OF_COLLECTION_FAIL = "GET_DT_TYPE_OF_COLLECTION_FAIL";

export const GET_DT_NATURE_OF_ACTIONS = "GET_DT_NATURE_OF_ACTIONS";
export const GET_DT_NATURE_OF_ACTIONS_SUCCESS = "GET_DT_NATURE_OF_ACTIONS_SUCCESS";
export const GET_DT_NATURE_OF_ACTIONS_FAIL = "GET_DT_NATURE_OF_ACTIONS_FAIL";

export const GET_DT_RELEASED_TO = "GET_DT_RELEASED_TO";
export const GET_DT_RELEASED_TO_SUCCESS = "GET_DT_RELEASED_TO_SUCCESS";
export const GET_DT_RELEASED_TO_FAIL = "GET_DT_RELEASED_TO_FAIL";

export const GET_DT_FUNDING_STATUSES = "GET_DT_FUNDING_STATUSES";
export const GET_DT_FUNDING_STATUSES_SUCCESS = "GET_DT_FUNDING_STATUSES_SUCCESS";
export const GET_DT_FUNDING_STATUSES_FAIL = "GET_DT_FUNDING_STATUSES_FAIL";

export const GET_DT_RANDOM_REASONS = "GET_DT_RANDOM_REASONS";
export const GET_DT_RANDOM_REASONS_SUCCESS = "GET_DT_RANDOM_REASONS_SUCCESS";
export const GET_DT_RANDOM_REASONS_FAIL = "GET_DT_RANDOM_REASONS_FAIL";

export const GET_TDP_TYPES = "GET_TDP_TYPES";
export const GET_TDP_TYPES_SUCCESS = "GET_TDP_TYPES_SUCCESS";
export const GET_TDP_TYPES_FAIL = "GET_TDP_TYPES_FAIL";

export const GET_QUARTER_REPORT_DATES = "GET_QUARTER_REPORT_DATES";
export const GET_QUARTER_REPORT_DATES_SUCCESS = "GET_QUARTER_REPORT_DATES_SUCCESS";
export const GET_QUARTER_REPORT_DATES_FAIL = "GET_QUARTER_REPORT_DATES_FAIL";

export const GET_VENDOR_LOG_STATUS_LISTS = "GET_VENDOR_LOG_STATUS_LISTS";
export const GET_VENDOR_LOG_STATUS_LISTS_SUCCESS = "GET_VENDOR_LOG_STATUS_LISTS_SUCCESS";
export const GET_VENDOR_LOG_STATUS_LISTS_FAIL = "GET_VENDOR_LOG_STATUS_LISTS_FAIL";

export const RESET = "RESET";

