import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_DEDUCTIONTYPES, GET_DEDUCTIONTYPES_DETAILS,  DEDUCTIONTYPE_CREATE, DEDUCTIONTYPE_DELETE,
  GET_ALL_DEDUCTIONTYPE_DETAILS,
   DEDUCTIONTYPE_UPDATE} from "./actionTypes";

import {
  getDeductiontypesSuccess,
  getDeductiontypesFail,
  getDeductiontypesDetailsSuccess,
  getDeductiontypesDetailsFail,
  createDeductiontypeSuccess,
  createDeductiontypeFail,
  updateDeductiontypeSuccess,
  updateDeductiontypeFail,
  deleteDeductiontypeSuccess,
  deleteDeductiontypeFail,
  getAllDeductionTypeDetailsSuccess,
  getAllDeductionTypeDetailsFail,
} from "./actions";

import {  createDeductiontype,deleteDeductiontype,
	getDeductiontypeDetails,getDeductiontypes
	,updateDeductiontype,
  getAllDeductiontypeDetails
 } from "../../_helper/services/deductiontypes";
function* onGetDeductiontypes({payload: {page,pageSize}}) {
  try {
    const response = yield call(getDeductiontypes,{page,pageSize});
    yield put(getDeductiontypesSuccess(response));
  } catch (error) {
    yield put(getDeductiontypesFail(error.response));
  }
}

function* onGetDeductiontypeDetails({ payload: code }) {
  try {
    const response = yield call(getDeductiontypeDetails, code);
    yield put(getDeductiontypesDetailsSuccess(response));
  } catch (error) {
    yield put(getDeductiontypesDetailsFail(error.response));
  }
}
function* onCreateDeductiontype({ payload }) {
	try {
	  const response = yield call(createDeductiontype, payload);
	  yield put(createDeductiontypeSuccess(response));
    ShowSucess('Deductiontype Created successfully', {
      position: 'top-right'
    });
    
	} catch (error) {
	  yield put(createDeductiontypeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdateDeductiontype({ payload: { code},payload }) {
	try {
	  const response = yield call(updateDeductiontype, code, payload);
	  yield put(updateDeductiontypeSuccess(response));
    ShowSucess('Deductiontype Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateDeductiontypeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteDeductiontype({ payload: code }) {
	try {
	  const response = yield call(deleteDeductiontype, code);
	  yield put(deleteDeductiontypeSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteDeductiontypeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

  function* onGetAllDeductiontypeDetails() {
    try {
      const response = yield call(getAllDeductiontypeDetails, );
      yield put(getAllDeductionTypeDetailsSuccess(response));
    } catch (error) {
      yield put(getAllDeductionTypeDetailsFail(error.response));
    }
  }

function* CartSaga() {
  yield takeLatest(GET_DEDUCTIONTYPES, onGetDeductiontypes);
  yield takeLatest(GET_DEDUCTIONTYPES_DETAILS, onGetDeductiontypeDetails);
  yield takeLatest(DEDUCTIONTYPE_CREATE, onCreateDeductiontype);
  yield takeLatest(DEDUCTIONTYPE_UPDATE, onUpdateDeductiontype);
  yield takeLatest(DEDUCTIONTYPE_DELETE, onDeleteDeductiontype);
  yield takeLatest(GET_ALL_DEDUCTIONTYPE_DETAILS, onGetAllDeductiontypeDetails);
}

export default CartSaga;