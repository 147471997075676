import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_DOA_HR_JV_INTERNAL_LOG, DOA_HR_JV_INTERNAL_LOG_CREATE, GET_DOA_HR_JV_INTERNAL_LOG_DETAILS, RESET, RESET_DOA_HR_JV_INTERNAL_LOG_CREATE, UPDATE_DOA_HR_JV_INTERNAL_LOG } from "./actionsTypes";

import {
    createDOAHRJVInternalLogSuccess,
    createDOAHRJVInternalLogFail,
    resetCreateDOAHRJVInternalLog,
    reset,
    getDOAHRJVInternalLogDetailsSuccess,
    getDOAHRJVInternalLogDetailsFail,
    updateDOAHRJVInternalLogSuccess,
    updateDOAHRJVInternalLogFail,
    deleteDOAHRJVInternalLogSuccess,
    deleteDOAHRJVInternalLogFail,
} from "./action";

import {
    createDOAHRJVInternalLog, deleteDOAHRJVInternalLog, getDOAHRJVInternalLogDetails, updateDOAHRJVInternalLog
} from "../../../_helper/services/DrugTesting/DOA-HRJVInternalLog";


function* onCreateDOAHRJVInternalLog({ payload }) {
    try {
        const response = yield call(createDOAHRJVInternalLog, payload);
        yield put(createDOAHRJVInternalLogSuccess(response));
        ShowSucess("DOA-HR JV Internal Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createDOAHRJVInternalLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateDOAHRJVInternalLog());
    }
}

function* onGetDOAHRJVInternalLogDetails({ payload: id }) {
    try {
        const response = yield call(getDOAHRJVInternalLogDetails, id);
        yield put(getDOAHRJVInternalLogDetailsSuccess(response));
    } catch (error) {
        yield put(getDOAHRJVInternalLogDetailsFail(error.response));
    }
}
function* onUpdateDOAHRJVInternalLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateDOAHRJVInternalLog, id, payload);
        yield put(updateDOAHRJVInternalLogSuccess(response));
        yield put(getDOAHRJVInternalLogDetailsSuccess(payload));
        ShowSucess(`DOA-HR JV Internal Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateDOAHRJVInternalLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteDOAHRJVInternalLog({ payload: id }) {
    try {
        const response = yield call(deleteDOAHRJVInternalLog, id);
        yield put(deleteDOAHRJVInternalLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteDOAHRJVInternalLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* DOAHRJVInternalLogSaga() {
    yield takeLatest(DOA_HR_JV_INTERNAL_LOG_CREATE, onCreateDOAHRJVInternalLog);
    yield takeLatest(RESET_DOA_HR_JV_INTERNAL_LOG_CREATE, resetCreateDOAHRJVInternalLog);
    yield takeLatest(GET_DOA_HR_JV_INTERNAL_LOG_DETAILS, onGetDOAHRJVInternalLogDetails);
    yield takeLatest(UPDATE_DOA_HR_JV_INTERNAL_LOG, onUpdateDOAHRJVInternalLog);
    yield takeLatest(DELETE_DOA_HR_JV_INTERNAL_LOG, onDeleteDOAHRJVInternalLog);
    yield takeLatest(RESET, reset);
}

export default DOAHRJVInternalLogSaga;