import {
    EVAL_APPEAL_CREATE,
    EVAL_APPEAL_CREATE_FAIL,
    EVAL_APPEAL_CREATE_SUCCESS,
    DELETE_EVAL_APPEAL,
    DELETE_EVAL_APPEAL_FAIL,
    DELETE_EVAL_APPEAL_SUCCESS,
    GET_EVAL_APPEAL_DETAILS,
    GET_EVAL_APPEAL_DETAILS_FAIL,
    GET_EVAL_APPEAL_DETAILS_SUCCESS,
    RESET,
    RESET_EVAL_APPEAL_CREATE,
    RESET_EVAL_APPEAL_DELETE,
    RESET_EVAL_APPEAL_DETAILS,
    UPDATE_EVAL_APPEAL,
    UPDATE_EVAL_APPEAL_FAIL,
    UPDATE_EVAL_APPEAL_SUCCESS,
} from "./actionsTypes";

const initialState = {
    evalAppealList: [],
    evalAppealDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const EvalAppealReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case EVAL_APPEAL_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case EVAL_APPEAL_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case EVAL_APPEAL_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_EVAL_APPEAL_DETAILS:
            state = { ...state, detailsLoading: true, evalAppealDetails: {} };
            break;
        case GET_EVAL_APPEAL_DETAILS_SUCCESS:
            state = { ...state, evalAppealDetails: action.payload, detailsLoading: false };
            break;
        case GET_EVAL_APPEAL_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_EVAL_APPEAL:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_EVAL_APPEAL_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_EVAL_APPEAL_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_EVAL_APPEAL:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_EVAL_APPEAL_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_EVAL_APPEAL_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_EVAL_APPEAL_DETAILS:
            state = { ...state, evalAppealDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, evalAppealList: [], createResult: {} };
            break;
        case RESET_EVAL_APPEAL_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_EVAL_APPEAL_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default EvalAppealReducer;