import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createMediaSource = (payload) => post(baseURL + url.MEDIA_SOURCE, payload);
export const getMediaSourceDetails = (id) => get(`${baseURL + url.MEDIA_SOURCE}/${id}`);
export const getMediaSources = () => get(`${baseURL + url.MEDIA_SOURCE}`);
export const updateMediaSource = (id, payload) => put(`${baseURL + url.MEDIA_SOURCE}/${id}`, payload);
export const deleteMediaSource = (id) => remove(`${baseURL + url.MEDIA_SOURCE}/${id}`);
