import {
    VENDOR_LOG_CREATE, VENDOR_LOG_CREATE_FAIL, VENDOR_LOG_CREATE_SUCCESS,
    RESET,
    RESET_VENDOR_LOG_CREATE,
    GET_VENDOR_LOG_DETAILS,
    GET_VENDOR_LOG_DETAILS_SUCCESS,
    GET_VENDOR_LOG_DETAILS_FAIL,
    UPDATE_VENDOR_LOG,
    UPDATE_VENDOR_LOG_SUCCESS,
    UPDATE_VENDOR_LOG_FAIL,
    DELETE_VENDOR_LOG,
    DELETE_VENDOR_LOG_SUCCESS,
    DELETE_VENDOR_LOG_FAIL,
    RESET_VENDOR_LOG_DETAILS,
} from "./actionsTypes";

//Create Actions
export const createVendorLog = (payload) => {
    return {
        type: VENDOR_LOG_CREATE,
        payload: payload
    }
};
export const createVendorLogSuccess = (response) => {
    return {
        type: VENDOR_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createVendorLogFail = (error) => {
    return {
        type: VENDOR_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getVendorLogDetails = (id) => {
    return {
        type: GET_VENDOR_LOG_DETAILS,
        payload: id,
    };
};
export const getVendorLogDetailsSuccess = (data) => {
    return {
        type: GET_VENDOR_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getVendorLogDetailsFail = (error) => {
    return {
        type: GET_VENDOR_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateVendorLog = (payload) => {
    return {
        type: UPDATE_VENDOR_LOG,
        payload: payload
    }
};
export const updateVendorLogSuccess = (response) => {
    return {
        type: UPDATE_VENDOR_LOG_SUCCESS,
        payload: response
    }
};
export const updateVendorLogFail = (error) => {
    return {
        type: UPDATE_VENDOR_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteVendorLog = (id) => {
    return {
        type: DELETE_VENDOR_LOG,
        payload: id
    }
};
export const deleteVendorLogSuccess = (response) => {
    return {
        type: DELETE_VENDOR_LOG_SUCCESS,
        payload: response
    }
};
export const deleteVendorLogFail = (error) => {
    return {
        type: DELETE_VENDOR_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateVendorLog = () => {
    return {
        type: RESET_VENDOR_LOG_CREATE
    }
};
export const resetVendorLogDetails = () => {
    return {
        type: RESET_VENDOR_LOG_DETAILS,
    };
};