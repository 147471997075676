// export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
// export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
// export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL";

// export const GET_DEPARTMENTS_DETAILS = "GET_DEPARTMENTS_DETAILS";
// export const GET_DEPARTMENTS_DETAILS_SUCCESS = "GET_DEPARTMENTS_DETAILS_SUCCESS";
// export const GET_DEPARTMENTS_DETAILS_FAIL = "GET_DEPARTMENTS_DETAILS_FAIL";

// export const DEPARTMENT_UPDATE= "DEPARTMENT_UPDATE";
// export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
// export const DEPARTMENT_UPDATE_FAIL = "DEPARTMENT_UPDATE_FAIL";

// export const DEPARTMENT_CREATE= "DEPARTMENT_CREATE";
// export const DEPARTMENT_CREATE_SUCCESS = "DEPARTMENT_CREATE_SUCCESS";
// export const DEPARTMENT_CREATE_FAIL = "DEPARTMENT_CREATE_FAIL";

// export const DEPARTMENT_DELETE= "DEPARTMENT_DELETE";
// export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
// export const DEPARTMENT_DELETE_FAIL = "DEPARTMENT_DELETE_FAIL";


export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL";

export const GET_DEPARTMENTS_DETAILS = "GET_DEPARTMENTS_DETAILS";
export const GET_DEPARTMENTS_DETAILS_SUCCESS = "GET_DEPARTMENTS_DETAILS_SUCCESS";
export const GET_DEPARTMENTS_DETAILS_FAIL = "GET_DEPARTMENTS_DETAILS_FAIL";

export const DEPARTMENT_UPDATE = "DEPARTMENT_UPDATE";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAIL = "DEPARTMENT_UPDATE_FAIL";

export const DEPARTMENT_CREATE = "DEPARTMENT_CREATE";
export const DEPARTMENT_CREATE_SUCCESS = "DEPARTMENT_CREATE_SUCCESS";
export const DEPARTMENT_CREATE_FAIL = "DEPARTMENT_CREATE_FAIL";

export const DEPARTMENT_DELETE = "DEPARTMENT_DELETE";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAIL = "DEPARTMENT_DELETE_FAIL";

export const RESET = "RESET";
