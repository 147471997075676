import {
	GET_TAGMASTERS, GET_TAGMASTERS_SUCCESS, GET_TAGMASTERS_FAIL,
	GET_TAGMASTERS_DETAILS, GET_TAGMASTERS_DETAILS_SUCCESS, GET_TAGMASTERS_DETAILS_FAIL,
	TAGMASTER_CREATE, TAGMASTER_CREATE_FAIL, TAGMASTER_CREATE_SUCCESS,
	TAGMASTER_DELETE, TAGMASTER_DELETE_FAIL, TAGMASTER_DELETE_SUCCESS,
	TAGMASTER_UPDATE, TAGMASTER_UPDATE_FAIL, TAGMASTER_UPDATE_SUCCESS,RESET,
} from "./actionTypes";

const initialState = {
	tagmasterList: [],
	tagmaster: {},
	postingResult: {},
	loading: false,
	loadingTagmasterDetails: false,
	tagmasterSetting:{},
	loadingtagmasterSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const TagmasterReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_TAGMASTERS:
			state = { ...state, loading: false };
			break;
		case GET_TAGMASTERS_SUCCESS:
			state = { ...state, tagmasterList: action.payload, loading: false };
			break;
		case GET_TAGMASTERS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_TAGMASTERS_DETAILS:
			state = { ...state, loadingTagmasterDetails: true };
			break;
		case GET_TAGMASTERS_DETAILS_SUCCESS:
			state = { ...state, tagmaster: action.payload, loadingTagmasterDetails: false };
			break;
		case GET_TAGMASTERS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingTagmasterDetails: false,
			};
			break;
		case TAGMASTER_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			console.log(state.postingResult);
			break;
		case TAGMASTER_CREATE_SUCCESS:
			console.log(action);
			state = { ...state, postingResult: action.payload, loading: false };
			console.log(state.postingResult);
			break;
		case TAGMASTER_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case TAGMASTER_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case TAGMASTER_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case TAGMASTER_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case TAGMASTER_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case TAGMASTER_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case TAGMASTER_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:
		state = { ...state, tagmaster: {} }
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default TagmasterReducer;