import {
    NATURE_OF_GRIEVANCE_CREATE,
    NATURE_OF_GRIEVANCE_CREATE_FAIL,
    NATURE_OF_GRIEVANCE_CREATE_SUCCESS,
    DELETE_NATURE_OF_GRIEVANCE,
    DELETE_NATURE_OF_GRIEVANCE_FAIL,
    DELETE_NATURE_OF_GRIEVANCE_SUCCESS,
    GET_NATURE_OF_GRIEVANCEES,
    GET_NATURE_OF_GRIEVANCEES_FAIL,
    GET_NATURE_OF_GRIEVANCEES_SUCCESS,
    GET_NATURE_OF_GRIEVANCE_DETAILS,
    GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL,
    GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS,
    RESET,
    RESET_NATURE_OF_GRIEVANCE_CREATE,
    RESET_NATURE_OF_GRIEVANCE_DELETE,
    RESET_NATURE_OF_GRIEVANCE_DETAILS,
    UPDATE_NATURE_OF_GRIEVANCE,
    UPDATE_NATURE_OF_GRIEVANCE_FAIL,
    UPDATE_NATURE_OF_GRIEVANCE_SUCCESS,
} from "./actionsTypes";

const initialState = {
    natureOfGrievanceList: [],
    natureOfGrievanceDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const NatureOfGrievanceReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case NATURE_OF_GRIEVANCE_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case NATURE_OF_GRIEVANCE_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case NATURE_OF_GRIEVANCE_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_NATURE_OF_GRIEVANCEES:
            state = { ...state, loading: true };
            break;
        case GET_NATURE_OF_GRIEVANCEES_SUCCESS:
            state = { ...state, natureOfGrievanceList: action.payload, loading: false };
            break;
        case GET_NATURE_OF_GRIEVANCEES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_NATURE_OF_GRIEVANCE_DETAILS:
            state = { ...state, detailsLoading: true, natureOfGrievanceDetails: {} };
            break;
        case GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS:
            state = { ...state, natureOfGrievanceDetails: action.payload, detailsLoading: false };
            break;
        case GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_NATURE_OF_GRIEVANCE:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_NATURE_OF_GRIEVANCE_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_NATURE_OF_GRIEVANCE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_NATURE_OF_GRIEVANCE:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_NATURE_OF_GRIEVANCE_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_NATURE_OF_GRIEVANCE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_NATURE_OF_GRIEVANCE_DETAILS:
            state = { ...state, natureOfGrievanceDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, natureOfGrievanceList: [], createResult: {} };
            break;
        case RESET_NATURE_OF_GRIEVANCE_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_NATURE_OF_GRIEVANCE_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default NatureOfGrievanceReducer;