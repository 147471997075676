import {
	GET_CERTIFICATIONS, GET_CERTIFICATIONS_SUCCESS, GET_CERTIFICATIONS_FAIL,
	GET_CERTIFICATIONS_DETAILS, GET_CERTIFICATIONS_DETAILS_SUCCESS, GET_CERTIFICATIONS_DETAILS_FAIL,
	CERTIFICATION_CREATE, CERTIFICATION_CREATE_FAIL, CERTIFICATION_CREATE_SUCCESS,
	CERTIFICATION_DELETE, CERTIFICATION_DELETE_FAIL, CERTIFICATION_DELETE_SUCCESS,
	CERTIFICATION_UPDATE, CERTIFICATION_UPDATE_FAIL, CERTIFICATION_UPDATE_SUCCESS,RESET,
	GET_ALL_CERTIFICATIONS_DETAILS,GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS,GET_ALL_CERTIFICATIONS_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
	certificationList: [],
	certification: {},
	postingResult: {},
	loading: false,
	loadingCertificationDetails: false,
	certificationSetting:{},
	loadingcertificationSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const CertificationReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_CERTIFICATIONS:
			state = { ...state, loading: false };
			break;
		case GET_CERTIFICATIONS_SUCCESS:
			state = { ...state, certificationList: action.payload, loading: false };
			break;
		case GET_CERTIFICATIONS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_CERTIFICATIONS_DETAILS:
			state = { ...state, loadingCertificationDetails: true };
			break;
		case GET_CERTIFICATIONS_DETAILS_SUCCESS:
			state = { ...state, certification: action.payload, loadingCertificationDetails: false };
			break;
		case GET_CERTIFICATIONS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingCertificationDetails: false,
			};
			break;
		case CERTIFICATION_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case CERTIFICATION_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			
			break;
		case CERTIFICATION_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case CERTIFICATION_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case CERTIFICATION_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case CERTIFICATION_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case CERTIFICATION_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case CERTIFICATION_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case CERTIFICATION_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;


// All Certification List Code
case GET_ALL_CERTIFICATIONS_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS:
      state = { ...state, certificationList: action.payload, loading: false };
      break;
    case GET_ALL_CERTIFICATIONS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };

			case RESET:
		state = { ...state, certification: {} };
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default CertificationReducer;