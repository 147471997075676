import { takeLatest, put, call } from "redux-saga/effects";

import {
    GET_DT_TEST_TYPES,
    GET_DT_RESULTS,
    RESET,
    GET_DT_RESULT_DETAILS,
    GET_DT_NATURE_OF_ACTIONS,
    GET_DT_RELEASED_TO,
    GET_DT_FUNDING_STATUSES,
    GET_DT_RANDOM_REASONS,
    GET_TDP_TYPES,
    GET_QUARTER_REPORT_DATES,
    GET_VENDOR_LOG_STATUS_LISTS
} from "./actionsTypes";

import {
    getDTTestTypesSuccess,
    getDTTestTypesFail,

    getDTResultsSuccess,
    getDTResultsFail,

    getDTResultDetailsSuccess,
    getDTResultDetailsFail,

    getDTTypeOfCollectionSuccess,
    getDTTypeOfCollectionFail,

    getDTNatureOfActionsSuccess,
    getDTNatureOfActionsFail,

    getDTReleasedToSuccess,
    getDTReleasedToFail,

    reset,
    getDTFundingStatuesSuccess,
    getDTFundingStatuesFail,
    getDTRandomReasonsSuccess,
    getDTRandomReasonsFail,
    getTDPTypesSuccess,
    getTDPTypesFail,
    getQuarterReportDatesSuccess,
    getQuarterReportDatesFail,
    getVendorLogStatusListsSuccess,
    getVendorLogStatusListsFail,
} from "./action";

import {
    getDTTestTypes,
    getDTResults,
    getDTResultDetails,
    getDTTypeOfCollection,
    getDTNatureOfActions,
    getDTReleasedTo,
    getDTFundingStatusLists,
    getDTRandomReasons,
    getTDPTypes,
    getQuarterReportDates,
    getVendorLogStatusLists
} from "../../_helper/services/DrugTesting/DT";

//DT TEST TYPES
function* onGetDTTestTypes() {
    try {
        const response = yield call(getDTTestTypes);
        yield put(getDTTestTypesSuccess(response));
    } catch (error) {
        yield put(getDTTestTypesFail(error.response));
    }
}

//DT RESULTS
function* onGetDTResults() {
    try {
        const response = yield call(getDTResults);
        yield put(getDTResultsSuccess(response));
    } catch (error) {
        yield put(getDTResultsFail(error.response));
    }
}

//DT RESULTS DETAILS
function* onGetDTResultDetails() {
    try {
        const response = yield call(getDTResultDetails);
        yield put(getDTResultDetailsSuccess(response));
    } catch (error) {
        yield put(getDTResultDetailsFail(error.response));
    }
}

//DT TYPE OF COLLECTION
function* onGetDTTypeOfCollection() {
    try {
        const response = yield call(getDTTypeOfCollection);
        yield put(getDTTypeOfCollectionSuccess(response));
    } catch (error) {
        yield put(getDTTypeOfCollectionFail(error.response));
    }
}

//DT NATURE OF ACTIONS
function* onGetDTNatureOfActions() {
    try {
        const response = yield call(getDTNatureOfActions);
        yield put(getDTNatureOfActionsSuccess(response));
    } catch (error) {
        yield put(getDTNatureOfActionsFail(error.response));
    }
}

//DT RELEASED TO
function* onGetDTReleasedTo() {
    try {
        const response = yield call(getDTReleasedTo);
        yield put(getDTReleasedToSuccess(response));
    } catch (error) {
        yield put(getDTReleasedToFail(error.response));
    }
}

//DT FUNDING STATUS
function* onGetDTFundingStatusLists() {
    try {
        const response = yield call(getDTFundingStatusLists);
        yield put(getDTFundingStatuesSuccess(response));
    } catch (error) {
        yield put(getDTFundingStatuesFail(error.response));
    }
}

//DT RANDOM REASONS
function* onGetDTRandomReasons() {
    try {
        const response = yield call(getDTRandomReasons);
        yield put(getDTRandomReasonsSuccess(response));
    } catch (error) {
        yield put(getDTRandomReasonsFail(error.response));
    }
}

//TDP TYPES
function* onGetTDPTypes() {
    try {
        const response = yield call(getTDPTypes);
        yield put(getTDPTypesSuccess(response));
    } catch (error) {
        yield put(getTDPTypesFail(error.response));
    }
}

//QUARTER REPORT DATES
function* onGetQuarterReportDates() {
    try {
        const response = yield call(getQuarterReportDates);
        yield put(getQuarterReportDatesSuccess(response));
    } catch (error) {
        yield put(getQuarterReportDatesFail(error.response));
    }
}

//VENDOR LOG STATUS
function* onGetVendorLogStatusLists() {
    try {
        const response = yield call(getVendorLogStatusLists);
        yield put(getVendorLogStatusListsSuccess(response));
    } catch (error) {
        yield put(getVendorLogStatusListsFail(error.response));
    }
}

function* DT() {
    //DT TEST TYPES
    yield takeLatest(GET_DT_TEST_TYPES, onGetDTTestTypes);

    //DT RESULTS
    yield takeLatest(GET_DT_RESULTS, onGetDTResults);

    //DT RESULTS DETAILS
    yield takeLatest(GET_DT_RESULT_DETAILS, onGetDTResultDetails);

    //DT TYPE OF COLLECTION
    yield takeLatest(GET_DT_TEST_TYPES, onGetDTTypeOfCollection);

    //DT NATURE OF ACTIONS
    yield takeLatest(GET_DT_NATURE_OF_ACTIONS, onGetDTNatureOfActions);

    //DT RELEASED TO
    yield takeLatest(GET_DT_RELEASED_TO, onGetDTReleasedTo);

    //DT FUNDING STATUS
    yield takeLatest(GET_DT_FUNDING_STATUSES, onGetDTFundingStatusLists);

    //DT RANDOM REASONS
    yield takeLatest(GET_DT_RANDOM_REASONS, onGetDTRandomReasons);

    //TDP TYPES
    yield takeLatest(GET_TDP_TYPES, onGetTDPTypes);

    //QUARTER REPORT DATES
    yield takeLatest(GET_QUARTER_REPORT_DATES, onGetQuarterReportDates);

    //VENDOR LOG STATUS
    yield takeLatest(GET_VENDOR_LOG_STATUS_LISTS, onGetVendorLogStatusLists);

    yield takeLatest(RESET, reset)
}

export default DT;