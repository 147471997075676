// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getEducationDetails =(id)=> get(`${baseURL+url.EDUCATION_BASE}/${id}`);
export const getEducations =({page,pageSize})=> get(baseURL+url.EDUCATION_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
export const updateEducation = (id,payload) => put(baseURL+url.EDUCATION_BASE+'/'+id,payload);
// export const updatePositioStatus = (ID,payload) => put(url.EDUCATION_BASE_UPDATE+'/'+ID, payload);
// export const updateEducationStatusList = (payload) => put(url.EDUCATION_BASE_UPDATE,payload);
export const createEducation =(payload) => post(baseURL+url.EDUCATION_BASE,payload);
//Post
export const deleteEducation = (ID) => remove(baseURL+url.EDUCATION_BASE+'/'+ID);