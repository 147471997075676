import {
	GET_POSITIONS, GET_POSITIONS_SUCCESS, GET_POSITIONS_FAIL,
	GET_POSITIONS_DETAILS, GET_POSITIONS_DETAILS_SUCCESS, GET_POSITIONS_DETAILS_FAIL,
	POSITION_CREATE, POSITION_CREATE_FAIL, POSITION_CREATE_SUCCESS,
	POSITION_DELETE, POSITION_DELETE_FAIL, POSITION_DELETE_SUCCESS,
	POSITION_UPDATE, POSITION_UPDATE_FAIL, POSITION_UPDATE_SUCCESS, RESET, GET_LOOKUP_POSITIONS, GET_LOOKUP_POSITIONS_SUCCESS, GET_LOOKUP_POSITIONS_FAIL,
} from "./actionTypes";

const initialState = {
	positionList: [],
	position: {},
	postingResult: {},
	loading: false,
	loadingPositionDetails: false,
	positionSetting: {},
	loadingpositionSetting: false,
	lookupPositionList: [],
	loadingLookupPositionList: false,
	error: {
		message: "",
	},
};

const PositionReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_POSITIONS:
			state = { ...state, loading: true };
			break;
		case GET_POSITIONS_SUCCESS:
			state = { ...state, positionList: action.payload, loading: false };
			break;
		case GET_POSITIONS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_LOOKUP_POSITIONS:
			state = { ...state, loadingLookupPositionList: true };
			break;
		case GET_LOOKUP_POSITIONS_SUCCESS:
			state = { ...state, lookupPositionList: action.payload, loadingLookupPositionList: false };
			break;
		case GET_LOOKUP_POSITIONS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingLookupPositionList: false,
			};
			break;
		case GET_POSITIONS_DETAILS:
			state = { ...state, loadingPositionDetails: true };
			break;
		case GET_POSITIONS_DETAILS_SUCCESS:
			state = { ...state, position: action.payload, loadingPositionDetails: false };
			break;
		case GET_POSITIONS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPositionDetails: false,
			};
			break;
		case POSITION_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			console.log(state.postingResult);
			break;
		case POSITION_CREATE_SUCCESS:
			console.log(action);
			state = { ...state, postingResult: action.payload, loading: false };
			console.log(state.postingResult);
			break;
		case POSITION_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case POSITION_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case POSITION_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case POSITION_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case POSITION_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case POSITION_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case POSITION_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case RESET:
			state = { ...state, position: {} }

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default PositionReducer;