import * as ActionTypes from "./actionTypes";

const initialState = {
  filterBy: {
    hrControl: "",
    ssn: "",
    name: "",
    date: null,
    status: "",
  },
  outsideEmployeeList: {},
  FetchOutsideEmployementDetails: {},
  postingResult: {},
  deleteResponse: {},
  OutsideEmploymentHistory: [],
  loading: false,
};

const outsideEmployment = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case ActionTypes.CHANGE_HEADER_FILTER: {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          [action.payload.data &&
          action.payload.data.target &&
          action.payload.data &&
          action.payload.data.target.name]:
            action.payload.data &&
            action.payload.data.target &&
            action.payload.data &&
            action.payload.data.target.value,
        },
      };
    }
    case ActionTypes.RESET_FILTER: {
      return {
        ...state,
        filterBy: {
          hrControl: "",
          ssn: "",
          name: "",
          date: null,
          status: "",
        },
      };
    }
    case ActionTypes.FIND_OUTSIDE_EMPLOYEEMENT:
      return { ...state };
    case ActionTypes.FIND_OUTSIDE_EMPLOYEEMENT_SUCCESS:
      return { ...state, outsideEmployeeList: { ...action.response } };
    case ActionTypes.OUTSIDE_EMPLOYEEMENT_HISTORY:
      return { ...state, loading: true };
    case ActionTypes.OUTSIDE_EMPLOYEEMENT_HISTORY_SUCCESS:
      return {
        ...state,
        OutsideEmploymentHistory: [...action.response],
        loading: false,
      };
    case ActionTypes.REQ_OUTSIDE_EMPLOYMENT:
      return { ...state, loading: true, postingResult: {} };
    case ActionTypes.REQ_OUTSIDE_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        postingResult: { ...action.response },
        loading: false,
      };
    case ActionTypes.REQ_OUTSIDE_EMPLOYMENT_FAIL:
      return { ...state, postingResult: {}, loading: false };
    case ActionTypes.FETCH_OUTSIDE_EMPLOYDETAILS:
      return { ...state, loading: true };
    case ActionTypes.FETCH_OUTSIDE_EMPLOYDETAILS_SUCCESS: {
      return {
        ...state,
        FetchOutsideEmployementDetails: { ...action.response },
        loading: false,
      };
    }
    case ActionTypes.EDIT_OUTSIDE_EMPLOYEMENT:
      return { ...state, loading: true, postingResult: {} };
    case ActionTypes.EDIT_OUTSIDE_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        postingResult: { ...action.response },
        loading: false,
      };
    case ActionTypes.DELETE_OUTSIDE_EMPLOYEMENT:
      return { ...state, loading: true };
    case ActionTypes.DELETE_OUTSIDE_EMPLOYEMENT_SUCCESS:
      return {
        ...state,
        deleteResponse: { ...action.response },
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default outsideEmployment;
