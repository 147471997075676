export const DTPM_DATA_LOG_CREATE = "DTPM_DATA_LOG_CREATE";
export const DTPM_DATA_LOG_CREATE_SUCCESS = "DTPM_DATA_LOG_CREATE_SUCCESS";
export const DTPM_DATA_LOG_CREATE_FAIL = "DTPM_DATA_LOG_CREATE_FAIL";

export const GET_DTPM_DATA_LOG_DETAILS = "GET_DTPM_DATA_LOG_DETAILS";
export const GET_DTPM_DATA_LOG_DETAILS_SUCCESS = "GET_DTPM_DATA_LOG_DETAILS_SUCCESS";
export const GET_DTPM_DATA_LOG_DETAILS_FAIL = "GET_DTPM_DATA_LOG_DETAILS_FAIL";

export const UPDATE_DTPM_DATA_LOG = "UPDATE_DTPM_DATA_LOG";
export const UPDATE_DTPM_DATA_LOG_SUCCESS = "UPDATE_DTPM_DATA_LOG_SUCCESS";
export const UPDATE_DTPM_DATA_LOG_FAIL = "UPDATE_DTPM_DATA_LOG_FAIL";

export const DELETE_DTPM_DATA_LOG = "DELETE_DTPM_DATA_LOG";
export const DELETE_DTPM_DATA_LOG_SUCCESS = "DELETE_DTPM_DATA_LOG_SUCCESS";
export const DELETE_DTPM_DATA_LOG_FAIL = "DELETE_DTPM_DATA_LOG_FAIL";

export const RESET = "RESET";
export const RESET_DTPM_DATA_LOG_CREATE = "RESET_DTPM_DATA_LOG_CREATE";
export const RESET_DTPM_DATA_LOG_DETAILS = "RESET_DTPM_DATA_LOG_DETAILS";