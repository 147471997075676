// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getDeductionplanDetails =(code)=> get(`${baseURL+url.DEDUCTIONPLAN_BASE}/${code}`);
export const getDeductionplans =({page,pageSize})=> get(baseURL+url.DEDUCTIONPLAN_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);

export const updateDeductionplan = (code, payload) => put(`${baseURL}${url.DEDUCTIONPLAN_BASE}/${code}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createDeductionplan =(payload) => post(baseURL+url.DEDUCTIONPLAN_BASE,payload);

export const deleteDeductionplan = (code) => remove(`${baseURL}${url.DEDUCTIONPLAN_BASE}/${code}`);
