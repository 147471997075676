import {
	GET_PAYGRADES, GET_PAYGRADES_SUCCESS, GET_PAYGRADES_FAIL,
	GET_PAYGRADES_DETAILS, GET_PAYGRADES_DETAILS_SUCCESS, GET_PAYGRADES_DETAILS_FAIL,
	PAYGRADE_CREATE, PAYGRADE_CREATE_FAIL, PAYGRADE_CREATE_SUCCESS,
	PAYGRADE_DELETE, PAYGRADE_DELETE_FAIL, PAYGRADE_DELETE_SUCCESS,
	PAYGRADE_UPDATE, PAYGRADE_UPDATE_FAIL, PAYGRADE_UPDATE_SUCCESS,RESET
} from "./actionTypes";

const initialState = {
	paygradeList: [],
	paygrade: {},
	postingResult: {},
	loading: false,
	loadingPaygradeDetails: false,
	paygradeSetting:{},
	loadingpaygradeSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const PaygradeReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_PAYGRADES:
			state = { ...state, loading: false };
			break;
		case GET_PAYGRADES_SUCCESS:
			state = { ...state, paygradeList: action.payload, loading: false };
			break;
		case GET_PAYGRADES_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_PAYGRADES_DETAILS:
			state = { ...state, loadingPaygradeDetails: true };
			break;
		case GET_PAYGRADES_DETAILS_SUCCESS:
			state = { ...state, paygrade: action.payload, loadingPaygradeDetails: false };
			break;
		case GET_PAYGRADES_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPaygradeDetails: false,
			};
			break;
		case PAYGRADE_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYGRADE_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			
			break;
		case PAYGRADE_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case PAYGRADE_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYGRADE_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PAYGRADE_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case PAYGRADE_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYGRADE_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PAYGRADE_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:
		state = { ...state, paygrade: {} };
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default PaygradeReducer;