import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { MEDIA_SOURCE_CREATE, DELETE_MEDIA_SOURCE, GET_MEDIA_SOURCES, GET_MEDIA_SOURCE_DETAILS, RESET, RESET_MEDIA_SOURCE_CREATE, UPDATE_MEDIA_SOURCE } from "./actionsTypes";

import {
    createMediaSourceSuccess,
    createMediaSourceFail,
    reset,
    resetCreateMediaSource,
    getMediaSourceDetailsSuccess,
    getMediaSourceDetailsFail,
    updateMediaSourceSuccess,
    deleteMediaSourceSuccess,
    deleteMediaSourceFail,
    updateMediaSourceFail,
    getMediaSourcesSuccess,
    getMediaSourcesFail,
} from "./action";

import {
    createMediaSource, deleteMediaSource, getMediaSourceDetails, getMediaSources, updateMediaSource
} from "../../../../_helper/services/EMR/mediaSource";


function* onCreateMediaSource({ payload }) {
    try {
        const response = yield call(createMediaSource, payload);
        yield put(createMediaSourceSuccess(response));
        ShowSucess("Media Source Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createMediaSourceFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateMediaSource());
    }
}

function* onGetMediaSourceDetails({ payload: id }) {
    try {
        const response = yield call(getMediaSourceDetails, id);
        yield put(getMediaSourceDetailsSuccess(response));
    } catch (error) {
        yield put(getMediaSourceDetailsFail(error.response));
    }
}

function* onGetMediaSources() {
    try {
        const response = yield call(getMediaSources);
        yield put(getMediaSourcesSuccess(response));
    } catch (error) {
        yield put(getMediaSourcesFail(error.response));
    }
}

function* onUpdateMediaSource({ payload: { id }, payload }) {
    try {
        const response = yield call(updateMediaSource, id, payload);
        yield put(updateMediaSourceSuccess(response));
        yield put(getMediaSourceDetailsSuccess(payload));
        ShowSucess(`Media Source Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateMediaSourceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteMediaSource({ payload: id }) {
    try {
        const response = yield call(deleteMediaSource, id);
        yield put(deleteMediaSourceSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteMediaSourceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* MediaSourceSaga() {
    yield takeLatest(MEDIA_SOURCE_CREATE, onCreateMediaSource);
    yield takeLatest(GET_MEDIA_SOURCE_DETAILS, onGetMediaSourceDetails);
    yield takeLatest(GET_MEDIA_SOURCES, onGetMediaSources);
    yield takeLatest(UPDATE_MEDIA_SOURCE, onUpdateMediaSource);
    yield takeLatest(DELETE_MEDIA_SOURCE, onDeleteMediaSource);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_MEDIA_SOURCE_CREATE, resetCreateMediaSource);
}

export default MediaSourceSaga;