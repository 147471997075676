export const DFWP_CONTACT_LIST_CREATE = "DFWP_CONTACT_LIST_CREATE";
export const DFWP_CONTACT_LIST_CREATE_SUCCESS = "DFWP_CONTACT_LIST_CREATE_SUCCESS";
export const DFWP_CONTACT_LIST_CREATE_FAIL = "DFWP_CONTACT_LIST_CREATE_FAIL";

export const GET_DFWP_CONTACT_LIST_DETAILS = "GET_DFWP_CONTACT_LIST_DETAILS";
export const GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS = "GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS";
export const GET_DFWP_CONTACT_LIST_DETAILS_FAIL = "GET_DFWP_CONTACT_LIST_DETAILS_FAIL";

export const UPDATE_DFWP_CONTACT_LIST = "UPDATE_DFWP_CONTACT_LIST";
export const UPDATE_DFWP_CONTACT_LIST_SUCCESS = "UPDATE_DFWP_CONTACT_LIST_SUCCESS";
export const UPDATE_DFWP_CONTACT_LIST_FAIL = "UPDATE_DFWP_CONTACT_LIST_FAIL";

export const DELETE_DFWP_CONTACT_LIST = "DELETE_DFWP_CONTACT_LIST";
export const DELETE_DFWP_CONTACT_LIST_SUCCESS = "DELETE_DFWP_CONTACT_LIST_SUCCESS";
export const DELETE_DFWP_CONTACT_LIST_FAIL = "DELETE_DFWP_CONTACT_LIST_FAIL";

export const RESET = "RESET";
export const RESET_DFWP_CONTACT_LIST_CREATE = "RESET_DFWP_CONTACT_LIST_CREATE";
export const RESET_DFWP_CONTACT_LIST_DETAILS = "RESET_DFWP_CONTACT_LIST_DETAILS";