import {
	GET_EDUCATIONS, GET_EDUCATIONS_SUCCESS, GET_EDUCATIONS_FAIL,
	GET_EDUCATIONS_DETAILS, GET_EDUCATIONS_DETAILS_SUCCESS, GET_EDUCATIONS_DETAILS_FAIL,
	EDUCATION_CREATE,EDUCATION_CREATE_FAIL,EDUCATION_CREATE_SUCCESS,
	EDUCATION_DELETE,EDUCATION_DELETE_FAIL,EDUCATION_DELETE_SUCCESS,
	EDUCATION_UPDATE,EDUCATION_UPDATE_FAIL,EDUCATION_UPDATE_SUCCESS,RESET, GET_EDUCATIONS_SETTING_FAIL, GET_EDUCATIONS_SETTING_SUCCESS, SAVE_EDUCATIONS_SETTING_FAIL, SAVE_EDUCATIONS_SETTING_SUCCESS, SAVE_EDUCATIONS_SETTING, GET_EDUCATIONS_SETTING, EDUCATION_COPY, EDUCATION_COPY_SUCCESS, EDUCATION_COPY_FAIL, EDUCATION_STATUS_CREATE, EDUCATION_STATUS_CREATE_SUCCESS, EDUCATION_STATUS_CREATE_FAIL, EDUCATION_STATUS_UPDATE, EDUCATION_STATUS_UPDATE_SUCCESS, EDUCATION_STATUS_UPDATE_FAIL, EDUCATION_STATUS_DELETE, EDUCATION_STATUS_DELETE_SUCCESS, EDUCATION_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getEducations = (payload) => {
	return {
	  type: GET_EDUCATIONS,
	  payload: payload
	};
  };
  
  export const getEducationsSuccess = (posts) => {
	return {
	  type: GET_EDUCATIONS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getEducationsFail = (error) => {
	return {
	  type: GET_EDUCATIONS_FAIL,
	  payload: error,
	};
  };
  
  export const getEducationsDetails = (id) => {
	return {
	  type: GET_EDUCATIONS_DETAILS,
	  payload: id,
	};
  };
  
  export const getEducationsDetailsSuccess = (post) => {
	return {
	  type: GET_EDUCATIONS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getEducationsDetailsFail = (error) => {
	return {
	  type: GET_EDUCATIONS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createEducation =(payload)=>{
	return {
		type: EDUCATION_CREATE,
		payload: payload
	}
  }
  export const createEducationSuccess =(response)=>{
	return {
		type: EDUCATION_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createEducationFail =(error)=>{
	return {
		type: EDUCATION_CREATE_FAIL,
		payload: error
	}
  }


  export const updateEducation =(payload)=>{
	
	return {
		type: EDUCATION_UPDATE,
		payload: payload
	}
  }
  export const updateEducationSuccess =(response)=>{
	return {
		type: EDUCATION_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateEducationFail =(error)=>{
	return {
		type: EDUCATION_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteEducation =(payload)=>{
	return {
		type: EDUCATION_DELETE,
		payload: payload
	}
  }
  export const deleteEducationSuccess =(response)=>{
	return {
		type: EDUCATION_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteEducationFail =(error)=>{
	return {
		type: EDUCATION_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

