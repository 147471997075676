import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { NATURE_OF_GRIEVANCE_CREATE, DELETE_NATURE_OF_GRIEVANCE, GET_NATURE_OF_GRIEVANCEES, GET_NATURE_OF_GRIEVANCE_DETAILS, RESET, RESET_NATURE_OF_GRIEVANCE_CREATE, UPDATE_NATURE_OF_GRIEVANCE } from "./actionsTypes";

import {
    createNatureOfGrievanceSuccess,
    createNatureOfGrievanceFail,
    reset,
    resetCreateNatureOfGrievance,
    getNatureOfGrievanceDetailsSuccess,
    getNatureOfGrievanceDetailsFail,
    updateNatureOfGrievanceSuccess,
    deleteNatureOfGrievanceSuccess,
    deleteNatureOfGrievanceFail,
    updateNatureOfGrievanceFail,
    getNatureOfGrievanceesSuccess,
    getNatureOfGrievanceesFail,
} from "./action";

import {
    createNatureOfGrievance, deleteNatureOfGrievance, getNatureOfGrievanceDetails, getNatureOfGrievancees, updateNatureOfGrievance
} from "../../../../_helper/services/EMR/NatureOfGrievance";


function* onCreateNatureOfGrievance({ payload }) {
    try {
        const response = yield call(createNatureOfGrievance, payload);
        yield put(createNatureOfGrievanceSuccess(response));
        ShowSucess("Nature Of Grievance Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createNatureOfGrievanceFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateNatureOfGrievance());
    }
}

function* onGetNatureOfGrievanceDetails({ payload: id }) {
    try {
        const response = yield call(getNatureOfGrievanceDetails, id);
        yield put(getNatureOfGrievanceDetailsSuccess(response));
    } catch (error) {
        yield put(getNatureOfGrievanceDetailsFail(error.response));
    }
}

function* onGetNatureOfGrievancees() {
    try {
        const response = yield call(getNatureOfGrievancees);
        yield put(getNatureOfGrievanceesSuccess(response));
    } catch (error) {
        yield put(getNatureOfGrievanceesFail(error.response));
    }
}

function* onUpdateNatureOfGrievance({ payload: { id }, payload }) {
    try {
        const response = yield call(updateNatureOfGrievance, id, payload);
        yield put(updateNatureOfGrievanceSuccess(response));
        yield put(getNatureOfGrievanceDetailsSuccess(payload));
        ShowSucess(`Nature Of Grievance Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateNatureOfGrievanceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteNatureOfGrievance({ payload: id }) {
    try {
        const response = yield call(deleteNatureOfGrievance, id);
        yield put(deleteNatureOfGrievanceSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteNatureOfGrievanceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* NatureOfGrievanceSaga() {
    yield takeLatest(NATURE_OF_GRIEVANCE_CREATE, onCreateNatureOfGrievance);
    yield takeLatest(GET_NATURE_OF_GRIEVANCE_DETAILS, onGetNatureOfGrievanceDetails);
    yield takeLatest(GET_NATURE_OF_GRIEVANCEES, onGetNatureOfGrievancees);
    yield takeLatest(UPDATE_NATURE_OF_GRIEVANCE, onUpdateNatureOfGrievance);
    yield takeLatest(DELETE_NATURE_OF_GRIEVANCE, onDeleteNatureOfGrievance);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_NATURE_OF_GRIEVANCE_CREATE, resetCreateNatureOfGrievance);
}

export default NatureOfGrievanceSaga;