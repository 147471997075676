import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_DEPARTMENTS, GET_DEPARTMENTS_DETAILS,  DEPARTMENT_CREATE, DEPARTMENT_DELETE,
   DEPARTMENT_UPDATE,RESET} from "./actionTypes";

import {
  getDepartmentsSuccess,
  getDepartmentsFail,
  getDepartmentsDetailsSuccess,
  getDepartmentsDetailsFail,
  createDepartmentSuccess,
  createDepartmentFail,
  updateDepartmentSuccess,
  updateDepartmentFail,
  deleteDepartmentSuccess,
  deleteDepartmentFail,
  reset
} from "./actions";

import {  createDepartment,deleteDepartment,
	getDepartmentDetails,getDepartments
	,updateDepartment,
 } from "../../_helper/services/department";
function* onGetDepartments({payload: {page,pageSize}}) {
  try {
    const response = yield call(getDepartments,{page,pageSize});
    yield put(getDepartmentsSuccess(response));
  } catch (error) {
    yield put(getDepartmentsFail(error.response));
  }
}

function* onGetDepartmentDetails({ payload: id }) {
  try {
    const response = yield call(getDepartmentDetails, id);
    yield put(getDepartmentsDetailsSuccess(response));
  } catch (error) {
    yield put(getDepartmentsDetailsFail(error.response));
  }
}
// function* onCreateDepartment({ payload }) {
// 	try {
// 	  const response = yield call(createDepartment, payload);
// 	  yield put(createDepartmentSuccess(response));
// 	} catch (error) {
// 	  yield put(createDepartmentFail(error.response));
// 	}
//   }
function* onCreateDepartment({payload}){
  try{
    const response=yield call(createDepartment, payload);
    yield put(createDepartmentSuccess(response));
    ShowSucess("Department Created Successfully",{
      position:"top-right"
    })
  }
    catch (error){
      yield put(createDepartmentFail(error.response));
      ShowError(error?.response?.data?.message);
    } finally {
          yield put(reset()); // Dispatch RESET action after handling createDepartment
        }
  }

  function* onUpdateDepartment({ payload: { id},payload }) {
	try {
	  const response = yield call(updateDepartment, id, payload);
	  yield put(updateDepartmentSuccess(response));
    ShowSucess('Department Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateDepartmentFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteDepartment({ payload: ID }) {
	try {
	  const response = yield call(deleteDepartment, ID);
	  yield put(deleteDepartmentSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteDepartmentFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_DEPARTMENTS, onGetDepartments);
  yield takeLatest(GET_DEPARTMENTS_DETAILS, onGetDepartmentDetails);
  yield takeLatest(DEPARTMENT_CREATE, onCreateDepartment);
  yield takeLatest(DEPARTMENT_UPDATE, onUpdateDepartment);
  yield takeLatest(DEPARTMENT_DELETE, onDeleteDepartment);
  yield takeLatest(RESET,reset)
}

export default CartSaga;



// import { takeLatest, put, call } from "redux-saga/effects";

// import { GET_DEPARTMENTS, GET_DEPARTMENTS_DETAILS,  DEPARTMENT_CREATE, DEPARTMENT_DELETE,
//    DEPARTMENT_UPDATE,RESET,} from "./actionTypes";

// import {
//   getDepartmentsSuccess,
//   getDepartmentsFail,
//   getDepartmentsDetailsSuccess,
//   getDepartmentsDetailsFail,
//   createDepartmentSuccess,
//   createDepartmentFail,
//   updateDepartmentSuccess,
//   updateDepartmentFail,
//   deleteDepartmentSuccess,
//   deleteDepartmentFail,
//   reset,
// } from "./actions";

// import {  createDepartment,deleteDepartment,
// 	getDepartmentDetails,getDepartments
// 	,updateDepartment,
//  } from "../../_helper/services/departmentaddorupdate";
// function* onGetDepartments({payload: {page,pageSize}}) {
//   try {
//     const response = yield call(getDepartments,{page,pageSize});
//     yield put(getDepartmentsSuccess(response));
//   } catch (error) {
//     yield put(getDepartmentsFail(error.response));
//   }
// }

// function* onGetDepartmentDetails({ payload: id }) {
//   try {
//     const response = yield call(getDepartmentDetails, id);
//     yield put(getDepartmentsDetailsSuccess(response));
//   } catch (error) {
//     yield put(getDepartmentsDetailsFail(error.response));
//   }
// }
// function* onCreateDepartment({ payload }) {
// 	try {
// 	  const response = yield call(createDepartment, payload);
// 	  yield put(createDepartmentSuccess(response));
// 	} catch (error) {
// 	  yield put(createDepartmentFail(error.response));
// 	}finally {
//     yield put(reset()); // Dispatch RESET action after handling createDepartment
//   }
//   }
//   function* onUpdateDepartment({ payload: { ID},payload }) {
// 	try {
// 	  const response = yield call(updateDepartment, ID, payload);
// 	  yield put(updateDepartmentSuccess(response));
// 	} catch (error) {
// 	  yield put(updateDepartmentFail(error.response));
// 	}
//   }
//   function* onDeleteDepartment({ payload: ID }) {
// 	try {
// 	  const response = yield call(deleteDepartment, ID);
// 	  yield put(deleteDepartmentSuccess(response));
// 	} catch (error) {
// 	  yield put(deleteDepartmentFail(error.response));
// 	}
//   }

// function* CartSaga() {
//   yield takeLatest(GET_DEPARTMENTS, onGetDepartments);
//   yield takeLatest(GET_DEPARTMENTS_DETAILS, onGetDepartmentDetails);
//   yield takeLatest(DEPARTMENT_CREATE, onCreateDepartment);
//   yield takeLatest(DEPARTMENT_UPDATE, onUpdateDepartment);
//   yield takeLatest(DEPARTMENT_DELETE, onDeleteDepartment);
//   yield takeLatest(RESET,reset)
// }

// export default CartSaga;