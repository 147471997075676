import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_PAYTIMECODES, GET_PAYTIMECODES_DETAILS,  PAYTIMECODE_CREATE, PAYTIMECODE_DELETE,
   PAYTIMECODE_UPDATE} from "./actionTypes";

import {
  getPaytimecodesSuccess,
  getPaytimecodesFail,
  getPaytimecodesDetailsSuccess,
  getPaytimecodesDetailsFail,
  createPaytimecodeSuccess,
  createPaytimecodeFail,
  updatePaytimecodeSuccess,
  updatePaytimecodeFail,
  deletePaytimecodeSuccess,
  deletePaytimecodeFail,
} from "./actions";

import {  createPaytimecode,deletePaytimecode,
	getPaytimecodeDetails,getPaytimecodes
	,updatePaytimecode,
 } from "../../_helper/services/paytimecodes";
function* onGetPaytimecodes({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPaytimecodes,{page,pageSize});
    yield put(getPaytimecodesSuccess(response));
  } catch (error) {
    yield put(getPaytimecodesFail(error.response));
  }
}

function* onGetPaytimecodeDetails({ payload: code }) {
  try {
    const response = yield call(getPaytimecodeDetails, code);
    yield put(getPaytimecodesDetailsSuccess(response));
  } catch (error) {
    yield put(getPaytimecodesDetailsFail(error.response));
  }
}
function* onCreatePaytimecode({ payload }) {
	try {
	  const response = yield call(createPaytimecode, payload);
	  yield put(createPaytimecodeSuccess(response));
    ShowSucess("Paytimecode Created Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(createPaytimecodeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdatePaytimecode({ payload: { code},payload }) {
	try {
	  const response = yield call(updatePaytimecode, code, payload);
	  yield put(updatePaytimecodeSuccess(response));
    ShowSucess("Paytimecode Details Updated Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(updatePaytimecodeFail(error.response));
    ShowError(error?.response?.data?.message);
    
	}
  }
  function* onDeletePaytimecode({ payload: code }) {
	try {
	  const response = yield call(deletePaytimecode, code);
	  yield put(deletePaytimecodeSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deletePaytimecodeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_PAYTIMECODES, onGetPaytimecodes);
  yield takeLatest(GET_PAYTIMECODES_DETAILS, onGetPaytimecodeDetails);
  yield takeLatest(PAYTIMECODE_CREATE, onCreatePaytimecode);
  yield takeLatest(PAYTIMECODE_UPDATE, onUpdatePaytimecode);
  yield takeLatest(PAYTIMECODE_DELETE, onDeletePaytimecode);
}

export default CartSaga;