export const TYPE_OF_CHARGES_CREATE = "TYPE_OF_CHARGES_CREATE";
export const TYPE_OF_CHARGES_CREATE_SUCCESS = "TYPE_OF_CHARGES_CREATE_SUCCESS";
export const TYPE_OF_CHARGES_CREATE_FAIL = "TYPE_OF_CHARGES_CREATE_FAIL";

export const GET_TYPE_OF_CHARGES_DETAILS = "GET_TYPE_OF_CHARGES_DETAILS";
export const GET_TYPE_OF_CHARGES_DETAILS_SUCCESS = "GET_TYPE_OF_CHARGES_DETAILS_SUCCESS";
export const GET_TYPE_OF_CHARGES_DETAILS_FAIL = "GET_TYPE_OF_CHARGES_DETAILS_FAIL";

export const GET_TYPE_OF_CHARGESES = "GET_TYPE_OF_CHARGESES";
export const GET_TYPE_OF_CHARGESES_SUCCESS = "GET_TYPE_OF_CHARGESES_SUCCESS";
export const GET_TYPE_OF_CHARGESES_FAIL = "GET_TYPE_OF_CHARGESES_FAIL";

export const UPDATE_TYPE_OF_CHARGES = "UPDATE_TYPE_OF_CHARGES";
export const UPDATE_TYPE_OF_CHARGES_SUCCESS = "UPDATE_TYPE_OF_CHARGES_SUCCESS";
export const UPDATE_TYPE_OF_CHARGES_FAIL = "UPDATE_TYPE_OF_CHARGES_FAIL";

export const DELETE_TYPE_OF_CHARGES = "DELETE_TYPE_OF_CHARGES";
export const DELETE_TYPE_OF_CHARGES_SUCCESS = "DELETE_TYPE_OF_CHARGES_SUCCESS";
export const DELETE_TYPE_OF_CHARGES_FAIL = "DELETE_TYPE_OF_CHARGES_FAIL";

export const RESET = "RESET";
export const RESET_TYPE_OF_CHARGES_CREATE = "RESET_TYPE_OF_CHARGES_CREATE";
export const RESET_TYPE_OF_CHARGES_DETAILS = "RESET_TYPE_OF_CHARGES_DETAILS";
export const RESET_TYPE_OF_CHARGES_DELETE = "RESET_TYPE_OF_CHARGES_DELETE";