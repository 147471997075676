import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { WAITING_PERIOD_CREATE, DELETE_WAITING_PERIOD, GET_WAITING_PERIODS, GET_WAITING_PERIOD_DETAILS, RESET, RESET_WAITING_PERIOD_CREATE, UPDATE_WAITING_PERIOD } from "./actionsTypes";

import {
    createWaitingPeriodSuccess,
    createWaitingPeriodFail,
    reset,
    resetCreateWaitingPeriod,
    getWaitingPeriodDetailsSuccess,
    getWaitingPeriodDetailsFail,
    updateWaitingPeriodSuccess,
    deleteWaitingPeriodSuccess,
    deleteWaitingPeriodFail,
    updateWaitingPeriodFail,
    getWaitingPeriodsSuccess,
    getWaitingPeriodsFail,
} from "./action";

import {
    createWaitingPeriod, deleteWaitingPeriod, getWaitingPeriodDetails, getWaitingPeriods, updateWaitingPeriod
} from "../../../../_helper/services/EMR/waitingPeriod";


function* onCreateWaitingPeriod({ payload }) {
    try {
        const response = yield call(createWaitingPeriod, payload);
        yield put(createWaitingPeriodSuccess(response));
        ShowSucess("Media Source Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createWaitingPeriodFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateWaitingPeriod());
    }
}

function* onGetWaitingPeriodDetails({ payload: id }) {
    try {
        const response = yield call(getWaitingPeriodDetails, id);
        yield put(getWaitingPeriodDetailsSuccess(response));
    } catch (error) {
        yield put(getWaitingPeriodDetailsFail(error.response));
    }
}

function* onGetWaitingPeriods() {
    try {
        const response = yield call(getWaitingPeriods);
        yield put(getWaitingPeriodsSuccess(response));
    } catch (error) {
        yield put(getWaitingPeriodsFail(error.response));
    }
}

function* onUpdateWaitingPeriod({ payload: { id }, payload }) {
    try {
        const response = yield call(updateWaitingPeriod, id, payload);
        yield put(updateWaitingPeriodSuccess(response));
        yield put(getWaitingPeriodDetailsSuccess(payload));
        ShowSucess(`Media Source Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateWaitingPeriodFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteWaitingPeriod({ payload: id }) {
    try {
        const response = yield call(deleteWaitingPeriod, id);
        yield put(deleteWaitingPeriodSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteWaitingPeriodFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* WaitingPeriodSaga() {
    yield takeLatest(WAITING_PERIOD_CREATE, onCreateWaitingPeriod);
    yield takeLatest(GET_WAITING_PERIOD_DETAILS, onGetWaitingPeriodDetails);
    yield takeLatest(GET_WAITING_PERIODS, onGetWaitingPeriods);
    yield takeLatest(UPDATE_WAITING_PERIOD, onUpdateWaitingPeriod);
    yield takeLatest(DELETE_WAITING_PERIOD, onDeleteWaitingPeriod);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_WAITING_PERIOD_CREATE, resetCreateWaitingPeriod);
}

export default WaitingPeriodSaga;