import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE, RESET, RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE, GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS, UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG, DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG } from "./actionsTypes";

import {
    createTDPEmployeeMasterListLogSuccess,
    createTDPEmployeeMasterListLogFail,
    resetCreateTDPEmployeeMasterListLog,
    reset,
    getTDPEmployeeMasterListLogDetailsSuccess,
    getTDPEmployeeMasterListLogDetailsFail,
    updateTDPEmployeeMasterListLogSuccess,
    updateTDPEmployeeMasterListLogFail,
    deleteTDPEmployeeMasterListLogSuccess,
    deleteTDPEmployeeMasterListLogFail,
} from "./action";

import {
    createTDPEmployeeMasterListLog, deleteTDPEmployeeMasterListLog, getTDPEmployeeMasterListLogDetails, updateTDPEmployeeMasterListLog
} from "../../../_helper/services/DrugTesting/TDPEmployeeMasterListLog";

function* onCreateTDPEmployeeMasterListLog({ payload }) {
    try {
        const response = yield call(createTDPEmployeeMasterListLog, payload);
        yield put(createTDPEmployeeMasterListLogSuccess(response));
        ShowSucess("TDP Employee Master List Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTDPEmployeeMasterListLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTDPEmployeeMasterListLog());
    }
}

function* onGetTDPEmployeeMasterListLogDetails({ payload: id }) {
    try {
        const response = yield call(getTDPEmployeeMasterListLogDetails, id);
        yield put(getTDPEmployeeMasterListLogDetailsSuccess(response));
    } catch (error) {
        yield put(getTDPEmployeeMasterListLogDetailsFail(error.response));
    }
}
function* onUpdateTDPEmployeeMasterListLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTDPEmployeeMasterListLog, id, payload);
        yield put(updateTDPEmployeeMasterListLogSuccess(response));
        yield put(getTDPEmployeeMasterListLogDetailsSuccess(payload));
        ShowSucess(`TDP Employee Master List Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTDPEmployeeMasterListLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTDPEmployeeMasterListLog({ payload: id }) {
    try {
        const response = yield call(deleteTDPEmployeeMasterListLog, id);
        yield put(deleteTDPEmployeeMasterListLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTDPEmployeeMasterListLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TDPEmployeeMasterListLogSaga() {
    yield takeLatest(TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE, onCreateTDPEmployeeMasterListLog);
    yield takeLatest(RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE, resetCreateTDPEmployeeMasterListLog);
    yield takeLatest(GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS, onGetTDPEmployeeMasterListLogDetails);
    yield takeLatest(UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG, onUpdateTDPEmployeeMasterListLog);
    yield takeLatest(DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG, onDeleteTDPEmployeeMasterListLog);
    yield takeLatest(RESET, reset);
}

export default TDPEmployeeMasterListLogSaga;