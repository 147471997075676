import {
    DELETE_DTPM_DATA_LOG,
    DELETE_DTPM_DATA_LOG_FAIL,
    DELETE_DTPM_DATA_LOG_SUCCESS,
    DTPM_DATA_LOG_CREATE,
    DTPM_DATA_LOG_CREATE_FAIL,
    DTPM_DATA_LOG_CREATE_SUCCESS,
    GET_DTPM_DATA_LOG_DETAILS,
    GET_DTPM_DATA_LOG_DETAILS_FAIL,
    GET_DTPM_DATA_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_DTPM_DATA_LOG_CREATE,
    RESET_DTPM_DATA_LOG_DETAILS,
    UPDATE_DTPM_DATA_LOG,
    UPDATE_DTPM_DATA_LOG_FAIL,
    UPDATE_DTPM_DATA_LOG_SUCCESS,
} from "./actionsTypes";

const initialState = {
    DTPMDataLogList: [],
    DTPMDataLogDetails: {},
    createResult: {},
    deleteResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    error: {
        message: "",
    },
};

const DTPMDataLogReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case DTPM_DATA_LOG_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case DTPM_DATA_LOG_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case DTPM_DATA_LOG_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        //GET individual DTPM Details
        case GET_DTPM_DATA_LOG_DETAILS:
            state = { ...state, detailsLoading: true, DTPMDataLogDetails: {} };
            break;
        case GET_DTPM_DATA_LOG_DETAILS_SUCCESS:
            state = { ...state, DTPMDataLogDetails: action.payload, detailsLoading: false };
            break;
        case GET_DTPM_DATA_LOG_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        //Update individual DTPM Details
        case UPDATE_DTPM_DATA_LOG:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_DTPM_DATA_LOG_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_DTPM_DATA_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        //Delete individual DTPM Details
        case DELETE_DTPM_DATA_LOG:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_DTPM_DATA_LOG_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_DTPM_DATA_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;

        //Reset DTPM Details
        case RESET_DTPM_DATA_LOG_DETAILS:
            state = { ...state, DTPMDataLogDetails: {}, detailsLoading: false };
            break;


        case RESET:
            state = { ...state, DTPMDataLogList: [], createResult: {} };
            break;
        case RESET_DTPM_DATA_LOG_CREATE:
            state = { ...state, createResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DTPMDataLogReducer;