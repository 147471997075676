import {
    TYPES_OF_WORK_HOURS_CREATE,
    TYPES_OF_WORK_HOURS_CREATE_FAIL,
    TYPES_OF_WORK_HOURS_CREATE_SUCCESS,
    DELETE_TYPES_OF_WORK_HOURS,
    DELETE_TYPES_OF_WORK_HOURS_FAIL,
    DELETE_TYPES_OF_WORK_HOURS_SUCCESS,
    GET_TYPES_OF_WORK_HOURSES,
    GET_TYPES_OF_WORK_HOURSES_FAIL,
    GET_TYPES_OF_WORK_HOURSES_SUCCESS,
    GET_TYPES_OF_WORK_HOURS_DETAILS,
    GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL,
    GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS,
    RESET,
    RESET_TYPES_OF_WORK_HOURS_CREATE,
    RESET_TYPES_OF_WORK_HOURS_DELETE,
    RESET_TYPES_OF_WORK_HOURS_DETAILS,
    UPDATE_TYPES_OF_WORK_HOURS,
    UPDATE_TYPES_OF_WORK_HOURS_FAIL,
    UPDATE_TYPES_OF_WORK_HOURS_SUCCESS,
} from "./actionsTypes";

const initialState = {
    typesOfWorkHoursList: [],
    typesOfWorkHoursDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const TypesOfWorkHoursReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case TYPES_OF_WORK_HOURS_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case TYPES_OF_WORK_HOURS_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case TYPES_OF_WORK_HOURS_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_TYPES_OF_WORK_HOURSES:
            state = { ...state, loading: true };
            break;
        case GET_TYPES_OF_WORK_HOURSES_SUCCESS:
            state = { ...state, typesOfWorkHoursList: action.payload, loading: false };
            break;
        case GET_TYPES_OF_WORK_HOURSES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_TYPES_OF_WORK_HOURS_DETAILS:
            state = { ...state, detailsLoading: true, typesOfWorkHoursDetails: {} };
            break;
        case GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS:
            state = { ...state, typesOfWorkHoursDetails: action.payload, detailsLoading: false };
            break;
        case GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_TYPES_OF_WORK_HOURS:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_TYPES_OF_WORK_HOURS_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_TYPES_OF_WORK_HOURS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_TYPES_OF_WORK_HOURS:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_TYPES_OF_WORK_HOURS_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_TYPES_OF_WORK_HOURS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_TYPES_OF_WORK_HOURS_DETAILS:
            state = { ...state, typesOfWorkHoursDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, typesOfWorkHoursList: [], createResult: {} };
            break;
        case RESET_TYPES_OF_WORK_HOURS_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_TYPES_OF_WORK_HOURS_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default TypesOfWorkHoursReducer;