export const GET_EMPLOYEETYPES = "GET_EMPLOYEETYPES";
export const GET_EMPLOYEETYPES_SUCCESS = "GET_EMPLOYEETYPES_SUCCESS";
export const GET_EMPLOYEETYPES_FAIL = "GET_EMPLOYEETYPES_FAIL";

export const GET_EMPLOYEETYPES_DETAILS = "GET_EMPLOYEETYPES_DETAILS";
export const GET_EMPLOYEETYPES_DETAILS_SUCCESS = "GET_EMPLOYEETYPES_DETAILS_SUCCESS";
export const GET_EMPLOYEETYPES_DETAILS_FAIL = "GET_EMPLOYEETYPES_DETAILS_FAIL";

export const EMPLOYEETYPE_UPDATE= "EMPLOYEETYPE_UPDATE";
export const EMPLOYEETYPE_UPDATE_SUCCESS = "EMPLOYEETYPE_UPDATE_SUCCESS";
export const EMPLOYEETYPE_UPDATE_FAIL = "EMPLOYEETYPE_UPDATE_FAIL";

export const EMPLOYEETYPE_CREATE= "EMPLOYEETYPE_CREATE";
export const EMPLOYEETYPE_CREATE_SUCCESS = "EMPLOYEETYPE_CREATE_SUCCESS";
export const EMPLOYEETYPE_CREATE_FAIL = "EMPLOYEETYPE_CREATE_FAIL";

export const EMPLOYEETYPE_DELETE= "EMPLOYEETYPE_DELETE";
export const EMPLOYEETYPE_DELETE_SUCCESS = "EMPLOYEETYPE_DELETE_SUCCESS";
export const EMPLOYEETYPE_DELETE_FAIL = "EMPLOYEETYPE_DELETE_FAIL";

