import {
	GET_TAGMASTERS, GET_TAGMASTERS_SUCCESS, GET_TAGMASTERS_FAIL,
	GET_TAGMASTERS_DETAILS, GET_TAGMASTERS_DETAILS_SUCCESS, GET_TAGMASTERS_DETAILS_FAIL,
	TAGMASTER_CREATE,TAGMASTER_CREATE_FAIL,TAGMASTER_CREATE_SUCCESS,
	TAGMASTER_DELETE,TAGMASTER_DELETE_FAIL,TAGMASTER_DELETE_SUCCESS,
	TAGMASTER_UPDATE,TAGMASTER_UPDATE_FAIL,TAGMASTER_UPDATE_SUCCESS,RESET, GET_TAGMASTERS_SETTING_FAIL, GET_TAGMASTERS_SETTING_SUCCESS, SAVE_TAGMASTERS_SETTING_FAIL, SAVE_TAGMASTERS_SETTING_SUCCESS, SAVE_TAGMASTERS_SETTING, GET_TAGMASTERS_SETTING, TAGMASTER_COPY, TAGMASTER_COPY_SUCCESS, TAGMASTER_COPY_FAIL, TAGMASTER_STATUS_CREATE, TAGMASTER_STATUS_CREATE_SUCCESS, TAGMASTER_STATUS_CREATE_FAIL, TAGMASTER_STATUS_UPDATE, TAGMASTER_STATUS_UPDATE_SUCCESS, TAGMASTER_STATUS_UPDATE_FAIL, TAGMASTER_STATUS_DELETE, TAGMASTER_STATUS_DELETE_SUCCESS, TAGMASTER_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getTagmasters = (payload) => {
	return {
	  type: GET_TAGMASTERS,
	  payload: payload
	};
  };
  
  export const getTagmastersSuccess = (posts) => {
	return {
	  type: GET_TAGMASTERS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getTagmastersFail = (error) => {
	return {
	  type: GET_TAGMASTERS_FAIL,
	  payload: error,
	};
  };
  
  export const getTagmastersDetails = (id) => {
	return {
	  type: GET_TAGMASTERS_DETAILS,
	  payload: id,
	};
  };
  
  export const getTagmastersDetailsSuccess = (post) => {
	return {
	  type: GET_TAGMASTERS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getTagmastersDetailsFail = (error) => {
	return {
	  type: GET_TAGMASTERS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createTagmaster =(payload)=>{
	return {
		type: TAGMASTER_CREATE,
		payload: payload
	}
  }
  export const createTagmasterSuccess =(response)=>{
	return {
		type: TAGMASTER_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createTagmasterFail =(error)=>{
	return {
		type: TAGMASTER_CREATE_FAIL,
		payload: error
	}
  }


  export const updateTagmaster =(payload)=>{
	
	return {
		type: TAGMASTER_UPDATE,
		payload: payload
	}
  }
  export const updateTagmasterSuccess =(response)=>{
	return {
		type: TAGMASTER_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateTagmasterFail =(error)=>{
	return {
		type: TAGMASTER_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteTagmaster =(payload)=>{
	return {
		type: TAGMASTER_DELETE,
		payload: payload
	}
  }
  export const deleteTagmasterSuccess =(response)=>{
	return {
		type: TAGMASTER_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteTagmasterFail =(error)=>{
	return {
		type: TAGMASTER_DELETE_FAIL,
		payload: error
	}
  }

  export const reset=()=>{
	return {
		type:RESET,
	}
  }

