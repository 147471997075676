export const DEPARTMENT_JV_CONTROL_NUMBER_CREATE = "DEPARTMENT_JV_CONTROL_NUMBER_CREATE";
export const DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS = "DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS";
export const DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL = "DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL";

export const GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS = "GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS";
export const GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS = "GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS";
export const GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL = "GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL";

export const UPDATE_DEPARTMENT_JV_CONTROL_NUMBER = "UPDATE_DEPARTMENT_JV_CONTROL_NUMBER";
export const UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS = "UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS";
export const UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL = "UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL";

export const DELETE_DEPARTMENT_JV_CONTROL_NUMBER = "DELETE_DEPARTMENT_JV_CONTROL_NUMBER";
export const DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS = "DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS";
export const DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL = "DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL";

export const RESET = "RESET";
export const RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE = "RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE";
export const RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS = "RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS";