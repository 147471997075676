import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { RWHR_CREATE, DELETE_RWHR, GET_RWHR_DETAILS, RESET, RESET_RWHR_CREATE, UPDATE_RWHR } from "./actionsTypes";

import {
    createRWHRSuccess,
    createRWHRFail,
    reset,
    resetCreateRWHR,
    getRWHRDetailsSuccess,
    getRWHRDetailsFail,
    updateRWHRSuccess,
    deleteRWHRSuccess,
    deleteRWHRFail,
    updateRWHRFail,
} from "./action";

import {
    createRWHR, deleteRWHR, getRWHRDetails, updateRWHR
} from "../../../_helper/services/EMR/RWHR";


function* onCreateRWHR({ payload }) {
    try {
        const response = yield call(createRWHR, payload);
        yield put(createRWHRSuccess(response));
        ShowSucess("Reduce Work Hour Record Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createRWHRFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateRWHR());
    }
}

function* onGetRWHRDetails({ payload: id }) {
    try {
        const response = yield call(getRWHRDetails, id);
        yield put(getRWHRDetailsSuccess(response));
    } catch (error) {
        yield put(getRWHRDetailsFail(error.response));
    }
}
function* onUpdateRWHR({ payload: { id }, payload }) {
    try {
        const response = yield call(updateRWHR, id, payload);
        yield put(updateRWHRSuccess(response));
        yield put(getRWHRDetailsSuccess(payload));
        ShowSucess(`Reduce Work Hour Record Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateRWHRFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteRWHR({ payload: id }) {
    try {
        const response = yield call(deleteRWHR, id);
        yield put(deleteRWHRSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteRWHRFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* RWHRSaga() {
    yield takeLatest(RWHR_CREATE, onCreateRWHR);
    yield takeLatest(GET_RWHR_DETAILS, onGetRWHRDetails);
    yield takeLatest(UPDATE_RWHR, onUpdateRWHR);
    yield takeLatest(DELETE_RWHR, onDeleteRWHR);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_RWHR_CREATE, resetCreateRWHR);
}

export default RWHRSaga;