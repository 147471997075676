import {
    SPEC_MEDICAL_EVAL_CREATE,
    SPEC_MEDICAL_EVAL_CREATE_FAIL,
    SPEC_MEDICAL_EVAL_CREATE_SUCCESS,
    DELETE_SPEC_MEDICAL_EVAL,
    DELETE_SPEC_MEDICAL_EVAL_FAIL,
    DELETE_SPEC_MEDICAL_EVAL_SUCCESS,
    GET_SPEC_MEDICAL_EVAL_DETAILS,
    GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL,
    GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS,
    RESET,
    RESET_SPEC_MEDICAL_EVAL_CREATE,
    RESET_SPEC_MEDICAL_EVAL_DELETE,
    RESET_SPEC_MEDICAL_EVAL_DETAILS,
    UPDATE_SPEC_MEDICAL_EVAL,
    UPDATE_SPEC_MEDICAL_EVAL_FAIL,
    UPDATE_SPEC_MEDICAL_EVAL_SUCCESS,
} from "./actionsTypes";

const initialState = {
    specMedicalEvalList: [],
    specMedicalEvalDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const SpecMedicalEvalReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case SPEC_MEDICAL_EVAL_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case SPEC_MEDICAL_EVAL_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case SPEC_MEDICAL_EVAL_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_SPEC_MEDICAL_EVAL_DETAILS:
            state = { ...state, detailsLoading: true, specMedicalEvalDetails: {} };
            break;
        case GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS:
            state = { ...state, specMedicalEvalDetails: action.payload, detailsLoading: false };
            break;
        case GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_SPEC_MEDICAL_EVAL:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_SPEC_MEDICAL_EVAL_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_SPEC_MEDICAL_EVAL_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_SPEC_MEDICAL_EVAL:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_SPEC_MEDICAL_EVAL_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_SPEC_MEDICAL_EVAL_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_SPEC_MEDICAL_EVAL_DETAILS:
            state = { ...state, specMedicalEvalDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, specMedicalEvalList: [], createResult: {} };
            break;
        case RESET_SPEC_MEDICAL_EVAL_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_SPEC_MEDICAL_EVAL_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default SpecMedicalEvalReducer;