import {
    GET_DT_TEST_TYPES, GET_DT_TEST_TYPES_SUCCESS, GET_DT_TEST_TYPES_FAIL,
    GET_DT_RESULTS, GET_DT_RESULTS_SUCCESS,GET_DT_RESULTS_FAIL,
    RESET,
    GET_DT_RESULT_DETAILS,
    GET_DT_RESULT_DETAILS_SUCCESS,
    GET_DT_RESULT_DETAILS_FAIL,
    GET_DT_TYPE_OF_COLLECTION,
    GET_DT_TYPE_OF_COLLECTION_SUCCESS,
    GET_DT_TYPE_OF_COLLECTION_FAIL,
    GET_DT_NATURE_OF_ACTIONS,
    GET_DT_NATURE_OF_ACTIONS_SUCCESS,
    GET_DT_NATURE_OF_ACTIONS_FAIL,
    GET_DT_RELEASED_TO,
    GET_DT_RELEASED_TO_SUCCESS,
    GET_DT_RELEASED_TO_FAIL,
    GET_DT_FUNDING_STATUSES,
    GET_DT_FUNDING_STATUSES_SUCCESS,
    GET_DT_FUNDING_STATUSES_FAIL,
    GET_DT_RANDOM_REASONS,
    GET_DT_RANDOM_REASONS_SUCCESS,
    GET_DT_RANDOM_REASONS_FAIL,
    GET_TDP_TYPES,
    GET_TDP_TYPES_SUCCESS,
    GET_TDP_TYPES_FAIL,
    GET_QUARTER_REPORT_DATES,
    GET_QUARTER_REPORT_DATES_SUCCESS,
    GET_QUARTER_REPORT_DATES_FAIL,
    GET_VENDOR_LOG_STATUS_LISTS,
    GET_VENDOR_LOG_STATUS_LISTS_FAIL,
    GET_VENDOR_LOG_STATUS_LISTS_SUCCESS,
} from "./actionsTypes";

//Get All DT Types Actions
export const getDTTestTypes = () => {
    return {
        type: GET_DT_TEST_TYPES
    };
};
export const getDTTestTypesSuccess = (results) => {
    return {
        type: GET_DT_TEST_TYPES_SUCCESS,
        payload: results,
    };
};
export const getDTTestTypesFail = (error) => {
    return {
        type: GET_DT_TEST_TYPES_FAIL,
        payload: error,
    };
};

//Get All DT Results Actions
export const getDTResults = () => {
    return {
        type: GET_DT_RESULTS
    };
};
export const getDTResultsSuccess = (results) => {
    return {
        type: GET_DT_RESULTS_SUCCESS,
        payload: results,
    };
};
export const getDTResultsFail = (error) => {
    return {
        type: GET_DT_RESULTS_FAIL,
        payload: error,
    };
};

//Get DT Result Details Actions
export const getDTResultDetails = () => {
    return {
        type: GET_DT_RESULT_DETAILS
    };
};
export const getDTResultDetailsSuccess = (results) => {
    return {
        type: GET_DT_RESULT_DETAILS_SUCCESS,
        payload: results,
    };
};
export const getDTResultDetailsFail = (error) => {
    return {
        type: GET_DT_RESULT_DETAILS_FAIL,
        payload: error,
    };
};

//Get DT Type Of Collection Actions
export const getDTTypeOfCollection = () => {
    return {
        type: GET_DT_TYPE_OF_COLLECTION,
    };
};
export const getDTTypeOfCollectionSuccess = (results) => {
    return {
        type: GET_DT_TYPE_OF_COLLECTION_SUCCESS,
        payload: results,
    };
};
export const getDTTypeOfCollectionFail = (error) => {
    return {
        type: GET_DT_TYPE_OF_COLLECTION_FAIL,
        payload: error,
    };
};

//Get DT Nature Of Actions
export const getDTNatureOfActions = () => {
    return {
        type: GET_DT_NATURE_OF_ACTIONS,
    };
};
export const getDTNatureOfActionsSuccess = (results) => {
    return {
        type: GET_DT_NATURE_OF_ACTIONS_SUCCESS,
        payload: results,
    };
};
export const getDTNatureOfActionsFail = (error) => {
    return {
        type: GET_DT_NATURE_OF_ACTIONS_FAIL,
        payload: error,
    };
};

//Get DT Released To
export const getDTReleasedTo = () => {
    return {
        type: GET_DT_RELEASED_TO,
    };
};
export const getDTReleasedToSuccess = (results) => {
    return {
        type: GET_DT_RELEASED_TO_SUCCESS,
        payload: results,
    };
};
export const getDTReleasedToFail = (error) => {
    return {
        type: GET_DT_RELEASED_TO_FAIL,
        payload: error,
    };
};

//Get Funding Status
export const getDTFundingStatues = () => {
    return {
        type: GET_DT_FUNDING_STATUSES,
    };
};
export const getDTFundingStatuesSuccess = (results) => {
    return {
        type: GET_DT_FUNDING_STATUSES_SUCCESS,
        payload: results,
    };
};
export const getDTFundingStatuesFail = (error) => {
    return {
        type: GET_DT_FUNDING_STATUSES_FAIL,
        payload: error,
    };
};


//Get DT Random Reasons
export const getDTRandomReasons = () => {
    return {
        type: GET_DT_RANDOM_REASONS,
    };
};
export const getDTRandomReasonsSuccess = (results) => {
    return {
        type: GET_DT_RANDOM_REASONS_SUCCESS,
        payload: results,
    };
};
export const getDTRandomReasonsFail = (error) => {
    return {
        type: GET_DT_RANDOM_REASONS_FAIL,
        payload: error,
    };
};

//Get TDP Types
export const getTDPTypes = () => {
    return {
        type: GET_TDP_TYPES,
    };
};
export const getTDPTypesSuccess = (results) => {
    return {
        type: GET_TDP_TYPES_SUCCESS,
        payload: results,
    };
};
export const getTDPTypesFail = (error) => {
    return {
        type: GET_TDP_TYPES_FAIL,
        payload: error,
    };
};

//Get Quarter Report Dates
export const getQuarterReportDates = () => {
    return {
        type: GET_QUARTER_REPORT_DATES,
    };
};
export const getQuarterReportDatesSuccess = (results) => {
    return {
        type: GET_QUARTER_REPORT_DATES_SUCCESS,
        payload: results,
    };
};
export const getQuarterReportDatesFail = (error) => {
    return {
        type: GET_QUARTER_REPORT_DATES_FAIL,
        payload: error,
    };
};

//Get Vendor Log Status
export const getVendorLogStatusLists = () => {
    return {
        type: GET_VENDOR_LOG_STATUS_LISTS,
    };
};
export const getVendorLogStatusListsSuccess = (results) => {
    return {
        type:GET_VENDOR_LOG_STATUS_LISTS_SUCCESS,
        payload: results,
    };
};
export const getVendorLogStatusListsFail = (error) => {
    return {
        type: GET_VENDOR_LOG_STATUS_LISTS_FAIL,
        payload: error,
    };
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,

    };
};