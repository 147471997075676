export const SPEC_MEDICAL_EVAL_CREATE = "SPEC_MEDICAL_EVAL_CREATE";
export const SPEC_MEDICAL_EVAL_CREATE_SUCCESS = "SPEC_MEDICAL_EVAL_CREATE_SUCCESS";
export const SPEC_MEDICAL_EVAL_CREATE_FAIL = "SPEC_MEDICAL_EVAL_CREATE_FAIL";

export const GET_SPEC_MEDICAL_EVAL_DETAILS = "GET_SPEC_MEDICAL_EVAL_DETAILS";
export const GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS = "GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS";
export const GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL = "GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL";

export const UPDATE_SPEC_MEDICAL_EVAL = "UPDATE_SPEC_MEDICAL_EVAL";
export const UPDATE_SPEC_MEDICAL_EVAL_SUCCESS = "UPDATE_SPEC_MEDICAL_EVAL_SUCCESS";
export const UPDATE_SPEC_MEDICAL_EVAL_FAIL = "UPDATE_SPEC_MEDICAL_EVAL_FAIL";

export const DELETE_SPEC_MEDICAL_EVAL = "DELETE_SPEC_MEDICAL_EVAL";
export const DELETE_SPEC_MEDICAL_EVAL_SUCCESS = "DELETE_SPEC_MEDICAL_EVAL_SUCCESS";
export const DELETE_SPEC_MEDICAL_EVAL_FAIL = "DELETE_SPEC_MEDICAL_EVAL_FAIL";

export const RESET = "RESET";
export const RESET_SPEC_MEDICAL_EVAL_CREATE = "RESET_SPEC_MEDICAL_EVAL_CREATE";
export const RESET_SPEC_MEDICAL_EVAL_DETAILS = "RESET_SPEC_MEDICAL_EVAL_DETAILS";
export const RESET_SPEC_MEDICAL_EVAL_DELETE = "RESET_SPEC_MEDICAL_EVAL_DELETE";