export const VENDOR_LOG_CREATE = "VENDOR_LOG_CREATE";
export const VENDOR_LOG_CREATE_SUCCESS = "VENDOR_LOG_CREATE_SUCCESS";
export const VENDOR_LOG_CREATE_FAIL = "VENDOR_LOG_CREATE_FAIL";

export const GET_VENDOR_LOG_DETAILS = "GET_VENDOR_LOG_DETAILS";
export const GET_VENDOR_LOG_DETAILS_SUCCESS = "GET_VENDOR_LOG_DETAILS_SUCCESS";
export const GET_VENDOR_LOG_DETAILS_FAIL = "GET_VENDOR_LOG_DETAILS_FAIL";

export const UPDATE_VENDOR_LOG = "UPDATE_VENDOR_LOG";
export const UPDATE_VENDOR_LOG_SUCCESS = "UPDATE_VENDOR_LOG_SUCCESS";
export const UPDATE_VENDOR_LOG_FAIL = "UPDATE_VENDOR_LOG_FAIL";

export const DELETE_VENDOR_LOG = "DELETE_VENDOR_LOG";
export const DELETE_VENDOR_LOG_SUCCESS = "DELETE_VENDOR_LOG_SUCCESS";
export const DELETE_VENDOR_LOG_FAIL = "DELETE_VENDOR_LOG_FAIL";

export const RESET = "RESET";
export const RESET_VENDOR_LOG_CREATE = "RESET_VENDOR_LOG_CREATE";
export const RESET_VENDOR_LOG_DETAILS = "RESET_VENDOR_LOG_DETAILS";