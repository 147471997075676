import {
    MEDIA_SOURCE_CREATE,
    MEDIA_SOURCE_CREATE_FAIL,
    MEDIA_SOURCE_CREATE_SUCCESS,
    DELETE_MEDIA_SOURCE,
    DELETE_MEDIA_SOURCE_FAIL,
    DELETE_MEDIA_SOURCE_SUCCESS,
    GET_MEDIA_SOURCES,
    GET_MEDIA_SOURCES_FAIL,
    GET_MEDIA_SOURCES_SUCCESS,
    GET_MEDIA_SOURCE_DETAILS,
    GET_MEDIA_SOURCE_DETAILS_FAIL,
    GET_MEDIA_SOURCE_DETAILS_SUCCESS,
    RESET,
    RESET_MEDIA_SOURCE_CREATE,
    RESET_MEDIA_SOURCE_DELETE,
    RESET_MEDIA_SOURCE_DETAILS,
    UPDATE_MEDIA_SOURCE,
    UPDATE_MEDIA_SOURCE_FAIL,
    UPDATE_MEDIA_SOURCE_SUCCESS,
} from "./actionsTypes";

const initialState = {
    mediaSourceList: [],
    mediaSourceDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const MediaSourceReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case MEDIA_SOURCE_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case MEDIA_SOURCE_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case MEDIA_SOURCE_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_MEDIA_SOURCES:
            state = { ...state, loading: true };
            break;
        case GET_MEDIA_SOURCES_SUCCESS:
            state = { ...state, mediaSourceList: action.payload, loading: false };
            break;
        case GET_MEDIA_SOURCES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_MEDIA_SOURCE_DETAILS:
            state = { ...state, detailsLoading: true, mediaSourceDetails: {} };
            break;
        case GET_MEDIA_SOURCE_DETAILS_SUCCESS:
            state = { ...state, mediaSourceDetails: action.payload, detailsLoading: false };
            break;
        case GET_MEDIA_SOURCE_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_MEDIA_SOURCE:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_MEDIA_SOURCE_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_MEDIA_SOURCE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_MEDIA_SOURCE:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_MEDIA_SOURCE_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_MEDIA_SOURCE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_MEDIA_SOURCE_DETAILS:
            state = { ...state, MediaSourceDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, MediaSourceList: [], createResult: {} };
            break;
        case RESET_MEDIA_SOURCE_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_MEDIA_SOURCE_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default MediaSourceReducer;