import {
    CBA_STATUS_CREATE, CBA_STATUS_CREATE_FAIL, CBA_STATUS_CREATE_SUCCESS,
    DELETE_CBA_STATUS,
    DELETE_CBA_STATUS_FAIL,
    DELETE_CBA_STATUS_SUCCESS,
    GET_CBA_STATUSES,
    GET_CBA_STATUSES_FAIL,
    GET_CBA_STATUSES_SUCCESS,
    GET_CBA_STATUS_DETAILS,
    GET_CBA_STATUS_DETAILS_FAIL,
    GET_CBA_STATUS_DETAILS_SUCCESS,
    RESET,
    RESET_CBA_STATUS_CREATE,
    RESET_CBA_STATUS_DELETE,
    RESET_CBA_STATUS_DETAILS,
    UPDATE_CBA_STATUS,
    UPDATE_CBA_STATUS_FAIL,
    UPDATE_CBA_STATUS_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createCBAStatus = (payload) => {
    return {
        type: CBA_STATUS_CREATE,
        payload: payload
    }
};
export const createCBAStatusSuccess = (response) => {
    return {
        type: CBA_STATUS_CREATE_SUCCESS,
        payload: response
    }
};
export const createCBAStatusFail = (error) => {
    return {
        type: CBA_STATUS_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getCBAStatusDetails = (id) => {
    return {
        type: GET_CBA_STATUS_DETAILS,
        payload: id,
    };
};
export const getCBAStatusDetailsSuccess = (data) => {
    return {
        type: GET_CBA_STATUS_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getCBAStatusDetailsFail = (error) => {
    return {
        type: GET_CBA_STATUS_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getCBAStatuses = () => {
    return {
        type: GET_CBA_STATUSES,
    };
};
export const getCBAStatusesSuccess = (data) => {
    return {
        type: GET_CBA_STATUSES_SUCCESS,
        payload: data,
    };
};
export const getCBAStatusesFail = (error) => {
    return {
        type: GET_CBA_STATUSES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateCBAStatus = (payload) => {

    return {
        type: UPDATE_CBA_STATUS,
        payload: payload
    }
};
export const updateCBAStatusSuccess = (response) => {
    return {
        type: UPDATE_CBA_STATUS_SUCCESS,
        payload: response
    }
};
export const updateCBAStatusFail = (error) => {
    return {
        type: UPDATE_CBA_STATUS_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteCBAStatus = (id) => {
    return {
        type: DELETE_CBA_STATUS,
        payload: id
    }
};
export const deleteCBAStatusSuccess = (response) => {
    return {
        type: DELETE_CBA_STATUS_SUCCESS,
        payload: response
    }
};
export const deleteCBAStatusFail = (error) => {
    return {
        type: DELETE_CBA_STATUS_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateCBAStatus = () => {
    return {
        type: RESET_CBA_STATUS_CREATE
    }
};

export const resetCBAStatusDetails = () => {
    return {
        type: RESET_CBA_STATUS_DETAILS
    }
};

export const resetCBAStatusDeleteData = () => {
    return {
        type: RESET_CBA_STATUS_DELETE
    }
};