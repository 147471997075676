import {
    STATUS_OF_APPT_CREATE,
    STATUS_OF_APPT_CREATE_FAIL,
    STATUS_OF_APPT_CREATE_SUCCESS,
    DELETE_STATUS_OF_APPT,
    DELETE_STATUS_OF_APPT_FAIL,
    DELETE_STATUS_OF_APPT_SUCCESS,
    GET_STATUS_OF_APPTES,
    GET_STATUS_OF_APPTES_FAIL,
    GET_STATUS_OF_APPTES_SUCCESS,
    GET_STATUS_OF_APPT_DETAILS,
    GET_STATUS_OF_APPT_DETAILS_FAIL,
    GET_STATUS_OF_APPT_DETAILS_SUCCESS,
    RESET,
    RESET_STATUS_OF_APPT_CREATE,
    RESET_STATUS_OF_APPT_DELETE,
    RESET_STATUS_OF_APPT_DETAILS,
    UPDATE_STATUS_OF_APPT,
    UPDATE_STATUS_OF_APPT_FAIL,
    UPDATE_STATUS_OF_APPT_SUCCESS,
} from "./actionsTypes";

const initialState = {
    statusOfAPPTList: [],
    statusOfAPPTDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const StatusOfAPPTReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case STATUS_OF_APPT_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case STATUS_OF_APPT_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case STATUS_OF_APPT_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_STATUS_OF_APPTES:
            state = { ...state, loading: true };
            break;
        case GET_STATUS_OF_APPTES_SUCCESS:
            state = { ...state, statusOfAPPTList: action.payload, loading: false };
            break;
        case GET_STATUS_OF_APPTES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_STATUS_OF_APPT_DETAILS:
            state = { ...state, detailsLoading: true, statusOfAPPTDetails: {} };
            break;
        case GET_STATUS_OF_APPT_DETAILS_SUCCESS:
            state = { ...state, statusOfAPPTDetails: action.payload, detailsLoading: false };
            break;
        case GET_STATUS_OF_APPT_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_STATUS_OF_APPT:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_STATUS_OF_APPT_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_STATUS_OF_APPT_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_STATUS_OF_APPT:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_STATUS_OF_APPT_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_STATUS_OF_APPT_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_STATUS_OF_APPT_DETAILS:
            state = { ...state, statusOfAPPTDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, statusOfAPPTList: [], createResult: {} };
            break;
        case RESET_STATUS_OF_APPT_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_STATUS_OF_APPT_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default StatusOfAPPTReducer;