import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_DEPARTMENT_RANDOM_DATE_LOG, DEPARTMENT_RANDOM_DATE_LOG_CREATE, GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS, RESET, RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE, UPDATE_DEPARTMENT_RANDOM_DATE_LOG } from "./actionsTypes";

import {
    createDepartmentRandomDateLogSuccess,
    createDepartmentRandomDateLogFail,
    reset,
    resetCreateDepartmentRandomDateLog,
    getDepartmentRandomDateLogDetailsSuccess,
    getDepartmentRandomDateLogDetailsFail,
    updateDepartmentRandomDateLogSuccess,
    updateDepartmentRandomDateLogFail,
    deleteDepartmentRandomDateLogSuccess,
    deleteDepartmentRandomDateLogFail,
} from "./action";

import {
    createDepartmentRandomDateLog, deleteDepartmentRandomDateLog, getDepartmentRandomDateLogDetails, updateDepartmentRandomDateLog
} from "../../../_helper/services/DrugTesting/departmentRandomDateLog";


function* onCreateDepartmentRandomDateLog({ payload }) {
    try {
        const response = yield call(createDepartmentRandomDateLog, payload);
        yield put(createDepartmentRandomDateLogSuccess(response));
        ShowSucess("Department Random Date Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createDepartmentRandomDateLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateDepartmentRandomDateLog());
    }
}

function* onGetDepartmentRandomDateLogDetails({ payload: id }) {
    try {
        const response = yield call(getDepartmentRandomDateLogDetails, id);
        yield put(getDepartmentRandomDateLogDetailsSuccess(response));
    } catch (error) {
        yield put(getDepartmentRandomDateLogDetailsFail(error.response));
    }
}
function* onUpdateDepartmentRandomDateLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateDepartmentRandomDateLog, id, payload);
        yield put(updateDepartmentRandomDateLogSuccess(response));
        yield put(getDepartmentRandomDateLogDetailsSuccess(payload));
        ShowSucess(`Department Random Date Log Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateDepartmentRandomDateLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteDepartmentRandomDateLog({ payload: id }) {
    try {
        const response = yield call(deleteDepartmentRandomDateLog, id);
        yield put(deleteDepartmentRandomDateLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteDepartmentRandomDateLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* DepartmentRandomDateLogSaga() {
    yield takeLatest(DEPARTMENT_RANDOM_DATE_LOG_CREATE, onCreateDepartmentRandomDateLog);
    yield takeLatest(RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE, resetCreateDepartmentRandomDateLog);
    yield takeLatest(GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS, onGetDepartmentRandomDateLogDetails);
    yield takeLatest(UPDATE_DEPARTMENT_RANDOM_DATE_LOG, onUpdateDepartmentRandomDateLog);
    yield takeLatest(DELETE_DEPARTMENT_RANDOM_DATE_LOG, onDeleteDepartmentRandomDateLog);
    yield takeLatest(RESET, reset)
}

export default DepartmentRandomDateLogSaga;