import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { TRAINING_LOG_CREATE, DELETE_TRAINING_LOG, GET_TRAINING_LOG_DETAILS, RESET, RESET_TRAINING_LOG_CREATE, UPDATE_TRAINING_LOG, GET_TRAINING__AUDIT_LOGS } from "./actionsTypes";

import {
    createTrainingLogSuccess,
    createTrainingLogFail,
    reset,
    resetCreateTrainingLog,
    getTrainingLogDetailsSuccess,
    getTrainingLogDetailsFail,
    updateTrainingLogSuccess,
    deleteTrainingLogSuccess,
    deleteTrainingLogFail,
    updateTrainingLogFail,
    getTraining__AuditLogsSuccess,
    getTraining__AuditLogsFail,
} from "./action";

import {
    createTrainingLog, deleteTrainingLog, getTrainingLogDetails, getTrainingLog__AuditLogs, updateTrainingLog
} from "../../../_helper/services/EMR/TrainingLog";


function* onCreateTrainingLog({ payload }) {
    try {
        const response = yield call(createTrainingLog, payload);
        yield put(createTrainingLogSuccess(response));
        ShowSucess("Training Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTrainingLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTrainingLog());
    }
}

function* onGetTrainingLogDetails({ payload: id }) {
    try {
        const response = yield call(getTrainingLogDetails, id);
        yield put(getTrainingLogDetailsSuccess(response));
    } catch (error) {
        yield put(getTrainingLogDetailsFail(error.response));
    }
}

function* onUpdateTrainingLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTrainingLog, id, payload);
        yield put(updateTrainingLogSuccess(response));
        yield put(getTrainingLogDetailsSuccess(payload));
        ShowSucess(`Training Log Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTrainingLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTrainingLog({ payload: id }) {
    try {
        const response = yield call(deleteTrainingLog, id);
        yield put(deleteTrainingLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTrainingLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onGetTraining__AuditLogs({ payload }) {
    try {
        const response = yield call(getTrainingLog__AuditLogs, payload);
        yield put(getTraining__AuditLogsSuccess(response?.results));
    } catch (error) {
        yield put(getTraining__AuditLogsFail(error.response));
    }
}

function* TrainingLogSaga() {
    yield takeLatest(TRAINING_LOG_CREATE, onCreateTrainingLog);
    yield takeLatest(GET_TRAINING_LOG_DETAILS, onGetTrainingLogDetails);
    yield takeLatest(UPDATE_TRAINING_LOG, onUpdateTrainingLog);
    yield takeLatest(DELETE_TRAINING_LOG, onDeleteTrainingLog);

    yield takeLatest(GET_TRAINING__AUDIT_LOGS, onGetTraining__AuditLogs);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_TRAINING_LOG_CREATE, resetCreateTrainingLog);
}

export default TrainingLogSaga;