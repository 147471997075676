import {
    TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE, TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_FAIL, TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_SUCCESS,
    RESET,
    RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE,
    GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS,
    GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_SUCCESS,
    GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_FAIL,
    UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG,
    UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS,
    UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL,
    DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG,
    DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS,
    DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL,
    RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS,
} from "./actionsTypes";

//Create Actions
export const createTDPEmployeeMasterListLog = (payload) => {
    return {
        type: TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE,
        payload: payload
    }
};
export const createTDPEmployeeMasterListLogSuccess = (response) => {
    return {
        type: TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createTDPEmployeeMasterListLogFail = (error) => {
    return {
        type: TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTDPEmployeeMasterListLogDetails = (id) => {
    return {
        type: GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS,
        payload: id,
    };
};
export const getTDPEmployeeMasterListLogDetailsSuccess = (data) => {
    return {
        type: GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTDPEmployeeMasterListLogDetailsFail = (error) => {
    return {
        type: GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTDPEmployeeMasterListLog = (payload) => {
    return {
        type: UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG,
        payload: payload
    }
};
export const updateTDPEmployeeMasterListLogSuccess = (response) => {
    return {
        type: UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS,
        payload: response
    }
};
export const updateTDPEmployeeMasterListLogFail = (error) => {
    return {
        type: UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTDPEmployeeMasterListLog = (id) => {
    return {
        type: DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG,
        payload: id
    }
};
export const deleteTDPEmployeeMasterListLogSuccess = (response) => {
    return {
        type: DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS,
        payload: response
    }
};
export const deleteTDPEmployeeMasterListLogFail = (error) => {
    return {
        type: DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateTDPEmployeeMasterListLog = () => {
    return {
        type: RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE
    }
};
export const resetTDPEmployeeMasterListLogDetails = () => {
    return {
        type: RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS,
    };
};