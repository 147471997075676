// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPaytimecodeDetails =(code)=> get(`${baseURL+url.PAYTIMECODE_BASE}/${code}`);
export const getPaytimecodes =({page,pageSize})=> get(baseURL+url.PAYTIMECODE_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);

export const updatePaytimecode = (code, payload) => put(`${baseURL}${url.PAYTIMECODE_BASE}/${code}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createPaytimecode =(payload) => post(baseURL+url.PAYTIMECODE_BASE,payload);

export const deletePaytimecode = (code) => remove(baseURL+url.PAYTIMECODE_BASE+'/'+code);