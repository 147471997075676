import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createTypesOfWorkHours = (payload) => post(baseURL + url.TYPES_OF_WORK_HOURS, payload);
export const getTypesOfWorkHoursDetails = (id) => get(`${baseURL + url.TYPES_OF_WORK_HOURS}/${id}`);
export const getTypesOfWorkHourses = () => get(`${baseURL + url.TYPES_OF_WORK_HOURS}`);
export const updateTypesOfWorkHours = (id, payload) => put(`${baseURL + url.TYPES_OF_WORK_HOURS}/${id}`, payload);
export const deleteTypesOfWorkHours = (id) => remove(`${baseURL + url.TYPES_OF_WORK_HOURS}/${id}`);
