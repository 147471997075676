import {
    RWHR_CREATE, RWHR_CREATE_FAIL, RWHR_CREATE_SUCCESS,
    DELETE_RWHR,
    DELETE_RWHR_FAIL,
    DELETE_RWHR_SUCCESS,
    GET_RWHR_DETAILS,
    GET_RWHR_DETAILS_FAIL,
    GET_RWHR_DETAILS_SUCCESS,
    RESET,
    RESET_RWHR_CREATE,
    RESET_RWHR_DELETE,
    RESET_RWHR_DETAILS,
    UPDATE_RWHR,
    UPDATE_RWHR_FAIL,
    UPDATE_RWHR_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createRWHR = (payload) => {
    return {
        type: RWHR_CREATE,
        payload: payload
    }
};
export const createRWHRSuccess = (response) => {
    return {
        type: RWHR_CREATE_SUCCESS,
        payload: response
    }
};
export const createRWHRFail = (error) => {
    return {
        type: RWHR_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getRWHRDetails = (id) => {
    return {
        type: GET_RWHR_DETAILS,
        payload: id,
    };
};
export const getRWHRDetailsSuccess = (data) => {
    return {
        type: GET_RWHR_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getRWHRDetailsFail = (error) => {
    return {
        type: GET_RWHR_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateRWHR = (payload) => {

    return {
        type: UPDATE_RWHR,
        payload: payload
    }
};
export const updateRWHRSuccess = (response) => {
    return {
        type: UPDATE_RWHR_SUCCESS,
        payload: response
    }
};
export const updateRWHRFail = (error) => {
    return {
        type: UPDATE_RWHR_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteRWHR = (id) => {
    return {
        type: DELETE_RWHR,
        payload: id
    }
};
export const deleteRWHRSuccess = (response) => {
    return {
        type: DELETE_RWHR_SUCCESS,
        payload: response
    }
};
export const deleteRWHRFail = (error) => {
    return {
        type: DELETE_RWHR_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateRWHR = () => {
    return {
        type: RESET_RWHR_CREATE
    }
};

export const resetRWHRDetails = () => {
    return {
        type: RESET_RWHR_DETAILS
    }
};

export const resetRWHRDeleteData = () => {
    return {
        type: RESET_RWHR_DELETE
    }
};