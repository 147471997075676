import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { TYPE_OF_TRAINING_CREATE, DELETE_TYPE_OF_TRAINING, GET_TYPE_OF_TRAININGES, GET_TYPE_OF_TRAINING_DETAILS, RESET, RESET_TYPE_OF_TRAINING_CREATE, UPDATE_TYPE_OF_TRAINING } from "./actionsTypes";

import {
    createTypeOfTrainingSuccess,
    createTypeOfTrainingFail,
    reset,
    resetCreateTypeOfTraining,
    getTypeOfTrainingDetailsSuccess,
    getTypeOfTrainingDetailsFail,
    updateTypeOfTrainingSuccess,
    deleteTypeOfTrainingSuccess,
    deleteTypeOfTrainingFail,
    updateTypeOfTrainingFail,
    getTypeOfTrainingesSuccess,
    getTypeOfTrainingesFail,
} from "./action";

import {
    createTypeOfTraining, deleteTypeOfTraining, getTypeOfTrainingDetails, getTypeOfTraininges, updateTypeOfTraining
} from "../../../../_helper/services/EMR/TypesOfTraining";


function* onCreateTypeOfTraining({ payload }) {
    try {
        const response = yield call(createTypeOfTraining, payload);
        yield put(createTypeOfTrainingSuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTypeOfTrainingFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTypeOfTraining());
    }
}

function* onGetTypeOfTrainingDetails({ payload: id }) {
    try {
        const response = yield call(getTypeOfTrainingDetails, id);
        yield put(getTypeOfTrainingDetailsSuccess(response));
    } catch (error) {
        yield put(getTypeOfTrainingDetailsFail(error.response));
    }
}

function* onGetTypeOfTraininges() {
    try {
        const response = yield call(getTypeOfTraininges);
        yield put(getTypeOfTrainingesSuccess(response));
    } catch (error) {
        yield put(getTypeOfTrainingesFail(error.response));
    }
}

function* onUpdateTypeOfTraining({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTypeOfTraining, id, payload);
        yield put(updateTypeOfTrainingSuccess(response));
        yield put(getTypeOfTrainingDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTypeOfTrainingFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTypeOfTraining({ payload: id }) {
    try {
        const response = yield call(deleteTypeOfTraining, id);
        yield put(deleteTypeOfTrainingSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTypeOfTrainingFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TypeOfTrainingSaga() {
    yield takeLatest(TYPE_OF_TRAINING_CREATE, onCreateTypeOfTraining);
    yield takeLatest(GET_TYPE_OF_TRAINING_DETAILS, onGetTypeOfTrainingDetails);
    yield takeLatest(GET_TYPE_OF_TRAININGES, onGetTypeOfTraininges);
    yield takeLatest(UPDATE_TYPE_OF_TRAINING, onUpdateTypeOfTraining);
    yield takeLatest(DELETE_TYPE_OF_TRAINING, onDeleteTypeOfTraining);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_TYPE_OF_TRAINING_CREATE, resetCreateTypeOfTraining);
}

export default TypeOfTrainingSaga;