import {
	GET_POSITIONS, GET_POSITIONS_SUCCESS, GET_POSITIONS_FAIL,
	GET_POSITIONS_DETAILS, GET_POSITIONS_DETAILS_SUCCESS, GET_POSITIONS_DETAILS_FAIL,
	POSITION_CREATE, POSITION_CREATE_FAIL, POSITION_CREATE_SUCCESS,
	POSITION_DELETE, POSITION_DELETE_FAIL, POSITION_DELETE_SUCCESS,
	POSITION_UPDATE, POSITION_UPDATE_FAIL, POSITION_UPDATE_SUCCESS,
} from "./actionTypes";

const initialState = {
	positionList: [],
	position: {},
	postingResult: {},
	loadingPositionList: false,
	loadingPositionDetails: false,
	positionSetting:{},
	loadingpositionSetting: false,
	posting: false,
	error: {
		message: "",
	},
};

const NewPositionReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_POSITIONS:
			state = { ...state, loadingPositionList: false };
			break;
		case GET_POSITIONS_SUCCESS:
			state = { ...state, positionList: action.payload, loadingPositionList: false };
			break;
		case GET_POSITIONS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPositionList: false,
			};
			break;
		case GET_POSITIONS_DETAILS:
			state = { ...state, loadingPositionDetails: true };
			break;
		case GET_POSITIONS_DETAILS_SUCCESS:
			state = { ...state, position: action.payload, loadingPositionDetails: false };
			break;
		case GET_POSITIONS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPositionDetails: false,
			};
			break;
		case POSITION_CREATE:
			state = { ...state, postingResult: {}, posting: true };
			break;
		case POSITION_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, posting: false };
			break;
		case POSITION_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				posting: false,
			};
			break;
		case POSITION_UPDATE:
			state = { ...state, postingResult: {}, posting: true };
			break;
		case POSITION_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, posting: false };
			break;
		case POSITION_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				posting: false,
			};
			break;
		case POSITION_DELETE:
			state = { ...state, postingResult: {}, posting: true };
			break;
		case POSITION_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, posting: false };
			break;
		case POSITION_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				posting: false,
			};
			break;
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default NewPositionReducer;