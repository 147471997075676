export const CBA_STATUS_CREATE = "CBA_STATUS_CREATE";
export const CBA_STATUS_CREATE_SUCCESS = "CBA_STATUS_CREATE_SUCCESS";
export const CBA_STATUS_CREATE_FAIL = "CBA_STATUS_CREATE_FAIL";

export const GET_CBA_STATUS_DETAILS = "GET_CBA_STATUS_DETAILS";
export const GET_CBA_STATUS_DETAILS_SUCCESS = "GET_CBA_STATUS_DETAILS_SUCCESS";
export const GET_CBA_STATUS_DETAILS_FAIL = "GET_CBA_STATUS_DETAILS_FAIL";

export const GET_CBA_STATUSES = "GET_CBA_STATUSES";
export const GET_CBA_STATUSES_SUCCESS = "GET_CBA_STATUSES_SUCCESS";
export const GET_CBA_STATUSES_FAIL = "GET_CBA_STATUSES_FAIL";

export const UPDATE_CBA_STATUS = "UPDATE_CBA_STATUS";
export const UPDATE_CBA_STATUS_SUCCESS = "UPDATE_CBA_STATUS_SUCCESS";
export const UPDATE_CBA_STATUS_FAIL = "UPDATE_CBA_STATUS_FAIL";

export const DELETE_CBA_STATUS = "DELETE_CBA_STATUS";
export const DELETE_CBA_STATUS_SUCCESS = "DELETE_CBA_STATUS_SUCCESS";
export const DELETE_CBA_STATUS_FAIL = "DELETE_CBA_STATUS_FAIL";

export const RESET = "RESET";
export const RESET_CBA_STATUS_CREATE = "RESET_CBA_STATUS_CREATE";
export const RESET_CBA_STATUS_DETAILS = "RESET_CBA_STATUS_DETAILS";
export const RESET_CBA_STATUS_DELETE = "RESET_CBA_STATUS_DELETE";