import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createGrievanceRequest = (payload) => post(baseURL + url.GRIEVANCE_APPEAL, payload);
export const getGrievanceRequestDetails = (id) => get(`${baseURL + url.GRIEVANCE_APPEAL}/${id}`);
export const getGrievanceRequestes = () => get(`${baseURL + url.GRIEVANCE_APPEAL}`);
export const updateGrievanceRequest = (id, payload) => put(`${baseURL + url.GRIEVANCE_APPEAL}/${id}`, payload);
export const deleteGrievanceRequest = (id) => remove(`${baseURL + url.GRIEVANCE_APPEAL}/${id}`);
