import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_DTPM_DATA_LOG, DTPM_DATA_LOG_CREATE, GET_DTPM_DATA_LOG_DETAILS, RESET, RESET_DTPM_DATA_LOG_CREATE, UPDATE_DTPM_DATA_LOG } from "./actionsTypes";

import {
    createDTPMDataLogSuccess,
    createDTPMDataLogFail,
    reset,
    resetCreateDTPMDataLog,
    getDTPMDataLogDetailsSuccess,
    getDTPMDataLogDetailsFail,
    updateDTPMDataLogSuccess,
    updateDTPMDataLogFail,
    deleteDTPMDataLogSuccess,
    deleteDTPMDataLogFail,
} from "./action";

import {
    createDTPMDataLog, deleteDTPMDataLog, getDTPMDataLogDetails, updateDTPMDataLog
} from "../../../_helper/services/DrugTesting/DTPMDataLog";


function* onCreateDTPMDataLog({ payload }) {
    try {
        const response = yield call(createDTPMDataLog, payload);
        yield put(createDTPMDataLogSuccess(response));
        ShowSucess("DTPM Data Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createDTPMDataLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateDTPMDataLog());
    }
}

function* onGetDTPMDataLogDetails({ payload: id }) {
    try {
        const response = yield call(getDTPMDataLogDetails, id);
        yield put(getDTPMDataLogDetailsSuccess(response));
    } catch (error) {
        yield put(getDTPMDataLogDetailsFail(error.response));
    }
}
function* onUpdateDTPMDataLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateDTPMDataLog, id, payload);
        yield put(updateDTPMDataLogSuccess(response));
        yield put(getDTPMDataLogDetailsSuccess(payload));
        ShowSucess(`DTPM Data Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateDTPMDataLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteDTPMDataLog({ payload: id }) {
    try {
        const response = yield call(deleteDTPMDataLog, id);
        yield put(deleteDTPMDataLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteDTPMDataLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* DTPMDataLogSaga() {
    yield takeLatest(DTPM_DATA_LOG_CREATE, onCreateDTPMDataLog);
    yield takeLatest(RESET_DTPM_DATA_LOG_CREATE, resetCreateDTPMDataLog);
    yield takeLatest(GET_DTPM_DATA_LOG_DETAILS, onGetDTPMDataLogDetails);
    yield takeLatest(UPDATE_DTPM_DATA_LOG, onUpdateDTPMDataLog);
    yield takeLatest(DELETE_DTPM_DATA_LOG, onDeleteDTPMDataLog);
    yield takeLatest(RESET, reset);
}

export default DTPMDataLogSaga;