import {
    ADVERSE_ACTION_CREATE,
    ADVERSE_ACTION_CREATE_FAIL,
    ADVERSE_ACTION_CREATE_SUCCESS,
    DELETE_ADVERSE_ACTION,
    DELETE_ADVERSE_ACTION_FAIL,
    DELETE_ADVERSE_ACTION_SUCCESS,
    GET_ADVERSE_ACTION_DETAILS,
    GET_ADVERSE_ACTION_DETAILS_FAIL,
    GET_ADVERSE_ACTION_DETAILS_SUCCESS,
    RESET,
    RESET_ADVERSE_ACTION_CREATE,
    RESET_ADVERSE_ACTION_DELETE,
    RESET_ADVERSE_ACTION_DETAILS,
    UPDATE_ADVERSE_ACTION,
    UPDATE_ADVERSE_ACTION_FAIL,
    UPDATE_ADVERSE_ACTION_SUCCESS,
} from "./actionsTypes";

const initialState = {
    adverseActionList: [],
    adverseActionDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const AdverseActionReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case ADVERSE_ACTION_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case ADVERSE_ACTION_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case ADVERSE_ACTION_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_ADVERSE_ACTION_DETAILS:
            state = { ...state, detailsLoading: true, adverseActionDetails: {} };
            break;
        case GET_ADVERSE_ACTION_DETAILS_SUCCESS:
            state = { ...state, adverseActionDetails: action.payload, detailsLoading: false };
            break;
        case GET_ADVERSE_ACTION_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_ADVERSE_ACTION:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_ADVERSE_ACTION_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_ADVERSE_ACTION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_ADVERSE_ACTION:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_ADVERSE_ACTION_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_ADVERSE_ACTION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_ADVERSE_ACTION_DETAILS:
            state = { ...state, adverseActionDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, adverseActionList: [], createResult: {} };
            break;
        case RESET_ADVERSE_ACTION_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_ADVERSE_ACTION_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AdverseActionReducer;