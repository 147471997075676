// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getVendorDetails = (id) => get(`${baseURL}${url.VENDOR_BASE}/${id}`);
export const getVendors = ({ page, pageSize }) => get(`${baseURL}${url.VENDOR_BASE}?pageNumber=${page}&pageSize=${pageSize}`);
export const updateVendor = (vendorNo, payload) => put(`${baseURL}${url.VENDOR_BASE}/${vendorNo}`, payload);
export const createVendor = (payload) => post(`${baseURL}${url.VENDOR_BASE}`, payload);
export const deleteVendor = (vendorNo) => remove(`${baseURL}${url.VENDOR_BASE}/${vendorNo}`);

export const getAllVendors = () => get(`${baseURL}${url.VENDOR_BASE}`);
