import {
    TYPES_OF_WORK_HOURS_CREATE, TYPES_OF_WORK_HOURS_CREATE_FAIL, TYPES_OF_WORK_HOURS_CREATE_SUCCESS,
    DELETE_TYPES_OF_WORK_HOURS,
    DELETE_TYPES_OF_WORK_HOURS_FAIL,
    DELETE_TYPES_OF_WORK_HOURS_SUCCESS,
    GET_TYPES_OF_WORK_HOURSES,
    GET_TYPES_OF_WORK_HOURSES_FAIL,
    GET_TYPES_OF_WORK_HOURSES_SUCCESS,
    GET_TYPES_OF_WORK_HOURS_DETAILS,
    GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL,
    GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS,
    RESET,
    RESET_TYPES_OF_WORK_HOURS_CREATE,
    RESET_TYPES_OF_WORK_HOURS_DELETE,
    RESET_TYPES_OF_WORK_HOURS_DETAILS,
    UPDATE_TYPES_OF_WORK_HOURS,
    UPDATE_TYPES_OF_WORK_HOURS_FAIL,
    UPDATE_TYPES_OF_WORK_HOURS_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createTypesOfWorkHours = (payload) => {
    return {
        type: TYPES_OF_WORK_HOURS_CREATE,
        payload: payload
    }
};
export const createTypesOfWorkHoursSuccess = (response) => {
    return {
        type: TYPES_OF_WORK_HOURS_CREATE_SUCCESS,
        payload: response
    }
};
export const createTypesOfWorkHoursFail = (error) => {
    return {
        type: TYPES_OF_WORK_HOURS_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTypesOfWorkHoursDetails = (id) => {
    return {
        type: GET_TYPES_OF_WORK_HOURS_DETAILS,
        payload: id,
    };
};
export const getTypesOfWorkHoursDetailsSuccess = (data) => {
    return {
        type: GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTypesOfWorkHoursDetailsFail = (error) => {
    return {
        type: GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getTypesOfWorkHourses = () => {
    return {
        type: GET_TYPES_OF_WORK_HOURSES,
    };
};
export const getTypesOfWorkHoursesSuccess = (data) => {
    return {
        type: GET_TYPES_OF_WORK_HOURSES_SUCCESS,
        payload: data,
    };
};
export const getTypesOfWorkHoursesFail = (error) => {
    return {
        type: GET_TYPES_OF_WORK_HOURSES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTypesOfWorkHours = (payload) => {

    return {
        type: UPDATE_TYPES_OF_WORK_HOURS,
        payload: payload
    }
};
export const updateTypesOfWorkHoursSuccess = (response) => {
    return {
        type: UPDATE_TYPES_OF_WORK_HOURS_SUCCESS,
        payload: response
    }
};
export const updateTypesOfWorkHoursFail = (error) => {
    return {
        type: UPDATE_TYPES_OF_WORK_HOURS_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTypesOfWorkHours = (id) => {
    return {
        type: DELETE_TYPES_OF_WORK_HOURS,
        payload: id
    }
};
export const deleteTypesOfWorkHoursSuccess = (response) => {
    return {
        type: DELETE_TYPES_OF_WORK_HOURS_SUCCESS,
        payload: response
    }
};
export const deleteTypesOfWorkHoursFail = (error) => {
    return {
        type: DELETE_TYPES_OF_WORK_HOURS_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateTypesOfWorkHours = () => {
    return {
        type: RESET_TYPES_OF_WORK_HOURS_CREATE
    }
};

export const resetTypesOfWorkHoursDetails = () => {
    return {
        type: RESET_TYPES_OF_WORK_HOURS_DETAILS
    }
};

export const resetTypesOfWorkHoursDeleteData = () => {
    return {
        type: RESET_TYPES_OF_WORK_HOURS_DELETE
    }
};