import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";


import { GET_EMPLOYEETYPES, GET_EMPLOYEETYPES_DETAILS,  EMPLOYEETYPE_CREATE, EMPLOYEETYPE_DELETE,
   EMPLOYEETYPE_UPDATE} from "./actionTypes";

import {
  getEmployeeTypesSuccess,
  getEmployeeTypesFail,
  getEmployeeTypesDetailsSuccess,
  getEmployeeTypesDetailsFail,
  createEmployeeTypeSuccess,
  createEmployeeTypeFail,
  updateEmployeeTypeSuccess,
  updateEmployeeTypeFail,
  deleteEmployeeTypeSuccess,
  deleteEmployeeTypeFail,
  
} from "./actions";

import {  createEmployeeType,deleteEmployeeType,
	getEmployeeTypeDetails,getEmployeeTypes
	,updateEmployeeType,
 } from "../../_helper/services/employeetype";
import { ToastHeader } from 'reactstrap';
import { ShowError,ShowSucess } from '../../_helper/helper';


function* onGetEmployeeTypes({payload: {page,pageSize}}) {
  try {
    const response = yield call(getEmployeeTypes,{page,pageSize});
    yield put(getEmployeeTypesSuccess(response));
  } catch (error) {
    yield put(getEmployeeTypesFail(error.response));
  }
}

function* onGetEmployeeTypeDetails({ payload: id }) {
  try {
    const response = yield call(getEmployeeTypeDetails, id);
    yield put(getEmployeeTypesDetailsSuccess(response));
  } catch (error) {
    yield put(getEmployeeTypesDetailsFail(error.response));
  }
}
function* onCreateEmployeeType({ payload }) {
	try {
	  const response = yield call(createEmployeeType, payload);
	  yield put(createEmployeeTypeSuccess(response));
    ShowSucess('Employee Created successfully', {
      position: 'top-right'
    });
    // yield put(push('/employee-type'));
    
	} catch (error) {
	  yield put(createEmployeeTypeFail(error.response));
	  ShowError(error?.response?.data?.message);
    console.log(error)
  }
}
  function* onUpdateEmployeeType({ payload: { id}, payload }) {
	try {
	  const response = yield call(updateEmployeeType, id, payload);
	  yield put(updateEmployeeTypeSuccess(response));
    ShowSucess('Employee Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateEmployeeTypeFail(error.response));
    console.log(error?.response?.data?.message);
	  ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteEmployeeType({ payload: ID }) {
    try {
      const response = yield call(deleteEmployeeType, ID);
      yield put(deleteEmployeeTypeSuccess(response));
      ShowSucess("Deleted Successfully",{
        position:"top-right"
      })
    } catch (error) {
      yield put(deleteEmployeeTypeFail(error.response));
	  ShowError(error?.response?.data?.message);
    }
    }

function* CartSaga() {
  yield takeLatest(GET_EMPLOYEETYPES, onGetEmployeeTypes);
  yield takeLatest(GET_EMPLOYEETYPES_DETAILS, onGetEmployeeTypeDetails);
  yield takeLatest(EMPLOYEETYPE_CREATE, onCreateEmployeeType);
  yield takeLatest(EMPLOYEETYPE_UPDATE, onUpdateEmployeeType);
  yield takeLatest(EMPLOYEETYPE_DELETE, onDeleteEmployeeType);
}

export default CartSaga;