import {
    DELETE_DOA_HR_JV_INTERNAL_LOG,
    DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL,
    DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
    DOA_HR_JV_INTERNAL_LOG_CREATE,
    DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL,
    DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_DOA_HR_JV_INTERNAL_LOG_CREATE,
    RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
    UPDATE_DOA_HR_JV_INTERNAL_LOG,
    UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL,
    UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
} from "./actionsTypes";

const initialState = {
    DOAHRJVInternalLogList: [],
    DOAHRJVInternalLogDetails: {},
    createResult: {},
    deleteResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    error: {
        message: "",
    },
};

const DOAHRJVInternalLogReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case DOA_HR_JV_INTERNAL_LOG_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_DOA_HR_JV_INTERNAL_LOG_DETAILS:
            state = { ...state, detailsLoading: true, DOAHRJVInternalLogDetails: {} };
            break;
        case GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS:
            state = { ...state, DOAHRJVInternalLogDetails: action.payload, detailsLoading: false };
            break;
        case GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_DOA_HR_JV_INTERNAL_LOG:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_DOA_HR_JV_INTERNAL_LOG:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS:
            state = { ...state, DOAHRJVInternalLogDetails: {}, detailsLoading: false };
            break;


        case RESET:
            state = { ...state, DOAHRJVInternalLogList: [], createResult: {} };
            break;
        case RESET_DOA_HR_JV_INTERNAL_LOG_CREATE:
            state = { ...state, createResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DOAHRJVInternalLogReducer;