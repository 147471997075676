import {
    LTD_CREATE, LTD_CREATE_FAIL, LTD_CREATE_SUCCESS,
    DELETE_LTD,
    DELETE_LTD_FAIL,
    DELETE_LTD_SUCCESS,
    GET_LTD_DETAILS,
    GET_LTD_DETAILS_FAIL,
    GET_LTD_DETAILS_SUCCESS,
    RESET,
    RESET_LTD_CREATE,
    RESET_LTD_DELETE,
    RESET_LTD_DETAILS,
    UPDATE_LTD,
    UPDATE_LTD_FAIL,
    UPDATE_LTD_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createLTD = (payload) => {
    return {
        type: LTD_CREATE,
        payload: payload
    }
};
export const createLTDSuccess = (response) => {
    return {
        type: LTD_CREATE_SUCCESS,
        payload: response
    }
};
export const createLTDFail = (error) => {
    return {
        type: LTD_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getLTDDetails = (id) => {
    return {
        type: GET_LTD_DETAILS,
        payload: id,
    };
};
export const getLTDDetailsSuccess = (data) => {
    return {
        type: GET_LTD_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getLTDDetailsFail = (error) => {
    return {
        type: GET_LTD_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateLTD = (payload) => {

    return {
        type: UPDATE_LTD,
        payload: payload
    }
};
export const updateLTDSuccess = (response) => {
    return {
        type: UPDATE_LTD_SUCCESS,
        payload: response
    }
};
export const updateLTDFail = (error) => {
    return {
        type: UPDATE_LTD_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteLTD = (id) => {
    return {
        type: DELETE_LTD,
        payload: id
    }
};
export const deleteLTDSuccess = (response) => {
    return {
        type: DELETE_LTD_SUCCESS,
        payload: response
    }
};
export const deleteLTDFail = (error) => {
    return {
        type: DELETE_LTD_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateLTD = () => {
    return {
        type: RESET_LTD_CREATE
    }
};

export const resetLTDDetails = () => {
    return {
        type: RESET_LTD_DETAILS
    }
};

export const resetLTDDeleteData = () => {
    return {
        type: RESET_LTD_DELETE
    }
};