export const GET_POSITIONS = "GET_POSITIONS";
export const GET_POSITIONS_SUCCESS = "GET_POSITIONS_SUCCESS";
export const GET_POSITIONS_FAIL = "GET_POSITIONS_FAIL";

export const GET_POSITIONS_DETAILS = "GET_POSITIONS_DETAILS";
export const GET_POSITIONS_DETAILS_SUCCESS = "GET_POSITIONS_DETAILS_SUCCESS";
export const GET_POSITIONS_DETAILS_FAIL = "GET_POSITIONS_DETAILS_FAIL";

export const POSITION_UPDATE= "POSITION_UPDATE";
export const POSITION_UPDATE_SUCCESS = "POSITION_UPDATE_SUCCESS";
export const POSITION_UPDATE_FAIL = "POSITION_UPDATE_FAIL";

export const POSITION_CREATE= "POSITION_CREATE";
export const POSITION_CREATE_SUCCESS = "POSITION_CREATE_SUCCESS";
export const POSITION_CREATE_FAIL = "POSITION_CREATE_FAIL";

export const POSITION_DELETE= "POSITION_DELETE";
export const POSITION_DELETE_SUCCESS = "POSITION_DELETE_SUCCESS";
export const POSITION_DELETE_FAIL = "POSITION_DELETE_FAIL";

