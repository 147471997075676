import {
    TYPE_OF_SME_CREATE,
    TYPE_OF_SME_CREATE_FAIL,
    TYPE_OF_SME_CREATE_SUCCESS,
    DELETE_TYPE_OF_SME,
    DELETE_TYPE_OF_SME_FAIL,
    DELETE_TYPE_OF_SME_SUCCESS,
    GET_TYPE_OF_SMEES,
    GET_TYPE_OF_SMEES_FAIL,
    GET_TYPE_OF_SMEES_SUCCESS,
    GET_TYPE_OF_SME_DETAILS,
    GET_TYPE_OF_SME_DETAILS_FAIL,
    GET_TYPE_OF_SME_DETAILS_SUCCESS,
    RESET,
    RESET_TYPE_OF_SME_CREATE,
    RESET_TYPE_OF_SME_DELETE,
    RESET_TYPE_OF_SME_DETAILS,
    UPDATE_TYPE_OF_SME,
    UPDATE_TYPE_OF_SME_FAIL,
    UPDATE_TYPE_OF_SME_SUCCESS,
} from "./actionsTypes";

const initialState = {
    typeOfSMEList: [],
    typeOfSMEDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const TypesOfSMEReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case TYPE_OF_SME_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case TYPE_OF_SME_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case TYPE_OF_SME_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_TYPE_OF_SMEES:
            state = { ...state, loading: true };
            break;
        case GET_TYPE_OF_SMEES_SUCCESS:
            state = { ...state, typeOfSMEList: action.payload, loading: false };
            break;
        case GET_TYPE_OF_SMEES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_TYPE_OF_SME_DETAILS:
            state = { ...state, detailsLoading: true, typeOfSMEDetails: {} };
            break;
        case GET_TYPE_OF_SME_DETAILS_SUCCESS:
            state = { ...state, typeOfSMEDetails: action.payload, detailsLoading: false };
            break;
        case GET_TYPE_OF_SME_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_TYPE_OF_SME:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_TYPE_OF_SME_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_TYPE_OF_SME_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_TYPE_OF_SME:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_TYPE_OF_SME_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_TYPE_OF_SME_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_TYPE_OF_SME_DETAILS:
            state = { ...state, typeOfSMEDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, typeOfSMEList: [], createResult: {} };
            break;
        case RESET_TYPE_OF_SME_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_TYPE_OF_SME_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default TypesOfSMEReducer;