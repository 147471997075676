export const TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE = "TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE";
export const TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_SUCCESS = "TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_SUCCESS";
export const TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_FAIL = "TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE_FAIL";

export const GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS = "GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS";
export const GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_SUCCESS = "GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_SUCCESS";
export const GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_FAIL = "GET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS_FAIL";

export const UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG = "UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG";
export const UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS = "UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS";
export const UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL = "UPDATE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL";

export const DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG = "DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG";
export const DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS = "DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_SUCCESS";
export const DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL = "DELETE_TDP_EMPLOYEE_MASTER_LIST_LOG_FAIL";

export const RESET = "RESET";
export const RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE = "RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_CREATE";
export const RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS = "RESET_TDP_EMPLOYEE_MASTER_LIST_LOG_DETAILS";