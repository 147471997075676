import {
  GET_EDUCATIONS,
  GET_EDUCATIONS_SUCCESS,
  GET_EDUCATIONS_FAIL,
  GET_EDUCATIONS_DETAILS,
  GET_EDUCATIONS_DETAILS_SUCCESS,
  GET_EDUCATIONS_DETAILS_FAIL,
  EDUCATION_CREATE,
  EDUCATION_CREATE_FAIL,
  EDUCATION_CREATE_SUCCESS,
  EDUCATION_DELETE,
  EDUCATION_DELETE_FAIL,
  EDUCATION_DELETE_SUCCESS,
  EDUCATION_UPDATE,
  EDUCATION_UPDATE_FAIL,
  EDUCATION_UPDATE_SUCCESS,
  RESET,
} from "./actionTypes";

const initialState = {
  educationList: [],
  education: {},
  postingResult: {},
  loading: false,
  educationSetting: {},
  error: {
    message: "",
  },
};

const EducationReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_EDUCATIONS:
      state = { ...state, loading: true };
      break;
    case GET_EDUCATIONS_SUCCESS:
      state = { ...state, educationList: action.payload, loading: false };
      break;
    case GET_EDUCATIONS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_EDUCATIONS_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_EDUCATIONS_DETAILS_SUCCESS:
      state = { ...state, education: action.payload, loading: false };
      break;
    case GET_EDUCATIONS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case EDUCATION_CREATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case EDUCATION_CREATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case EDUCATION_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        loading: false,
      };
      break;
    case EDUCATION_UPDATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case EDUCATION_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case EDUCATION_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case EDUCATION_DELETE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case EDUCATION_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case EDUCATION_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case RESET:
      state = { ...state, education: {} };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EducationReducer;
