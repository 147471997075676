import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createTypeOfCharges = (payload) => post(baseURL + url.TYPE_OF_CHARGES, payload);
export const getTypeOfChargesDetails = (id) => get(`${baseURL + url.TYPE_OF_CHARGES}/${id}`);
export const getTypeOfChargeses = () => get(`${baseURL + url.TYPE_OF_CHARGES}`);
export const updateTypeOfCharges = (id, payload) => put(`${baseURL + url.TYPE_OF_CHARGES}/${id}`, payload);
export const deleteTypeOfCharges = (id) => remove(`${baseURL + url.TYPE_OF_CHARGES}/${id}`);
