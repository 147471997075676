import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createCBAStatus = (payload) => post(baseURL + url.CBA_STATUS, payload);
export const getCBAStatusDetails = (id) => get(`${baseURL + url.CBA_STATUS}/${id}`);
export const getCBAStatuses = () => get(`${baseURL + url.CBA_STATUS}`);
export const updateCBAStatus = (id, payload) => put(`${baseURL + url.CBA_STATUS}/${id}`, payload);
export const deleteCBAStatus = (id) => remove(`${baseURL + url.CBA_STATUS}/${id}`);
