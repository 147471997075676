import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  PAYROLLSTAGING_DIFFERENCE,
  PAYROLLSTAGING_DIFFERENCE_SUCCESS,
  PAYROLLSTAGING_DIFFERENCE_FAIL,
  MOVE_TO_PAYROLL,
  MOVE_TO_PAYROLL_SUCCESS,
  MOVE_TO_PAYROLL_FAIL,
} from "./actionTypes";
import {
  getPayrollStagingDiff,
  MoveToPayroll,
} from "../../_helper/payrollStaging_helper";

function* getpayrollStagingDifference({ payload }) {
  try {
    const response = yield call(getPayrollStagingDiff, payload);
    yield put({ type: PAYROLLSTAGING_DIFFERENCE_SUCCESS, response });
  } catch (error) {
    yield put({ type: PAYROLLSTAGING_DIFFERENCE_FAIL, message: error.message });
  }
}
function* moveTopayroll({ payload }) {
  try {
    const response = yield call(MoveToPayroll, payload);
    yield put({ type: MOVE_TO_PAYROLL_SUCCESS, response });
    toast.success(response.Message);
  } catch (error) {
    yield put({ type: MOVE_TO_PAYROLL_FAIL, message: error.message });
  }
}
function* CartSaga() {
  yield takeEvery(PAYROLLSTAGING_DIFFERENCE, getpayrollStagingDifference);
  yield takeEvery(MOVE_TO_PAYROLL, moveTopayroll);
}

export default CartSaga;
