export const NATURE_OF_GRIEVANCE_CREATE = "NATURE_OF_GRIEVANCE_CREATE";
export const NATURE_OF_GRIEVANCE_CREATE_SUCCESS = "NATURE_OF_GRIEVANCE_CREATE_SUCCESS";
export const NATURE_OF_GRIEVANCE_CREATE_FAIL = "NATURE_OF_GRIEVANCE_CREATE_FAIL";

export const GET_NATURE_OF_GRIEVANCE_DETAILS = "GET_NATURE_OF_GRIEVANCE_DETAILS";
export const GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS = "GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS";
export const GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL = "GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL";

export const GET_NATURE_OF_GRIEVANCEES = "GET_NATURE_OF_GRIEVANCEES";
export const GET_NATURE_OF_GRIEVANCEES_SUCCESS = "GET_NATURE_OF_GRIEVANCEES_SUCCESS";
export const GET_NATURE_OF_GRIEVANCEES_FAIL = "GET_NATURE_OF_GRIEVANCEES_FAIL";

export const UPDATE_NATURE_OF_GRIEVANCE = "UPDATE_NATURE_OF_GRIEVANCE";
export const UPDATE_NATURE_OF_GRIEVANCE_SUCCESS = "UPDATE_NATURE_OF_GRIEVANCE_SUCCESS";
export const UPDATE_NATURE_OF_GRIEVANCE_FAIL = "UPDATE_NATURE_OF_GRIEVANCE_FAIL";

export const DELETE_NATURE_OF_GRIEVANCE = "DELETE_NATURE_OF_GRIEVANCE";
export const DELETE_NATURE_OF_GRIEVANCE_SUCCESS = "DELETE_NATURE_OF_GRIEVANCE_SUCCESS";
export const DELETE_NATURE_OF_GRIEVANCE_FAIL = "DELETE_NATURE_OF_GRIEVANCE_FAIL";

export const RESET = "RESET";
export const RESET_NATURE_OF_GRIEVANCE_CREATE = "RESET_NATURE_OF_GRIEVANCE_CREATE";
export const RESET_NATURE_OF_GRIEVANCE_DETAILS = "RESET_NATURE_OF_GRIEVANCE_DETAILS";
export const RESET_NATURE_OF_GRIEVANCE_DELETE = "RESET_NATURE_OF_GRIEVANCE_DELETE";