export const GET_TAGMASTERS = "GET_TAGMASTERS";
export const GET_TAGMASTERS_SUCCESS = "GET_TAGMASTERS_SUCCESS";
export const GET_TAGMASTERS_FAIL = "GET_TAGMASTERS_FAIL";

export const GET_TAGMASTERS_DETAILS = "GET_TAGMASTERS_DETAILS";
export const GET_TAGMASTERS_DETAILS_SUCCESS = "GET_TAGMASTERS_DETAILS_SUCCESS";
export const GET_TAGMASTERS_DETAILS_FAIL = "GET_TAGMASTERS_DETAILS_FAIL";

export const TAGMASTER_UPDATE= "TAGMASTER_UPDATE";
export const TAGMASTER_UPDATE_SUCCESS = "TAGMASTER_UPDATE_SUCCESS";
export const TAGMASTER_UPDATE_FAIL = "TAGMASTER_UPDATE_FAIL";

export const TAGMASTER_CREATE= "TAGMASTER_CREATE";
export const TAGMASTER_CREATE_SUCCESS = "TAGMASTER_CREATE_SUCCESS";
export const TAGMASTER_CREATE_FAIL = "TAGMASTER_CREATE_FAIL";

export const TAGMASTER_DELETE= "TAGMASTER_DELETE";
export const TAGMASTER_DELETE_SUCCESS = "TAGMASTER_DELETE_SUCCESS";
export const TAGMASTER_DELETE_FAIL = "TAGMASTER_DELETE_FAIL";
export const RESET="RESET";


