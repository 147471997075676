import {
    RWHR_CREATE,
    RWHR_CREATE_FAIL,
    RWHR_CREATE_SUCCESS,
    DELETE_RWHR,
    DELETE_RWHR_FAIL,
    DELETE_RWHR_SUCCESS,
    GET_RWHR_DETAILS,
    GET_RWHR_DETAILS_FAIL,
    GET_RWHR_DETAILS_SUCCESS,
    RESET,
    RESET_RWHR_CREATE,
    RESET_RWHR_DELETE,
    RESET_RWHR_DETAILS,
    UPDATE_RWHR,
    UPDATE_RWHR_FAIL,
    UPDATE_RWHR_SUCCESS,
} from "./actionsTypes";

const initialState = {
    RWHRList: [],
    RWHRDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const RWHRReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case RWHR_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case RWHR_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case RWHR_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_RWHR_DETAILS:
            state = { ...state, detailsLoading: true, RWHRDetails: {} };
            break;
        case GET_RWHR_DETAILS_SUCCESS:
            state = { ...state, RWHRDetails: action.payload, detailsLoading: false };
            break;
        case GET_RWHR_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_RWHR:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_RWHR_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_RWHR_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_RWHR:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_RWHR_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_RWHR_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_RWHR_DETAILS:
            state = { ...state, RWHRDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, RWHRList: [], createResult: {} };
            break;
        case RESET_RWHR_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_RWHR_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default RWHRReducer;