export const TYPES_OF_WORK_HOURS_CREATE = "TYPES_OF_WORK_HOURS_CREATE";
export const TYPES_OF_WORK_HOURS_CREATE_SUCCESS = "TYPES_OF_WORK_HOURS_CREATE_SUCCESS";
export const TYPES_OF_WORK_HOURS_CREATE_FAIL = "TYPES_OF_WORK_HOURS_CREATE_FAIL";

export const GET_TYPES_OF_WORK_HOURS_DETAILS = "GET_TYPES_OF_WORK_HOURS_DETAILS";
export const GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS = "GET_TYPES_OF_WORK_HOURS_DETAILS_SUCCESS";
export const GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL = "GET_TYPES_OF_WORK_HOURS_DETAILS_FAIL";

export const GET_TYPES_OF_WORK_HOURSES = "GET_TYPES_OF_WORK_HOURSES";
export const GET_TYPES_OF_WORK_HOURSES_SUCCESS = "GET_TYPES_OF_WORK_HOURSES_SUCCESS";
export const GET_TYPES_OF_WORK_HOURSES_FAIL = "GET_TYPES_OF_WORK_HOURSES_FAIL";

export const UPDATE_TYPES_OF_WORK_HOURS = "UPDATE_TYPES_OF_WORK_HOURS";
export const UPDATE_TYPES_OF_WORK_HOURS_SUCCESS = "UPDATE_TYPES_OF_WORK_HOURS_SUCCESS";
export const UPDATE_TYPES_OF_WORK_HOURS_FAIL = "UPDATE_TYPES_OF_WORK_HOURS_FAIL";

export const DELETE_TYPES_OF_WORK_HOURS = "DELETE_TYPES_OF_WORK_HOURS";
export const DELETE_TYPES_OF_WORK_HOURS_SUCCESS = "DELETE_TYPES_OF_WORK_HOURS_SUCCESS";
export const DELETE_TYPES_OF_WORK_HOURS_FAIL = "DELETE_TYPES_OF_WORK_HOURS_FAIL";

export const RESET = "RESET";
export const RESET_TYPES_OF_WORK_HOURS_CREATE = "RESET_TYPES_OF_WORK_HOURS_CREATE";
export const RESET_TYPES_OF_WORK_HOURS_DETAILS = "RESET_TYPES_OF_WORK_HOURS_DETAILS";
export const RESET_TYPES_OF_WORK_HOURS_DELETE = "RESET_TYPES_OF_WORK_HOURS_DELETE";