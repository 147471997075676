import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { SPEC_MEDICAL_EVAL_CREATE, DELETE_SPEC_MEDICAL_EVAL, GET_SPEC_MEDICAL_EVAL_DETAILS, RESET, RESET_SPEC_MEDICAL_EVAL_CREATE, UPDATE_SPEC_MEDICAL_EVAL } from "./actionsTypes";

import {
    createSpecMedicalEvalSuccess,
    createSpecMedicalEvalFail,
    reset,
    resetCreateSpecMedicalEval,
    getSpecMedicalEvalDetailsSuccess,
    getSpecMedicalEvalDetailsFail,
    updateSpecMedicalEvalSuccess,
    deleteSpecMedicalEvalSuccess,
    deleteSpecMedicalEvalFail,
    updateSpecMedicalEvalFail,
} from "./action";

import {
    createSpecMedicalEval, deleteSpecMedicalEval, getSpecMedicalEvalDetails, updateSpecMedicalEval
} from "../../../_helper/services/EMR/SpecMedicalEval";


function* onCreateSpecMedicalEval({ payload }) {
    try {
        const response = yield call(createSpecMedicalEval, payload);
        yield put(createSpecMedicalEvalSuccess(response));
        ShowSucess("Spec Medical Eval Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createSpecMedicalEvalFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateSpecMedicalEval());
    }
}

function* onGetSpecMedicalEvalDetails({ payload: id }) {
    try {
        const response = yield call(getSpecMedicalEvalDetails, id);
        yield put(getSpecMedicalEvalDetailsSuccess(response));
    } catch (error) {
        yield put(getSpecMedicalEvalDetailsFail(error.response));
    }
}
function* onUpdateSpecMedicalEval({ payload: { id }, payload }) {
    try {
        const response = yield call(updateSpecMedicalEval, id, payload);
        yield put(updateSpecMedicalEvalSuccess(response));
        yield put(getSpecMedicalEvalDetailsSuccess(payload));
        ShowSucess(`Spec Medical Eval Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateSpecMedicalEvalFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteSpecMedicalEval({ payload: id }) {
    try {
        const response = yield call(deleteSpecMedicalEval, id);
        yield put(deleteSpecMedicalEvalSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteSpecMedicalEvalFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* SpecMedicalEvalSaga() {
    yield takeLatest(SPEC_MEDICAL_EVAL_CREATE, onCreateSpecMedicalEval);
    yield takeLatest(GET_SPEC_MEDICAL_EVAL_DETAILS, onGetSpecMedicalEvalDetails);
    yield takeLatest(UPDATE_SPEC_MEDICAL_EVAL, onUpdateSpecMedicalEval);
    yield takeLatest(DELETE_SPEC_MEDICAL_EVAL, onDeleteSpecMedicalEval);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_SPEC_MEDICAL_EVAL_CREATE, resetCreateSpecMedicalEval);
}

export default SpecMedicalEvalSaga;