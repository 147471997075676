import {
	GET_PMRCNATURES, GET_PMRCNATURES_SUCCESS, GET_PMRCNATURES_FAIL,
	GET_PMRCNATURES_DETAILS, GET_PMRCNATURES_DETAILS_SUCCESS, GET_PMRCNATURES_DETAILS_FAIL,
	PMRCNATURE_CREATE, PMRCNATURE_CREATE_FAIL, PMRCNATURE_CREATE_SUCCESS,
	PMRCNATURE_DELETE, PMRCNATURE_DELETE_FAIL, PMRCNATURE_DELETE_SUCCESS,
	PMRCNATURE_UPDATE, PMRCNATURE_UPDATE_FAIL, PMRCNATURE_UPDATE_SUCCESS,RESET,
} from "./actionTypes";

const initialState = {
	pmrcnatureList: [],
	pmrcnature: {},
	postingResult: {},
	loading: false,
	loadingPmrcnatureDetails: false,
	pmrcnatureSetting:{},
	loadingpmrcnatureSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const PmrcNatureReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_PMRCNATURES:
			state = { ...state, loading: false };
			break;
		case GET_PMRCNATURES_SUCCESS:
			state = { ...state, pmrcnatureList: action.payload, loading: false };
			break;
		case GET_PMRCNATURES_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_PMRCNATURES_DETAILS:
			state = { ...state, loadingPmrcnatureDetails: true };
			break;
		case GET_PMRCNATURES_DETAILS_SUCCESS:
			state = { ...state, pmrcnature: action.payload, loadingPmrcnatureDetails: false };
			break;
		case GET_PMRCNATURES_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPmrcnatureDetails: false,
			};
			break;
		case PMRCNATURE_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCNATURE_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCNATURE_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case PMRCNATURE_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCNATURE_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCNATURE_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case PMRCNATURE_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCNATURE_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCNATURE_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:{
				state={...state, pmrcnature:{}}
			}
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default PmrcNatureReducer;