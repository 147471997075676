import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { PE_RATING_CREATE, DELETE_PE_RATING, GET_PE_RATINGES, GET_PE_RATING_DETAILS, RESET, RESET_PE_RATING_CREATE, UPDATE_PE_RATING } from "./actionsTypes";

import {
    createPERatingSuccess,
    createPERatingFail,
    reset,
    resetCreatePERating,
    getPERatingDetailsSuccess,
    getPERatingDetailsFail,
    updatePERatingSuccess,
    deletePERatingSuccess,
    deletePERatingFail,
    updatePERatingFail,
    getPERatingesSuccess,
    getPERatingesFail,
} from "./action";

import {
    createPERating, deletePERating, getPERatingDetails, getPERatinges, updatePERating
} from "../../../../_helper/services/EMR/PERating";


function* onCreatePERating({ payload }) {
    try {
        const response = yield call(createPERating, payload);
        yield put(createPERatingSuccess(response));
        ShowSucess("PE Rating Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createPERatingFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreatePERating());
    }
}

function* onGetPERatingDetails({ payload: id }) {
    try {
        const response = yield call(getPERatingDetails, id);
        yield put(getPERatingDetailsSuccess(response));
    } catch (error) {
        yield put(getPERatingDetailsFail(error.response));
    }
}

function* onGetPERatinges() {
    try {
        const response = yield call(getPERatinges);
        yield put(getPERatingesSuccess(response));
    } catch (error) {
        yield put(getPERatingesFail(error.response));
    }
}

function* onUpdatePERating({ payload: { id }, payload }) {
    try {
        const response = yield call(updatePERating, id, payload);
        yield put(updatePERatingSuccess(response));
        yield put(getPERatingDetailsSuccess(payload));
        ShowSucess(`PE Rating Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updatePERatingFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeletePERating({ payload: id }) {
    try {
        const response = yield call(deletePERating, id);
        yield put(deletePERatingSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deletePERatingFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* PERatingSaga() {
    yield takeLatest(PE_RATING_CREATE, onCreatePERating);
    yield takeLatest(GET_PE_RATING_DETAILS, onGetPERatingDetails);
    yield takeLatest(GET_PE_RATINGES, onGetPERatinges);
    yield takeLatest(UPDATE_PE_RATING, onUpdatePERating);
    yield takeLatest(DELETE_PE_RATING, onDeletePERating);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_PE_RATING_CREATE, resetCreatePERating);
}

export default PERatingSaga;