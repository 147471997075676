export const LTD_CREATE = "LTD_CREATE";
export const LTD_CREATE_SUCCESS = "LTD_CREATE_SUCCESS";
export const LTD_CREATE_FAIL = "LTD_CREATE_FAIL";

export const GET_LTD_DETAILS = "GET_LTD_DETAILS";
export const GET_LTD_DETAILS_SUCCESS = "GET_LTD_DETAILS_SUCCESS";
export const GET_LTD_DETAILS_FAIL = "GET_LTD_DETAILS_FAIL";

export const UPDATE_LTD = "UPDATE_LTD";
export const UPDATE_LTD_SUCCESS = "UPDATE_LTD_SUCCESS";
export const UPDATE_LTD_FAIL = "UPDATE_LTD_FAIL";

export const DELETE_LTD = "DELETE_LTD";
export const DELETE_LTD_SUCCESS = "DELETE_LTD_SUCCESS";
export const DELETE_LTD_FAIL = "DELETE_LTD_FAIL";

export const RESET = "RESET";
export const RESET_LTD_CREATE = "RESET_LTD_CREATE";
export const RESET_LTD_DETAILS = "RESET_LTD_DETAILS";
export const RESET_LTD_DELETE = "RESET_LTD_DELETE";