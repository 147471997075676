import {
    DELETE_RANDOM_DT_LOG,
    DELETE_RANDOM_DT_LOG_FAIL,
    DELETE_RANDOM_DT_LOG_SUCCESS,
    GET_RANDOM_DT_LOG_DETAILS,
    GET_RANDOM_DT_LOG_DETAILS_FAIL,
    GET_RANDOM_DT_LOG_DETAILS_SUCCESS,
    RANDOM_DT_LOG_CREATE, RANDOM_DT_LOG_CREATE_FAIL, RANDOM_DT_LOG_CREATE_SUCCESS,
    RESET,
    RESET_RANDOM_DT_LOG_CREATE,
    RESET_RANDOM_DT_LOG_DETAILS,
    UPDATE_RANDOM_DT_LOG,
    UPDATE_RANDOM_DT_LOG_FAIL,
    UPDATE_RANDOM_DT_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createRandomDTLog = (payload) => {
    return {
        type: RANDOM_DT_LOG_CREATE,
        payload: payload
    }
};
export const createRandomDTLogSuccess = (response) => {
    return {
        type: RANDOM_DT_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createRandomDTLogFail = (error) => {
    return {
        type: RANDOM_DT_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getRandomDTLogDetails = (id) => {
    return {
        type: GET_RANDOM_DT_LOG_DETAILS,
        payload: id,
    };
};
export const getRandomDTLogDetailsSuccess = (data) => {
    return {
        type: GET_RANDOM_DT_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getRandomDTLogDetailsFail = (error) => {
    return {
        type: GET_RANDOM_DT_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateRandomDTLog = (payload) => {
    return {
        type: UPDATE_RANDOM_DT_LOG,
        payload: payload
    }
};
export const updateRandomDTLogSuccess = (response) => {
    return {
        type: UPDATE_RANDOM_DT_LOG_SUCCESS,
        payload: response
    }
};
export const updateRandomDTLogFail = (error) => {
    return {
        type: UPDATE_RANDOM_DT_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteRandomDTLog = (id) => {
    return {
        type: DELETE_RANDOM_DT_LOG,
        payload: id
    }
};
export const deleteRandomDTLogSuccess = (response) => {
    return {
        type: DELETE_RANDOM_DT_LOG_SUCCESS,
        payload: response
    }
};
export const deleteRandomDTLogFail = (error) => {
    return {
        type: DELETE_RANDOM_DT_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateRandomDTLog = () => {
    return {
        type: RESET_RANDOM_DT_LOG_CREATE
    }
};
export const resetRandomDTLogDetails = () => {
    return {
        type: RESET_RANDOM_DT_LOG_DETAILS,
    };
};