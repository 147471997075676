import {
	GET_PMRCNATURES, GET_PMRCNATURES_SUCCESS, GET_PMRCNATURES_FAIL,
	GET_PMRCNATURES_DETAILS, GET_PMRCNATURES_DETAILS_SUCCESS, GET_PMRCNATURES_DETAILS_FAIL,
	PMRCNATURE_CREATE,PMRCNATURE_CREATE_FAIL,PMRCNATURE_CREATE_SUCCESS,
	PMRCNATURE_DELETE,PMRCNATURE_DELETE_FAIL,PMRCNATURE_DELETE_SUCCESS,
	PMRCNATURE_UPDATE,PMRCNATURE_UPDATE_FAIL,PMRCNATURE_UPDATE_SUCCESS,RESET, GET_PMRCNATURES_SETTING_FAIL, GET_PMRCNATURES_SETTING_SUCCESS, SAVE_PMRCNATURES_SETTING_FAIL, SAVE_PMRCNATURES_SETTING_SUCCESS, SAVE_PMRCNATURES_SETTING, GET_PMRCNATURES_SETTING, PMRCNATURE_COPY, PMRCNATURE_COPY_SUCCESS, PMRCNATURE_COPY_FAIL, PMRCNATURE_STATUS_CREATE, PMRCNATURE_STATUS_CREATE_SUCCESS, PMRCNATURE_STATUS_CREATE_FAIL, PMRCNATURE_STATUS_UPDATE, PMRCNATURE_STATUS_UPDATE_SUCCESS, PMRCNATURE_STATUS_UPDATE_FAIL, PMRCNATURE_STATUS_DELETE, PMRCNATURE_STATUS_DELETE_SUCCESS, PMRCNATURE_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPmrcNatures = (payload) => {
	return {
	  type: GET_PMRCNATURES,
	  payload: payload
	};
  };
  
  export const getPmrcNaturesSuccess = (posts) => {
	return {
	  type: GET_PMRCNATURES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPmrcNaturesFail = (error) => {
	return {
	  type: GET_PMRCNATURES_FAIL,
	  payload: error,
	};
  };
  
  export const getPmrcNaturesDetails = (id) => {
	return {
	  type: GET_PMRCNATURES_DETAILS,
	  payload: id,
	};
  };
  
  export const getPmrcNaturesDetailsSuccess = (post) => {
	return {
	  type: GET_PMRCNATURES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPmrcNaturesDetailsFail = (error) => {
	return {
	  type: GET_PMRCNATURES_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPmrcNature =(payload)=>{
	return {
		type: PMRCNATURE_CREATE,
		payload: payload
	}
  }
  export const createPmrcNatureSuccess =(response)=>{
	return {
		type: PMRCNATURE_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPmrcNatureFail =(error)=>{
	return {
		type: PMRCNATURE_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePmrcNature =(payload)=>{
	
	return {
		type: PMRCNATURE_UPDATE,
		payload: payload
	}
  }
  export const updatePmrcNatureSuccess =(response)=>{
	return {
		type: PMRCNATURE_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePmrcNatureFail =(error)=>{
	return {
		type: PMRCNATURE_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePmrcNature =(payload)=>{
	return {
		type: PMRCNATURE_DELETE,
		payload: payload
	}
  }
  export const deletePmrcNatureSuccess =(response)=>{
	return {
		type: PMRCNATURE_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePmrcNatureFail =(error)=>{
	return {
		type: PMRCNATURE_DELETE_FAIL,
		payload: error
	}
  }
  export const reset=()=>{
	return {
		type:RESET,
	}
  }

