import * as Actions from '../actions';

const initialState = {
    healthInsDetail: {
        coverageAndClassData: {
            planCode:'',
            planName:'',
            planRemark:'',
            effectiveDate: new Date(),
            endDate: new Date(),
            empClass:'',
            coverage:'',
            insClass:'',
            empMedicalShare:'',
            empDentalShare:'',
            govMedicalShare:'',
            govDentalShare:'',
            task:'newEnrollment',
            changeOfStatus:'',
        },
        dependentsData: [{
            fName:'',
            lName:'',
            mi:'',
            ssn:'',
            dob: new Date(),
            gender:null,
            relationship:null,
        }],
        medicalCoverageData: [{
            partANo: '',
            partAEffDate: '',
            partBNo: '',
            partBEffDate: '',
            insFor: undefined
        }],
        otherInsData: [{
            insName: '',
            insCarrier: '',
            effectiveDate: new Date()
        }]
    }
};

const healthInsDetail = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_HEALTH_INS_DETAIL: {
            return {
                ...state,
                healthInsDetail: {
                    ...state.healthInsDetail,
                    ...action.payload
                }
            }
        }

        default: {
            return state;
        }
    }
};

export default healthInsDetail;
