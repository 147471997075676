import { SEND_QUICK_ACTION_REQUEST, SEND_QUICK_ACTION_SUCCESS, SEND_QUICK_ACTION_FAILURE,
    GET_MAIN_MENU,GET_MAIN_MENU_SUCCESS,GET_MAIN_MENU_FAILURE
} from "./actiionType";

const initialState = {
    loading: false,
    error: null,
    quickAction: [],
 
    postingResult: {},
    mainMenuData: {},
};
export const MainMenuReducer = (state = initialState, action) => {
    if (!action) return state;
    switch (action.type) {
        case SEND_QUICK_ACTION_REQUEST:
            return { ...state, postingResult: {}, loading: true, error: null };
        case SEND_QUICK_ACTION_SUCCESS:
            return { ...state,mainMenuData: action.payload, loading: false };
        case SEND_QUICK_ACTION_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case GET_MAIN_MENU:
            state = { ...state,  loading: true,   };
            break;
        case GET_MAIN_MENU_SUCCESS:
         
            state = {
                ...state,
                quickAction: action.payload,
                loading: false,
              
            };
         
            break;
        case GET_MAIN_MENU_FAILURE:
            state = {
                ...state,
                error: action.payload,
                loading: false,
            };
            break;
        default:
            state = { ...state }
            break;
    }
    return state

};
export default MainMenuReducer;