import { combineReducers } from "redux";
import benefitMasterDetail from "./benefitDetail.reducer";
import list from './benefitList.reducer';
import healthIns from './healthInsDetail.reducer';
import lifeIns from './lifeInsDetail.reducer';
import deathClaim from './deathClaimDetail.reducer';

const reducer = combineReducers({
    benefitMasterDetail,
    list,
    healthIns,
    lifeIns,
    deathClaim
});

export default reducer;
