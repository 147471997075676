export const TEST_DESIGNATED_POSITION_LOG_CREATE = "TEST_DESIGNATED_POSITION_LOG_CREATE";
export const TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS = "TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS";
export const TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL = "TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL";

export const GET_TEST_DESIGNATED_POSITION_LOG_DETAILS = "GET_TEST_DESIGNATED_POSITION_LOG_DETAILS";
export const GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS = "GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS";
export const GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL = "GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL";

export const UPDATE_TEST_DESIGNATED_POSITION_LOG = "UPDATE_TEST_DESIGNATED_POSITION_LOG";
export const UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS = "UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS";
export const UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL = "UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL";

export const DELETE_TEST_DESIGNATED_POSITION_LOG = "DELETE_TEST_DESIGNATED_POSITION_LOG";
export const DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS = "DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS";
export const DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL = "DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL";

export const RESET = "RESET";
export const RESET_TEST_DESIGNATED_POSITION_LOG_CREATE = "RESET_TEST_DESIGNATED_POSITION_LOG_CREATE";
export const RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS = "RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS";