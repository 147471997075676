import {
	GET_DEDUCTIONPLANS, GET_DEDUCTIONPLANS_SUCCESS, GET_DEDUCTIONPLANS_FAIL,
	GET_DEDUCTIONPLANS_DETAILS, GET_DEDUCTIONPLANS_DETAILS_SUCCESS, GET_DEDUCTIONPLANS_DETAILS_FAIL,
	DEDUCTIONPLAN_CREATE,DEDUCTIONPLAN_CREATE_FAIL,DEDUCTIONPLAN_CREATE_SUCCESS,
	DEDUCTIONPLAN_DELETE,DEDUCTIONPLAN_DELETE_FAIL,DEDUCTIONPLAN_DELETE_SUCCESS,
	DEDUCTIONPLAN_UPDATE,DEDUCTIONPLAN_UPDATE_FAIL,DEDUCTIONPLAN_UPDATE_SUCCESS,RESET, GET_DEDUCTIONPLANS_SETTING_FAIL, GET_DEDUCTIONPLANS_SETTING_SUCCESS, SAVE_DEDUCTIONPLANS_SETTING_FAIL, SAVE_DEDUCTIONPLANS_SETTING_SUCCESS, SAVE_DEDUCTIONPLANS_SETTING, GET_DEDUCTIONPLANS_SETTING, DEDUCTIONPLAN_COPY, DEDUCTIONPLAN_COPY_SUCCESS, DEDUCTIONPLAN_COPY_FAIL, DEDUCTIONPLAN_STATUS_CREATE, DEDUCTIONPLAN_STATUS_CREATE_SUCCESS, DEDUCTIONPLAN_STATUS_CREATE_FAIL, DEDUCTIONPLAN_STATUS_UPDATE, DEDUCTIONPLAN_STATUS_UPDATE_SUCCESS, DEDUCTIONPLAN_STATUS_UPDATE_FAIL, DEDUCTIONPLAN_STATUS_DELETE, DEDUCTIONPLAN_STATUS_DELETE_SUCCESS, DEDUCTIONPLAN_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getDeductionplans = (payload) => {
	return {
	  type: GET_DEDUCTIONPLANS,
	  payload: payload
	};
  };
  
  export const getDeductionplansSuccess = (posts) => {
	return {
	  type: GET_DEDUCTIONPLANS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getDeductionplansFail = (error) => {
	return {
	  type: GET_DEDUCTIONPLANS_FAIL,
	  payload: error,
	};
  };
  
  export const getDeductionplansDetails = (id) => {
	return {
	  type: GET_DEDUCTIONPLANS_DETAILS,
	  payload: id,
	};
  };
  
  export const getDeductionplansDetailsSuccess = (post) => {
	return {
	  type: GET_DEDUCTIONPLANS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getDeductionplansDetailsFail = (error) => {
	return {
	  type: GET_DEDUCTIONPLANS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createDeductionplan =(payload)=>{
	return {
		type: DEDUCTIONPLAN_CREATE,
		payload: payload
	}
  }
  export const createDeductionplanSuccess =(response)=>{
	return {
		type: DEDUCTIONPLAN_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createDeductionplanFail =(error)=>{
	return {
		type: DEDUCTIONPLAN_CREATE_FAIL,
		payload: error
	}
  }


  export const updateDeductionplan =(payload)=>{
	
	return {
		type: DEDUCTIONPLAN_UPDATE,
		payload: payload
	}
  }
  export const updateDeductionplanSuccess =(response)=>{
	return {
		type: DEDUCTIONPLAN_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateDeductionplanFail =(error)=>{
	return {
		type: DEDUCTIONPLAN_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteDeductionplan =(payload)=>{
	return {
		type: DEDUCTIONPLAN_DELETE,
		payload: payload
	}
  }
  export const deleteDeductionplanSuccess =(response)=>{
	return {
		type: DEDUCTIONPLAN_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteDeductionplanFail =(error)=>{
	return {
		type: DEDUCTIONPLAN_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

