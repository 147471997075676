import {
	GET_WORKFLOWS, GET_WORKFLOWS_SUCCESS, GET_WORKFLOWS_FAIL,
	GET_WORKFLOWS_DETAILS, GET_WORKFLOWS_DETAILS_SUCCESS, GET_WORKFLOWS_DETAILS_FAIL,
	WORKFLOW_CREATE,WORKFLOW_CREATE_FAIL,WORKFLOW_CREATE_SUCCESS,
	WORKFLOW_DELETE,WORKFLOW_DELETE_FAIL,WORKFLOW_DELETE_SUCCESS,
	WORKFLOW_UPDATE,WORKFLOW_UPDATE_FAIL,WORKFLOW_UPDATE_SUCCESS, GET_WORKFLOWS_SETTING_FAIL, GET_WORKFLOWS_SETTING_SUCCESS, SAVE_WORKFLOWS_SETTING_FAIL, SAVE_WORKFLOWS_SETTING_SUCCESS, SAVE_WORKFLOWS_SETTING, GET_WORKFLOWS_SETTING, WORKFLOW_COPY, WORKFLOW_COPY_SUCCESS, WORKFLOW_COPY_FAIL
  } from "./actionTypes";

  export const getWorkflows = (payload) => {
	return {
	  type: GET_WORKFLOWS,
	  payload: payload
	};
  };
  
  export const getWorkflowsSuccess = (posts) => {
	return {
	  type: GET_WORKFLOWS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getWorkflowsFail = (error) => {
	return {
	  type: GET_WORKFLOWS_FAIL,
	  payload: error,
	};
  };
  
  export const getWorkflowsDetails = (id) => {
	return {
	  type: GET_WORKFLOWS_DETAILS,
	  payload: id,
	};
  };
  
  export const getWorkflowsDetailsSuccess = (post) => {
	return {
	  type: GET_WORKFLOWS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getWorkflowsDetailsFail = (error) => {
	return {
	  type: GET_WORKFLOWS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createWorkflow =(payload)=>{
	return {
		type: WORKFLOW_CREATE,
		payload: payload
	}
  }
  export const createWorkflowSuccess =(response)=>{
	return {
		type: WORKFLOW_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createWorkflowFail =(error)=>{
	return {
		type: WORKFLOW_CREATE_FAIL,
		payload: error
	}
  }


  export const updateWorkflow =(payload)=>{
	
	return {
		type: WORKFLOW_UPDATE,
		payload: payload
	}
  }
  export const updateWorkflowSuccess =(response)=>{
	return {
		type: WORKFLOW_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateWorkflowFail =(error)=>{
	return {
		type: WORKFLOW_UPDATE_FAIL,
		payload: error
	}
  }


  export const copyWorkflow =(payload)=>{
	
	return {
		type: WORKFLOW_COPY,
		payload: payload
	}
  }
  export const copyWorkflowSuccess =(response)=>{
	return {
		type: WORKFLOW_COPY_SUCCESS,
		payload: response
	}
  }
  export const copyWorkflowFail =(error)=>{
	return {
		type: WORKFLOW_COPY_FAIL,
		payload: error
	}
  }


  export const deleteWorkflow =(payload)=>{
	return {
		type: WORKFLOW_DELETE,
		payload: payload
	}
  }
  export const deleteWorkflowSuccess =(response)=>{
	return {
		type: WORKFLOW_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteWorkflowFail =(error)=>{
	return {
		type: WORKFLOW_DELETE_FAIL,
		payload: error
	}
  }


  export const getWorkflowsSettingSuccess = (posts) => {
	return {
	  type: GET_WORKFLOWS_SETTING_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getWorkflowsSettingFail = (error) => {
	return {
	  type: GET_WORKFLOWS_SETTING_FAIL,
	  payload: error,
	};
  };
  
  export const getWorkflowsSetting = (id) => {
	return {
	  type: GET_WORKFLOWS_SETTING,
	  payload: id,
	};
  };


  export const updateWorkflowSetting =(payload)=>{
	
	return {
		type: SAVE_WORKFLOWS_SETTING,
		payload: payload
	}
  }
  export const updateWorkflowSettingSuccess =(response)=>{
	return {
		type: SAVE_WORKFLOWS_SETTING_SUCCESS,
		payload: response
	}
  }
  export const updateWorkflowSettingFail =(error)=>{
	return {
		type: SAVE_WORKFLOWS_SETTING_FAIL,
		payload: error
	}
  }