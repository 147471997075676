import {
    GET_BILLING_INVOICES,
    GET_BILLING_INVOICES_SUCCESS,
    GET_BILLING_INVOICES_FAIL,
    GET_BILLING_INVOICES_DETAILS,
    GET_BILLING_INVOICES_DETAILS_SUCCESS,
    GET_BILLING_INVOICES_DETAILS_FAIL,
    BILLING_INVOICE_CREATE,
    BILLING_INVOICE_CREATE_FAIL,
    BILLING_INVOICE_CREATE_SUCCESS,
    BILLING_INVOICE_DELETE,
    BILLING_INVOICE_DELETE_FAIL,
    BILLING_INVOICE_DELETE_SUCCESS,
    BILLING_INVOICE_UPDATE,
    BILLING_INVOICE_UPDATE_FAIL,
    BILLING_INVOICE_UPDATE_SUCCESS,
    RESET,
    GET_BILLING_INVOICE_STATUS_LISTS,
    GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS,
    GET_BILLING_INVOICE_STATUS_LISTS_FAIL,
    RESET_BILLING_INVOICE_CREATE,
    RESET_BILLING_INVOICES_DETAILS,
    RESET_BILLING_INVOICES_DELETE_DATA,
} from "./actionsTypes";

const initialState = {
    billingInvoiceList: [],
    billingInvoice: {},
    createResult: {},
    loading: false,
    loadingBillingInvoiceDetails: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },

    billingInvoiceStatusLists: [],
    loadingBillingInvoiceStatusLists: false,
    ErrorBillingInvoiceStatusLists: {
        message: ""
    }
};

const BillingInvoiceReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case GET_BILLING_INVOICES:
            state = { ...state, loading: true, billingInvoiceList: [] };
            break;
        case GET_BILLING_INVOICES_SUCCESS:
            state = { ...state, billingInvoiceList: action.payload, loading: false };
            break;
        case GET_BILLING_INVOICES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_BILLING_INVOICES_DETAILS:
            state = { ...state, loadingBillingInvoiceDetails: true, billingInvoice: {} };
            break;
        case GET_BILLING_INVOICES_DETAILS_SUCCESS:
            state = { ...state, billingInvoice: action.payload, loadingBillingInvoiceDetails: false };
            break;
        case GET_BILLING_INVOICES_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingBillingInvoiceDetails: false,
            };
            break;

        case BILLING_INVOICE_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case BILLING_INVOICE_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case BILLING_INVOICE_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case BILLING_INVOICE_UPDATE:
            state = { ...state, createResult: {}, loading: true };
            break;
        case BILLING_INVOICE_UPDATE_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case BILLING_INVOICE_UPDATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false
            };
            break;

        case BILLING_INVOICE_DELETE:
            state = { ...state, deleteResult: {}, loading: true };
            break;
        case BILLING_INVOICE_DELETE_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case BILLING_INVOICE_DELETE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;

        case GET_BILLING_INVOICE_STATUS_LISTS:
            state = { ...state, loadingBillingInvoiceStatusLists: true, billingInvoiceStatusLists: [] };
            break;
        case GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS:
            state = { ...state, billingInvoiceStatusLists: action.payload, loadingBillingInvoiceStatusLists: false };
            break;
        case GET_BILLING_INVOICE_STATUS_LISTS_FAIL:
            state = {
                ...state,
                ErrorBillingInvoiceStatusLists: {
                    message: "Error",
                },
                loadingBillingInvoiceStatusLists: false,
            };
            break;


        case RESET:
            state = { ...state, billingInvoice: {}, createResult: {} };
            break;
        case RESET_BILLING_INVOICE_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_BILLING_INVOICES_DETAILS:
            state = { ...state, loadingBillingInvoiceDetails: false, billingInvoice: {} };
            break;
        case RESET_BILLING_INVOICES_DELETE_DATA:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default BillingInvoiceReducer;