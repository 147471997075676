import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createEvalAppealReason = (payload) => post(baseURL + url.EVAL_APPEAL_REASON, payload);
export const getEvalAppealReasonDetails = (id) => get(`${baseURL + url.EVAL_APPEAL_REASON}/${id}`);
export const getEvalAppealReasones = () => get(`${baseURL + url.EVAL_APPEAL_REASON}`);
export const updateEvalAppealReason = (id, payload) => put(`${baseURL + url.EVAL_APPEAL_REASON}/${id}`, payload);
export const deleteEvalAppealReason = (id) => remove(`${baseURL + url.EVAL_APPEAL_REASON}/${id}`);
