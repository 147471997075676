export const GET_LEAVES = "GET_LEAVES";
export const GET_LEAVES_SUCCESS = "GET_LEAVES_SUCCESS";
export const GET_LEAVES_FAIL = "GET_LEAVES_FAIL";

export const GET_LEAVE_DETAILS = "GET_LEAVE_DETAILS";
export const GET_LEAVE_DETAILS_SUCCESS = "GET_LEAVE_DETAILS_SUCCESS";
export const GET_LEAVE_DETAILS_FAIL = "GET_LEAVE_DETAILS_FAIL";

export const SET_HEADER_FILTER = "SET HEADER FILTER";
export const CHANGE_HEADER_FILTER = "CHANGE HEADER FILTER";
export const TOGGLE_DIALOG = "TOGGLE DIALOG";
export const UPDATE_LEAVE_SHARE_DETAIL = "UPDATE LEAVE SHARE DETAIL";
export const RESET_FILTER = "RESET_FILTER";
export const REQ_LEAVE_APPLICATION = "REQ_LEAVE_APPLICATION";
export const REQ_LEAVE_APPLICATION_SUCCESS = "REQ_LEAVE_APPLICATION_SUCCESS";
export const REQ_LEAVE_APPLICATION_FAIL = "REQ_LEAVE_APPLICATION_FAIL";
export const FIND_LEAVE_APPLICATION = "FIND_LEAVE_APPLICATION";
export const FIND_LEAVE_APPLICATION_SUCCESS = "FIND_LEAVE_APPLICATION_SUCCESS";
export const FIND_LEAVE_APPLICATION_FAIL = "FIND_LEAVE_APPLICATION_FAIL";

export const LEAVE_APPLICATION_DETAILS = "LEAVE_APPLICATION_DETAILS";
export const LEAVE_APPLICATION_DETAILS_SUCCESS =
  "LEAVE_APPLICATION_DETAILS_SUCCESS";
export const LEAVE_APPLICATION_DETAILS_FAIL = "LEAVE_APPLICATION_DETAILS_FAIL";
export const EDIT_LEAVE_APPLICATION = "EDIT_LEAVE_APPLICATION";
export const EDIT_LEAVE_APPLICATION_SUCCESS = "EDIT_LEAVE_APPLICATION_SUCCESS";
export const EDIT_LEAVE_APPLICATION_FAIL = "EDIT_LEAVE_APPLICATION_FAIL";
export const DELETE_LEAVE_APPLICATION = "DELETE_LEAVE_APPLICATION";
export const DELETE_LEAVE_APPLICATION_SUCCESS =
  "DELETE_LEAVE_APPLICATION_SUCCESS";
export const DELETE_LEAVE_APPLICATION_FAIL = "DELETE_LEAVE_APPLICATION_FAIL";
export const CALCULATE_HOURS = "CALCULATE_HOURS";
export const CALCULATE_HOURS_SUCCESS = "CALCULATE_HOURS_SUCCESS";
export const CALCULATE_HOURS_FAIL = "CALCULATE_HOURS_FAIL";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const LEAVE_APPLICATION_HISTORY = "LEAVE_APPLICATION_HISTORY";
export const LEAVE_APPLICATION_HISTORY_SUCCESS =
  "LEAVE_APPLICATION_HISTORY_SUCCESS";
export const LEAVE_APPLICATION_HISTORY_FAIL = "LEAVE_APPLICATION_HISTORY_FAIL";
export const UPDATE_LEAVE_STATUS = "UPDATE_LEAVE_STATUS";
export const UPDATE_LEAVE_STATUS_SUCCESS = "UPDATE_LEAVE_STATUS_SUCCESS";
export const UPDATE_LEAVE_STATUS_FAIL = "UPDATE_LEAVE_STATUS_FAIL";
