import {
	GET_PMRCSALARIESS, GET_PMRCSALARIESS_SUCCESS, GET_PMRCSALARIESS_FAIL,
	GET_PMRCSALARIESS_DETAILS, GET_PMRCSALARIESS_DETAILS_SUCCESS, GET_PMRCSALARIESS_DETAILS_FAIL,
	PMRCSALARIES_CREATE, PMRCSALARIES_CREATE_FAIL, PMRCSALARIES_CREATE_SUCCESS,
	PMRCSALARIES_DELETE, PMRCSALARIES_DELETE_FAIL, PMRCSALARIES_DELETE_SUCCESS,
	PMRCSALARIES_UPDATE, PMRCSALARIES_UPDATE_FAIL, PMRCSALARIES_UPDATE_SUCCESS,RESET
} from "./actionTypes";

const initialState = {
	pmrcsalariesList: [],
	pmrcsalaries: {},
	postingResult: {},
	loading: false,
	loadingPmrcsalariesDetails: false,
	pmrcsalariesSetting:{},
	loadingpmrcsalariesSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const PmrcsalariesReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_PMRCSALARIESS:
			state = { ...state, loading: false };
			break;
		case GET_PMRCSALARIESS_SUCCESS:
			state = { ...state, pmrcsalariesList: action.payload, loading: false };
			break;
		case GET_PMRCSALARIESS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_PMRCSALARIESS_DETAILS:
			state = { ...state, loadingPmrcsalariesDetails: true };
			break;
		case GET_PMRCSALARIESS_DETAILS_SUCCESS:
			state = { ...state, pmrcsalaries: action.payload, loadingPmrcsalariesDetails: false };
			break;
		case GET_PMRCSALARIESS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPmrcsalariesDetails: false,
			};
			break;
		case PMRCSALARIES_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCSALARIES_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCSALARIES_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case PMRCSALARIES_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCSALARIES_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCSALARIES_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case PMRCSALARIES_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PMRCSALARIES_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PMRCSALARIES_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:{
				state={...state, pmrcsalaries:{}}
			}
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default PmrcsalariesReducer;