import {
	GET_PMRCSALARIESS, GET_PMRCSALARIESS_SUCCESS, GET_PMRCSALARIESS_FAIL,
	GET_PMRCSALARIESS_DETAILS, GET_PMRCSALARIESS_DETAILS_SUCCESS, GET_PMRCSALARIESS_DETAILS_FAIL,
	PMRCSALARIES_CREATE,PMRCSALARIES_CREATE_FAIL,PMRCSALARIES_CREATE_SUCCESS,
	PMRCSALARIES_DELETE,PMRCSALARIES_DELETE_FAIL,PMRCSALARIES_DELETE_SUCCESS,
	PMRCSALARIES_UPDATE,PMRCSALARIES_UPDATE_FAIL,PMRCSALARIES_UPDATE_SUCCESS,RESET, GET_PMRCSALARIESS_SETTING_FAIL, GET_PMRCSALARIESS_SETTING_SUCCESS, SAVE_PMRCSALARIESS_SETTING_FAIL, SAVE_PMRCSALARIESS_SETTING_SUCCESS, SAVE_PMRCSALARIESS_SETTING, GET_PMRCSALARIESS_SETTING, PMRCSALARIES_COPY, PMRCSALARIES_COPY_SUCCESS, PMRCSALARIES_COPY_FAIL, PMRCSALARIES_STATUS_CREATE, PMRCSALARIES_STATUS_CREATE_SUCCESS, PMRCSALARIES_STATUS_CREATE_FAIL, PMRCSALARIES_STATUS_UPDATE, PMRCSALARIES_STATUS_UPDATE_SUCCESS, PMRCSALARIES_STATUS_UPDATE_FAIL, PMRCSALARIES_STATUS_DELETE, PMRCSALARIES_STATUS_DELETE_SUCCESS, PMRCSALARIES_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPmrcsalariess = (payload) => {
	return {
	  type: GET_PMRCSALARIESS,
	  payload: payload
	};
  };
  
  export const getPmrcsalariessSuccess = (posts) => {
	return {
	  type: GET_PMRCSALARIESS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPmrcsalariessFail = (error) => {
	return {
	  type: GET_PMRCSALARIESS_FAIL,
	  payload: error,
	};
  };
  
  export const getPmrcsalariessDetails = (id) => {
	return {
	  type: GET_PMRCSALARIESS_DETAILS,
	  payload: id,
	};
  };
  
  export const getPmrcsalariessDetailsSuccess = (post) => {
	return {
	  type: GET_PMRCSALARIESS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPmrcsalariessDetailsFail = (error) => {
	return {
	  type: GET_PMRCSALARIESS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPmrcsalaries =(payload)=>{
	return {
		type: PMRCSALARIES_CREATE,
		payload: payload
	}
  }
  export const createPmrcsalariesSuccess =(response)=>{
	return {
		type: PMRCSALARIES_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPmrcsalariesFail =(error)=>{
	return {
		type: PMRCSALARIES_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePmrcsalaries =(payload)=>{
	
	return {
		type: PMRCSALARIES_UPDATE,
		payload: payload
	}
  }
  export const updatePmrcsalariesSuccess =(response)=>{
	return {
		type: PMRCSALARIES_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePmrcsalariesFail =(error)=>{
	return {
		type: PMRCSALARIES_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePmrcsalaries =(payload)=>{
	console.log("payload:", payload);
	return {
		type: PMRCSALARIES_DELETE,
		payload: payload  ,}}
  export const deletePmrcsalariesSuccess =(response)=>{
	return {
		type: PMRCSALARIES_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePmrcsalariesFail =(error)=>{
	return {
		type: PMRCSALARIES_DELETE_FAIL,
		payload: error
	}
  }
  export const reset=()=>{
	return {
		type:RESET,
	}
  }

