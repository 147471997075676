export const EVAL_APPEAL_REASON_CREATE = "EVAL_APPEAL_REASON_CREATE";
export const EVAL_APPEAL_REASON_CREATE_SUCCESS = "EVAL_APPEAL_REASON_CREATE_SUCCESS";
export const EVAL_APPEAL_REASON_CREATE_FAIL = "EVAL_APPEAL_REASON_CREATE_FAIL";

export const GET_EVAL_APPEAL_REASON_DETAILS = "GET_EVAL_APPEAL_REASON_DETAILS";
export const GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS = "GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS";
export const GET_EVAL_APPEAL_REASON_DETAILS_FAIL = "GET_EVAL_APPEAL_REASON_DETAILS_FAIL";

export const GET_EVAL_APPEAL_REASONES = "GET_EVAL_APPEAL_REASONES";
export const GET_EVAL_APPEAL_REASONES_SUCCESS = "GET_EVAL_APPEAL_REASONES_SUCCESS";
export const GET_EVAL_APPEAL_REASONES_FAIL = "GET_EVAL_APPEAL_REASONES_FAIL";

export const UPDATE_EVAL_APPEAL_REASON = "UPDATE_EVAL_APPEAL_REASON";
export const UPDATE_EVAL_APPEAL_REASON_SUCCESS = "UPDATE_EVAL_APPEAL_REASON_SUCCESS";
export const UPDATE_EVAL_APPEAL_REASON_FAIL = "UPDATE_EVAL_APPEAL_REASON_FAIL";

export const DELETE_EVAL_APPEAL_REASON = "DELETE_EVAL_APPEAL_REASON";
export const DELETE_EVAL_APPEAL_REASON_SUCCESS = "DELETE_EVAL_APPEAL_REASON_SUCCESS";
export const DELETE_EVAL_APPEAL_REASON_FAIL = "DELETE_EVAL_APPEAL_REASON_FAIL";

export const RESET = "RESET";
export const RESET_EVAL_APPEAL_REASON_CREATE = "RESET_EVAL_APPEAL_REASON_CREATE";
export const RESET_EVAL_APPEAL_REASON_DETAILS = "RESET_EVAL_APPEAL_REASON_DETAILS";
export const RESET_EVAL_APPEAL_REASON_DELETE = "RESET_EVAL_APPEAL_REASON_DELETE";