// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getEmployeeTypeDetails =(id)=> get(`${baseURL+url.EMPLOYEETYPES_BASE}/${id}`);
export const getEmployeeTypes =({page,pageSize})=> get(baseURL+url.EMPLOYEETYPES_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
// export const updateEmployeeType = (ID,payload) => put(url.EMPLOYEETYPES_BASE+'/'+ID,payload);


export const updateEmployeeType = (ID, payload) => put(`${baseURL}${url.EMPLOYEETYPES_BASE}/${ID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
// export const updatePositioStatus = (ID,payload) => put(url.EMPLOYEETYPES_BASE_UPDATE+'/'+ID, payload);
// export const updateEmployeeTypeStatusList = (payload) => put(url.EMPLOYEETYPES_BASE_UPDATE,payload);
export const createEmployeeType =(payload) => post(baseURL+url.EMPLOYEETYPES_BASE,payload);
//Post
export const deleteEmployeeType = (ID) => remove(baseURL+url.EMPLOYEETYPES_BASE+'/'+ID);