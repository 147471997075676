import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createPERating = (payload) => post(baseURL + url.PE_RATING, payload);
export const getPERatingDetails = (id) => get(`${baseURL + url.PE_RATING}/${id}`);
export const getPERatinges = () => get(`${baseURL + url.PE_RATING}`);
export const updatePERating = (id, payload) => put(`${baseURL + url.PE_RATING}/${id}`, payload);
export const deletePERating = (id) => remove(`${baseURL + url.PE_RATING}/${id}`);
