export const EVAL_APPEAL_CREATE = "EVAL_APPEAL_CREATE";
export const EVAL_APPEAL_CREATE_SUCCESS = "EVAL_APPEAL_CREATE_SUCCESS";
export const EVAL_APPEAL_CREATE_FAIL = "EVAL_APPEAL_CREATE_FAIL";

export const GET_EVAL_APPEAL_DETAILS = "GET_EVAL_APPEAL_DETAILS";
export const GET_EVAL_APPEAL_DETAILS_SUCCESS = "GET_EVAL_APPEAL_DETAILS_SUCCESS";
export const GET_EVAL_APPEAL_DETAILS_FAIL = "GET_EVAL_APPEAL_DETAILS_FAIL";

export const UPDATE_EVAL_APPEAL = "UPDATE_EVAL_APPEAL";
export const UPDATE_EVAL_APPEAL_SUCCESS = "UPDATE_EVAL_APPEAL_SUCCESS";
export const UPDATE_EVAL_APPEAL_FAIL = "UPDATE_EVAL_APPEAL_FAIL";

export const DELETE_EVAL_APPEAL = "DELETE_EVAL_APPEAL";
export const DELETE_EVAL_APPEAL_SUCCESS = "DELETE_EVAL_APPEAL_SUCCESS";
export const DELETE_EVAL_APPEAL_FAIL = "DELETE_EVAL_APPEAL_FAIL";

export const RESET = "RESET";
export const RESET_EVAL_APPEAL_CREATE = "RESET_EVAL_APPEAL_CREATE";
export const RESET_EVAL_APPEAL_DETAILS = "RESET_EVAL_APPEAL_DETAILS";
export const RESET_EVAL_APPEAL_DELETE = "RESET_EVAL_APPEAL_DELETE";