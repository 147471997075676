export const TYPE_OF_TRAINING_CREATE = "TYPE_OF_TRAINING_CREATE";
export const TYPE_OF_TRAINING_CREATE_SUCCESS = "TYPE_OF_TRAINING_CREATE_SUCCESS";
export const TYPE_OF_TRAINING_CREATE_FAIL = "TYPE_OF_TRAINING_CREATE_FAIL";

export const GET_TYPE_OF_TRAINING_DETAILS = "GET_TYPE_OF_TRAINING_DETAILS";
export const GET_TYPE_OF_TRAINING_DETAILS_SUCCESS = "GET_TYPE_OF_TRAINING_DETAILS_SUCCESS";
export const GET_TYPE_OF_TRAINING_DETAILS_FAIL = "GET_TYPE_OF_TRAINING_DETAILS_FAIL";

export const GET_TYPE_OF_TRAININGES = "GET_TYPE_OF_TRAININGES";
export const GET_TYPE_OF_TRAININGES_SUCCESS = "GET_TYPE_OF_TRAININGES_SUCCESS";
export const GET_TYPE_OF_TRAININGES_FAIL = "GET_TYPE_OF_TRAININGES_FAIL";

export const UPDATE_TYPE_OF_TRAINING = "UPDATE_TYPE_OF_TRAINING";
export const UPDATE_TYPE_OF_TRAINING_SUCCESS = "UPDATE_TYPE_OF_TRAINING_SUCCESS";
export const UPDATE_TYPE_OF_TRAINING_FAIL = "UPDATE_TYPE_OF_TRAINING_FAIL";

export const DELETE_TYPE_OF_TRAINING = "DELETE_TYPE_OF_TRAINING";
export const DELETE_TYPE_OF_TRAINING_SUCCESS = "DELETE_TYPE_OF_TRAINING_SUCCESS";
export const DELETE_TYPE_OF_TRAINING_FAIL = "DELETE_TYPE_OF_TRAINING_FAIL";

export const RESET = "RESET";
export const RESET_TYPE_OF_TRAINING_CREATE = "RESET_TYPE_OF_TRAINING_CREATE";
export const RESET_TYPE_OF_TRAINING_DETAILS = "RESET_TYPE_OF_TRAINING_DETAILS";
export const RESET_TYPE_OF_TRAINING_DELETE = "RESET_TYPE_OF_TRAINING_DELETE";