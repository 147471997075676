import {
    SPEC_MEDICAL_EVAL_CREATE, SPEC_MEDICAL_EVAL_CREATE_FAIL, SPEC_MEDICAL_EVAL_CREATE_SUCCESS,
    DELETE_SPEC_MEDICAL_EVAL,
    DELETE_SPEC_MEDICAL_EVAL_FAIL,
    DELETE_SPEC_MEDICAL_EVAL_SUCCESS,
    GET_SPEC_MEDICAL_EVAL_DETAILS,
    GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL,
    GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS,
    RESET,
    RESET_SPEC_MEDICAL_EVAL_CREATE,
    RESET_SPEC_MEDICAL_EVAL_DELETE,
    RESET_SPEC_MEDICAL_EVAL_DETAILS,
    UPDATE_SPEC_MEDICAL_EVAL,
    UPDATE_SPEC_MEDICAL_EVAL_FAIL,
    UPDATE_SPEC_MEDICAL_EVAL_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createSpecMedicalEval = (payload) => {
    return {
        type: SPEC_MEDICAL_EVAL_CREATE,
        payload: payload
    }
};
export const createSpecMedicalEvalSuccess = (response) => {
    return {
        type: SPEC_MEDICAL_EVAL_CREATE_SUCCESS,
        payload: response
    }
};
export const createSpecMedicalEvalFail = (error) => {
    return {
        type: SPEC_MEDICAL_EVAL_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getSpecMedicalEvalDetails = (id) => {
    return {
        type: GET_SPEC_MEDICAL_EVAL_DETAILS,
        payload: id,
    };
};
export const getSpecMedicalEvalDetailsSuccess = (data) => {
    return {
        type: GET_SPEC_MEDICAL_EVAL_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getSpecMedicalEvalDetailsFail = (error) => {
    return {
        type: GET_SPEC_MEDICAL_EVAL_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateSpecMedicalEval = (payload) => {

    return {
        type: UPDATE_SPEC_MEDICAL_EVAL,
        payload: payload
    }
};
export const updateSpecMedicalEvalSuccess = (response) => {
    return {
        type: UPDATE_SPEC_MEDICAL_EVAL_SUCCESS,
        payload: response
    }
};
export const updateSpecMedicalEvalFail = (error) => {
    return {
        type: UPDATE_SPEC_MEDICAL_EVAL_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteSpecMedicalEval = (id) => {
    return {
        type: DELETE_SPEC_MEDICAL_EVAL,
        payload: id
    }
};
export const deleteSpecMedicalEvalSuccess = (response) => {
    return {
        type: DELETE_SPEC_MEDICAL_EVAL_SUCCESS,
        payload: response
    }
};
export const deleteSpecMedicalEvalFail = (error) => {
    return {
        type: DELETE_SPEC_MEDICAL_EVAL_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateSpecMedicalEval = () => {
    return {
        type: RESET_SPEC_MEDICAL_EVAL_CREATE
    }
};

export const resetSpecMedicalEvalDetails = () => {
    return {
        type: RESET_SPEC_MEDICAL_EVAL_DETAILS
    }
};

export const resetSpecMedicalEvalDeleteData = () => {
    return {
        type: RESET_SPEC_MEDICAL_EVAL_DELETE
    }
};