import {
	GET_PAYTIMECODES, GET_PAYTIMECODES_SUCCESS, GET_PAYTIMECODES_FAIL,
	GET_PAYTIMECODES_DETAILS, GET_PAYTIMECODES_DETAILS_SUCCESS, GET_PAYTIMECODES_DETAILS_FAIL,
	PAYTIMECODE_CREATE,PAYTIMECODE_CREATE_FAIL,PAYTIMECODE_CREATE_SUCCESS,
	PAYTIMECODE_DELETE,PAYTIMECODE_DELETE_FAIL,PAYTIMECODE_DELETE_SUCCESS,
	PAYTIMECODE_UPDATE,PAYTIMECODE_UPDATE_FAIL,PAYTIMECODE_UPDATE_SUCCESS,RESET, GET_PAYTIMECODES_SETTING_FAIL, GET_PAYTIMECODES_SETTING_SUCCESS, SAVE_PAYTIMECODES_SETTING_FAIL, SAVE_PAYTIMECODES_SETTING_SUCCESS, SAVE_PAYTIMECODES_SETTING, GET_PAYTIMECODES_SETTING, PAYTIMECODE_COPY, PAYTIMECODE_COPY_SUCCESS, PAYTIMECODE_COPY_FAIL, PAYTIMECODE_STATUS_CREATE, PAYTIMECODE_STATUS_CREATE_SUCCESS, PAYTIMECODE_STATUS_CREATE_FAIL, PAYTIMECODE_STATUS_UPDATE, PAYTIMECODE_STATUS_UPDATE_SUCCESS, PAYTIMECODE_STATUS_UPDATE_FAIL, PAYTIMECODE_STATUS_DELETE, PAYTIMECODE_STATUS_DELETE_SUCCESS, PAYTIMECODE_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPaytimecodes = (payload) => {
	return {
	  type: GET_PAYTIMECODES,
	  payload: payload
	};
  };
  
  export const getPaytimecodesSuccess = (posts) => {
	return {
	  type: GET_PAYTIMECODES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPaytimecodesFail = (error) => {
	return {
	  type: GET_PAYTIMECODES_FAIL,
	  payload: error,
	};
  };
  
  export const getPaytimecodesDetails = (id) => {
	return {
	  type: GET_PAYTIMECODES_DETAILS,
	  payload: id,
	};
  };
  
  export const getPaytimecodesDetailsSuccess = (post) => {
	return {
	  type: GET_PAYTIMECODES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPaytimecodesDetailsFail = (error) => {
	return {
	  type: GET_PAYTIMECODES_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPaytimecode =(payload)=>{
	return {
		type: PAYTIMECODE_CREATE,
		payload: payload
	}
  }
  export const createPaytimecodeSuccess =(response)=>{
	return {
		type: PAYTIMECODE_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPaytimecodeFail =(error)=>{
	return {
		type: PAYTIMECODE_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePaytimecode =(payload)=>{
	
	return {
		type: PAYTIMECODE_UPDATE,
		payload: payload
	}
  }
  export const updatePaytimecodeSuccess =(response)=>{
	return {
		type: PAYTIMECODE_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePaytimecodeFail =(error)=>{
	return {
		type: PAYTIMECODE_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePaytimecode =(payload)=>{
	return {
		type: PAYTIMECODE_DELETE,
		payload: payload
	}
  }
  export const deletePaytimecodeSuccess =(response)=>{
	return {
		type: PAYTIMECODE_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePaytimecodeFail =(error)=>{
	return {
		type: PAYTIMECODE_DELETE_FAIL,
		payload: error
	}
  }

  export const reset=()=>{
	return {
		type:RESET,
	}
  }

