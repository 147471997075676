import {
    WAITING_PERIOD_CREATE,
    WAITING_PERIOD_CREATE_FAIL,
    WAITING_PERIOD_CREATE_SUCCESS,
    DELETE_WAITING_PERIOD,
    DELETE_WAITING_PERIOD_FAIL,
    DELETE_WAITING_PERIOD_SUCCESS,
    GET_WAITING_PERIODS,
    GET_WAITING_PERIODS_FAIL,
    GET_WAITING_PERIODS_SUCCESS,
    GET_WAITING_PERIOD_DETAILS,
    GET_WAITING_PERIOD_DETAILS_FAIL,
    GET_WAITING_PERIOD_DETAILS_SUCCESS,
    RESET,
    RESET_WAITING_PERIOD_CREATE,
    RESET_WAITING_PERIOD_DELETE,
    RESET_WAITING_PERIOD_DETAILS,
    UPDATE_WAITING_PERIOD,
    UPDATE_WAITING_PERIOD_FAIL,
    UPDATE_WAITING_PERIOD_SUCCESS,
} from "./actionsTypes";

const initialState = {
    waitingPeriodList: [],
    waitingPeriodDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const WaitingPeriodReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case WAITING_PERIOD_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case WAITING_PERIOD_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case WAITING_PERIOD_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_WAITING_PERIODS:
            state = { ...state, loading: true };
            break;
        case GET_WAITING_PERIODS_SUCCESS:
            state = { ...state, waitingPeriodList: action.payload, loading: false };
            break;
        case GET_WAITING_PERIODS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_WAITING_PERIOD_DETAILS:
            state = { ...state, detailsLoading: true, waitingPeriodDetails: {} };
            break;
        case GET_WAITING_PERIOD_DETAILS_SUCCESS:
            state = { ...state, waitingPeriodDetails: action.payload, detailsLoading: false };
            break;
        case GET_WAITING_PERIOD_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_WAITING_PERIOD:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_WAITING_PERIOD_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_WAITING_PERIOD_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_WAITING_PERIOD:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_WAITING_PERIOD_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_WAITING_PERIOD_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_WAITING_PERIOD_DETAILS:
            state = { ...state, MediaSourceDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, MediaSourceList: [], createResult: {} };
            break;
        case RESET_WAITING_PERIOD_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_WAITING_PERIOD_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default WaitingPeriodReducer;