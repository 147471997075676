export const GET_BENEFIT_DETAIL = "GET_BENEFIT_DETAIL";
export const GET_GENERAL_DETAIL = "GET_GENERAL_DETAIL";
export const UPDATE_BENEFIT_DETAIL = "UPDATE_BENEFIT_DETAIL";

export const getBenefitDetail = () => {
  const payload = {
    generalDetail: {
      ssn: "333-10-1001",
      fName: "ROBERT",
      lName: "DUENAS",
      mi: "B",
      dob: "11/05/1965",
      gender: "Male",
      maritalStatus: "Single",
      department: "rtl",
      position: "agent",
      employmentDate: "11/05/1995",
      status: "Active",
      employStatus: "Reg Duty",
      employClass: "Active",
    },
    healthInsuranceDetail: {
      planCode: "1234",
      planName: "Health Plan 1",
      status: "Active",
      statusDate: new Date(),
      effectiveDate: new Date(),
      endDate: new Date(),
      coverage: "Medical Only",
      insClass: "1",
      position: "agent",
      empMedicalShare: "1500",
      empDentalShare: "1500",
      govMedicalShare: "1200",
      govDentalShare: "1200",
    },
    dependentsDetail: {
      fName: "ROBORT",
      lName: "TAITAO",
      mi: "B",
      ssn: "123",
      dob: "15/02/1990",
      gender: "Male",
      relationship: "child",
    },
    otherInsuranceDetail: {
      insName: "Heath Ins 2",
      insCarrier: "carrier",
      effectiveDate: new Date(),
    },
    medicalCoverageDetail: {
      partANo: "123",
      partAEffDate: "15/03/1995",
      partBNo: "234",
      partBEffDate: "25/05/1998",
      insFor: "Self",
    },
    currentLifeInsDetail: {
      planCode: "11234",
      planName: "Life Ins 1",
      status: "Active",
      statusDate: new Date(),
      effectiveDate: new Date(),
      endDate: new Date(),
      employeeClass: "Active",
      activeEmpClassCoverage: {
        coverage: "Basic",
        compositeRate: "150",
        govShare: "454",
        dependents: "515",
        govDependents: "455",
        ageLessThen31: "454",
        ageBetween31To40: "455",
        ageBetween41To50: "555",
        ageBetween51To60: "655",
        ageBetween61To70: "755",
        ageGreater70: "855",
      },
      retiredEmpClassCoverage: {
        coverage: "Basic",
        cost: "9565",
        dependents: "696",
        govShare: "565",
        govDeptCost: "445",
      },
    },
    beneficiaryDetail: {
      type: "Primary",
      benefitPR: "15",
      lName: "DUENAS",
      fName: "ROBERT",
      mi: "B",
      relationship: "Child",
      dob: "25/11/197",
      ssn: "333-10-1001",
      gender: "Male",
      mailingAddress: "2131, Ring road",
    },
  };

  return {
    type: GET_BENEFIT_DETAIL,
    payload,
  };
};

export const getGeneralDetail = () => {
  const payload = {
    generalDetail: {
      ssn: "333-10-1001",
      fName: "ROBERT",
      lName: "DUENAS",
      mi: "B",
      dob: "11/05/1965",
      gender: "Male",
      maritalStatus: "Single",
      department: "rtl",
      position: "agent",
      employmentDate: "11/05/1995",
      status: "Active",
      employStatus: "Reg Duty",
      employClass: "Active",
    },
  };

  return {
    type: GET_GENERAL_DETAIL,
    payload,
  };
};

export const updateBenefitDetail = (payload) => {
  return {
    type: UPDATE_BENEFIT_DETAIL,
    payload,
  };
};
