export const GET_WORKFLOWS = "GET_WORKFLOWS";
export const GET_WORKFLOWS_SUCCESS = "GET_WORKFLOWS_SUCCESS";
export const GET_WORKFLOWS_FAIL = "GET_WORKFLOWS_FAIL";

export const GET_WORKFLOWS_SETTING = "GET_WORKFLOWS_SETTING";
export const GET_WORKFLOWS_SETTING_SUCCESS = "GET_WORKFLOWS_SETTING_SUCCESS";
export const GET_WORKFLOWS_SETTING_FAIL = "GET_WORKFLOWS_SETTING_FAIL";

export const SAVE_WORKFLOWS_SETTING = "SAVE_WORKFLOWS_SETTING";
export const SAVE_WORKFLOWS_SETTING_SUCCESS = "SAVE_WORKFLOWS_SETTING_SUCCESS";
export const SAVE_WORKFLOWS_SETTING_FAIL = "SAVE_WORKFLOWS_SETTING_FAIL";

export const GET_WORKFLOWS_DETAILS = "GET_WORKFLOWS_DETAILS";
export const GET_WORKFLOWS_DETAILS_SUCCESS = "GET_WORKFLOWS_DETAILS_SUCCESS";
export const GET_WORKFLOWS_DETAILS_FAIL = "GET_WORKFLOWS_DETAILS_FAIL";

export const WORKFLOW_UPDATE= "WORKFLOW_UPDATE";
export const WORKFLOW_UPDATE_SUCCESS = "WORKFLOW_UPDATE_SUCCESS";
export const WORKFLOW_UPDATE_FAIL = "WORKFLOW_UPDATE_FAIL";

export const WORKFLOW_COPY= "WORKFLOW_COPY";
export const WORKFLOW_COPY_SUCCESS = "WORKFLOW_COPY_SUCCESS";
export const WORKFLOW_COPY_FAIL = "WORKFLOW_COPY_FAIL";

export const WORKFLOW_CREATE= "WORKFLOW_CREATE";
export const WORKFLOW_CREATE_SUCCESS = "WORKFLOW_CREATE_SUCCESS";
export const WORKFLOW_CREATE_FAIL = "WORKFLOW_CREATE_FAIL";

export const WORKFLOW_DELETE= "WORKFLOW_DELETE";
export const WORKFLOW_DELETE_SUCCESS = "WORKFLOW_DELETE_SUCCESS";
export const WORKFLOW_DELETE_FAIL = "WORKFLOW_DELETE_FAIL";