// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPmrcNatureDetails =(id)=> get(`${baseURL+url.PMRCNATURE_BASE}/${id}`);
export const getPmrcNatures =({page,pageSize})=> get(baseURL+url.PMRCNATURE_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
// export const updatePmrcNature = (ID,payload) => put(url.PMRCNATURE_BASE+'/'+ID,payload);

export const updatePmrcNature = (ID, payload) => put(`${baseURL}${url.PMRCNATURE_BASE}/${ID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// export const updatePositioStatus = (ID,payload) => put(url.PMRCNATURE_BASE_UPDATE+'/'+ID, payload);
// export const updatePmrcNatureStatusList = (payload) => put(url.PMRCNATURE_BASE_UPDATE,payload);
export const createPmrcNature =(payload) => post(baseURL+url.PMRCNATURE_BASE,payload);
//Post
export const deletePmrcNature = (ID) => remove(baseURL+url.PMRCNATURE_BASE+'/'+ID);