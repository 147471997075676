import {
    EVAL_APPEAL_REASON_CREATE,
    EVAL_APPEAL_REASON_CREATE_FAIL,
    EVAL_APPEAL_REASON_CREATE_SUCCESS,
    DELETE_EVAL_APPEAL_REASON,
    DELETE_EVAL_APPEAL_REASON_FAIL,
    DELETE_EVAL_APPEAL_REASON_SUCCESS,
    GET_EVAL_APPEAL_REASONES,
    GET_EVAL_APPEAL_REASONES_FAIL,
    GET_EVAL_APPEAL_REASONES_SUCCESS,
    GET_EVAL_APPEAL_REASON_DETAILS,
    GET_EVAL_APPEAL_REASON_DETAILS_FAIL,
    GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS,
    RESET,
    RESET_EVAL_APPEAL_REASON_CREATE,
    RESET_EVAL_APPEAL_REASON_DELETE,
    RESET_EVAL_APPEAL_REASON_DETAILS,
    UPDATE_EVAL_APPEAL_REASON,
    UPDATE_EVAL_APPEAL_REASON_FAIL,
    UPDATE_EVAL_APPEAL_REASON_SUCCESS,
} from "./actionsTypes";

const initialState = {
    evalAppealReasonList: [],
    evalAppealReasonDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const EvalAppealReasonReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case EVAL_APPEAL_REASON_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case EVAL_APPEAL_REASON_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case EVAL_APPEAL_REASON_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_EVAL_APPEAL_REASONES:
            state = { ...state, loading: true };
            break;
        case GET_EVAL_APPEAL_REASONES_SUCCESS:
            state = { ...state, evalAppealReasonList: action.payload, loading: false };
            break;
        case GET_EVAL_APPEAL_REASONES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_EVAL_APPEAL_REASON_DETAILS:
            state = { ...state, detailsLoading: true, evalAppealReasonDetails: {} };
            break;
        case GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS:
            state = { ...state, evalAppealReasonDetails: action.payload, detailsLoading: false };
            break;
        case GET_EVAL_APPEAL_REASON_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_EVAL_APPEAL_REASON:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_EVAL_APPEAL_REASON_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_EVAL_APPEAL_REASON_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_EVAL_APPEAL_REASON:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_EVAL_APPEAL_REASON_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_EVAL_APPEAL_REASON_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_EVAL_APPEAL_REASON_DETAILS:
            state = { ...state, evalAppealReasonDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, evalAppealReasonList: [], createResult: {} };
            break;
        case RESET_EVAL_APPEAL_REASON_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_EVAL_APPEAL_REASON_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default EvalAppealReasonReducer;