// import { put } from "redux-saga/effects";
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getWorkflowDetails = (id) =>
  get(`${baseURL}${url.WORKFLOW_DETAILS}/${Number(id)}`);
export const getWorkflows = ({ page, pageSize }) =>
  get(url.WORKFLOW_LIST + `?page=${page}&pageSize=${pageSize}`);
export const getWorkflowSetting = (id) => get(`${url.WORKFLOW_SETTING}/${id}`);
//Post
export const updateWorkflow = (ID, payload) =>
  put(baseURL + url.WORKFLOW_UPDATE + "/" + Number(ID), payload);
export const copyWorkflow = (ID, payload) =>
  post(baseURL + url.WORKFLOW_COPY, payload);
export const updateWorkflowSetting = (ID, payload) =>
  put(url.WORKFLOW_SETTING_SAVE + "/" + ID, payload);
export const createWorkflow = (payload) =>
  post(baseURL + url.WORKFLOW_CREATE, payload);
//Post
export const deleteWorkflow = (ID) =>
  remove(baseURL + url.WORKFLOW_DELETE + "/" + Number(ID));
