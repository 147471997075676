import { takeLatest, put, call } from "redux-saga/effects";
import { STORED_THEME, USER_CONFIGURATION } from "./actionTypes";
import {
  getStoredThemeSuccess,
  getStoredThemeFail,
  userConfigurationSuccess,
  userConfigurationFail,
} from "./action";
import {
  gettingStoredTheme,
  getUserConfiguration,
} from "../../_helper/services/themeCustomizer";
import { ShowError, ShowSucess } from "../../_helper/helper";

function* onGetStoredTheme({ payload }) {
  try {
    const response = yield call(gettingStoredTheme, payload);
    yield put(getStoredThemeSuccess(response));
  } catch (error) {
    yield put(getStoredThemeFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}
function* onUserConfiguration() {
  try {
    const response = yield call(getUserConfiguration);
    yield put(userConfigurationSuccess(response));
  } catch (error) {
    yield put(userConfigurationFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}
function* CartSaga() {
  yield takeLatest(STORED_THEME, onGetStoredTheme);
  yield takeLatest(USER_CONFIGURATION, onUserConfiguration);
}

export default CartSaga;
