import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { TYPES_OF_WORK_HOURS_CREATE, DELETE_TYPES_OF_WORK_HOURS, GET_TYPES_OF_WORK_HOURSES, GET_TYPES_OF_WORK_HOURS_DETAILS, RESET, RESET_TYPES_OF_WORK_HOURS_CREATE, UPDATE_TYPES_OF_WORK_HOURS } from "./actionsTypes";

import {
    createTypesOfWorkHoursSuccess,
    createTypesOfWorkHoursFail,
    reset,
    resetCreateTypesOfWorkHours,
    getTypesOfWorkHoursDetailsSuccess,
    getTypesOfWorkHoursDetailsFail,
    updateTypesOfWorkHoursSuccess,
    deleteTypesOfWorkHoursSuccess,
    deleteTypesOfWorkHoursFail,
    updateTypesOfWorkHoursFail,
    getTypesOfWorkHoursesSuccess,
    getTypesOfWorkHoursesFail,
} from "./action";

import {
    createTypesOfWorkHours, deleteTypesOfWorkHours, getTypesOfWorkHoursDetails, getTypesOfWorkHourses, updateTypesOfWorkHours
} from "../../../../_helper/services/EMR/TypesOfWorkHours";


function* onCreateTypesOfWorkHours({ payload }) {
    try {
        const response = yield call(createTypesOfWorkHours, payload);
        yield put(createTypesOfWorkHoursSuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTypesOfWorkHoursFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTypesOfWorkHours());
    }
}

function* onGetTypesOfWorkHoursDetails({ payload: id }) {
    try {
        const response = yield call(getTypesOfWorkHoursDetails, id);
        yield put(getTypesOfWorkHoursDetailsSuccess(response));
    } catch (error) {
        yield put(getTypesOfWorkHoursDetailsFail(error.response));
    }
}

function* onGetTypesOfWorkHourses() {
    try {
        const response = yield call(getTypesOfWorkHourses);
        yield put(getTypesOfWorkHoursesSuccess(response));
    } catch (error) {
        yield put(getTypesOfWorkHoursesFail(error.response));
    }
}

function* onUpdateTypesOfWorkHours({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTypesOfWorkHours, id, payload);
        yield put(updateTypesOfWorkHoursSuccess(response));
        yield put(getTypesOfWorkHoursDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTypesOfWorkHoursFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTypesOfWorkHours({ payload: id }) {
    try {
        const response = yield call(deleteTypesOfWorkHours, id);
        yield put(deleteTypesOfWorkHoursSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTypesOfWorkHoursFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TypesOfWorkHoursSaga() {
    yield takeLatest(TYPES_OF_WORK_HOURS_CREATE, onCreateTypesOfWorkHours);
    yield takeLatest(GET_TYPES_OF_WORK_HOURS_DETAILS, onGetTypesOfWorkHoursDetails);
    yield takeLatest(GET_TYPES_OF_WORK_HOURSES, onGetTypesOfWorkHourses);
    yield takeLatest(UPDATE_TYPES_OF_WORK_HOURS, onUpdateTypesOfWorkHours);
    yield takeLatest(DELETE_TYPES_OF_WORK_HOURS, onDeleteTypesOfWorkHours);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_TYPES_OF_WORK_HOURS_CREATE, resetCreateTypesOfWorkHours);
}

export default TypesOfWorkHoursSaga;