import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_CERTIFICATIONS, GET_CERTIFICATIONS_DETAILS,  CERTIFICATION_CREATE, CERTIFICATION_DELETE,
   CERTIFICATION_UPDATE,GET_ALL_CERTIFICATIONS_DETAILS} from "./actionTypes";

import {
  getCertificationsSuccess,
  getCertificationsFail,
  getCertificationsDetailsSuccess,
  getCertificationsDetailsFail,
  createCertificationSuccess,
  createCertificationFail,
  updateCertificationSuccess,
  updateCertificationFail,
  deleteCertificationSuccess,
  deleteCertificationFail,
  getAllCertificationsFail,
  getAllCertificationsSuccess
} from "./actions";

import {  createCertification,deleteCertification,
	getCertificationDetails,getCertifications
	,updateCertification,getAllCertifications
 } from "../../_helper/services/certification";
function* onGetCertifications({payload: {page,pageSize}}) {
  try {
    const response = yield call(getCertifications,{page,pageSize});
    yield put(getCertificationsSuccess(response));
  } catch (error) {
    yield put(getCertificationsFail(error.response));
  }
}
// All Certificate List
function* onGetAllCertificationsDetails() {
  try {
    const response = yield call(getAllCertifications);
    yield put(getAllCertificationsSuccess(response));
  } catch (error) {
    yield put(getAllCertificationsFail(error.response));
  }
}



function* onGetCertificationDetails({ payload: code }) {
  try {
    const response = yield call(getCertificationDetails, code);
    yield put(getCertificationsDetailsSuccess(response));
  } catch (error) {
    yield put(getCertificationsDetailsFail(error.response));
  }
}
function* onCreateCertification({ payload }) {
	try {
	  const response = yield call(createCertification, payload);
	  yield put(createCertificationSuccess(response));
    ShowSucess('Certification Created successfully', {
      position: 'top-right'
    });
    
	} catch (error) {
	  yield put(createCertificationFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdateCertification({ payload: { code},payload }) {
	try {
	  const response = yield call(updateCertification, code, payload);
	  yield put(updateCertificationSuccess(response));
    ShowSucess('Certification Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateCertificationFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  // function* onDeleteCertification({ payload: code }) {
	// try {
	//   const response = yield call(deleteCertification, code);
	//   yield put(deleteCertificationSuccess(response));
  //   ShowSucess('Deleted successfully', {
  //     position: 'top-right'
  //   });
	// } catch (error) {
	//   yield put(deleteCertificationFail(error.response));
  //   ShowError(error?.response?.data?.message);
	// }
  // }

  function* onDeleteCertification({ payload: code }) {
    try {
      const response = yield call(deleteCertification, code);
      yield put(deleteCertificationSuccess(code));
      ShowSucess('Deleted successfully', {
        position: 'top-right'
      });
    } catch (error) {
      yield put(deleteCertificationFail(error.response));
      ShowError(error?.response?.data?.message);
    }
  }
  

function* CartSaga() {
  yield takeLatest(GET_CERTIFICATIONS, onGetCertifications);
  yield takeLatest(GET_CERTIFICATIONS_DETAILS, onGetCertificationDetails);
  yield takeLatest(CERTIFICATION_CREATE, onCreateCertification);
  yield takeLatest(CERTIFICATION_UPDATE, onUpdateCertification);
  yield takeLatest(CERTIFICATION_DELETE, onDeleteCertification);
  yield takeLatest(GET_ALL_CERTIFICATIONS_DETAILS,onGetAllCertificationsDetails);
}

export default CartSaga;