import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_DFWP_CONTACT_LIST, DFWP_CONTACT_LIST_CREATE, GET_DFWP_CONTACT_LIST_DETAILS, RESET, RESET_DFWP_CONTACT_LIST_CREATE, UPDATE_DFWP_CONTACT_LIST } from "./actionsTypes";

import {
    createDFWPContactListSuccess,
    createDFWPContactListFail,
    resetCreateDFWPContactList,
    reset,
    getDFWPContactListDetailsSuccess,
    getDFWPContactListDetailsFail,
    updateDFWPContactListSuccess,
    updateDFWPContactListFail,
    deleteDFWPContactListSuccess,
    deleteDFWPContactListFail,
} from "./action";

import {
    createDFWPContactList, getDFWPContactListDetails, updateDFWPContactList, deleteDFWPContactList
} from "../../../_helper/services/DrugTesting/DFWPContactList";


function* onCreateDFWPContactList({ payload }) {
    try {
        const response = yield call(createDFWPContactList, payload);
        yield put(createDFWPContactListSuccess(response));
        ShowSucess("DFWP Contact List Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createDFWPContactListFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateDFWPContactList());
    }
}

function* onGetDFWPContactListDetails({ payload: id }) {
    try {
        const response = yield call(getDFWPContactListDetails, id);
        yield put(getDFWPContactListDetailsSuccess(response));
    } catch (error) {
        yield put(getDFWPContactListDetailsFail(error.response));
    }
}
function* onUpdateDFWPContactList({ payload: { id }, payload }) {
    try {
        const response = yield call(updateDFWPContactList, id, payload);
        yield put(updateDFWPContactListSuccess(response));
        yield put(getDFWPContactListDetailsSuccess(payload));
        ShowSucess(`DFWP Contact List updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateDFWPContactListFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteDFWPContactList({ payload: id }) {
    try {
        const response = yield call(deleteDFWPContactList, id);
        yield put(deleteDFWPContactListSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteDFWPContactListFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* DFWPContactListSaga() {
    yield takeLatest(DFWP_CONTACT_LIST_CREATE, onCreateDFWPContactList);
    yield takeLatest(RESET_DFWP_CONTACT_LIST_CREATE, resetCreateDFWPContactList);
    yield takeLatest(GET_DFWP_CONTACT_LIST_DETAILS, onGetDFWPContactListDetails);
    yield takeLatest(UPDATE_DFWP_CONTACT_LIST, onUpdateDFWPContactList);
    yield takeLatest(DELETE_DFWP_CONTACT_LIST, onDeleteDFWPContactList);
    yield takeLatest(RESET, reset);
}

export default DFWPContactListSaga;