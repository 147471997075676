import { get, post, put, remove } from "./api_helper";
import * as url from "./url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

//Post
export const getPosts = () => get(url.GET_POSTS);

//Post
export const getPostDetails = (id) =>
  get(url.GET_POST_DETAILS, {
    params: {
      id: id,
    },
  });

export const getLeaves = () => {};
export const findOutSideEmployeement = async (id) => {
  console.log("findOutSideEmployeement", id);
  const result = await get(
    `${baseURL}${url.FIND_OUTSIDE_EMPLOYEEMENT}?EmployeeID=${id}`
  );
  console.log("api call", result);
  return result;
};

export const requestOutSideEmploy = (payload) =>
  post(baseURL + url.REQ_OUTSIDE_EMPLOYEEMENT, payload);
export const fetchOutSideEmploy = async (id) =>
  await get(`${baseURL}${url.REQ_OUTSIDE_EMPLOYEEMENT}/${Number(id)}`);
export const editOutSideEmploy = async (payload, id) =>
  await put(`${baseURL}${url.REQ_OUTSIDE_EMPLOYEEMENT}/${Number(id)}`, payload);

export const updateStatus = async (payload, id) =>
  await post(
    `${baseURL}${url.REQ_OUTSIDE_EMPLOYEEMENT}/updatestatus?id=${Number(id)}`,
    payload
  );
export const deleteOutsideEmployment = async (id) =>
  await remove(`${baseURL}${url.REQ_OUTSIDE_EMPLOYEEMENT}/${Number(id)}`);

export const findLeaveApplic = async (id) =>
  await get(`${baseURL}${url.FIND_LEAVE_APPLICATION}?EmployeeID=${id}`);
export const requestLeaveApplication = async (payload) =>
  await post(baseURL + url?.REQ_LEAVE_APPLICATION, payload);
export const fetchLeaveApplic = async (id) =>
  await get(`${baseURL}${url.REQ_LEAVE_APPLICATION}/${Number(id)}`);
export const editLeaveApplic = async (payload, id) =>
  await put(`${baseURL}${url.REQ_LEAVE_APPLICATION}/${Number(id)}`, payload);
export const updateLeaveStatus = async (payload, id) =>
  await post(
    `${baseURL}${url.REQ_LEAVE_APPLICATION}/updatestatus?id=${Number(id)}`,
    payload
  );
export const deleteLeaveApplication = async (id) =>
  await remove(`${baseURL}${url.REQ_LEAVE_APPLICATION}/${Number(id)}`);
export const calculateHours = async (startDate, endDate) =>
  await get(
    `${baseURL}${url.CALCULATE_HOURS}?startDate=${startDate}&endDate=${endDate}`
  );
export const getoutSideEmployHistory = async (id) =>
  await get(`${baseURL}${url.OUTSIDEEMPLOYMENT_HISTORY}/${Number(id)}`);
export const leaveApplicationHistory = async (id) =>
  await get(`${baseURL}${url.LEAVEAPPLICATION_HISTORY}/${Number(id)}`);
// export const updateEmployeeType = async (id, payload) =>
// await put(`${baseURL + url.EMPLOYEETYPES_BASE}/${Number(id)}`, payload);
