import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShowError,ShowSucess } from '../../_helper/helper';


import { takeLatest, put, call } from "redux-saga/effects";

import { GET_PMRCNATURES, GET_PMRCNATURES_DETAILS,  PMRCNATURE_CREATE, PMRCNATURE_DELETE,
   PMRCNATURE_UPDATE} from "./actionTypes";

import {
  getPmrcNaturesSuccess,
  getPmrcNaturesFail,
  getPmrcNaturesDetailsSuccess,
  getPmrcNaturesDetailsFail,
  createPmrcNatureSuccess,
  createPmrcNatureFail,
  updatePmrcNatureSuccess,
  updatePmrcNatureFail,
  deletePmrcNatureSuccess,
  deletePmrcNatureFail,
} from "./actions";

import {  createPmrcNature,deletePmrcNature,
	getPmrcNatureDetails,getPmrcNatures
	,updatePmrcNature,
 } from "../../_helper/services/pmrcnature";
function* onGetPmrcNatures({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPmrcNatures,{page,pageSize});
    yield put(getPmrcNaturesSuccess(response));
  } catch (error) {
    yield put(getPmrcNaturesFail(error.response));
  }
}

function* onGetPmrcNatureDetails({ payload: id }) {
  try {
    const response = yield call(getPmrcNatureDetails, id);
    yield put(getPmrcNaturesDetailsSuccess(response));
  } catch (error) {
    yield put(getPmrcNaturesDetailsFail(error.response));
  }
}

function* onCreatePmrcNature({ payload }) {
  try {
    const response = yield call(createPmrcNature, payload);
    yield put(createPmrcNatureSuccess(response));
    ShowSucess('PmrcNature Created successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(createPmrcNatureFail(error.response));
    ShowError(error?.response?.data?.message);
    // Handle error
  }
}
  // function* onUpdatePmrcNature({ payload: { id},payload }) {
	// try {
	//   const response = yield call(updatePmrcNature, id, payload);
	//   yield put(updatePmrcNatureSuccess(response));
	// } catch (error) {
	//   yield put(updatePmrcNatureFail(error.response));
	// }
  // }
  function* onUpdatePmrcNature({ payload: { id }, payload }) {
    try {
      const response = yield call(updatePmrcNature, id, payload);
      yield put(updatePmrcNatureSuccess(response));
      ShowSucess('PmrcNature Details updated successfully', {
        position: 'top-right'
      });
    } catch (error) {
      yield put(updatePmrcNatureFail(error.response));
      ShowError(error?.response?.data?.message);
      // Handle error
    }
  }
  function* onDeletePmrcNature({ payload: ID }) {
	try {
	  const response = yield call(deletePmrcNature, ID);
	  yield put(deletePmrcNatureSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deletePmrcNatureFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_PMRCNATURES, onGetPmrcNatures);
  yield takeLatest(GET_PMRCNATURES_DETAILS, onGetPmrcNatureDetails);
  yield takeLatest(PMRCNATURE_CREATE, onCreatePmrcNature);
  yield takeLatest(PMRCNATURE_UPDATE, onUpdatePmrcNature);
  yield takeLatest(PMRCNATURE_DELETE, onDeletePmrcNature);
}

export default CartSaga;