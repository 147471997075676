import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { GRIEVANCE_APPEAL_STATUS_CREATE, DELETE_GRIEVANCE_APPEAL_STATUS, GET_GRIEVANCE_APPEAL_STATUSES, GET_GRIEVANCE_APPEAL_STATUS_DETAILS, RESET, RESET_GRIEVANCE_APPEAL_STATUS_CREATE, UPDATE_GRIEVANCE_APPEAL_STATUS } from "./actionsTypes";

import {
    createGrievanceAppealStatusSuccess,
    createGrievanceAppealStatusFail,
    reset,
    resetCreateGrievanceAppealStatus,
    getGrievanceAppealStatusDetailsSuccess,
    getGrievanceAppealStatusDetailsFail,
    updateGrievanceAppealStatusSuccess,
    deleteGrievanceAppealStatusSuccess,
    deleteGrievanceAppealStatusFail,
    updateGrievanceAppealStatusFail,
    getGrievanceAppealStatusesSuccess,
    getGrievanceAppealStatusesFail,
} from "./action";

import {
    createGrievanceAppealStatus, deleteGrievanceAppealStatus, getGrievanceAppealStatusDetails, getGrievanceAppealStatuses, updateGrievanceAppealStatus
} from "../../../../_helper/services/EMR/GrievanceAppealStatus";


function* onCreateGrievanceAppealStatus({ payload }) {
    try {
        const response = yield call(createGrievanceAppealStatus, payload);
        yield put(createGrievanceAppealStatusSuccess(response));
        ShowSucess("Grievance Appeal Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createGrievanceAppealStatusFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateGrievanceAppealStatus());
    }
}

function* onGetGrievanceAppealStatusDetails({ payload: id }) {
    try {
        const response = yield call(getGrievanceAppealStatusDetails, id);
        yield put(getGrievanceAppealStatusDetailsSuccess(response));
    } catch (error) {
        yield put(getGrievanceAppealStatusDetailsFail(error.response));
    }
}

function* onGetGrievanceAppealStatuses() {
    try {
        const response = yield call(getGrievanceAppealStatuses);
        yield put(getGrievanceAppealStatusesSuccess(response));
    } catch (error) {
        yield put(getGrievanceAppealStatusesFail(error.response));
    }
}

function* onUpdateGrievanceAppealStatus({ payload: { id }, payload }) {
    try {
        const response = yield call(updateGrievanceAppealStatus, id, payload);
        yield put(updateGrievanceAppealStatusSuccess(response));
        yield put(getGrievanceAppealStatusDetailsSuccess(payload));
        ShowSucess(`Grievance Appeal Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateGrievanceAppealStatusFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteGrievanceAppealStatus({ payload: id }) {
    try {
        const response = yield call(deleteGrievanceAppealStatus, id);
        yield put(deleteGrievanceAppealStatusSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteGrievanceAppealStatusFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* GrievanceAppealStatusSaga() {
    yield takeLatest(GRIEVANCE_APPEAL_STATUS_CREATE, onCreateGrievanceAppealStatus);
    yield takeLatest(GET_GRIEVANCE_APPEAL_STATUS_DETAILS, onGetGrievanceAppealStatusDetails);
    yield takeLatest(GET_GRIEVANCE_APPEAL_STATUSES, onGetGrievanceAppealStatuses);
    yield takeLatest(UPDATE_GRIEVANCE_APPEAL_STATUS, onUpdateGrievanceAppealStatus);
    yield takeLatest(DELETE_GRIEVANCE_APPEAL_STATUS, onDeleteGrievanceAppealStatus);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_GRIEVANCE_APPEAL_STATUS_CREATE, resetCreateGrievanceAppealStatus);
}

export default GrievanceAppealStatusSaga;