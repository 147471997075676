import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { STATUS_OF_APPT_CREATE, DELETE_STATUS_OF_APPT, GET_STATUS_OF_APPTES, GET_STATUS_OF_APPT_DETAILS, RESET, RESET_STATUS_OF_APPT_CREATE, UPDATE_STATUS_OF_APPT } from "./actionsTypes";

import {
    createStatusOfAPPTSuccess,
    createStatusOfAPPTFail,
    reset,
    resetCreateStatusOfAPPT,
    getStatusOfAPPTDetailsSuccess,
    getStatusOfAPPTDetailsFail,
    updateStatusOfAPPTSuccess,
    deleteStatusOfAPPTSuccess,
    deleteStatusOfAPPTFail,
    updateStatusOfAPPTFail,
    getStatusOfAPPTesSuccess,
    getStatusOfAPPTesFail,
} from "./action";

import {
    createStatusOfAPPT, deleteStatusOfAPPT, getStatusOfAPPTDetails, getStatusOfAPPTes, updateStatusOfAPPT
} from "../../../../_helper/services/EMR/StatusOfAPPT";


function* onCreateStatusOfAPPT({ payload }) {
    try {
        const response = yield call(createStatusOfAPPT, payload);
        yield put(createStatusOfAPPTSuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createStatusOfAPPTFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateStatusOfAPPT());
    }
}

function* onGetStatusOfAPPTDetails({ payload: id }) {
    try {
        const response = yield call(getStatusOfAPPTDetails, id);
        yield put(getStatusOfAPPTDetailsSuccess(response));
    } catch (error) {
        yield put(getStatusOfAPPTDetailsFail(error.response));
    }
}

function* onGetStatusOfAPPTes() {
    try {
        const response = yield call(getStatusOfAPPTes);
        yield put(getStatusOfAPPTesSuccess(response));
    } catch (error) {
        yield put(getStatusOfAPPTesFail(error.response));
    }
}

function* onUpdateStatusOfAPPT({ payload: { id }, payload }) {
    try {
        const response = yield call(updateStatusOfAPPT, id, payload);
        yield put(updateStatusOfAPPTSuccess(response));
        yield put(getStatusOfAPPTDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateStatusOfAPPTFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteStatusOfAPPT({ payload: id }) {
    try {
        const response = yield call(deleteStatusOfAPPT, id);
        yield put(deleteStatusOfAPPTSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteStatusOfAPPTFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* StatusOfAPPTSaga() {
    yield takeLatest(STATUS_OF_APPT_CREATE, onCreateStatusOfAPPT);
    yield takeLatest(GET_STATUS_OF_APPT_DETAILS, onGetStatusOfAPPTDetails);
    yield takeLatest(GET_STATUS_OF_APPTES, onGetStatusOfAPPTes);
    yield takeLatest(UPDATE_STATUS_OF_APPT, onUpdateStatusOfAPPT);
    yield takeLatest(DELETE_STATUS_OF_APPT, onDeleteStatusOfAPPT);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_STATUS_OF_APPT_CREATE, resetCreateStatusOfAPPT);
}

export default StatusOfAPPTSaga;