 import { SEND_QUICK_ACTION_REQUEST,SEND_QUICK_ACTION_SUCCESS,SEND_QUICK_ACTION_FAILURE ,
    GET_MAIN_MENU,GET_MAIN_MENU_SUCCESS,GET_MAIN_MENU_FAILURE
} from "./actiionType";
 


export const sendQuickActionRequest = (data) => ({
    type: SEND_QUICK_ACTION_REQUEST,
    payload: data,
  });
  
  export const sendQuickActionSuccess = () => ({
    type: SEND_QUICK_ACTION_SUCCESS,
  });
  
  export const sendQuickActionFailure = (error) => ({
    type: SEND_QUICK_ACTION_FAILURE,
    payload: error,
  });

  export const getmainmenurequest=( )=>({
    type:GET_MAIN_MENU,
    
  });
  export const getmainmenusuccess=( payload)=>({
    type:GET_MAIN_MENU_SUCCESS,
    payload:payload
  })

 

  export const getmainmenufailure=(error)=>({
    type:GET_MAIN_MENU_FAILURE,
    payload:error
  })