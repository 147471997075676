import {
  GET_VENDORS,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAIL,
  GET_VENDORS_DETAILS,
  GET_VENDORS_DETAILS_SUCCESS,
  GET_VENDORS_DETAILS_FAIL,
  VENDOR_CREATE,
  VENDOR_CREATE_FAIL,
  VENDOR_CREATE_SUCCESS,
  VENDOR_DELETE,
  VENDOR_DELETE_FAIL,
  VENDOR_DELETE_SUCCESS,
  VENDOR_UPDATE,
  VENDOR_UPDATE_FAIL,
  VENDOR_UPDATE_SUCCESS,
  RESET,
  GET_ALL_VENDOR_DETAILS,
  GET_ALL_VENDOR_DETAILS_SUCCESS,
  GET_ALL_VENDOR_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  vendorList: [],
  vendor: {},
  postingResult: {},
  loading: false,
  vendorSetting: {},
  error: {
    message: "",
  },
};

const VendorReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_VENDORS:
      state = { ...state, loading: true };
      break;
    case GET_VENDORS_SUCCESS:
      state = { ...state, vendorList: action.payload, loading: false };
      break;
    case GET_VENDORS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_VENDORS_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_VENDORS_DETAILS_SUCCESS:
      state = { ...state, vendor: action.payload, loading: false };
      break;
    case GET_VENDORS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case VENDOR_CREATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case VENDOR_CREATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case VENDOR_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        loading: false,
      };
      break;
    case VENDOR_UPDATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case VENDOR_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case VENDOR_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case VENDOR_DELETE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case VENDOR_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case VENDOR_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
      // Getting All Vendor List Details Here
    case GET_ALL_VENDOR_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_ALL_VENDOR_DETAILS_SUCCESS:
      state = { ...state, vendorList: action.payload, loading: false };
      break;
    case GET_ALL_VENDOR_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case RESET:
      state = { ...state, vendor: {} };

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default VendorReducer;
