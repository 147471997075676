export const GET_BILLING_INVOICES = "GET_BILLING_INVOICES";
export const GET_BILLING_INVOICES_SUCCESS = "GET_BILLING_INVOICES_SUCCESS";
export const GET_BILLING_INVOICES_FAIL = "GET_BILLING_INVOICES_FAIL";

export const GET_BILLING_INVOICES_DETAILS = "GET_BILLING_INVOICES_DETAILS";
export const GET_BILLING_INVOICES_DETAILS_SUCCESS = "GET_BILLING_INVOICES_DETAILS_SUCCESS";
export const GET_BILLING_INVOICES_DETAILS_FAIL = "GET_BILLING_INVOICES_DETAILS_FAIL";

export const BILLING_INVOICE_UPDATE = "BILLING_INVOICE_UPDATE";
export const BILLING_INVOICE_UPDATE_SUCCESS = "BILLING_INVOICE_UPDATE_SUCCESS";
export const BILLING_INVOICE_UPDATE_FAIL = "BILLING_INVOICE_UPDATE_FAIL";

export const BILLING_INVOICE_CREATE = "BILLING_INVOICE_CREATE";
export const BILLING_INVOICE_CREATE_SUCCESS = "BILLING_INVOICE_CREATE_SUCCESS";
export const BILLING_INVOICE_CREATE_FAIL = "BILLING_INVOICE_CREATE_FAIL";

export const BILLING_INVOICE_DELETE = "BILLING_INVOICE_DELETE";
export const BILLING_INVOICE_DELETE_SUCCESS = "BILLING_INVOICE_DELETE_SUCCESS";
export const BILLING_INVOICE_DELETE_FAIL = "BILLING_INVOICE_DELETE_FAIL";

export const GET_BILLING_INVOICE_STATUS_LISTS = "GET_BILLING_INVOICE_STATUS_LISTS"
export const GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS = "GET_BILLING_INVOICE_STATUS_LISTS_SUCCESS"
export const GET_BILLING_INVOICE_STATUS_LISTS_FAIL = "GET_BILLING_INVOICE_STATUS_LISTS_FAIL"

export const RESET = "RESET";
export const RESET_BILLING_INVOICE_CREATE = "RESET_BILLING_INVOICE_CREATE";
export const RESET_BILLING_INVOICES_DETAILS = "RESET_BILLING_INVOICES_DETAILS";
export const RESET_BILLING_INVOICES_DELETE_DATA = "RESET_BILLING_INVOICES_DELETE_DATA";