import {
	GET_PAYGRADES, GET_PAYGRADES_SUCCESS, GET_PAYGRADES_FAIL,
	GET_PAYGRADES_DETAILS, GET_PAYGRADES_DETAILS_SUCCESS, GET_PAYGRADES_DETAILS_FAIL,
	PAYGRADE_CREATE,PAYGRADE_CREATE_FAIL,PAYGRADE_CREATE_SUCCESS,
	PAYGRADE_DELETE,PAYGRADE_DELETE_FAIL,PAYGRADE_DELETE_SUCCESS,
	PAYGRADE_UPDATE,PAYGRADE_UPDATE_FAIL,PAYGRADE_UPDATE_SUCCESS,RESET, GET_PAYGRADES_SETTING_FAIL, GET_PAYGRADES_SETTING_SUCCESS, SAVE_PAYGRADES_SETTING_FAIL, SAVE_PAYGRADES_SETTING_SUCCESS, SAVE_PAYGRADES_SETTING, GET_PAYGRADES_SETTING, PAYGRADE_COPY, PAYGRADE_COPY_SUCCESS, PAYGRADE_COPY_FAIL, PAYGRADE_STATUS_CREATE, PAYGRADE_STATUS_CREATE_SUCCESS, PAYGRADE_STATUS_CREATE_FAIL, PAYGRADE_STATUS_UPDATE, PAYGRADE_STATUS_UPDATE_SUCCESS, PAYGRADE_STATUS_UPDATE_FAIL, PAYGRADE_STATUS_DELETE, PAYGRADE_STATUS_DELETE_SUCCESS, PAYGRADE_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPaygrades = (payload) => {
	return {
	  type: GET_PAYGRADES,
	  payload: payload
	};
  };
  
  export const getPaygradesSuccess = (posts) => {
	return {
	  type: GET_PAYGRADES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPaygradesFail = (error) => {
	return {
	  type: GET_PAYGRADES_FAIL,
	  payload: error,
	};
  };
  
  export const getPaygradesDetails = (id) => {
	return {
	  type: GET_PAYGRADES_DETAILS,
	  payload: id,
	};
  };
  
  export const getPaygradesDetailsSuccess = (post) => {
	return {
	  type: GET_PAYGRADES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPaygradesDetailsFail = (error) => {
	return {
	  type: GET_PAYGRADES_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPaygrade =(payload)=>{
	return {
		type: PAYGRADE_CREATE,
		payload: payload
	}
  }
  export const createPaygradeSuccess =(response)=>{
	return {
		type: PAYGRADE_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPaygradeFail =(error)=>{
	return {
		type: PAYGRADE_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePaygrade =(payload)=>{
	
	return {
		type: PAYGRADE_UPDATE,
		payload: payload
	}
  }
  export const updatePaygradeSuccess =(response)=>{
	return {
		type: PAYGRADE_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePaygradeFail =(error)=>{
	return {
		type: PAYGRADE_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePaygrade =(payload)=>{
	return {
		type: PAYGRADE_DELETE,
		payload: payload
	}
  }
  export const deletePaygradeSuccess =(response)=>{
	return {
		type: PAYGRADE_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePaygradeFail =(error)=>{
	return {
		type: PAYGRADE_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

