export const GET_PAYSTEPS = "GET_PAYSTEPS";
export const GET_PAYSTEPS_SUCCESS = "GET_PAYSTEPS_SUCCESS";
export const GET_PAYSTEPS_FAIL = "GET_PAYSTEPS_FAIL";

export const GET_PAYSTEPS_DETAILS = "GET_PAYSTEPS_DETAILS";
export const GET_PAYSTEPS_DETAILS_SUCCESS = "GET_PAYSTEPS_DETAILS_SUCCESS";
export const GET_PAYSTEPS_DETAILS_FAIL = "GET_PAYSTEPS_DETAILS_FAIL";

export const PAYSTEP_UPDATE= "PAYSTEP_UPDATE";
export const PAYSTEP_UPDATE_SUCCESS = "PAYSTEP_UPDATE_SUCCESS";
export const PAYSTEP_UPDATE_FAIL = "PAYSTEP_UPDATE_FAIL";

export const PAYSTEP_CREATE= "PAYSTEP_CREATE";
export const PAYSTEP_CREATE_SUCCESS = "PAYSTEP_CREATE_SUCCESS";
export const PAYSTEP_CREATE_FAIL = "PAYSTEP_CREATE_FAIL";

export const PAYSTEP_DELETE= "PAYSTEP_DELETE";
export const PAYSTEP_DELETE_SUCCESS = "PAYSTEP_DELETE_SUCCESS";
export const PAYSTEP_DELETE_FAIL = "PAYSTEP_DELETE_FAIL";
export const RESET="RESET";

