import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_DEPARTMENT_JV_CONTROL_NUMBER, DEPARTMENT_JV_CONTROL_NUMBER_CREATE, GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS, RESET, RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE, UPDATE_DEPARTMENT_JV_CONTROL_NUMBER } from "./actionsTypes";

import {
    createDepartmentJVControlNumberSuccess,
    createDepartmentJVControlNumberFail,
    reset,
    resetCreateDepartmentJVControlNumber,
    updateDepartmentJVControlNumberSuccess,
    updateDepartmentJVControlNumberFail,
    deleteDepartmentJVControlNumberSuccess,
    deleteDepartmentJVControlNumberFail,
    getDepartmentJVControlNumberDetailsSuccess,
    getDepartmentJVControlNumberDetailsFail,
} from "./action";

import { createDepartmentsJVControlNumber, deleteDepartmentsJVControlNumber, getDepartmentsJVControlNumberDetails, updateDepartmentsJVControlNumber } from "../../../_helper/services/DrugTesting/departmentsJVControlNumber";


function* onCreateDepartmentJVControlNumber({ payload }) {
    try {
        const response = yield call(createDepartmentsJVControlNumber, payload);
        yield put(createDepartmentJVControlNumberSuccess(response));
        ShowSucess("Department JV Control Number Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createDepartmentJVControlNumberFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateDepartmentJVControlNumber());
    }
}

function* onGetDepartmentJVControlNumberDetails({ payload: id }) {
    try {
        const response = yield call(getDepartmentsJVControlNumberDetails, id);
        yield put(getDepartmentJVControlNumberDetailsSuccess(response));
    } catch (error) {
        yield put(getDepartmentJVControlNumberDetailsFail(error.response));
    }
}

function* onUpdateDepartmentJVControlNumber({ payload: { id }, payload }) {
    try {
        const response = yield call(updateDepartmentsJVControlNumber, id, payload);
        yield put(updateDepartmentJVControlNumberSuccess(response));
        yield put(getDepartmentJVControlNumberDetailsSuccess(payload));
        ShowSucess('Department JV Control Number updated successfully', { position: 'top-right' });
    } catch (error) {
        yield put(updateDepartmentJVControlNumberFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteDepartmentJVControlNumber({ payload: id }) {
    try {
        const response = yield call(deleteDepartmentsJVControlNumber, id);
        yield put(deleteDepartmentJVControlNumberSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteDepartmentJVControlNumberFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* DepartmentJVControlNumberSaga() {
    yield takeLatest(DEPARTMENT_JV_CONTROL_NUMBER_CREATE, onCreateDepartmentJVControlNumber);
    yield takeLatest(RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE, resetCreateDepartmentJVControlNumber);
    yield takeLatest(GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS, onGetDepartmentJVControlNumberDetails);
    yield takeLatest(UPDATE_DEPARTMENT_JV_CONTROL_NUMBER, onUpdateDepartmentJVControlNumber);
    yield takeLatest(DELETE_DEPARTMENT_JV_CONTROL_NUMBER, onDeleteDepartmentJVControlNumber);
    yield takeLatest(RESET, reset)
}

export default DepartmentJVControlNumberSaga;