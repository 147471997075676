import {
    DELETE_MIS_DATA_LOG,
    DELETE_MIS_DATA_LOG_FAIL,
    DELETE_MIS_DATA_LOG_SUCCESS,
    GET_MIS_DATA_LOG_DETAILS,
    GET_MIS_DATA_LOG_DETAILS_FAIL,
    GET_MIS_DATA_LOG_DETAILS_SUCCESS,
    MIS_DATA_LOG_CREATE, MIS_DATA_LOG_CREATE_FAIL, MIS_DATA_LOG_CREATE_SUCCESS,
    RESET,
    RESET_MIS_DATA_LOG_CREATE,
    RESET_MIS_DATA_LOG_DETAILS,
    UPDATE_MIS_DATA_LOG,
    UPDATE_MIS_DATA_LOG_FAIL,
    UPDATE_MIS_DATA_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createMISDataLog = (payload) => {
    return {
        type: MIS_DATA_LOG_CREATE,
        payload: payload
    }
};
export const createMISDataLogSuccess = (response) => {
    return {
        type: MIS_DATA_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createMISDataLogFail = (error) => {
    return {
        type: MIS_DATA_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getMISDataLogDetails = (id) => {
    return {
        type: GET_MIS_DATA_LOG_DETAILS,
        payload: id,
    };
};
export const getMISDataLogDetailsSuccess = (data) => {
    return {
        type: GET_MIS_DATA_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getMISDataLogDetailsFail = (error) => {
    return {
        type: GET_MIS_DATA_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateMISDataLog = (payload) => {

    return {
        type: UPDATE_MIS_DATA_LOG,
        payload: payload
    }
};
export const updateMISDataLogSuccess = (response) => {
    return {
        type: UPDATE_MIS_DATA_LOG_SUCCESS,
        payload: response
    }
};
export const updateMISDataLogFail = (error) => {
    return {
        type: UPDATE_MIS_DATA_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteMISDataLog = (id) => {
    return {
        type: DELETE_MIS_DATA_LOG,
        payload: id
    }
};
export const deleteMISDataLogSuccess = (response) => {
    return {
        type: DELETE_MIS_DATA_LOG_SUCCESS,
        payload: response
    }
};
export const deleteMISDataLogFail = (error) => {
    return {
        type: DELETE_MIS_DATA_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateMISDataLog = () => {
    return {
        type: RESET_MIS_DATA_LOG_CREATE
    }
};
export const resetMISDataLogDetails = () => {
    return {
        type: RESET_MIS_DATA_LOG_DETAILS,
    };
};