import { get } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

//DT TEST TYPES
export const getDTTestTypes = () => get(`${baseURL + url.DT_TEST_TYPES_LISTS}`);

//DT RESULTS
export const getDTResults = () => get(`${baseURL + url.DT_TEST_RESULT_LISTS}`);

//DT RESULT DETAILS
export const getDTResultDetails = () => get(`${baseURL + url.DT_TEST_RESULT_DETAILS_LISTS}`);

//TYPE OF COLLECTIONS
export const getDTTypeOfCollection = () => get(`${baseURL + url.DT_TYPE_OF_COLLECTION}`);

//NATURE OF ACTION
export const getDTNatureOfActions = () => get(`${baseURL + url.DT_NATURE_OF_ACTION}`);

//RELEASED TO
export const getDTReleasedTo = () => get(`${baseURL + url.DT_RELEASED_TO}`);

//FUNDING STATUS
export const getDTFundingStatusLists = () => get(`${baseURL + url.DT_FUNDING_STATUS}`);

//DT RANDOM REASON
export const getDTRandomReasons = () => get(`${baseURL + url.DT_RANDOM_REASON}`);

//TDP TYPES
export const getTDPTypes = () => get(`${baseURL + url.DT_TDP_TYPES}`);

//QUARTER REPORT DATES
export const getQuarterReportDates = () => get(`${baseURL + url.QUARTER_REPORT_DATE}`);

//VENDOR LOG STATUS
export const getVendorLogStatusLists = () => get(`${baseURL + url.VENDOR_LOG_STATUS_LIST}`);