export const DEPARTMENT_RANDOM_DATE_LOG_CREATE = "DEPARTMENT_RANDOM_DATE_LOG_CREATE";
export const DEPARTMENT_RANDOM_DATE_LOG_CREATE_SUCCESS = "DEPARTMENT_RANDOM_DATE_LOG_CREATE_SUCCESS";
export const DEPARTMENT_RANDOM_DATE_LOG_CREATE_FAIL = "DEPARTMENT_RANDOM_DATE_LOG_CREATE_FAIL";

export const GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS = "GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS";
export const GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_SUCCESS = "GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_SUCCESS";
export const GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_FAIL = "GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_FAIL";

export const UPDATE_DEPARTMENT_RANDOM_DATE_LOG = "UPDATE_DEPARTMENT_RANDOM_DATE_LOG";
export const UPDATE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS = "UPDATE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS";
export const UPDATE_DEPARTMENT_RANDOM_DATE_LOG_FAIL = "UPDATE_DEPARTMENT_RANDOM_DATE_LOG_FAIL";

export const DELETE_DEPARTMENT_RANDOM_DATE_LOG = "DELETE_DEPARTMENT_RANDOM_DATE_LOG";
export const DELETE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS = "DELETE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS";
export const DELETE_DEPARTMENT_RANDOM_DATE_LOG_FAIL = "DELETE_DEPARTMENT_RANDOM_DATE_LOG_FAIL";

export const RESET = "RESET";
export const RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE = "RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE";
export const RESET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS = "RESET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS";