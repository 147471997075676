export const TRAINING_LOG_CREATE = "TRAINING_LOG_CREATE";
export const TRAINING_LOG_CREATE_SUCCESS = "TRAINING_LOG_CREATE_SUCCESS";
export const TRAINING_LOG_CREATE_FAIL = "TRAINING_LOG_CREATE_FAIL";

export const GET_TRAINING_LOG_DETAILS = "GET_TRAINING_LOG_DETAILS";
export const GET_TRAINING_LOG_DETAILS_SUCCESS = "GET_TRAINING_LOG_DETAILS_SUCCESS";
export const GET_TRAINING_LOG_DETAILS_FAIL = "GET_TRAINING_LOG_DETAILS_FAIL";

export const UPDATE_TRAINING_LOG = "UPDATE_TRAINING_LOG";
export const UPDATE_TRAINING_LOG_SUCCESS = "UPDATE_TRAINING_LOG_SUCCESS";
export const UPDATE_TRAINING_LOG_FAIL = "UPDATE_TRAINING_LOG_FAIL";

export const DELETE_TRAINING_LOG = "DELETE_TRAINING_LOG";
export const DELETE_TRAINING_LOG_SUCCESS = "DELETE_TRAINING_LOG_SUCCESS";
export const DELETE_TRAINING_LOG_FAIL = "DELETE_TRAINING_LOG_FAIL";

export const RESET = "RESET";
export const RESET_TRAINING_LOG_CREATE = "RESET_TRAINING_LOG_CREATE";
export const RESET_TRAINING_LOG_DETAILS = "RESET_TRAINING_LOG_DETAILS";
export const RESET_TRAINING_LOG_DELETE = "RESET_TRAINING_LOG_DELETE";

export const GET_TRAINING__AUDIT_LOGS = "GET_TRAINING__AUDIT_LOGS";
export const GET_TRAINING__AUDIT_LOGS_SUCCESS = "GET_TRAINING__AUDIT_LOGS_SUCCESS";
export const GET_TRAINING__AUDIT_LOGS_FAIL = "GET_TRAINING__AUDIT_LOGS_FAIL";
