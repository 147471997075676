import {
    CBAR_LOG_CREATE, CBAR_LOG_CREATE_FAIL, CBAR_LOG_CREATE_SUCCESS,
    DELETE_CBAR_LOG,
    DELETE_CBAR_LOG_FAIL,
    DELETE_CBAR_LOG_SUCCESS,
    GET_CBAR_LOG_DETAILS,
    GET_CBAR_LOG_DETAILS_FAIL,
    GET_CBAR_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_CBAR_LOG_CREATE,
    RESET_CBAR_LOG_DELETE,
    RESET_CBAR_LOG_DETAILS,
    UPDATE_CBAR_LOG,
    UPDATE_CBAR_LOG_FAIL,
    UPDATE_CBAR_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createCBARLog = (payload) => {
    return {
        type: CBAR_LOG_CREATE,
        payload: payload
    }
};
export const createCBARLogSuccess = (response) => {
    return {
        type: CBAR_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createCBARLogFail = (error) => {
    return {
        type: CBAR_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getCBARLogDetails = (id) => {
    return {
        type: GET_CBAR_LOG_DETAILS,
        payload: id,
    };
};
export const getCBARLogDetailsSuccess = (data) => {
    return {
        type: GET_CBAR_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getCBARLogDetailsFail = (error) => {
    return {
        type: GET_CBAR_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateCBARLog = (payload) => {

    return {
        type: UPDATE_CBAR_LOG,
        payload: payload
    }
};
export const updateCBARLogSuccess = (response) => {
    return {
        type: UPDATE_CBAR_LOG_SUCCESS,
        payload: response
    }
};
export const updateCBARLogFail = (error) => {
    return {
        type: UPDATE_CBAR_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteCBARLog = (id) => {
    return {
        type: DELETE_CBAR_LOG,
        payload: id
    }
};
export const deleteCBARLogSuccess = (response) => {
    return {
        type: DELETE_CBAR_LOG_SUCCESS,
        payload: response
    }
};
export const deleteCBARLogFail = (error) => {
    return {
        type: DELETE_CBAR_LOG_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateCBARLog = () => {
    return {
        type: RESET_CBAR_LOG_CREATE
    }
};

export const resetCBARLogDetails = () => {
    return {
        type: RESET_CBAR_LOG_DETAILS
    }
};

export const resetCBARLogDeleteData = () => {
    return {
        type: RESET_CBAR_LOG_DELETE
    }
};