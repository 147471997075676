import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_HISTORYS, GET_HISTORYS_DETAILS,  HISTORY_CREATE, HISTORY_DELETE,
   HISTORY_UPDATE,RESET} from "./actionTypes";

import {
  getHistorysSuccess,
  getHistorysFail,
  getHistorysDetailsSuccess,
  getHistorysDetailsFail,
  createHistorySuccess,
  createHistoryFail,
  updateHistorySuccess,
  updateHistoryFail,
  deleteHistorySuccess,
  deleteHistoryFail,
  reset
} from "./actions";

import {  createHistory,deleteHistory,
	getHistoryDetails,getHistorys
	,updateHistory,
 } from "../../_helper/services/prhistory";
function* onGetHistorys({payload: {page,pageSize}}) {
  try {
    const response = yield call(getHistorys,{page,pageSize});
    yield put(getHistorysSuccess(response));
  } catch (error) {
    yield put(getHistorysFail(error.response));
  }
}

function* onGetHistoryDetails({ payload: id }) {
  try {
    const response = yield call(getHistoryDetails, id);
    yield put(getHistorysDetailsSuccess(response));
  } catch (error) {
    yield put(getHistorysDetailsFail(error.response));
  }
}

function* onCreateHistory({payload}){
  try{
    const response=yield call(createHistory, payload);
    yield put(createHistorySuccess(response));
    ShowSucess("History Created Successfully",{
      position:"top-right"
    })
  }
    catch (error){
      yield put(createHistoryFail(error.response));
      ShowError(error?.response?.data?.message);
    } finally {
          yield put(reset()); // Dispatch RESET action after handling createHistory
        }
  }

  function* onUpdateHistory({ payload: { id},payload }) {
	try {
	  const response = yield call(updateHistory, id, payload);
	  yield put(updateHistorySuccess(response));
    ShowSucess('History Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateHistoryFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteHistory({ payload: id }) {
	try {
	  const response = yield call(deleteHistory, id);
	  yield put(deleteHistorySuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteHistoryFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_HISTORYS, onGetHistorys);
  yield takeLatest(GET_HISTORYS_DETAILS, onGetHistoryDetails);
  yield takeLatest(HISTORY_CREATE, onCreateHistory);
  yield takeLatest(HISTORY_UPDATE, onUpdateHistory);
  yield takeLatest(HISTORY_DELETE, onDeleteHistory);
  yield takeLatest(RESET,reset)
}

export default CartSaga;



