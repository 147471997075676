import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { VENDOR_LOG_CREATE, RESET, RESET_VENDOR_LOG_CREATE, GET_VENDOR_LOG_DETAILS, UPDATE_VENDOR_LOG, DELETE_VENDOR_LOG } from "./actionsTypes";

import {
    createVendorLogSuccess,
    createVendorLogFail,
    resetCreateVendorLog,
    reset,
    getVendorLogDetailsSuccess,
    getVendorLogDetailsFail,
    updateVendorLogSuccess,
    updateVendorLogFail,
    deleteVendorLogSuccess,
    deleteVendorLogFail,
} from "./action";

import {
    createVendorLog, deleteVendorLog, getVendorLogDetails, updateVendorLog
} from "../../../_helper/services/DrugTesting/vendorLog";


function* onCreateVendorLog({ payload }) {
    try {
        const response = yield call(createVendorLog, payload);
        yield put(createVendorLogSuccess(response));
        ShowSucess("Vendor Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createVendorLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateVendorLog());
    }
}

function* onGetVendorLogDetails({ payload: id }) {
    try {
        const response = yield call(getVendorLogDetails, id);
        yield put(getVendorLogDetailsSuccess(response));
    } catch (error) {
        yield put(getVendorLogDetailsFail(error.response));
    }
}
function* onUpdateVendorLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateVendorLog, id, payload);
        yield put(updateVendorLogSuccess(response));
        yield put(getVendorLogDetailsSuccess(payload));
        ShowSucess(`Vendor Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateVendorLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteVendorLog({ payload: id }) {
    try {
        const response = yield call(deleteVendorLog, id);
        yield put(deleteVendorLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteVendorLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* VendorLogSaga() {
    yield takeLatest(VENDOR_LOG_CREATE, onCreateVendorLog);
    yield takeLatest(RESET_VENDOR_LOG_CREATE, resetCreateVendorLog);
    yield takeLatest(GET_VENDOR_LOG_DETAILS, onGetVendorLogDetails);
    yield takeLatest(UPDATE_VENDOR_LOG, onUpdateVendorLog);
    yield takeLatest(DELETE_VENDOR_LOG, onDeleteVendorLog);
    yield takeLatest(RESET, reset);
}

export default VendorLogSaga;