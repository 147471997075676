import {
	GET_CERTIFICATIONS, GET_CERTIFICATIONS_SUCCESS, GET_CERTIFICATIONS_FAIL,
	GET_CERTIFICATIONS_DETAILS, GET_CERTIFICATIONS_DETAILS_SUCCESS, GET_CERTIFICATIONS_DETAILS_FAIL,
	CERTIFICATION_CREATE,CERTIFICATION_CREATE_FAIL,CERTIFICATION_CREATE_SUCCESS,
	CERTIFICATION_DELETE,CERTIFICATION_DELETE_FAIL,CERTIFICATION_DELETE_SUCCESS,
	CERTIFICATION_UPDATE,CERTIFICATION_UPDATE_FAIL,CERTIFICATION_UPDATE_SUCCESS,RESET,GET_ALL_CERTIFICATIONS_DETAILS,GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS,GET_ALL_CERTIFICATIONS_DETAILS_FAIL, GET_CERTIFICATIONS_SETTING_FAIL, GET_CERTIFICATIONS_SETTING_SUCCESS, SAVE_CERTIFICATIONS_SETTING_FAIL, SAVE_CERTIFICATIONS_SETTING_SUCCESS, SAVE_CERTIFICATIONS_SETTING, GET_CERTIFICATIONS_SETTING, CERTIFICATION_COPY, CERTIFICATION_COPY_SUCCESS, CERTIFICATION_COPY_FAIL, CERTIFICATION_STATUS_CREATE, CERTIFICATION_STATUS_CREATE_SUCCESS, CERTIFICATION_STATUS_CREATE_FAIL, CERTIFICATION_STATUS_UPDATE, CERTIFICATION_STATUS_UPDATE_SUCCESS, CERTIFICATION_STATUS_UPDATE_FAIL, CERTIFICATION_STATUS_DELETE, CERTIFICATION_STATUS_DELETE_SUCCESS, CERTIFICATION_STATUS_DELETE_FAIL
  } from "./actionTypes";



  export const getAllCertifications = () => {
	return {
	  type: GET_ALL_CERTIFICATIONS_DETAILS,
	  
	};
  };
  
  export const getAllCertificationsSuccess = (response) => {
	return {
	  type: GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS,
	  payload: response
	};
  };
  
  export const getAllCertificationsFail = (error) => {
	return {
	  type: GET_ALL_CERTIFICATIONS_DETAILS_FAIL,
	  payload: error
	};
  };

  export const getCertifications = (payload) => {
	return {
	  type: GET_CERTIFICATIONS,
	  payload: payload
	};
  };
  
  export const getCertificationsSuccess = (posts) => {
	return {
	  type: GET_CERTIFICATIONS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getCertificationsFail = (error) => {
	return {
	  type: GET_CERTIFICATIONS_FAIL,
	  payload: error,
	};
  };
  
  export const getCertificationsDetails = (code) => {
	return {
	  type: GET_CERTIFICATIONS_DETAILS,
	  payload: code,
	};
  };
  
  export const getCertificationsDetailsSuccess = (post) => {
	return {
	  type: GET_CERTIFICATIONS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getCertificationsDetailsFail = (error) => {
	return {
	  type: GET_CERTIFICATIONS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createCertification =(payload)=>{
	return {
		type: CERTIFICATION_CREATE,
		payload: payload
	}
  }
  export const createCertificationSuccess =(response)=>{
	return {
		type: CERTIFICATION_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createCertificationFail =(error)=>{
	return {
		type: CERTIFICATION_CREATE_FAIL,
		payload: error
	}
  }


  export const updateCertification =(payload)=>{
	
	return {
		type: CERTIFICATION_UPDATE,
		payload: payload
	}
  }
  export const updateCertificationSuccess =(response)=>{
	return {
		type: CERTIFICATION_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateCertificationFail =(error)=>{
	return {
		type: CERTIFICATION_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteCertification =(payload)=>{
	return {
		type: CERTIFICATION_DELETE,
		payload: payload
	}
  }
//   export const deleteCertificationSuccess =(response)=>{
// 	return {
// 		type: CERTIFICATION_DELETE_SUCCESS,
// 		payload: response
// 	}
//   }
export const deleteCertificationSuccess = (code) => {
	return {
	  type: CERTIFICATION_DELETE_SUCCESS,
	  payload: code,
	};
  };
  
  export const deleteCertificationFail =(error)=>{
	return {
		type: CERTIFICATION_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

