import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_TAGMASTERS, GET_TAGMASTERS_DETAILS,  TAGMASTER_CREATE, TAGMASTER_DELETE,
   TAGMASTER_UPDATE} from "./actionTypes";

import {
  getTagmastersSuccess,
  getTagmastersFail,
  getTagmastersDetailsSuccess,
  getTagmastersDetailsFail,
  createTagmasterSuccess,
  createTagmasterFail,
  updateTagmasterSuccess,
  updateTagmasterFail,
  deleteTagmasterSuccess,
  deleteTagmasterFail,
} from "./actions";

import {  createTagmaster,deleteTagmaster,
	getTagmasterDetails,getTagmasters
	,updateTagmaster,
 } from "../../_helper/services/tagmaster";
function* onGetTagmasters({payload: {page,pageSize}}) {
  try {
    const response = yield call(getTagmasters,{page,pageSize});
    yield put(getTagmastersSuccess(response));
  } catch (error) {
    yield put(getTagmastersFail(error.response));
  }
}

function* onGetTagmasterDetails({ payload: code }) {
  try {
    const response = yield call(getTagmasterDetails, code);
    yield put(getTagmastersDetailsSuccess(response));
  } catch (error) {
    yield put(getTagmastersDetailsFail(error.response));
  }
}
function* onCreateTagmaster({ payload }) {
	try {
	  const response = yield call(createTagmaster, payload);
	  yield put(createTagmasterSuccess(response));
    ShowSucess("Tagmaster Created Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(createTagmasterFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdateTagmaster({ payload: { code},payload }) {
	try {
	  const response = yield call(updateTagmaster, code, payload);
	  yield put(updateTagmasterSuccess(response));
    ShowSucess("Tagmaster Details Updated Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(updateTagmasterFail(error.response));
    ShowError(error?.response?.data?.message);
    
	}
  }
  function* onDeleteTagmaster({ payload: code }) {
	try {
	  const response = yield call(deleteTagmaster, code);
	  yield put(deleteTagmasterSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteTagmasterFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_TAGMASTERS, onGetTagmasters);
  yield takeLatest(GET_TAGMASTERS_DETAILS, onGetTagmasterDetails);
  yield takeLatest(TAGMASTER_CREATE, onCreateTagmaster);
  yield takeLatest(TAGMASTER_UPDATE, onUpdateTagmaster);
  yield takeLatest(TAGMASTER_DELETE, onDeleteTagmaster);
}

export default CartSaga;