import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { LTD_CREATE, DELETE_LTD, GET_LTD_DETAILS, RESET, RESET_LTD_CREATE, UPDATE_LTD } from "./actionsTypes";

import {
    createLTDSuccess,
    createLTDFail,
    reset,
    resetCreateLTD,
    getLTDDetailsSuccess,
    getLTDDetailsFail,
    updateLTDSuccess,
    deleteLTDSuccess,
    deleteLTDFail,
    updateLTDFail,
} from "./action";

import {
    createLTD, deleteLTD, getLTDDetails, updateLTD
} from "../../../_helper/services/EMR/LTD";


function* onCreateLTD({ payload }) {
    try {
        const response = yield call(createLTD, payload);
        yield put(createLTDSuccess(response));
        ShowSucess("LTD Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createLTDFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateLTD());
    }
}

function* onGetLTDDetails({ payload: id }) {
    try {
        const response = yield call(getLTDDetails, id);
        yield put(getLTDDetailsSuccess(response));
    } catch (error) {
        yield put(getLTDDetailsFail(error.response));
    }
}
function* onUpdateLTD({ payload: { id }, payload }) {
    try {
        const response = yield call(updateLTD, id, payload);
        yield put(updateLTDSuccess(response));
        yield put(getLTDDetailsSuccess(payload));
        ShowSucess(`LTD Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateLTDFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteLTD({ payload: id }) {
    try {
        const response = yield call(deleteLTD, id);
        yield put(deleteLTDSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteLTDFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* LTDSaga() {
    yield takeLatest(LTD_CREATE, onCreateLTD);
    yield takeLatest(GET_LTD_DETAILS, onGetLTDDetails);
    yield takeLatest(UPDATE_LTD, onUpdateLTD);
    yield takeLatest(DELETE_LTD, onDeleteLTD);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_LTD_CREATE, resetCreateLTD);
}

export default LTDSaga;