import {
	GET_DEDUCTIONTYPES, GET_DEDUCTIONTYPES_SUCCESS, GET_DEDUCTIONTYPES_FAIL,
	GET_DEDUCTIONTYPES_DETAILS, GET_DEDUCTIONTYPES_DETAILS_SUCCESS, GET_DEDUCTIONTYPES_DETAILS_FAIL,
	DEDUCTIONTYPE_CREATE, DEDUCTIONTYPE_CREATE_FAIL, DEDUCTIONTYPE_CREATE_SUCCESS,
	DEDUCTIONTYPE_DELETE, DEDUCTIONTYPE_DELETE_FAIL, DEDUCTIONTYPE_DELETE_SUCCESS,
	DEDUCTIONTYPE_UPDATE, DEDUCTIONTYPE_UPDATE_FAIL, DEDUCTIONTYPE_UPDATE_SUCCESS,RESET,
	GET_ALL_DEDUCTIONTYPE_DETAILS,
	GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS,
	GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL
} from "./actionTypes";

const initialState = {
	deductiontypeList: [],
	deductiontype: {},
	postingResult: {},
	loading: false,
	loadingDeductiontypeDetails: false,
	deductiontypeSetting:{},
	loadingdeductiontypeSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const DeductiontypeReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_DEDUCTIONTYPES:
			state = { ...state, loading: false };
			break;
		case GET_DEDUCTIONTYPES_SUCCESS:
			state = { ...state, deductiontypeList: action.payload, loading: false };
			break;
		case GET_DEDUCTIONTYPES_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_DEDUCTIONTYPES_DETAILS:
			state = { ...state, loadingDeductiontypeDetails: true };
			break;
		case GET_DEDUCTIONTYPES_DETAILS_SUCCESS:
			state = { ...state, deductiontype: action.payload, loadingDeductiontypeDetails: false };
			break;
		case GET_DEDUCTIONTYPES_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingDeductiontypeDetails: false,
			};
			break;
		case DEDUCTIONTYPE_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONTYPE_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			
			break;
		case DEDUCTIONTYPE_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case DEDUCTIONTYPE_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONTYPE_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case DEDUCTIONTYPE_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case DEDUCTIONTYPE_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONTYPE_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case DEDUCTIONTYPE_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:
		state = { ...state, deductiontype: {} };
		break;
	  case GET_ALL_DEDUCTIONTYPE_DETAILS:
		state = { ...state, loading: false };
		break;
	  case GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS:
		state = { ...state, deductiontypeList: action.payload, loading: false };
		break;
	  case GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loading: false,
		};
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default DeductiontypeReducer;