import {
    MEDIA_SOURCE_CREATE, MEDIA_SOURCE_CREATE_FAIL, MEDIA_SOURCE_CREATE_SUCCESS,
    DELETE_MEDIA_SOURCE,
    DELETE_MEDIA_SOURCE_FAIL,
    DELETE_MEDIA_SOURCE_SUCCESS,
    GET_MEDIA_SOURCE_DETAILS,
    GET_MEDIA_SOURCE_DETAILS_FAIL,
    GET_MEDIA_SOURCE_DETAILS_SUCCESS,
    RESET,
    RESET_MEDIA_SOURCE_CREATE,
    RESET_MEDIA_SOURCE_DELETE,
    RESET_MEDIA_SOURCE_DETAILS,
    UPDATE_MEDIA_SOURCE,
    UPDATE_MEDIA_SOURCE_FAIL,
    UPDATE_MEDIA_SOURCE_SUCCESS,
    GET_MEDIA_SOURCES,
    GET_MEDIA_SOURCES_SUCCESS,
    GET_MEDIA_SOURCES_FAIL,
} from "./actionsTypes";

//Create Actions
export const createMediaSource = (payload) => {
    return {
        type: MEDIA_SOURCE_CREATE,
        payload: payload
    }
};
export const createMediaSourceSuccess = (response) => {
    return {
        type: MEDIA_SOURCE_CREATE_SUCCESS,
        payload: response
    }
};
export const createMediaSourceFail = (error) => {
    return {
        type: MEDIA_SOURCE_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getMediaSourceDetails = (id) => {
    return {
        type: GET_MEDIA_SOURCE_DETAILS,
        payload: id,
    };
};
export const getMediaSourceDetailsSuccess = (data) => {
    return {
        type: GET_MEDIA_SOURCE_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getMediaSourceDetailsFail = (error) => {
    return {
        type: GET_MEDIA_SOURCE_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getMediaSources = () => {
    return {
        type: GET_MEDIA_SOURCES,
    };
};
export const getMediaSourcesSuccess = (data) => {
    return {
        type: GET_MEDIA_SOURCES_SUCCESS,
        payload: data,
    };
};
export const getMediaSourcesFail = (error) => {
    return {
        type: GET_MEDIA_SOURCES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateMediaSource = (payload) => {

    return {
        type: UPDATE_MEDIA_SOURCE,
        payload: payload
    }
};
export const updateMediaSourceSuccess = (response) => {
    return {
        type: UPDATE_MEDIA_SOURCE_SUCCESS,
        payload: response
    }
};
export const updateMediaSourceFail = (error) => {
    return {
        type: UPDATE_MEDIA_SOURCE_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteMediaSource = (id) => {
    return {
        type: DELETE_MEDIA_SOURCE,
        payload: id
    }
};
export const deleteMediaSourceSuccess = (response) => {
    return {
        type: DELETE_MEDIA_SOURCE_SUCCESS,
        payload: response
    }
};
export const deleteMediaSourceFail = (error) => {
    return {
        type: DELETE_MEDIA_SOURCE_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateMediaSource = () => {
    return {
        type: RESET_MEDIA_SOURCE_CREATE
    }
};

export const resetMediaSourceDetails = () => {
    return {
        type: RESET_MEDIA_SOURCE_DETAILS
    }
};

export const resetMediaSourceDeleteData = () => {
    return {
        type: RESET_MEDIA_SOURCE_DELETE
    }
};