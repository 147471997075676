export const RWHR_CREATE = "RWHR_CREATE";
export const RWHR_CREATE_SUCCESS = "RWHR_CREATE_SUCCESS";
export const RWHR_CREATE_FAIL = "RWHR_CREATE_FAIL";

export const GET_RWHR_DETAILS = "GET_RWHR_DETAILS";
export const GET_RWHR_DETAILS_SUCCESS = "GET_RWHR_DETAILS_SUCCESS";
export const GET_RWHR_DETAILS_FAIL = "GET_RWHR_DETAILS_FAIL";

export const UPDATE_RWHR = "UPDATE_RWHR";
export const UPDATE_RWHR_SUCCESS = "UPDATE_RWHR_SUCCESS";
export const UPDATE_RWHR_FAIL = "UPDATE_RWHR_FAIL";

export const DELETE_RWHR = "DELETE_RWHR";
export const DELETE_RWHR_SUCCESS = "DELETE_RWHR_SUCCESS";
export const DELETE_RWHR_FAIL = "DELETE_RWHR_FAIL";

export const RESET = "RESET";
export const RESET_RWHR_CREATE = "RESET_RWHR_CREATE";
export const RESET_RWHR_DETAILS = "RESET_RWHR_DETAILS";
export const RESET_RWHR_DELETE = "RESET_RWHR_DELETE";