import {
	GET_DEDUCTIONTYPES, GET_DEDUCTIONTYPES_SUCCESS, GET_DEDUCTIONTYPES_FAIL,
	GET_DEDUCTIONTYPES_DETAILS, GET_DEDUCTIONTYPES_DETAILS_SUCCESS, GET_DEDUCTIONTYPES_DETAILS_FAIL,
	DEDUCTIONTYPE_CREATE,DEDUCTIONTYPE_CREATE_FAIL,DEDUCTIONTYPE_CREATE_SUCCESS,
	DEDUCTIONTYPE_DELETE,DEDUCTIONTYPE_DELETE_FAIL,DEDUCTIONTYPE_DELETE_SUCCESS,
	DEDUCTIONTYPE_UPDATE,DEDUCTIONTYPE_UPDATE_FAIL,DEDUCTIONTYPE_UPDATE_SUCCESS,RESET,
	GET_ALL_DEDUCTIONTYPE_DETAILS,
	GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS,
	GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL, GET_DEDUCTIONTYPES_SETTING_FAIL, GET_DEDUCTIONTYPES_SETTING_SUCCESS, SAVE_DEDUCTIONTYPES_SETTING_FAIL, SAVE_DEDUCTIONTYPES_SETTING_SUCCESS, SAVE_DEDUCTIONTYPES_SETTING, GET_DEDUCTIONTYPES_SETTING, DEDUCTIONTYPE_COPY, DEDUCTIONTYPE_COPY_SUCCESS, DEDUCTIONTYPE_COPY_FAIL, DEDUCTIONTYPE_STATUS_CREATE, DEDUCTIONTYPE_STATUS_CREATE_SUCCESS, DEDUCTIONTYPE_STATUS_CREATE_FAIL, DEDUCTIONTYPE_STATUS_UPDATE, DEDUCTIONTYPE_STATUS_UPDATE_SUCCESS, DEDUCTIONTYPE_STATUS_UPDATE_FAIL, DEDUCTIONTYPE_STATUS_DELETE, DEDUCTIONTYPE_STATUS_DELETE_SUCCESS, DEDUCTIONTYPE_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getDeductiontypes = (payload) => {
	return {
	  type: GET_DEDUCTIONTYPES,
	  payload: payload
	};
  };
  
  export const getDeductiontypesSuccess = (posts) => {
	return {
	  type: GET_DEDUCTIONTYPES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getDeductiontypesFail = (error) => {
	return {
	  type: GET_DEDUCTIONTYPES_FAIL,
	  payload: error,
	};
  };
  
  export const getDeductiontypesDetails = (id) => {
	return {
	  type: GET_DEDUCTIONTYPES_DETAILS,
	  payload: id,
	};
  };
  
  export const getDeductiontypesDetailsSuccess = (post) => {
	return {
	  type: GET_DEDUCTIONTYPES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getDeductiontypesDetailsFail = (error) => {
	return {
	  type: GET_DEDUCTIONTYPES_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createDeductiontype =(payload)=>{
	return {
		type: DEDUCTIONTYPE_CREATE,
		payload: payload
	}
  }
  export const createDeductiontypeSuccess =(response)=>{
	return {
		type: DEDUCTIONTYPE_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createDeductiontypeFail =(error)=>{
	return {
		type: DEDUCTIONTYPE_CREATE_FAIL,
		payload: error
	}
  }


  export const updateDeductiontype =(payload)=>{
	
	return {
		type: DEDUCTIONTYPE_UPDATE,
		payload: payload
	}
  }
  export const updateDeductiontypeSuccess =(response)=>{
	return {
		type: DEDUCTIONTYPE_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateDeductiontypeFail =(error)=>{
	return {
		type: DEDUCTIONTYPE_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteDeductiontype =(payload)=>{
	return {
		type: DEDUCTIONTYPE_DELETE,
		payload: payload
	}
  }
  export const deleteDeductiontypeSuccess =(response)=>{
	return {
		type: DEDUCTIONTYPE_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteDeductiontypeFail =(error)=>{
	return {
		type: DEDUCTIONTYPE_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

  export const getAllDeductionTypeDetails = () => {
	return {
	  type: GET_ALL_DEDUCTIONTYPE_DETAILS
	};
  };
  
  export const getAllDeductionTypeDetailsSuccess = (details) => {
	return {
	  type: GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS,
	  payload: details
	};
  };
  
  export const getAllDeductionTypeDetailsFail = (error) => {
	return {
	  type: GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL,
	  payload: error
	};
  };

