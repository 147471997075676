import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_DEDUCTIONPLANS, GET_DEDUCTIONPLANS_DETAILS,  DEDUCTIONPLAN_CREATE, DEDUCTIONPLAN_DELETE,
   DEDUCTIONPLAN_UPDATE} from "./actionTypes";

import {
  getDeductionplansSuccess,
  getDeductionplansFail,
  getDeductionplansDetailsSuccess,
  getDeductionplansDetailsFail,
  createDeductionplanSuccess,
  createDeductionplanFail,
  updateDeductionplanSuccess,
  updateDeductionplanFail,
  deleteDeductionplanSuccess,
  deleteDeductionplanFail,
} from "./actions";

import {  createDeductionplan,deleteDeductionplan,
	getDeductionplanDetails,getDeductionplans
	,updateDeductionplan,
 } from "../../_helper/services/deductionplan";
function* onGetDeductionplans({payload: {page,pageSize}}) {
  try {
    const response = yield call(getDeductionplans,{page,pageSize});
    yield put(getDeductionplansSuccess(response));
  } catch (error) {
    yield put(getDeductionplansFail(error.response));
  }
}

function* onGetDeductionplanDetails({ payload: code }) {
  try {
    const response = yield call(getDeductionplanDetails, code);
    yield put(getDeductionplansDetailsSuccess(response));
  } catch (error) {
    yield put(getDeductionplansDetailsFail(error.response));
  }
}
function* onCreateDeductionplan({ payload }) {
	try {
	  const response = yield call(createDeductionplan, payload);
	  yield put(createDeductionplanSuccess(response));
    ShowSucess('Deductionplan Created successfully', {
      position: 'top-right'
    });
    
	} catch (error) {
	  yield put(createDeductionplanFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdateDeductionplan({ payload: { code},payload }) {
	try {
	  const response = yield call(updateDeductionplan, code, payload);
	  yield put(updateDeductionplanSuccess(response));
    ShowSucess('Deductionplan Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateDeductionplanFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteDeductionplan({ payload: code }) {
	try {
	  const response = yield call(deleteDeductionplan, code);
	  yield put(deleteDeductionplanSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteDeductionplanFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_DEDUCTIONPLANS, onGetDeductionplans);
  yield takeLatest(GET_DEDUCTIONPLANS_DETAILS, onGetDeductionplanDetails);
  yield takeLatest(DEDUCTIONPLAN_CREATE, onCreateDeductionplan);
  yield takeLatest(DEDUCTIONPLAN_UPDATE, onUpdateDeductionplan);
  yield takeLatest(DEDUCTIONPLAN_DELETE, onDeleteDeductionplan);
}

export default CartSaga;