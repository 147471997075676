// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getCertificationDetails =(code)=> get(`${baseURL+url.CERTIFICATION_BASE}/${code}`);
export const getCertifications =({page,pageSize})=> get(baseURL+url.CERTIFICATION_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);

export const updateCertification = (code, payload) => put(`${baseURL}${url.CERTIFICATION_BASE}/${code}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createCertification =(payload) => post(baseURL+url.CERTIFICATION_BASE,payload);

export const deleteCertification = (code) => remove(`${baseURL}${url.CERTIFICATION_BASE}/${code}`);

export const getAllCertifications = () => get(`${baseURL}${url.CERTIFICATION_BASE}`);