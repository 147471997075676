export const GET_CERTIFICATIONS = "GET_CERTIFICATIONS";
export const GET_CERTIFICATIONS_SUCCESS = "GET_CERTIFICATIONS_SUCCESS";
export const GET_CERTIFICATIONS_FAIL = "GET_CERTIFICATIONS_FAIL";

export const GET_CERTIFICATIONS_DETAILS = "GET_CERTIFICATIONS_DETAILS";
export const GET_CERTIFICATIONS_DETAILS_SUCCESS = "GET_CERTIFICATIONS_DETAILS_SUCCESS";
export const GET_CERTIFICATIONS_DETAILS_FAIL = "GET_CERTIFICATIONS_DETAILS_FAIL";

// All Certification list
export const GET_ALL_CERTIFICATIONS_DETAILS = "GET_ALL_CERTIFICATIONS_DETAILS";
export const GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS = "GET_ALL_CERTIFICATIONS_DETAILS_SUCCESS";
export const GET_ALL_CERTIFICATIONS_DETAILS_FAIL = "GET_ALL_CERTIFICATIONS_DETAILS_FAIL";

export const CERTIFICATION_UPDATE= "CERTIFICATION_UPDATE";
export const CERTIFICATION_UPDATE_SUCCESS = "CERTIFICATION_UPDATE_SUCCESS";
export const CERTIFICATION_UPDATE_FAIL = "CERTIFICATION_UPDATE_FAIL";

export const CERTIFICATION_CREATE= "CERTIFICATION_CREATE";
export const CERTIFICATION_CREATE_SUCCESS = "CERTIFICATION_CREATE_SUCCESS";
export const CERTIFICATION_CREATE_FAIL = "CERTIFICATION_CREATE_FAIL";

export const CERTIFICATION_DELETE= "CERTIFICATION_DELETE";
export const CERTIFICATION_DELETE_SUCCESS = "CERTIFICATION_DELETE_SUCCESS";
export const CERTIFICATION_DELETE_FAIL = "CERTIFICATION_DELETE_FAIL";
export const RESET = "RESET";

