import {
    DELETE_DTPM_DATA_LOG,
    DELETE_DTPM_DATA_LOG_FAIL,
    DELETE_DTPM_DATA_LOG_SUCCESS,
    DTPM_DATA_LOG_CREATE, DTPM_DATA_LOG_CREATE_FAIL, DTPM_DATA_LOG_CREATE_SUCCESS,
    GET_DTPM_DATA_LOG_DETAILS,
    GET_DTPM_DATA_LOG_DETAILS_FAIL,
    GET_DTPM_DATA_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_DTPM_DATA_LOG_CREATE,
    RESET_DTPM_DATA_LOG_DETAILS,
    UPDATE_DTPM_DATA_LOG,
    UPDATE_DTPM_DATA_LOG_FAIL,
    UPDATE_DTPM_DATA_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createDTPMDataLog = (payload) => {
    return {
        type: DTPM_DATA_LOG_CREATE,
        payload: payload
    }
};
export const createDTPMDataLogSuccess = (response) => {
    return {
        type: DTPM_DATA_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createDTPMDataLogFail = (error) => {
    return {
        type: DTPM_DATA_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getDTPMDataLogDetails = (id) => {
    return {
        type: GET_DTPM_DATA_LOG_DETAILS,
        payload: id,
    };
};
export const getDTPMDataLogDetailsSuccess = (data) => {
    return {
        type: GET_DTPM_DATA_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getDTPMDataLogDetailsFail = (error) => {
    return {
        type: GET_DTPM_DATA_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateDTPMDataLog = (payload) => {

    return {
        type: UPDATE_DTPM_DATA_LOG,
        payload: payload
    }
};
export const updateDTPMDataLogSuccess = (response) => {
    return {
        type: UPDATE_DTPM_DATA_LOG_SUCCESS,
        payload: response
    }
};
export const updateDTPMDataLogFail = (error) => {
    return {
        type: UPDATE_DTPM_DATA_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteDTPMDataLog = (id) => {
    return {
        type: DELETE_DTPM_DATA_LOG,
        payload: id
    }
};
export const deleteDTPMDataLogSuccess = (response) => {
    return {
        type: DELETE_DTPM_DATA_LOG_SUCCESS,
        payload: response
    }
};
export const deleteDTPMDataLogFail = (error) => {
    return {
        type: DELETE_DTPM_DATA_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateDTPMDataLog = () => {
    return {
        type: RESET_DTPM_DATA_LOG_CREATE
    }
};
export const resetDTPMDataLogDetails = () => {
    return {
        type: RESET_DTPM_DATA_LOG_DETAILS,
    };
};