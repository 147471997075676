import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { CBAR_LOG_CREATE, DELETE_CBAR_LOG, GET_CBAR_LOG_DETAILS, RESET, RESET_CBAR_LOG_CREATE, UPDATE_CBAR_LOG } from "./actionsTypes";

import {
    createCBARLogSuccess,
    createCBARLogFail,
    reset,
    resetCreateCBARLog,
    getCBARLogDetailsSuccess,
    getCBARLogDetailsFail,
    updateCBARLogSuccess,
    deleteCBARLogSuccess,
    deleteCBARLogFail,
    updateCBARLogFail,
} from "./action";

import {
    createCBARLog, deleteCBARLog, getCBARLogDetails, updateCBARLog
} from "../../../_helper/services/EMR/CBARLog";


function* onCreateCBARLog({ payload }) {
    try {
        const response = yield call(createCBARLog, payload);
        yield put(createCBARLogSuccess(response));
        ShowSucess("CBAR Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createCBARLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateCBARLog());
    }
}

function* onGetCBARLogDetails({ payload: id }) {
    try {
        const response = yield call(getCBARLogDetails, id);
        yield put(getCBARLogDetailsSuccess(response));
    } catch (error) {
        yield put(getCBARLogDetailsFail(error.response));
    }
}
function* onUpdateCBARLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateCBARLog, id, payload);
        yield put(updateCBARLogSuccess(response));
        yield put(getCBARLogDetailsSuccess(payload));
        ShowSucess(`CBAR Log Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateCBARLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteCBARLog({ payload: id }) {
    try {
        const response = yield call(deleteCBARLog, id);
        yield put(deleteCBARLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteCBARLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* CBARLogSaga() {
    yield takeLatest(CBAR_LOG_CREATE, onCreateCBARLog);
    yield takeLatest(GET_CBAR_LOG_DETAILS, onGetCBARLogDetails);
    yield takeLatest(UPDATE_CBAR_LOG, onUpdateCBARLog);
    yield takeLatest(DELETE_CBAR_LOG, onDeleteCBARLog);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_CBAR_LOG_CREATE, resetCreateCBARLog);
}

export default CBARLogSaga;