import {
    ADVERSE_ACTION_CREATE, ADVERSE_ACTION_CREATE_FAIL, ADVERSE_ACTION_CREATE_SUCCESS,
    DELETE_ADVERSE_ACTION,
    DELETE_ADVERSE_ACTION_FAIL,
    DELETE_ADVERSE_ACTION_SUCCESS,
    GET_ADVERSE_ACTION_DETAILS,
    GET_ADVERSE_ACTION_DETAILS_FAIL,
    GET_ADVERSE_ACTION_DETAILS_SUCCESS,
    RESET,
    RESET_ADVERSE_ACTION_CREATE,
    RESET_ADVERSE_ACTION_DELETE,
    RESET_ADVERSE_ACTION_DETAILS,
    UPDATE_ADVERSE_ACTION,
    UPDATE_ADVERSE_ACTION_FAIL,
    UPDATE_ADVERSE_ACTION_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createAdverseAction = (payload) => {
    return {
        type: ADVERSE_ACTION_CREATE,
        payload: payload
    }
};
export const createAdverseActionSuccess = (response) => {
    return {
        type: ADVERSE_ACTION_CREATE_SUCCESS,
        payload: response
    }
};
export const createAdverseActionFail = (error) => {
    return {
        type: ADVERSE_ACTION_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getAdverseActionDetails = (id) => {
    return {
        type: GET_ADVERSE_ACTION_DETAILS,
        payload: id,
    };
};
export const getAdverseActionDetailsSuccess = (data) => {
    return {
        type: GET_ADVERSE_ACTION_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getAdverseActionDetailsFail = (error) => {
    return {
        type: GET_ADVERSE_ACTION_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateAdverseAction = (payload) => {

    return {
        type: UPDATE_ADVERSE_ACTION,
        payload: payload
    }
};
export const updateAdverseActionSuccess = (response) => {
    return {
        type: UPDATE_ADVERSE_ACTION_SUCCESS,
        payload: response
    }
};
export const updateAdverseActionFail = (error) => {
    return {
        type: UPDATE_ADVERSE_ACTION_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteAdverseAction = (id) => {
    return {
        type: DELETE_ADVERSE_ACTION,
        payload: id
    }
};
export const deleteAdverseActionSuccess = (response) => {
    return {
        type: DELETE_ADVERSE_ACTION_SUCCESS,
        payload: response
    }
};
export const deleteAdverseActionFail = (error) => {
    return {
        type: DELETE_ADVERSE_ACTION_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateAdverseAction = () => {
    return {
        type: RESET_ADVERSE_ACTION_CREATE
    }
};

export const resetAdverseActionDetails = () => {
    return {
        type: RESET_ADVERSE_ACTION_DETAILS
    }
};

export const resetAdverseActionDeleteData = () => {
    return {
        type: RESET_ADVERSE_ACTION_DELETE
    }
};