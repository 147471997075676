import {
    CARMENS_OWN_DT_LOG_CREATE, CARMENS_OWN_DT_LOG_CREATE_FAIL, CARMENS_OWN_DT_LOG_CREATE_SUCCESS,
    DELETE_CARMENS_OWN_DT_LOG,
    DELETE_CARMENS_OWN_DT_LOG_FAIL,
    DELETE_CARMENS_OWN_DT_LOG_SUCCESS,
    GET_CARMENS_OWN_DT_LOG_DETAILS,
    GET_CARMENS_OWN_DT_LOG_DETAILS_FAIL,
    GET_CARMENS_OWN_DT_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_CARMENS_OWN_DT_LOG_CREATE,
    RESET_CARMENS_OWN_DT_LOG_DELETE,
    RESET_CARMENS_OWN_DT_LOG_DETAILS,
    UPDATE_CARMENS_OWN_DT_LOG,
    UPDATE_CARMENS_OWN_DT_LOG_FAIL,
    UPDATE_CARMENS_OWN_DT_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createCarmensOwnDTLog = (payload) => {
    return {
        type: CARMENS_OWN_DT_LOG_CREATE,
        payload: payload
    }
};
export const createCarmensOwnDTLogSuccess = (response) => {
    return {
        type: CARMENS_OWN_DT_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createCarmensOwnDTLogFail = (error) => {
    return {
        type: CARMENS_OWN_DT_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getCarmensOwnDTLogDetails = (id) => {
    return {
        type: GET_CARMENS_OWN_DT_LOG_DETAILS,
        payload: id,
    };
};
export const getCarmensOwnDTLogDetailsSuccess = (data) => {
    return {
        type: GET_CARMENS_OWN_DT_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getCarmensOwnDTLogDetailsFail = (error) => {
    return {
        type: GET_CARMENS_OWN_DT_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateCarmensOwnDTLog = (payload) => {

    return {
        type: UPDATE_CARMENS_OWN_DT_LOG,
        payload: payload
    }
};
export const updateCarmensOwnDTLogSuccess = (response) => {
    return {
        type: UPDATE_CARMENS_OWN_DT_LOG_SUCCESS,
        payload: response
    }
};
export const updateCarmensOwnDTLogFail = (error) => {
    return {
        type: UPDATE_CARMENS_OWN_DT_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteCarmensOwnDTLog = (id) => {
    return {
        type: DELETE_CARMENS_OWN_DT_LOG,
        payload: id
    }
};
export const deleteCarmensOwnDTLogSuccess = (response) => {
    return {
        type: DELETE_CARMENS_OWN_DT_LOG_SUCCESS,
        payload: response
    }
};
export const deleteCarmensOwnDTLogFail = (error) => {
    return {
        type: DELETE_CARMENS_OWN_DT_LOG_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateCarmensOwnDTLog = () => {
    return {
        type: RESET_CARMENS_OWN_DT_LOG_CREATE
    }
};

export const resetCarmensOwnDTLogDetails = () => {
    return {
        type: RESET_CARMENS_OWN_DT_LOG_DETAILS
    }
};

export const resetCarmensOwnDTLogDeleteData = () => {
    return {
        type: RESET_CARMENS_OWN_DT_LOG_DELETE
    }
};