import {
    DELETE_DFWP_CONTACT_LIST,
    DELETE_DFWP_CONTACT_LIST_FAIL,
    DELETE_DFWP_CONTACT_LIST_SUCCESS,
    DFWP_CONTACT_LIST_CREATE, DFWP_CONTACT_LIST_CREATE_FAIL, DFWP_CONTACT_LIST_CREATE_SUCCESS,
    GET_DFWP_CONTACT_LIST_DETAILS,
    GET_DFWP_CONTACT_LIST_DETAILS_FAIL,
    GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS,
    RESET,
    RESET_DFWP_CONTACT_LIST_CREATE,
    RESET_DFWP_CONTACT_LIST_DETAILS,
    UPDATE_DFWP_CONTACT_LIST,
    UPDATE_DFWP_CONTACT_LIST_FAIL,
    UPDATE_DFWP_CONTACT_LIST_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createDFWPContactList = (payload) => {
    return {
        type: DFWP_CONTACT_LIST_CREATE,
        payload: payload
    }
};
export const createDFWPContactListSuccess = (response) => {
    return {
        type: DFWP_CONTACT_LIST_CREATE_SUCCESS,
        payload: response
    }
};
export const createDFWPContactListFail = (error) => {
    return {
        type: DFWP_CONTACT_LIST_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getDFWPContactListDetails = (id) => {
    return {
        type: GET_DFWP_CONTACT_LIST_DETAILS,
        payload: id,
    };
};
export const getDFWPContactListDetailsSuccess = (data) => {
    return {
        type: GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getDFWPContactListDetailsFail = (error) => {
    return {
        type: GET_DFWP_CONTACT_LIST_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateDFWPContactList = (payload) => {

    return {
        type: UPDATE_DFWP_CONTACT_LIST,
        payload: payload
    }
};
export const updateDFWPContactListSuccess = (response) => {
    return {
        type: UPDATE_DFWP_CONTACT_LIST_SUCCESS,
        payload: response
    }
};
export const updateDFWPContactListFail = (error) => {
    return {
        type: UPDATE_DFWP_CONTACT_LIST_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteDFWPContactList = (id) => {
    return {
        type: DELETE_DFWP_CONTACT_LIST,
        payload: id
    }
};
export const deleteDFWPContactListSuccess = (response) => {
    return {
        type: DELETE_DFWP_CONTACT_LIST_SUCCESS,
        payload: response
    }
};
export const deleteDFWPContactListFail = (error) => {
    return {
        type: DELETE_DFWP_CONTACT_LIST_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateDFWPContactList = () => {
    return {
        type: RESET_DFWP_CONTACT_LIST_CREATE
    }
};
export const resetDFWPContactListDetails = () => {
    return {
        type: RESET_DFWP_CONTACT_LIST_DETAILS,
    };
};