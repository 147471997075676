import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_RANDOM_DT_LOG, GET_RANDOM_DT_LOG_DETAILS, RANDOM_DT_LOG_CREATE, RESET, RESET_RANDOM_DT_LOG_CREATE, UPDATE_RANDOM_DT_LOG } from "./actionsTypes";

import {
    createRandomDTLogSuccess,
    createRandomDTLogFail,
    resetCreateRandomDTLog,
    reset,
    getRandomDTLogDetailsSuccess,
    getRandomDTLogDetailsFail,
    updateRandomDTLogSuccess,
    updateRandomDTLogFail,
    deleteRandomDTLogSuccess,
    deleteRandomDTLogFail,
} from "./action";

import {
    createRandomDTLog, deleteRandomDTLog, getRandomDTLogDetails, updateRandomDTLog
} from "../../../_helper/services/DrugTesting/randomDTLog";


function* onCreateRandomDTLog({ payload }) {
    try {
        const response = yield call(createRandomDTLog, payload);
        yield put(createRandomDTLogSuccess(response));
        ShowSucess("Random DT Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createRandomDTLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateRandomDTLog());
    }
}

function* onGetRandomDTLogDetails({ payload: id }) {
    try {
        const response = yield call(getRandomDTLogDetails, id);
        yield put(getRandomDTLogDetailsSuccess(response));
    } catch (error) {
        yield put(getRandomDTLogDetailsFail(error.response));
    }
}
function* onUpdateRandomDTLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateRandomDTLog, id, payload);
        yield put(updateRandomDTLogSuccess(response));
        yield put(getRandomDTLogDetailsSuccess(payload));
        ShowSucess(`Random DT Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateRandomDTLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteRandomDTLog({ payload: id }) {
    try {
        const response = yield call(deleteRandomDTLog, id);
        yield put(deleteRandomDTLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteRandomDTLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* RandomDTLogSaga() {
    yield takeLatest(RANDOM_DT_LOG_CREATE, onCreateRandomDTLog);
    yield takeLatest(RESET_RANDOM_DT_LOG_CREATE, resetCreateRandomDTLog);
    yield takeLatest(GET_RANDOM_DT_LOG_DETAILS, onGetRandomDTLogDetails);
    yield takeLatest(UPDATE_RANDOM_DT_LOG, onUpdateRandomDTLog);
    yield takeLatest(DELETE_RANDOM_DT_LOG, onDeleteRandomDTLog);
    yield takeLatest(RESET, reset);
}

export default RandomDTLogSaga;