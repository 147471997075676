import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { CARMENS_OWN_DT_LOG_CREATE, DELETE_CARMENS_OWN_DT_LOG, GET_CARMENS_OWN_DT_LOG_DETAILS, RESET, RESET_CARMENS_OWN_DT_LOG_CREATE, UPDATE_CARMENS_OWN_DT_LOG } from "./actionsTypes";

import {
    createCarmensOwnDTLogSuccess,
    createCarmensOwnDTLogFail,
    reset,
    resetCreateCarmensOwnDTLog,
    getCarmensOwnDTLogDetailsSuccess,
    getCarmensOwnDTLogDetailsFail,
    updateCarmensOwnDTLogSuccess,
    deleteCarmensOwnDTLogSuccess,
    deleteCarmensOwnDTLogFail,
    updateCarmensOwnDTLogFail,
} from "./action";

import {
    createCarmensOwnDTLog, deleteCarmensOwnDTLog, getCarmensOwnDTLogDetails, updateCarmensOwnDTLog
} from "../../../_helper/services/DrugTesting/carmensOwnDTLog";


function* onCreateCarmensOwnDTLog({ payload }) {
    try {
        const response = yield call(createCarmensOwnDTLog, payload);
        yield put(createCarmensOwnDTLogSuccess(response));
        ShowSucess("Archival DT Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createCarmensOwnDTLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateCarmensOwnDTLog());
    }
}

function* onGetCarmensOwnDTLogDetails({ payload: id }) {
    try {
        const response = yield call(getCarmensOwnDTLogDetails, id);
        yield put(getCarmensOwnDTLogDetailsSuccess(response));
    } catch (error) {
        yield put(getCarmensOwnDTLogDetailsFail(error.response));
    }
}
function* onUpdateCarmensOwnDTLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateCarmensOwnDTLog, id, payload);
        yield put(updateCarmensOwnDTLogSuccess(response));
        yield put(getCarmensOwnDTLogDetailsSuccess(payload));
        ShowSucess(`Archival DT Log Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateCarmensOwnDTLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteCarmensOwnDTLog({ payload: id }) {
    try {
        const response = yield call(deleteCarmensOwnDTLog, id);
        yield put(deleteCarmensOwnDTLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteCarmensOwnDTLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* CarmensOwnDTLogSaga() {
    yield takeLatest(CARMENS_OWN_DT_LOG_CREATE, onCreateCarmensOwnDTLog);
    yield takeLatest(GET_CARMENS_OWN_DT_LOG_DETAILS, onGetCarmensOwnDTLogDetails);
    yield takeLatest(UPDATE_CARMENS_OWN_DT_LOG, onUpdateCarmensOwnDTLog);
    yield takeLatest(DELETE_CARMENS_OWN_DT_LOG, onDeleteCarmensOwnDTLog);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_CARMENS_OWN_DT_LOG_CREATE, resetCreateCarmensOwnDTLog);
}

export default CarmensOwnDTLogSaga;