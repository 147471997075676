import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { GRIEVANCE_REQUEST_CREATE, DELETE_GRIEVANCE_REQUEST, GET_GRIEVANCE_REQUEST_DETAILS, RESET, RESET_GRIEVANCE_REQUEST_CREATE, UPDATE_GRIEVANCE_REQUEST } from "./actionsTypes";

import {
    createGrievanceRequestSuccess,
    createGrievanceRequestFail,
    reset,
    resetCreateGrievanceRequest,
    getGrievanceRequestDetailsSuccess,
    getGrievanceRequestDetailsFail,
    updateGrievanceRequestSuccess,
    deleteGrievanceRequestSuccess,
    deleteGrievanceRequestFail,
    updateGrievanceRequestFail,
} from "./action";

import {
    createGrievanceRequest, deleteGrievanceRequest, getGrievanceRequestDetails, updateGrievanceRequest
} from "../../../_helper/services/EMR/GrievanceRequest";


function* onCreateGrievanceRequest({ payload }) {
    try {
        const response = yield call(createGrievanceRequest, payload);
        yield put(createGrievanceRequestSuccess(response));
        ShowSucess("Grievance Appeal Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createGrievanceRequestFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateGrievanceRequest());
    }
}

function* onGetGrievanceRequestDetails({ payload: id }) {
    try {
        const response = yield call(getGrievanceRequestDetails, id);
        yield put(getGrievanceRequestDetailsSuccess(response));
    } catch (error) {
        yield put(getGrievanceRequestDetailsFail(error.response));
    }
}
function* onUpdateGrievanceRequest({ payload: { id }, payload }) {
    try {
        const response = yield call(updateGrievanceRequest, id, payload);
        yield put(updateGrievanceRequestSuccess(response));
        yield put(getGrievanceRequestDetailsSuccess(payload));
        ShowSucess(`Grievance Appeal Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateGrievanceRequestFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteGrievanceRequest({ payload: id }) {
    try {
        const response = yield call(deleteGrievanceRequest, id);
        yield put(deleteGrievanceRequestSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteGrievanceRequestFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* GrievanceRequestSaga() {
    yield takeLatest(GRIEVANCE_REQUEST_CREATE, onCreateGrievanceRequest);
    yield takeLatest(GET_GRIEVANCE_REQUEST_DETAILS, onGetGrievanceRequestDetails);
    yield takeLatest(UPDATE_GRIEVANCE_REQUEST, onUpdateGrievanceRequest);
    yield takeLatest(DELETE_GRIEVANCE_REQUEST, onDeleteGrievanceRequest);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_GRIEVANCE_REQUEST_CREATE, resetCreateGrievanceRequest);
}

export default GrievanceRequestSaga;