import {
	GET_DEDUCTIONPLANS, GET_DEDUCTIONPLANS_SUCCESS, GET_DEDUCTIONPLANS_FAIL,
	GET_DEDUCTIONPLANS_DETAILS, GET_DEDUCTIONPLANS_DETAILS_SUCCESS, GET_DEDUCTIONPLANS_DETAILS_FAIL,
	DEDUCTIONPLAN_CREATE, DEDUCTIONPLAN_CREATE_FAIL, DEDUCTIONPLAN_CREATE_SUCCESS,
	DEDUCTIONPLAN_DELETE, DEDUCTIONPLAN_DELETE_FAIL, DEDUCTIONPLAN_DELETE_SUCCESS,
	DEDUCTIONPLAN_UPDATE, DEDUCTIONPLAN_UPDATE_FAIL, DEDUCTIONPLAN_UPDATE_SUCCESS,RESET
} from "./actionTypes";

const initialState = {
	deductionplanList: [],
	deductionplan: {},
	postingResult: {},
	loading: false,
	loadingDeductionplanDetails: false,
	deductionplanSetting:{},
	loadingdeductionplanSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const DeductionplanReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_DEDUCTIONPLANS:
			state = { ...state, loading: false };
			break;
		case GET_DEDUCTIONPLANS_SUCCESS:
			state = { ...state, deductionplanList: action.payload, loading: false };
			break;
		case GET_DEDUCTIONPLANS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_DEDUCTIONPLANS_DETAILS:
			state = { ...state, loadingDeductionplanDetails: true };
			break;
		case GET_DEDUCTIONPLANS_DETAILS_SUCCESS:
			state = { ...state, deductionplan: action.payload, loadingDeductionplanDetails: false };
			break;
		case GET_DEDUCTIONPLANS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingDeductionplanDetails: false,
			};
			break;
		case DEDUCTIONPLAN_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONPLAN_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			
			break;
		case DEDUCTIONPLAN_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case DEDUCTIONPLAN_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONPLAN_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case DEDUCTIONPLAN_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case DEDUCTIONPLAN_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case DEDUCTIONPLAN_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case DEDUCTIONPLAN_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:
		state = { ...state, deductionplan: {} };
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default DeductionplanReducer;