import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getBillingInvoiceStatusLists = () => get(`${baseURL + url.BILLING_INVOICE_STATUS_LISTS}`);

export const createBillingInvoice = (payload) => post(baseURL + url.BILLING_INVOICE_LOG, payload);
export const getBillingInvoiceDetails = (id) => get(`${baseURL + url.BILLING_INVOICE_LOG}/${id}`);
export const updateBillingInvoice = (id, payload) => put(`${baseURL + url.BILLING_INVOICE_LOG}/${id}`, payload);
export const deleteBillingInvoice = (id) => remove(`${baseURL + url.BILLING_INVOICE_LOG}/${id}`);