export const GET_ETHICGROUPS = "GET_ETHICGROUPS";
export const GET_ETHICGROUPS_SUCCESS = "GET_ETHICGROUPS_SUCCESS";
export const GET_ETHICGROUPS_FAIL = "GET_ETHICGROUPS_FAIL";

export const GET_ETHICGROUPS_DETAILS = "GET_ETHICGROUPS_DETAILS";
export const GET_ETHICGROUPS_DETAILS_SUCCESS = "GET_ETHICGROUPS_DETAILS_SUCCESS";
export const GET_ETHICGROUPS_DETAILS_FAIL = "GET_ETHICGROUPS_DETAILS_FAIL";

export const ETHICGROUP_UPDATE= "ETHICGROUP_UPDATE";
export const ETHICGROUP_UPDATE_SUCCESS = "ETHICGROUP_UPDATE_SUCCESS";
export const ETHICGROUP_UPDATE_FAIL = "ETHICGROUP_UPDATE_FAIL";

export const ETHICGROUP_CREATE= "ETHICGROUP_CREATE";
export const ETHICGROUP_CREATE_SUCCESS = "ETHICGROUP_CREATE_SUCCESS";
export const ETHICGROUP_CREATE_FAIL = "ETHICGROUP_CREATE_FAIL";

export const ETHICGROUP_DELETE= "ETHICGROUP_DELETE";
export const ETHICGROUP_DELETE_SUCCESS = "ETHICGROUP_DELETE_SUCCESS";
export const ETHICGROUP_DELETE_FAIL = "ETHICGROUP_DELETE_FAIL";
// export const NAVIGATE_TO_EMPLOYEE_TYPE= "NAVIGATE_TO_EMPLOYEE_TYPE"

export const RESET = "RESET";