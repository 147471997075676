import {
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER,
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
    DEPARTMENT_JV_CONTROL_NUMBER_CREATE, DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL, DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS,
    RESET,
    RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE,
    RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createDepartmentJVControlNumber = (payload) => {
    return {
        type: DEPARTMENT_JV_CONTROL_NUMBER_CREATE,
        payload: payload
    }
};
export const createDepartmentJVControlNumberSuccess = (response) => {
    return {
        type: DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS,
        payload: response
    }
};
export const createDepartmentJVControlNumberFail = (error) => {
    return {
        type: DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getDepartmentJVControlNumberDetails = (id) => {
    return {
        type: GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
        payload: id,
    };
};
export const getDepartmentJVControlNumberDetailsSuccess = (data) => {
    return {
        type: GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getDepartmentJVControlNumberDetailsFail = (error) => {
    return {
        type: GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateDepartmentJVControlNumber = (payload) => {

    return {
        type: UPDATE_DEPARTMENT_JV_CONTROL_NUMBER,
        payload: payload
    }
};
export const updateDepartmentJVControlNumberSuccess = (response) => {
    return {
        type: UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
        payload: response
    }
};
export const updateDepartmentJVControlNumberFail = (error) => {
    return {
        type: UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteDepartmentJVControlNumber = (id) => {
    return {
        type: DELETE_DEPARTMENT_JV_CONTROL_NUMBER,
        payload: id
    }
};
export const deleteDepartmentJVControlNumberSuccess = (response) => {
    return {
        type: DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
        payload: response
    }
};
export const deleteDepartmentJVControlNumberFail = (error) => {
    return {
        type: DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateDepartmentJVControlNumber = () => {
    return {
        type: RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE
    }
};
export const resetDepartmentJVControlNumberDetails = () => {
    return {
        type: RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
    };
};