export const STATUS_OF_APPT_CREATE = "STATUS_OF_APPT_CREATE";
export const STATUS_OF_APPT_CREATE_SUCCESS = "STATUS_OF_APPT_CREATE_SUCCESS";
export const STATUS_OF_APPT_CREATE_FAIL = "STATUS_OF_APPT_CREATE_FAIL";

export const GET_STATUS_OF_APPT_DETAILS = "GET_STATUS_OF_APPT_DETAILS";
export const GET_STATUS_OF_APPT_DETAILS_SUCCESS = "GET_STATUS_OF_APPT_DETAILS_SUCCESS";
export const GET_STATUS_OF_APPT_DETAILS_FAIL = "GET_STATUS_OF_APPT_DETAILS_FAIL";

export const GET_STATUS_OF_APPTES = "GET_STATUS_OF_APPTES";
export const GET_STATUS_OF_APPTES_SUCCESS = "GET_STATUS_OF_APPTES_SUCCESS";
export const GET_STATUS_OF_APPTES_FAIL = "GET_STATUS_OF_APPTES_FAIL";

export const UPDATE_STATUS_OF_APPT = "UPDATE_STATUS_OF_APPT";
export const UPDATE_STATUS_OF_APPT_SUCCESS = "UPDATE_STATUS_OF_APPT_SUCCESS";
export const UPDATE_STATUS_OF_APPT_FAIL = "UPDATE_STATUS_OF_APPT_FAIL";

export const DELETE_STATUS_OF_APPT = "DELETE_STATUS_OF_APPT";
export const DELETE_STATUS_OF_APPT_SUCCESS = "DELETE_STATUS_OF_APPT_SUCCESS";
export const DELETE_STATUS_OF_APPT_FAIL = "DELETE_STATUS_OF_APPT_FAIL";

export const RESET = "RESET";
export const RESET_STATUS_OF_APPT_CREATE = "RESET_STATUS_OF_APPT_CREATE";
export const RESET_STATUS_OF_APPT_DETAILS = "RESET_STATUS_OF_APPT_DETAILS";
export const RESET_STATUS_OF_APPT_DELETE = "RESET_STATUS_OF_APPT_DELETE";