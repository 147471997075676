export const PE_RATING_CREATE = "PE_RATING_CREATE";
export const PE_RATING_CREATE_SUCCESS = "PE_RATING_CREATE_SUCCESS";
export const PE_RATING_CREATE_FAIL = "PE_RATING_CREATE_FAIL";

export const GET_PE_RATING_DETAILS = "GET_PE_RATING_DETAILS";
export const GET_PE_RATING_DETAILS_SUCCESS = "GET_PE_RATING_DETAILS_SUCCESS";
export const GET_PE_RATING_DETAILS_FAIL = "GET_PE_RATING_DETAILS_FAIL";

export const GET_PE_RATINGES = "GET_PE_RATINGES";
export const GET_PE_RATINGES_SUCCESS = "GET_PE_RATINGES_SUCCESS";
export const GET_PE_RATINGES_FAIL = "GET_PE_RATINGES_FAIL";

export const UPDATE_PE_RATING = "UPDATE_PE_RATING";
export const UPDATE_PE_RATING_SUCCESS = "UPDATE_PE_RATING_SUCCESS";
export const UPDATE_PE_RATING_FAIL = "UPDATE_PE_RATING_FAIL";

export const DELETE_PE_RATING = "DELETE_PE_RATING";
export const DELETE_PE_RATING_SUCCESS = "DELETE_PE_RATING_SUCCESS";
export const DELETE_PE_RATING_FAIL = "DELETE_PE_RATING_FAIL";

export const RESET = "RESET";
export const RESET_PE_RATING_CREATE = "RESET_PE_RATING_CREATE";
export const RESET_PE_RATING_DETAILS = "RESET_PE_RATING_DETAILS";
export const RESET_PE_RATING_DELETE = "RESET_PE_RATING_DELETE";