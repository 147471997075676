import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createStatusOfAPPT = (payload) => post(baseURL + url.STATUS_OF_APPT, payload);
export const getStatusOfAPPTDetails = (id) => get(`${baseURL + url.STATUS_OF_APPT}/${id}`);
export const getStatusOfAPPTes = () => get(`${baseURL + url.STATUS_OF_APPT}`);
export const updateStatusOfAPPT = (id, payload) => put(`${baseURL + url.STATUS_OF_APPT}/${id}`, payload);
export const deleteStatusOfAPPT = (id) => remove(`${baseURL + url.STATUS_OF_APPT}/${id}`);
