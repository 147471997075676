import {
	GET_VENDORS, GET_VENDORS_SUCCESS, GET_VENDORS_FAIL,
	GET_VENDORS_DETAILS, GET_VENDORS_DETAILS_SUCCESS, GET_VENDORS_DETAILS_FAIL,
	VENDOR_CREATE,VENDOR_CREATE_FAIL,VENDOR_CREATE_SUCCESS,
	VENDOR_DELETE,VENDOR_DELETE_FAIL,VENDOR_DELETE_SUCCESS,
	VENDOR_UPDATE,VENDOR_UPDATE_FAIL,VENDOR_UPDATE_SUCCESS,RESET,GET_ALL_VENDOR_DETAILS,GET_ALL_VENDOR_DETAILS_SUCCESS,GET_ALL_VENDOR_DETAILS_FAIL, GET_VENDORS_SETTING_FAIL, GET_VENDORS_SETTING_SUCCESS, SAVE_VENDORS_SETTING_FAIL, SAVE_VENDORS_SETTING_SUCCESS, SAVE_VENDORS_SETTING, GET_VENDORS_SETTING, VENDOR_COPY, VENDOR_COPY_SUCCESS, VENDOR_COPY_FAIL, VENDOR_STATUS_CREATE, VENDOR_STATUS_CREATE_SUCCESS, VENDOR_STATUS_CREATE_FAIL, VENDOR_STATUS_UPDATE, VENDOR_STATUS_UPDATE_SUCCESS, VENDOR_STATUS_UPDATE_FAIL, VENDOR_STATUS_DELETE, VENDOR_STATUS_DELETE_SUCCESS, VENDOR_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getAllVendors = () => {
	return {
	  type: GET_ALL_VENDOR_DETAILS,
	  
	};
  };
  
  export const getAllVendorsSuccess = (response) => {
	return {
	  type: GET_ALL_VENDOR_DETAILS_SUCCESS,
	  payload: response
	};
  };
  
  export const getAllVendorsFail = (error) => {
	return {
	  type: GET_ALL_VENDOR_DETAILS_FAIL,
	  payload: error
	};
  };
  

  export const getVendors = (payload) => {
	return {
	  type: GET_VENDORS,
	  payload: payload
	};
  };
  
  export const getVendorsSuccess = (posts) => {
	return {
	  type: GET_VENDORS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getVendorsFail = (error) => {
	return {
	  type: GET_VENDORS_FAIL,
	  payload: error,
	};
  };
  
  export const getVendorsDetails = (id) => {
	return {
	  type: GET_VENDORS_DETAILS,
	  payload: id,
	};
  };
  
  export const getVendorsDetailsSuccess = (post) => {
	return {
	  type: GET_VENDORS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getVendorsDetailsFail = (error) => {
	return {
	  type: GET_VENDORS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createVendor =(payload)=>{
	return {
		type: VENDOR_CREATE,
		payload: payload
	}
  }
  export const createVendorSuccess =(response)=>{
	return {
		type: VENDOR_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createVendorFail =(error)=>{
	return {
		type: VENDOR_CREATE_FAIL,
		payload: error
	}
  }


  export const updateVendor =(payload)=>{
	
	return {
		type: VENDOR_UPDATE,
		payload: payload
	}
  }
  export const updateVendorSuccess =(response)=>{
	return {
		type: VENDOR_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateVendorFail =(error)=>{
	return {
		type: VENDOR_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteVendor =(payload)=>{
	return {
		type: VENDOR_DELETE,
		payload: payload
	}
  }
  export const deleteVendorSuccess =(response)=>{
	return {
		type: VENDOR_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteVendorFail =(error)=>{
	return {
		type: VENDOR_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

