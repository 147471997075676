import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createWaitingPeriod = (payload) => post(baseURL + url.WAITING_PERIOD, payload);
export const getWaitingPeriodDetails = (id) => get(`${baseURL + url.WAITING_PERIOD}/${id}`);
export const getWaitingPeriods = () => get(`${baseURL + url.WAITING_PERIOD}`);
export const updateWaitingPeriod = (id, payload) => put(`${baseURL + url.WAITING_PERIOD}/${id}`, payload);
export const deleteWaitingPeriod = (id) => remove(`${baseURL + url.WAITING_PERIOD}/${id}`);
