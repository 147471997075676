// import {
// 	GET_DEPARTMENTS, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAIL,
// 	GET_DEPARTMENTS_DETAILS, GET_DEPARTMENTS_DETAILS_SUCCESS, GET_DEPARTMENTS_DETAILS_FAIL,
// 	DEPARTMENT_CREATE, DEPARTMENT_CREATE_FAIL, DEPARTMENT_CREATE_SUCCESS,
// 	DEPARTMENT_DELETE, DEPARTMENT_DELETE_FAIL, DEPARTMENT_DELETE_SUCCESS,
// 	DEPARTMENT_UPDATE, DEPARTMENT_UPDATE_FAIL, DEPARTMENT_UPDATE_SUCCESS,
// } from "./actionTypes";

// const initialState = {
// 	departmentList: [],
// 	department: {},
// 	postingResult: {},
// 	loadingDepartmentList: false,
// 	loadingDepartmentDetails: false,
// 	departmentSetting:{},
// 	loadingdepartmentSetting: false,
// 	posting: false,
// 	error: {
// 		message: "",
// 	},
// };

// const NewDepartmentReducer = (state = initialState, action) => {
// 	if (!action)
// 		return state;
// 	switch (action.type) {
// 		case GET_DEPARTMENTS:
// 			state = { ...state, loadingDepartmentList: false };
// 			break;
// 		case GET_DEPARTMENTS_SUCCESS:
// 			state = { ...state, departmentList: action.payload, loadingDepartmentList: false };
// 			break;
// 		case GET_DEPARTMENTS_FAIL:
// 			state = {
// 				...state,
// 				error: {
// 					message: "Error",
// 				},
// 				loadingDepartmentList: false,
// 			};
// 			break;
// 		case GET_DEPARTMENTS_DETAILS:
// 			state = { ...state, loadingDepartmentDetails: true };
// 			break;
// 		case GET_DEPARTMENTS_DETAILS_SUCCESS:
// 			state = { ...state, department: action.payload, loadingDepartmentDetails: false };
// 			break;
// 		case GET_DEPARTMENTS_DETAILS_FAIL:
// 			state = {
// 				...state,
// 				error: {
// 					message: "Error",
// 				},
// 				loadingDepartmentDetails: false,
// 			};
// 			break;
// 		case DEPARTMENT_CREATE:
// 			state = { ...state, postingResult: {}, posting: true };
// 			break;
// 		case DEPARTMENT_CREATE_SUCCESS:
// 			state = { ...state, postingResult: action.payload, posting: false };
// 			break;
// 		case DEPARTMENT_CREATE_FAIL:
// 			state = {
// 				...state,
// 				error: {
// 					message: "Error",
// 				},
// 				postingResult: {},
// 				posting: false,
// 			};
// 			break;
// 		case DEPARTMENT_UPDATE:
// 			state = { ...state, postingResult: {}, posting: true };
// 			break;
// 		case DEPARTMENT_UPDATE_SUCCESS:
// 			state = { ...state, postingResult: action.payload, posting: false };
// 			break;
// 		case DEPARTMENT_UPDATE_FAIL:
// 			state = {
// 				...state,
// 				error: {
// 					message: "Error",
// 				},
// 				posting: false,
// 			};
// 			break;
// 		case DEPARTMENT_DELETE:
// 			state = { ...state, postingResult: {}, posting: true };
// 			break;
// 		case DEPARTMENT_DELETE_SUCCESS:
// 			state = { ...state, postingResult: action.payload, posting: false };
// 			break;
// 		case DEPARTMENT_DELETE_FAIL:
// 			state = {
// 				...state,
// 				error: {
// 					message: "Error",
// 				},
// 				posting: false,
// 			};
// 			break;
			
// 		default:
// 			state = { ...state };
// 			break;
// 	}
// 	return state;
// };

// export default NewDepartmentReducer;
import {
	GET_DEPARTMENTS,
	GET_DEPARTMENTS_SUCCESS,
	GET_DEPARTMENTS_FAIL,
	GET_DEPARTMENTS_DETAILS,
	GET_DEPARTMENTS_DETAILS_SUCCESS,
	GET_DEPARTMENTS_DETAILS_FAIL,
	DEPARTMENT_CREATE,
	DEPARTMENT_CREATE_FAIL,
	DEPARTMENT_CREATE_SUCCESS,
	DEPARTMENT_DELETE,
	DEPARTMENT_DELETE_FAIL,
	DEPARTMENT_DELETE_SUCCESS,
	DEPARTMENT_UPDATE,
	DEPARTMENT_UPDATE_FAIL,
	DEPARTMENT_UPDATE_SUCCESS,
	RESET,
  } from "./actionTypes";
  
  const initialState = {
	departmentList: [],
	department: {},
	postingResult: {},
	loadingDepartmentList: false,
	loadingDepartmentDetails: false,
	departmentSetting: {},
	loadingdepartmentSetting: false,
	posting: false,
	error: {
	  message: "",
	},
  };
  
  const NewDepartmentReducer = (state = initialState, action) => {
	if (!action) return state;
	switch (action.type) {
	  case GET_DEPARTMENTS:
		state = { ...state, loadingDepartmentList: true, departmentList: [] };
		break;
	  case GET_DEPARTMENTS_SUCCESS:
		state = { ...state, departmentList: action.payload, loadingDepartmentList: false };
		break;
	  case GET_DEPARTMENTS_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingDepartmentList: false,
		};
		break;
	  case GET_DEPARTMENTS_DETAILS:
		state = { ...state, loadingDepartmentDetails: true, department: {} };
		break;
	  case GET_DEPARTMENTS_DETAILS_SUCCESS:
		state = { ...state, department: action.payload, loadingDepartmentDetails: false };
		break;
	  case GET_DEPARTMENTS_DETAILS_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  loadingDepartmentDetails: false,
		};
		break;
	  case DEPARTMENT_CREATE:
		state = { ...state, postingResult: {}, posting: true, department: {} };
		break;
	  case DEPARTMENT_CREATE_SUCCESS:
		state = { ...state, postingResult: action.payload, posting: false };
		break;
	  case DEPARTMENT_CREATE_FAIL:
		state = {
		  ...state,
		  error: {
			message: "Error",
		  },
		  postingResult: {},
		  posting: false,
		};
		break;
		
		case DEPARTMENT_UPDATE:
			state = { ...state, postingResult: {}, posting: true };
			break;
		case DEPARTMENT_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, posting: false };
			break;
		case DEPARTMENT_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				posting: false,
			};
			break;
		case DEPARTMENT_DELETE:
			state = { ...state, postingResult: {}, posting: true };
			break;
		case DEPARTMENT_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, posting: false };
			break;
		case DEPARTMENT_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				posting: false,
			};
			break;
	  case RESET:
		state = { ...state, department: {} };
		break;
	  default:
		state = { ...state };
		break;
	}
	return state;
  };
  
  export default NewDepartmentReducer;
  
  
  