import * as Actions from '../actions';

const initialState = {
    lifeInsDetail: {
        coverageAndClassData: {
            task:'initialEnroll',
            additionalTask:'additionalLife',
            dependentTask:'dependentCoverage',
            planCode:'',
            planName:'',
            planRemark:'',
            effectiveDate: new Date(),
            endDate: new Date(),
            empClass:'Active',
            empMedicalShare:'',
            empDentalShare:'',
            govMedicalShare:'',
            govDentalShare:'',
            activeEmpClassCoverage:{
                coverage:'Basic',
                compositeRate:'',
                govShare:'',
                dependents:'',
                govDependents:'',
                ageLessThen31:'',
                ageBetween31To40: '',
                ageBetween41To50: '',
                ageBetween51To60: '',
                ageBetween61To70: '',
                ageGreater70:''
            },
            retiredEmpClassCoverage:{
                coverage:'Basic',
                cost:'',
                dependents: '',
                govShare:'',
                govDeptCost:''
            }
        },
       beneficiaryData: [{
           type: 'Primary',
           benefitPR: '',
           lName: '',
           fName: '',
           mi: '',
           relationship: null,
           dob: new Date(),
           ssn: '',
           gender: null,
           mailingAddress: ''
       }]
    }
};

const lifeInsDetail = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_LIFE_INS_DETAIL: {
            return {
                ...state,
                lifeInsDetail: {
                    ...state.lifeInsDetail,
                    ...action.payload
                }
            }
        }

        default: {
            return state;
        }
    }
};

export default lifeInsDetail;
