import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { TEST_DESIGNATED_POSITION_LOG_CREATE, RESET, RESET_TEST_DESIGNATED_POSITION_LOG_CREATE, GET_TEST_DESIGNATED_POSITION_LOG_DETAILS, UPDATE_TEST_DESIGNATED_POSITION_LOG, DELETE_TEST_DESIGNATED_POSITION_LOG } from "./actionsTypes";

import {
    createTestDesignatedPositionLogSuccess,
    createTestDesignatedPositionLogFail,
    reset,
    resetCreateTestDesignatedPositionLog,
    getTestDesignatedPositionLogDetailsSuccess,
    getTestDesignatedPositionLogDetailsFail,
    updateTestDesignatedPositionLogSuccess,
    updateTestDesignatedPositionLogFail,
    deleteTestDesignatedPositionLogSuccess,
    deleteTestDesignatedPositionLogFail,
} from "./action";

import { createTestDesignatedPositionLog, deleteTestDesignatedPositionLog, getTestDesignatedPositionLogDetails, updateTestDesignatedPositionLog } from "../../../_helper/services/DrugTesting/testDesignatedPositionLog";


function* onCreateTestDesignatedPositionLog({ payload }) {
    try {
        const response = yield call(createTestDesignatedPositionLog, payload);
        yield put(createTestDesignatedPositionLogSuccess(response));
        ShowSucess("Test Designated Position Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTestDesignatedPositionLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTestDesignatedPositionLog());
    }
}

function* onGetTestDesignatedPositionLogDetails({ payload: id }) {
    try {
        const response = yield call(getTestDesignatedPositionLogDetails, id);
        yield put(getTestDesignatedPositionLogDetailsSuccess(response));
    } catch (error) {
        yield put(getTestDesignatedPositionLogDetailsFail(error.response));
    }
}
function* onUpdateTestDesignatedPositionLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTestDesignatedPositionLog, id, payload);
        yield put(updateTestDesignatedPositionLogSuccess(response));
        yield put(getTestDesignatedPositionLogDetailsSuccess(payload));
        ShowSucess(`Test Designated Position Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTestDesignatedPositionLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTestDesignatedPositionLog({ payload: id }) {
    try {
        const response = yield call(deleteTestDesignatedPositionLog, id);
        yield put(deleteTestDesignatedPositionLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTestDesignatedPositionLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TestDesignatedPositionLogSaga() {
    yield takeLatest(TEST_DESIGNATED_POSITION_LOG_CREATE, onCreateTestDesignatedPositionLog);
    yield takeLatest(RESET_TEST_DESIGNATED_POSITION_LOG_CREATE, resetCreateTestDesignatedPositionLog);
    yield takeLatest(GET_TEST_DESIGNATED_POSITION_LOG_DETAILS, onGetTestDesignatedPositionLogDetails);
    yield takeLatest(UPDATE_TEST_DESIGNATED_POSITION_LOG, onUpdateTestDesignatedPositionLog);
    yield takeLatest(DELETE_TEST_DESIGNATED_POSITION_LOG, onDeleteTestDesignatedPositionLog);
    yield takeLatest(RESET, reset);
}

export default TestDesignatedPositionLogSaga;