import {
    GRIEVANCE_APPEAL_STATUS_CREATE, GRIEVANCE_APPEAL_STATUS_CREATE_FAIL, GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS,
    DELETE_GRIEVANCE_APPEAL_STATUS,
    DELETE_GRIEVANCE_APPEAL_STATUS_FAIL,
    DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
    GET_GRIEVANCE_APPEAL_STATUSES,
    GET_GRIEVANCE_APPEAL_STATUSES_FAIL,
    GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS,
    RESET,
    RESET_GRIEVANCE_APPEAL_STATUS_CREATE,
    RESET_GRIEVANCE_APPEAL_STATUS_DELETE,
    RESET_GRIEVANCE_APPEAL_STATUS_DETAILS,
    UPDATE_GRIEVANCE_APPEAL_STATUS,
    UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL,
    UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createGrievanceAppealStatus = (payload) => {
    return {
        type: GRIEVANCE_APPEAL_STATUS_CREATE,
        payload: payload
    }
};
export const createGrievanceAppealStatusSuccess = (response) => {
    return {
        type: GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS,
        payload: response
    }
};
export const createGrievanceAppealStatusFail = (error) => {
    return {
        type: GRIEVANCE_APPEAL_STATUS_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getGrievanceAppealStatusDetails = (id) => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUS_DETAILS,
        payload: id,
    };
};
export const getGrievanceAppealStatusDetailsSuccess = (data) => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getGrievanceAppealStatusDetailsFail = (error) => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getGrievanceAppealStatuses = () => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUSES,
    };
};
export const getGrievanceAppealStatusesSuccess = (data) => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS,
        payload: data,
    };
};
export const getGrievanceAppealStatusesFail = (error) => {
    return {
        type: GET_GRIEVANCE_APPEAL_STATUSES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateGrievanceAppealStatus = (payload) => {

    return {
        type: UPDATE_GRIEVANCE_APPEAL_STATUS,
        payload: payload
    }
};
export const updateGrievanceAppealStatusSuccess = (response) => {
    return {
        type: UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
        payload: response
    }
};
export const updateGrievanceAppealStatusFail = (error) => {
    return {
        type: UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteGrievanceAppealStatus = (id) => {
    return {
        type: DELETE_GRIEVANCE_APPEAL_STATUS,
        payload: id
    }
};
export const deleteGrievanceAppealStatusSuccess = (response) => {
    return {
        type: DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
        payload: response
    }
};
export const deleteGrievanceAppealStatusFail = (error) => {
    return {
        type: DELETE_GRIEVANCE_APPEAL_STATUS_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateGrievanceAppealStatus = () => {
    return {
        type: RESET_GRIEVANCE_APPEAL_STATUS_CREATE
    }
};

export const resetGrievanceAppealStatusDetails = () => {
    return {
        type: RESET_GRIEVANCE_APPEAL_STATUS_DETAILS
    }
};

export const resetGrievanceAppealStatusDeleteData = () => {
    return {
        type: RESET_GRIEVANCE_APPEAL_STATUS_DELETE
    }
};