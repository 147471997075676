import * as Actions from "../actions";
const initialState = {
    userList: [
        {
            ssn: "1",
            first_name: "fName1",
            last_name: "lName1",
            mi: "mi",
            department: "department",
            position: "position1",
            step: "1",
            grade: "A",
            rate: "12",
        },
        {
            ssn: "2",
            first_name: "fName2",
            last_name: "lName2",
            mi: "mi",
            department: "department",
            position: "position2",
            step: "2",
            grade: "A",
            rate: "22",
        },
        {
            ssn: "3",
            first_name: "fName3",
            last_name: "lName3",
            mi: "mi",
            department: "department",
            position: "position3",
            step: "3",
            grade: "A",
            rate: "32",
        },
        {
            ssn: "4",
            first_name: "fName4",
            last_name: "lName4",
            mi: "mi",
            department: "department",
            position: "position4",
            step: "4",
            grade: "A",
            rate: "42",
        },
    ],
    filterBy: { id: "", name: "", salary: "" },
    benefitList: [
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341238",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-123456",
            type: 'Health',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Disapproved",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341238",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-1234356",
            type: 'Life',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Pending",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341238",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-345345345",
            type: 'Death Claim',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Pending",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341238",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-345345789",
            type: 'Health',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Pending",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341238",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-123456",
            type: 'Life',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Pending",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341235",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-123457",
            type: 'Death Claim',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Approved",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341230",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-123452",
            type: 'Benefit Master',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Approved",
        },
        {
            donor: [
                {
                    id: 0,
                    ssn: "",
                    first_name: "",
                    last_name: "",
                    mi: "",
                    department: "",
                    position: "",
                    grade: "",
                    step: "",
                    rate: "",
                    donor_hours: "",
                    leave_type: "",
                    remarks: "",
                },
            ],
            ssn: "887341234",
            first_name: "first name",
            last_name: "last name",
            mi: "mi",
            department: "rtl",
            position: "agent",
            grade: "A",
            step: "2",
            rate: "12",
            request_date: new Date(),
            request_hours: "",
            from: new Date(),
            to: new Date(),
            reason: "",
            leave_type: "Sick",
            remarks: "",
            support_document: { id: "", name: "", type: "" },
            head_supervisor: {
                approve: false,
                approve_date: new Date(),
            },
            department_head: {
                approve: false,
                approve_date: new Date(),
            },
            doa: {
                approve: false,
                approve_date: new Date(),
            },
            hr_control: "BM-123453",
            type: 'Benefit Master',
            hr_status: "",
            hr_remarks: "",
            selectedDonor: 0,
            status: "Approved",
        },
    ],
};

const benefitList = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CHANGE_HEADER_FILTER: {
            return {
                ...state,
                filterBy: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

export default benefitList;
