import {
    STATUS_OF_APPT_CREATE, STATUS_OF_APPT_CREATE_FAIL, STATUS_OF_APPT_CREATE_SUCCESS,
    DELETE_STATUS_OF_APPT,
    DELETE_STATUS_OF_APPT_FAIL,
    DELETE_STATUS_OF_APPT_SUCCESS,
    GET_STATUS_OF_APPTES,
    GET_STATUS_OF_APPTES_FAIL,
    GET_STATUS_OF_APPTES_SUCCESS,
    GET_STATUS_OF_APPT_DETAILS,
    GET_STATUS_OF_APPT_DETAILS_FAIL,
    GET_STATUS_OF_APPT_DETAILS_SUCCESS,
    RESET,
    RESET_STATUS_OF_APPT_CREATE,
    RESET_STATUS_OF_APPT_DELETE,
    RESET_STATUS_OF_APPT_DETAILS,
    UPDATE_STATUS_OF_APPT,
    UPDATE_STATUS_OF_APPT_FAIL,
    UPDATE_STATUS_OF_APPT_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createStatusOfAPPT = (payload) => {
    return {
        type: STATUS_OF_APPT_CREATE,
        payload: payload
    }
};
export const createStatusOfAPPTSuccess = (response) => {
    return {
        type: STATUS_OF_APPT_CREATE_SUCCESS,
        payload: response
    }
};
export const createStatusOfAPPTFail = (error) => {
    return {
        type: STATUS_OF_APPT_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getStatusOfAPPTDetails = (id) => {
    return {
        type: GET_STATUS_OF_APPT_DETAILS,
        payload: id,
    };
};
export const getStatusOfAPPTDetailsSuccess = (data) => {
    return {
        type: GET_STATUS_OF_APPT_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getStatusOfAPPTDetailsFail = (error) => {
    return {
        type: GET_STATUS_OF_APPT_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getStatusOfAPPTes = () => {
    return {
        type: GET_STATUS_OF_APPTES,
    };
};
export const getStatusOfAPPTesSuccess = (data) => {
    return {
        type: GET_STATUS_OF_APPTES_SUCCESS,
        payload: data,
    };
};
export const getStatusOfAPPTesFail = (error) => {
    return {
        type: GET_STATUS_OF_APPTES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateStatusOfAPPT = (payload) => {

    return {
        type: UPDATE_STATUS_OF_APPT,
        payload: payload
    }
};
export const updateStatusOfAPPTSuccess = (response) => {
    return {
        type: UPDATE_STATUS_OF_APPT_SUCCESS,
        payload: response
    }
};
export const updateStatusOfAPPTFail = (error) => {
    return {
        type: UPDATE_STATUS_OF_APPT_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteStatusOfAPPT = (id) => {
    return {
        type: DELETE_STATUS_OF_APPT,
        payload: id
    }
};
export const deleteStatusOfAPPTSuccess = (response) => {
    return {
        type: DELETE_STATUS_OF_APPT_SUCCESS,
        payload: response
    }
};
export const deleteStatusOfAPPTFail = (error) => {
    return {
        type: DELETE_STATUS_OF_APPT_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateStatusOfAPPT = () => {
    return {
        type: RESET_STATUS_OF_APPT_CREATE
    }
};

export const resetStatusOfAPPTDetails = () => {
    return {
        type: RESET_STATUS_OF_APPT_DETAILS
    }
};

export const resetStatusOfAPPTDeleteData = () => {
    return {
        type: RESET_STATUS_OF_APPT_DELETE
    }
};