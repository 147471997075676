import {
    EVAL_APPEAL_CREATE, EVAL_APPEAL_CREATE_FAIL, EVAL_APPEAL_CREATE_SUCCESS,
    DELETE_EVAL_APPEAL,
    DELETE_EVAL_APPEAL_FAIL,
    DELETE_EVAL_APPEAL_SUCCESS,
    GET_EVAL_APPEAL_DETAILS,
    GET_EVAL_APPEAL_DETAILS_FAIL,
    GET_EVAL_APPEAL_DETAILS_SUCCESS,
    RESET,
    RESET_EVAL_APPEAL_CREATE,
    RESET_EVAL_APPEAL_DELETE,
    RESET_EVAL_APPEAL_DETAILS,
    UPDATE_EVAL_APPEAL,
    UPDATE_EVAL_APPEAL_FAIL,
    UPDATE_EVAL_APPEAL_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createEvalAppeal = (payload) => {
    return {
        type: EVAL_APPEAL_CREATE,
        payload: payload
    }
};
export const createEvalAppealSuccess = (response) => {
    return {
        type: EVAL_APPEAL_CREATE_SUCCESS,
        payload: response
    }
};
export const createEvalAppealFail = (error) => {
    return {
        type: EVAL_APPEAL_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getEvalAppealDetails = (id) => {
    return {
        type: GET_EVAL_APPEAL_DETAILS,
        payload: id,
    };
};
export const getEvalAppealDetailsSuccess = (data) => {
    return {
        type: GET_EVAL_APPEAL_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getEvalAppealDetailsFail = (error) => {
    return {
        type: GET_EVAL_APPEAL_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateEvalAppeal = (payload) => {

    return {
        type: UPDATE_EVAL_APPEAL,
        payload: payload
    }
};
export const updateEvalAppealSuccess = (response) => {
    return {
        type: UPDATE_EVAL_APPEAL_SUCCESS,
        payload: response
    }
};
export const updateEvalAppealFail = (error) => {
    return {
        type: UPDATE_EVAL_APPEAL_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteEvalAppeal = (id) => {
    return {
        type: DELETE_EVAL_APPEAL,
        payload: id
    }
};
export const deleteEvalAppealSuccess = (response) => {
    return {
        type: DELETE_EVAL_APPEAL_SUCCESS,
        payload: response
    }
};
export const deleteEvalAppealFail = (error) => {
    return {
        type: DELETE_EVAL_APPEAL_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateEvalAppeal = () => {
    return {
        type: RESET_EVAL_APPEAL_CREATE
    }
};

export const resetEvalAppealDetails = () => {
    return {
        type: RESET_EVAL_APPEAL_DETAILS
    }
};

export const resetEvalAppealDeleteData = () => {
    return {
        type: RESET_EVAL_APPEAL_DELETE
    }
};