import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "@mui/material";

const ConfirmModal = (props) => {
  const {
    open,
    setOpen,
    message,
    confimClick,
    modalHeader,
    cnfrmBtnName,
    cancelBtnName,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modalHeader ? modalHeader : ""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message ? message : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "0.5rem",
            marginBottom: "1rem",
            // margin: "1rem",
          }}
        >
          <Button variant="contained" onClick={handleClose} color="inherit">
            {cancelBtnName ? cancelBtnName : "Cancel"}
          </Button>
          <Tooltip title={props.tooltipMsg}>
            <Button
              onClick={() => confimClick()}
              variant="contained"
              color="primary"
              autoFocus
            >
              {cnfrmBtnName ? cnfrmBtnName : "Continue"}
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
