import {
    NATURE_OF_GRIEVANCE_CREATE, NATURE_OF_GRIEVANCE_CREATE_FAIL, NATURE_OF_GRIEVANCE_CREATE_SUCCESS,
    DELETE_NATURE_OF_GRIEVANCE,
    DELETE_NATURE_OF_GRIEVANCE_FAIL,
    DELETE_NATURE_OF_GRIEVANCE_SUCCESS,
    GET_NATURE_OF_GRIEVANCEES,
    GET_NATURE_OF_GRIEVANCEES_FAIL,
    GET_NATURE_OF_GRIEVANCEES_SUCCESS,
    GET_NATURE_OF_GRIEVANCE_DETAILS,
    GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL,
    GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS,
    RESET,
    RESET_NATURE_OF_GRIEVANCE_CREATE,
    RESET_NATURE_OF_GRIEVANCE_DELETE,
    RESET_NATURE_OF_GRIEVANCE_DETAILS,
    UPDATE_NATURE_OF_GRIEVANCE,
    UPDATE_NATURE_OF_GRIEVANCE_FAIL,
    UPDATE_NATURE_OF_GRIEVANCE_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createNatureOfGrievance = (payload) => {
    return {
        type: NATURE_OF_GRIEVANCE_CREATE,
        payload: payload
    }
};
export const createNatureOfGrievanceSuccess = (response) => {
    return {
        type: NATURE_OF_GRIEVANCE_CREATE_SUCCESS,
        payload: response
    }
};
export const createNatureOfGrievanceFail = (error) => {
    return {
        type: NATURE_OF_GRIEVANCE_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getNatureOfGrievanceDetails = (id) => {
    return {
        type: GET_NATURE_OF_GRIEVANCE_DETAILS,
        payload: id,
    };
};
export const getNatureOfGrievanceDetailsSuccess = (data) => {
    return {
        type: GET_NATURE_OF_GRIEVANCE_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getNatureOfGrievanceDetailsFail = (error) => {
    return {
        type: GET_NATURE_OF_GRIEVANCE_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getNatureOfGrievancees = () => {
    return {
        type: GET_NATURE_OF_GRIEVANCEES,
    };
};
export const getNatureOfGrievanceesSuccess = (data) => {
    return {
        type: GET_NATURE_OF_GRIEVANCEES_SUCCESS,
        payload: data,
    };
};
export const getNatureOfGrievanceesFail = (error) => {
    return {
        type: GET_NATURE_OF_GRIEVANCEES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateNatureOfGrievance = (payload) => {

    return {
        type: UPDATE_NATURE_OF_GRIEVANCE,
        payload: payload
    }
};
export const updateNatureOfGrievanceSuccess = (response) => {
    return {
        type: UPDATE_NATURE_OF_GRIEVANCE_SUCCESS,
        payload: response
    }
};
export const updateNatureOfGrievanceFail = (error) => {
    return {
        type: UPDATE_NATURE_OF_GRIEVANCE_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteNatureOfGrievance = (id) => {
    return {
        type: DELETE_NATURE_OF_GRIEVANCE,
        payload: id
    }
};
export const deleteNatureOfGrievanceSuccess = (response) => {
    return {
        type: DELETE_NATURE_OF_GRIEVANCE_SUCCESS,
        payload: response
    }
};
export const deleteNatureOfGrievanceFail = (error) => {
    return {
        type: DELETE_NATURE_OF_GRIEVANCE_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateNatureOfGrievance = () => {
    return {
        type: RESET_NATURE_OF_GRIEVANCE_CREATE
    }
};

export const resetNatureOfGrievanceDetails = () => {
    return {
        type: RESET_NATURE_OF_GRIEVANCE_DETAILS
    }
};

export const resetNatureOfGrievanceDeleteData = () => {
    return {
        type: RESET_NATURE_OF_GRIEVANCE_DELETE
    }
};