import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_VENDORS, GET_VENDORS_DETAILS,  VENDOR_CREATE, VENDOR_DELETE,
   VENDOR_UPDATE,RESET,GET_ALL_VENDOR_DETAILS,GET_ALL_VENDOR_DETAILS_SUCCESS,GET_ALL_VENDOR_DETAILS_FAIL,} from "./actionTypes";

import {
  getVendorsSuccess,
  getVendorsFail,
  getVendorsDetailsSuccess,
  getVendorsDetailsFail,
  createVendorSuccess,
  createVendorFail,
  updateVendorSuccess,
  updateVendorFail,
  deleteVendorSuccess,
  deleteVendorFail,
  getAllVendorsSuccess,
  getAllVendorsFail

} from "./actions";

import {  createVendor,deleteVendor,
	getVendorDetails,getVendors
	,updateVendor,getAllVendors
 } from "../../_helper/services/vendor";
function* onGetVendors({payload: {page,pageSize}}) {
  try {
    const response = yield call(getVendors,{page,pageSize});
    yield put(getVendorsSuccess(response));
  } catch (error) {
    yield put(getVendorsFail(error.response));
  }
}
// All Vendor List
function* onGetAllVendorDetails() {
  try {
    const response = yield call(getAllVendors);
    yield put(getAllVendorsSuccess(response));
  } catch (error) {
    yield put(getAllVendorsFail(error.response));
  }
}

function* onGetVendorDetails({ payload: id }) {
  try {
    const response = yield call(getVendorDetails, id);
    yield put(getVendorsDetailsSuccess(response));
  } catch (error) {
    yield put(getVendorsDetailsFail(error.response));
  }
}
function* onCreateVendor({ payload }) {
	try {
	  const response = yield call(createVendor, payload);
	  yield put(createVendorSuccess(response));
    ShowSucess('Vendor Created successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(createVendorFail(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error)
   
	}
  }
  function* onUpdateVendor({ payload: { vendorNo},payload }) {
	try {
	  const response = yield call(updateVendor, vendorNo, payload);
	  yield put(updateVendorSuccess(response));
    ShowSucess('Vendor updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateVendorFail(error.response));
    console.log(error?.response?.data?.message);
    ShowError(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
	}
  }
  function* onDeleteVendor({ payload: vendorNo }) {
	try {
	  const response = yield call(deleteVendor, vendorNo);
	  yield put(deleteVendorSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteVendorFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_VENDORS, onGetVendors);
  yield takeLatest(GET_VENDORS_DETAILS, onGetVendorDetails);
  yield takeLatest(VENDOR_CREATE, onCreateVendor);
  yield takeLatest(VENDOR_UPDATE, onUpdateVendor);
  yield takeLatest(VENDOR_DELETE, onDeleteVendor);
  yield takeLatest(GET_ALL_VENDOR_DETAILS, onGetAllVendorDetails);

}

export default CartSaga;