import { get, post, put, remove } from "../../api_helper";
import * as url from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const createTypeOfTraining = (payload) => post(baseURL + url.TYPE_OF_TRAINING, payload);
export const getTypeOfTrainingDetails = (id) => get(`${baseURL + url.TYPE_OF_TRAINING}/${id}`);
export const getTypeOfTraininges = () => get(`${baseURL + url.TYPE_OF_TRAINING}`);
export const updateTypeOfTraining = (id, payload) => put(`${baseURL + url.TYPE_OF_TRAINING}/${id}`, payload);
export const deleteTypeOfTraining = (id) => remove(`${baseURL + url.TYPE_OF_TRAINING}/${id}`);
