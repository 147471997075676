import { takeEvery, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  FIND_OUTSIDE_EMPLOYEEMENT,
  FIND_OUTSIDE_EMPLOYEEMENT_SUCCESS,
  FIND_OUTSIDE_EMPLOYEEMENT_FAIL,
  REQ_OUTSIDE_EMPLOYMENT,
  REQ_OUTSIDE_EMPLOYMENT_SUCCESS,
  REQ_OUTSIDE_EMPLOYMENT_FAIL,
  FETCH_OUTSIDE_EMPLOYDETAILS,
  FETCH_OUTSIDE_EMPLOYDETAILS_SUCCESS,
  FETCH_OUTSIDE_EMPLOYDETAILS_FAIL,
  EDIT_OUTSIDE_EMPLOYEMENT,
  EDIT_OUTSIDE_EMPLOYEMENT_SUCCESS,
  EDIT_OUTSIDE_EMPLOYEMENT_FAIL,
  DELETE_OUTSIDE_EMPLOYEMENT,
  DELETE_OUTSIDE_EMPLOYEMENT_SUCCESS,
  DELETE_OUTSIDE_EMPLOYEMENT_FAIL,
  OUTSIDE_EMPLOYEEMENT_HISTORY_SUCCESS,
  OUTSIDE_EMPLOYEEMENT_HISTORY_FAIL,
  OUTSIDE_EMPLOYEEMENT_HISTORY,
} from "./actionTypes";
import {
  findOutSideEmployeement,
  requestOutSideEmploy,
  fetchOutSideEmploy,
  editOutSideEmploy,
  deleteOutsideEmployment,
  getoutSideEmployHistory,
} from "../../_helper/backend_helper";
import { ShowError } from "../../_helper/helper";

function* getOutsideEmployeement({ payload }) {
  try {
    const response = yield call(findOutSideEmployeement, payload);
    yield put({ type: FIND_OUTSIDE_EMPLOYEEMENT_SUCCESS, response });
  } catch (error) {
    yield put({
      type: FIND_OUTSIDE_EMPLOYEEMENT_FAIL,
      message: error?.response?.data?.message,
    });
  }
}
function* getOutsideEmployeementHistory({ payload }) {
  try {
    const response = yield call(getoutSideEmployHistory, payload);
    yield put({ type: OUTSIDE_EMPLOYEEMENT_HISTORY_SUCCESS, response });
  } catch (error) {
    yield put({
      type: OUTSIDE_EMPLOYEEMENT_HISTORY_FAIL,
      message: error?.response?.data?.message,
    });
  }
}
function* reqOutsideEmployeement({ payload }) {
  try {
    const response = yield call(requestOutSideEmploy, payload);
    yield put({ type: REQ_OUTSIDE_EMPLOYMENT_SUCCESS, response });
  } catch (error) {
    yield put({
      type: REQ_OUTSIDE_EMPLOYMENT_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* getOutsideEmploymentDetails({ payload }) {
  try {
    const response = yield call(fetchOutSideEmploy, payload);
    yield put({ type: FETCH_OUTSIDE_EMPLOYDETAILS_SUCCESS, response });
  } catch (error) {
    yield put({
      type: FETCH_OUTSIDE_EMPLOYDETAILS_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* editOutsideEmployDetails({ payload, id }) {
  console.log("payload", payload, id);
  try {
    const response = yield call(editOutSideEmploy, payload, id);
    yield put({ type: EDIT_OUTSIDE_EMPLOYEMENT_SUCCESS, response });
  } catch (error) {
    yield put({
      type: EDIT_OUTSIDE_EMPLOYEMENT_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* DeleteOutsideEmployment({ payload }) {
  try {
    const response = yield call(deleteOutsideEmployment, payload);
    yield put({ type: DELETE_OUTSIDE_EMPLOYEMENT_SUCCESS, response });
  } catch (error) {
    yield put({
      type: DELETE_OUTSIDE_EMPLOYEMENT_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* CartSaga() {
  // yield takeLatest(GET_LEAVE_DETAILS, onGetLeaveDetails);
  yield takeEvery(FIND_OUTSIDE_EMPLOYEEMENT, getOutsideEmployeement);
  yield takeEvery(REQ_OUTSIDE_EMPLOYMENT, reqOutsideEmployeement);
  yield takeEvery(FETCH_OUTSIDE_EMPLOYDETAILS, getOutsideEmploymentDetails);
  yield takeEvery(EDIT_OUTSIDE_EMPLOYEMENT, editOutsideEmployDetails);
  yield takeEvery(DELETE_OUTSIDE_EMPLOYEMENT, DeleteOutsideEmployment);
  yield takeEvery(OUTSIDE_EMPLOYEEMENT_HISTORY, getOutsideEmployeementHistory);
}

export default CartSaga;
