export const GET_LEAVESSHAREFORM_DETAILS = "GET_LEAVESSHAREFORM_DETAILS";
export const GET_LEAVESSHAREFORM_SUCCESS = "GET_LEAVESSHAREFORM_SUCCESS";
export const GET_LEAVESHAREFORM_FAILURE = "GET_LEAVESHAREFORM_FAILURE";



export const LEAVESHAREFORM_DELETE= "LEAVESHAREFORM_DELETE";
export const LEAVESHAREFORM_DELETE_SUCCESS = "LEAVESHAREFORM_DELETE_SUCCESS";
export const LEAVESHREFORM_DELETE_FAIL = "LEAVESHREFORM_DELETE_FAIL";

export const LEAVESHAREFORM_UPDATE= "LEAVESHAREFORM_UPDATE";
export const LEAVESHAREFORM_UPDATE_SUCCESS = "LEAVESHAREFORM_UPDATE_SUCCESS";
export const LEAVESHAREFORM_UPDATE_FAIL = "LEAVESHAREFORM_UPDATE_FAIL";

export const LEAVESHAREFORM_CREATE= "LEAVESHAREFORM_CREATE";
export const LEAVESHAREFORM_CREATE_SUCCESS = "LEAVESHAREFORM_CREATE_SUCCESS";
export const LEAVESHAREFORM_CREATE_FAIL = "LEAVESHAREFORM_CREATE_FAIL";


export const LEAVESHAREFORM_GETINFO= "LEAVESHAREFORM_GETINFO";
export const LEAVESHAREFORM_GETINFO_SUCCESS = "LEAVESHAREFORM_GETINFO_SUCCESS";
export const LEAVESHAREFORM_GETINFO_FAIL = "LEAVESHAREFORM_GETINFO_FAIL";

