import React, { Fragment, useState, useContext } from "react";
import { Input } from "reactstrap";
import { H6 } from "../../../../AbstractElements";
import ConfigDB from "../../../../Config/ThemeConfig";
import { useSelector } from "react-redux";
import {
  Fade,
  FadeBottom,
  None,
  RouterAnimationText,
  SildeFade,
  ZoomFade,
  ZoomOut,
} from "../../../../Constant";
import AnimationThemeContext from "../../../../_helper/AnimationTheme";
import CustomizerContext from "../../../../_helper/Customizer";

const AnimationFade = () => {
  const { routerAnimation } = useContext(AnimationThemeContext);
  const [value, setValue] = useState("");
  const { RouterAnimation } = useContext(CustomizerContext);
  const { StoredUserConfiguration, storedTheme } = useSelector(
    (state) => state?.ThemeCustomizerReducer
  );
  const layout_animation =
    StoredUserConfiguration?.router_animation || storedTheme?.router_animation;
  const selectAnimation = (e) => {
    const value = e.target.value;
    setValue(value);
    routerAnimation(value);
    RouterAnimation(value);
  };
  return (
    <Fragment>
      <H6>
        {RouterAnimationText} {value ? value : layout_animation}
      </H6>
      <Input
        type="select"
        // defaultValue={layout_animation}
        value={value ? value : layout_animation}
        name="selectMulti"
        onChange={(e) => selectAnimation(e)}
      >
        <option value="zoomfade">{ZoomFade}</option>
        <option value="slidefade">{SildeFade}</option>
        <option value="fadebottom">{FadeBottom}</option>
        <option value="fade">{Fade}</option>
        <option value="zoomout">{ZoomOut}</option>
        <option value="none">{None}</option>
      </Input>
    </Fragment>
  );
};

export default AnimationFade;
