import {
    CBA_STATUS_CREATE,
    CBA_STATUS_CREATE_FAIL,
    CBA_STATUS_CREATE_SUCCESS,
    DELETE_CBA_STATUS,
    DELETE_CBA_STATUS_FAIL,
    DELETE_CBA_STATUS_SUCCESS,
    GET_CBA_STATUSES,
    GET_CBA_STATUSES_FAIL,
    GET_CBA_STATUSES_SUCCESS,
    GET_CBA_STATUS_DETAILS,
    GET_CBA_STATUS_DETAILS_FAIL,
    GET_CBA_STATUS_DETAILS_SUCCESS,
    RESET,
    RESET_CBA_STATUS_CREATE,
    RESET_CBA_STATUS_DELETE,
    RESET_CBA_STATUS_DETAILS,
    UPDATE_CBA_STATUS,
    UPDATE_CBA_STATUS_FAIL,
    UPDATE_CBA_STATUS_SUCCESS,
} from "./actionsTypes";

const initialState = {
    CBAStatusList: [],
    CBAStatusDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const CBAStatusReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case CBA_STATUS_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case CBA_STATUS_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case CBA_STATUS_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_CBA_STATUSES:
            state = { ...state, loading: true };
            break;
        case GET_CBA_STATUSES_SUCCESS:
            state = { ...state, CBAStatusList: action.payload, loading: false };
            break;
        case GET_CBA_STATUSES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_CBA_STATUS_DETAILS:
            state = { ...state, detailsLoading: true, CBAStatusDetails: {} };
            break;
        case GET_CBA_STATUS_DETAILS_SUCCESS:
            state = { ...state, CBAStatusDetails: action.payload, detailsLoading: false };
            break;
        case GET_CBA_STATUS_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_CBA_STATUS:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_CBA_STATUS_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_CBA_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_CBA_STATUS:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_CBA_STATUS_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_CBA_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_CBA_STATUS_DETAILS:
            state = { ...state, CBAStatusDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, CBAStatusList: [], createResult: {} };
            break;
        case RESET_CBA_STATUS_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_CBA_STATUS_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CBAStatusReducer;