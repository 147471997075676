export const DOA_HR_JV_INTERNAL_LOG_CREATE = "DOA_HR_JV_INTERNAL_LOG_CREATE";
export const DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS = "DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS";
export const DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL = "DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL";

export const GET_DOA_HR_JV_INTERNAL_LOG_DETAILS = "GET_DOA_HR_JV_INTERNAL_LOG_DETAILS";
export const GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS = "GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS";
export const GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL = "GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL";

export const UPDATE_DOA_HR_JV_INTERNAL_LOG = "UPDATE_DOA_HR_JV_INTERNAL_LOG";
export const UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS = "UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS";
export const UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL = "UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL";

export const DELETE_DOA_HR_JV_INTERNAL_LOG = "DELETE_DOA_HR_JV_INTERNAL_LOG";
export const DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS = "DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS";
export const DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL = "DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL";

export const RESET = "RESET";
export const RESET_DOA_HR_JV_INTERNAL_LOG_CREATE = "RESET_DOA_HR_JV_INTERNAL_LOG_CREATE";
export const RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS = "RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS";