import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_PAYGRADES, GET_PAYGRADES_DETAILS,  PAYGRADE_CREATE, PAYGRADE_DELETE,
   PAYGRADE_UPDATE} from "./actionTypes";

import {
  getPaygradesSuccess,
  getPaygradesFail,
  getPaygradesDetailsSuccess,
  getPaygradesDetailsFail,
  createPaygradeSuccess,
  createPaygradeFail,
  updatePaygradeSuccess,
  updatePaygradeFail,
  deletePaygradeSuccess,
  deletePaygradeFail,
} from "./actions";

import {  createPaygrade,deletePaygrade,
	getPaygradeDetails,getPaygrades
	,updatePaygrade,
 } from "../../_helper/services/paygrade";
function* onGetPaygrades({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPaygrades,{page,pageSize});
    yield put(getPaygradesSuccess(response));
  } catch (error) {
    yield put(getPaygradesFail(error.response));
  }
}

function* onGetPaygradeDetails({ payload: id }) {
  try {
    const response = yield call(getPaygradeDetails, id);
    yield put(getPaygradesDetailsSuccess(response));
  } catch (error) {
    yield put(getPaygradesDetailsFail(error.response));
  }
}
function* onCreatePaygrade({ payload }) {
	try {
	  const response = yield call(createPaygrade, payload);
	  yield put(createPaygradeSuccess(response));
    ShowSucess('Paygrade Created successfully', {
      position: 'top-right'
    });
    
	} catch (error) {
	  yield put(createPaygradeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdatePaygrade({ payload: { id},payload }) {
	try {
	  const response = yield call(updatePaygrade, id, payload);
	  yield put(updatePaygradeSuccess(response));
    ShowSucess('Paygrade Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updatePaygradeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onDeletePaygrade({ payload: ID }) {
	try {
	  const response = yield call(deletePaygrade, ID);
	  yield put(deletePaygradeSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deletePaygradeFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_PAYGRADES, onGetPaygrades);
  yield takeLatest(GET_PAYGRADES_DETAILS, onGetPaygradeDetails);
  yield takeLatest(PAYGRADE_CREATE, onCreatePaygrade);
  yield takeLatest(PAYGRADE_UPDATE, onUpdatePaygrade);
  yield takeLatest(PAYGRADE_DELETE, onDeletePaygrade);
}

export default CartSaga;