import {
    TEST_DESIGNATED_POSITION_LOG_CREATE,
    TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL,
    TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS,
    RESET,
    RESET_TEST_DESIGNATED_POSITION_LOG_CREATE,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL,
    UPDATE_TEST_DESIGNATED_POSITION_LOG,
    UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
    UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL,
    DELETE_TEST_DESIGNATED_POSITION_LOG,
    DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
    DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL,
    RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
} from "./actionsTypes";

const initialState = {
    testDesignatedPositionLogs: [],
    testDesignatedPositionLogDetails: {},
    createResult: {},
    deleteResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    error: {
        message: "",
    },
};

const TestDesignatedPositionLogReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case TEST_DESIGNATED_POSITION_LOG_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        //GET individual Test Designated Position Log
        case GET_TEST_DESIGNATED_POSITION_LOG_DETAILS:
            state = { ...state, detailsLoading: true, testDesignatedPositionLogDetails: {} };
            break;
        case GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS:
            state = { ...state, testDesignatedPositionLogDetails: action.payload, detailsLoading: false };
            break;
        case GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        //Update individual Test Designated Position Log
        case UPDATE_TEST_DESIGNATED_POSITION_LOG:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false
            };
            break;

        //Delete individual Test Designated Position Log
        case DELETE_TEST_DESIGNATED_POSITION_LOG:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;

        //Reset Test Designated Position Log
        case RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS:
            state = { ...state, testDesignatedPositionLogDetails: {}, loading: false };
            break;

        case RESET:
            state = { ...state, testDesignatedPositionLogs: [], createResult: {} };
            break;
        case RESET_TEST_DESIGNATED_POSITION_LOG_CREATE:
            state = { ...state, createResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default TestDesignatedPositionLogReducer;