import {
    DELETE_DEPARTMENT_RANDOM_DATE_LOG,
    DELETE_DEPARTMENT_RANDOM_DATE_LOG_FAIL,
    DELETE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS,
    DEPARTMENT_RANDOM_DATE_LOG_CREATE, DEPARTMENT_RANDOM_DATE_LOG_CREATE_FAIL, DEPARTMENT_RANDOM_DATE_LOG_CREATE_SUCCESS,
    GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS,
    GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_FAIL,
    GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE,
    RESET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS,
    UPDATE_DEPARTMENT_RANDOM_DATE_LOG,
    UPDATE_DEPARTMENT_RANDOM_DATE_LOG_FAIL,
    UPDATE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createDepartmentRandomDateLog = (payload) => {
    return {
        type: DEPARTMENT_RANDOM_DATE_LOG_CREATE,
        payload: payload
    }
};
export const createDepartmentRandomDateLogSuccess = (response) => {
    return {
        type: DEPARTMENT_RANDOM_DATE_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createDepartmentRandomDateLogFail = (error) => {
    return {
        type: DEPARTMENT_RANDOM_DATE_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getDepartmentRandomDateLogDetails = (id) => {
    return {
        type: GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS,
        payload: id,
    };
};
export const getDepartmentRandomDateLogDetailsSuccess = (data) => {
    return {
        type: GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getDepartmentRandomDateLogDetailsFail = (error) => {
    return {
        type: GET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateDepartmentRandomDateLog = (payload) => {

    return {
        type: UPDATE_DEPARTMENT_RANDOM_DATE_LOG,
        payload: payload
    }
};
export const updateDepartmentRandomDateLogSuccess = (response) => {
    return {
        type: UPDATE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS,
        payload: response
    }
};
export const updateDepartmentRandomDateLogFail = (error) => {
    return {
        type: UPDATE_DEPARTMENT_RANDOM_DATE_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteDepartmentRandomDateLog = (id) => {
    return {
        type: DELETE_DEPARTMENT_RANDOM_DATE_LOG,
        payload: id
    }
};
export const deleteDepartmentRandomDateLogSuccess = (response) => {
    return {
        type: DELETE_DEPARTMENT_RANDOM_DATE_LOG_SUCCESS,
        payload: response
    }
};
export const deleteDepartmentRandomDateLogFail = (error) => {
    return {
        type: DELETE_DEPARTMENT_RANDOM_DATE_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateDepartmentRandomDateLog = () => {
    return {
        type: RESET_DEPARTMENT_RANDOM_DATE_LOG_CREATE
    }
};
export const resetDepartmentRandomDateDetails = () => {
    return {
        type: RESET_DEPARTMENT_RANDOM_DATE_LOG_DETAILS,
    };
};