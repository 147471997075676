import {
    TYPE_OF_TRAINING_CREATE, TYPE_OF_TRAINING_CREATE_FAIL, TYPE_OF_TRAINING_CREATE_SUCCESS,
    DELETE_TYPE_OF_TRAINING,
    DELETE_TYPE_OF_TRAINING_FAIL,
    DELETE_TYPE_OF_TRAINING_SUCCESS,
    GET_TYPE_OF_TRAININGES,
    GET_TYPE_OF_TRAININGES_FAIL,
    GET_TYPE_OF_TRAININGES_SUCCESS,
    GET_TYPE_OF_TRAINING_DETAILS,
    GET_TYPE_OF_TRAINING_DETAILS_FAIL,
    GET_TYPE_OF_TRAINING_DETAILS_SUCCESS,
    RESET,
    RESET_TYPE_OF_TRAINING_CREATE,
    RESET_TYPE_OF_TRAINING_DELETE,
    RESET_TYPE_OF_TRAINING_DETAILS,
    UPDATE_TYPE_OF_TRAINING,
    UPDATE_TYPE_OF_TRAINING_FAIL,
    UPDATE_TYPE_OF_TRAINING_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createTypeOfTraining = (payload) => {
    return {
        type: TYPE_OF_TRAINING_CREATE,
        payload: payload
    }
};
export const createTypeOfTrainingSuccess = (response) => {
    return {
        type: TYPE_OF_TRAINING_CREATE_SUCCESS,
        payload: response
    }
};
export const createTypeOfTrainingFail = (error) => {
    return {
        type: TYPE_OF_TRAINING_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTypeOfTrainingDetails = (id) => {
    return {
        type: GET_TYPE_OF_TRAINING_DETAILS,
        payload: id,
    };
};
export const getTypeOfTrainingDetailsSuccess = (data) => {
    return {
        type: GET_TYPE_OF_TRAINING_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTypeOfTrainingDetailsFail = (error) => {
    return {
        type: GET_TYPE_OF_TRAINING_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getTypeOfTraininges = () => {
    return {
        type: GET_TYPE_OF_TRAININGES,
    };
};
export const getTypeOfTrainingesSuccess = (data) => {
    return {
        type: GET_TYPE_OF_TRAININGES_SUCCESS,
        payload: data,
    };
};
export const getTypeOfTrainingesFail = (error) => {
    return {
        type: GET_TYPE_OF_TRAININGES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTypeOfTraining = (payload) => {

    return {
        type: UPDATE_TYPE_OF_TRAINING,
        payload: payload
    }
};
export const updateTypeOfTrainingSuccess = (response) => {
    return {
        type: UPDATE_TYPE_OF_TRAINING_SUCCESS,
        payload: response
    }
};
export const updateTypeOfTrainingFail = (error) => {
    return {
        type: UPDATE_TYPE_OF_TRAINING_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTypeOfTraining = (id) => {
    return {
        type: DELETE_TYPE_OF_TRAINING,
        payload: id
    }
};
export const deleteTypeOfTrainingSuccess = (response) => {
    return {
        type: DELETE_TYPE_OF_TRAINING_SUCCESS,
        payload: response
    }
};
export const deleteTypeOfTrainingFail = (error) => {
    return {
        type: DELETE_TYPE_OF_TRAINING_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateTypeOfTraining = () => {
    return {
        type: RESET_TYPE_OF_TRAINING_CREATE
    }
};

export const resetTypeOfTrainingDetails = () => {
    return {
        type: RESET_TYPE_OF_TRAINING_DETAILS
    }
};

export const resetTypeOfTrainingDeleteData = () => {
    return {
        type: RESET_TYPE_OF_TRAINING_DELETE
    }
};