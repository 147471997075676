import {
    TRAINING_LOG_CREATE, TRAINING_LOG_CREATE_FAIL, TRAINING_LOG_CREATE_SUCCESS,
    DELETE_TRAINING_LOG,
    DELETE_TRAINING_LOG_FAIL,
    DELETE_TRAINING_LOG_SUCCESS,
    GET_TRAINING_LOG_DETAILS,
    GET_TRAINING_LOG_DETAILS_FAIL,
    GET_TRAINING_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_TRAINING_LOG_CREATE,
    RESET_TRAINING_LOG_DELETE,
    RESET_TRAINING_LOG_DETAILS,
    UPDATE_TRAINING_LOG,
    UPDATE_TRAINING_LOG_FAIL,
    UPDATE_TRAINING_LOG_SUCCESS,
    GET_TRAINING__AUDIT_LOGS,
    GET_TRAINING__AUDIT_LOGS_SUCCESS,
    GET_TRAINING__AUDIT_LOGS_FAIL,
} from "./actionsTypes";

//Create Actions
export const createTrainingLog = (payload) => {
    return {
        type: TRAINING_LOG_CREATE,
        payload: payload
    }
};
export const createTrainingLogSuccess = (response) => {
    return {
        type: TRAINING_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createTrainingLogFail = (error) => {
    return {
        type: TRAINING_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTrainingLogDetails = (id) => {
    return {
        type: GET_TRAINING_LOG_DETAILS,
        payload: id,
    };
};
export const getTrainingLogDetailsSuccess = (data) => {
    return {
        type: GET_TRAINING_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTrainingLogDetailsFail = (error) => {
    return {
        type: GET_TRAINING_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTrainingLog = (payload) => {

    return {
        type: UPDATE_TRAINING_LOG,
        payload: payload
    }
};
export const updateTrainingLogSuccess = (response) => {
    return {
        type: UPDATE_TRAINING_LOG_SUCCESS,
        payload: response
    }
};
export const updateTrainingLogFail = (error) => {
    return {
        type: UPDATE_TRAINING_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTrainingLog = (id) => {
    return {
        type: DELETE_TRAINING_LOG,
        payload: id
    }
};
export const deleteTrainingLogSuccess = (response) => {
    return {
        type: DELETE_TRAINING_LOG_SUCCESS,
        payload: response
    }
};
export const deleteTrainingLogFail = (error) => {
    return {
        type: DELETE_TRAINING_LOG_FAIL,
        payload: error
    }
};

//Get Audit Logs
export const getTraining__AuditLogs = (payload) => {
    return {
        type: GET_TRAINING__AUDIT_LOGS,
        payload: payload,
    };
};
export const getTraining__AuditLogsSuccess = (data) => {
    return {
        type: GET_TRAINING__AUDIT_LOGS_SUCCESS,
        payload: data,
    };
};
export const getTraining__AuditLogsFail = (error) => {
    return {
        type: GET_TRAINING__AUDIT_LOGS_FAIL,
        payload: error,
    };
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateTrainingLog = () => {
    return {
        type: RESET_TRAINING_LOG_CREATE
    }
};

export const resetTrainingLogDetails = () => {
    return {
        type: RESET_TRAINING_LOG_DETAILS
    }
};

export const resetTrainingLogDeleteData = () => {
    return {
        type: RESET_TRAINING_LOG_DELETE
    }
};