export const GET_PAYTIMECODES = "GET_PAYTIMECODES";
export const GET_PAYTIMECODES_SUCCESS = "GET_PAYTIMECODES_SUCCESS";
export const GET_PAYTIMECODES_FAIL = "GET_PAYTIMECODES_FAIL";

export const GET_PAYTIMECODES_DETAILS = "GET_PAYTIMECODES_DETAILS";
export const GET_PAYTIMECODES_DETAILS_SUCCESS = "GET_PAYTIMECODES_DETAILS_SUCCESS";
export const GET_PAYTIMECODES_DETAILS_FAIL = "GET_PAYTIMECODES_DETAILS_FAIL";

export const PAYTIMECODE_UPDATE= "PAYTIMECODE_UPDATE";
export const PAYTIMECODE_UPDATE_SUCCESS = "PAYTIMECODE_UPDATE_SUCCESS";
export const PAYTIMECODE_UPDATE_FAIL = "PAYTIMECODE_UPDATE_FAIL";

export const PAYTIMECODE_CREATE= "PAYTIMECODE_CREATE";
export const PAYTIMECODE_CREATE_SUCCESS = "PAYTIMECODE_CREATE_SUCCESS";
export const PAYTIMECODE_CREATE_FAIL = "PAYTIMECODE_CREATE_FAIL";

export const PAYTIMECODE_DELETE= "PAYTIMECODE_DELETE";
export const PAYTIMECODE_DELETE_SUCCESS = "PAYTIMECODE_DELETE_SUCCESS";
export const PAYTIMECODE_DELETE_FAIL = "PAYTIMECODE_DELETE_FAIL";
export const RESET="RESET";


