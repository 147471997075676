import {
    TYPE_OF_CHARGES_CREATE, TYPE_OF_CHARGES_CREATE_FAIL, TYPE_OF_CHARGES_CREATE_SUCCESS,
    DELETE_TYPE_OF_CHARGES,
    DELETE_TYPE_OF_CHARGES_FAIL,
    DELETE_TYPE_OF_CHARGES_SUCCESS,
    GET_TYPE_OF_CHARGESES,
    GET_TYPE_OF_CHARGESES_FAIL,
    GET_TYPE_OF_CHARGESES_SUCCESS,
    GET_TYPE_OF_CHARGES_DETAILS,
    GET_TYPE_OF_CHARGES_DETAILS_FAIL,
    GET_TYPE_OF_CHARGES_DETAILS_SUCCESS,
    RESET,
    RESET_TYPE_OF_CHARGES_CREATE,
    RESET_TYPE_OF_CHARGES_DELETE,
    RESET_TYPE_OF_CHARGES_DETAILS,
    UPDATE_TYPE_OF_CHARGES,
    UPDATE_TYPE_OF_CHARGES_FAIL,
    UPDATE_TYPE_OF_CHARGES_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createTypeOfCharges = (payload) => {
    return {
        type: TYPE_OF_CHARGES_CREATE,
        payload: payload
    }
};
export const createTypeOfChargesSuccess = (response) => {
    return {
        type: TYPE_OF_CHARGES_CREATE_SUCCESS,
        payload: response
    }
};
export const createTypeOfChargesFail = (error) => {
    return {
        type: TYPE_OF_CHARGES_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTypeOfChargesDetails = (id) => {
    return {
        type: GET_TYPE_OF_CHARGES_DETAILS,
        payload: id,
    };
};
export const getTypeOfChargesDetailsSuccess = (data) => {
    return {
        type: GET_TYPE_OF_CHARGES_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTypeOfChargesDetailsFail = (error) => {
    return {
        type: GET_TYPE_OF_CHARGES_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getTypeOfChargeses = () => {
    return {
        type: GET_TYPE_OF_CHARGESES,
    };
};
export const getTypeOfChargesesSuccess = (data) => {
    return {
        type: GET_TYPE_OF_CHARGESES_SUCCESS,
        payload: data,
    };
};
export const getTypeOfChargesesFail = (error) => {
    return {
        type: GET_TYPE_OF_CHARGESES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTypeOfCharges = (payload) => {

    return {
        type: UPDATE_TYPE_OF_CHARGES,
        payload: payload
    }
};
export const updateTypeOfChargesSuccess = (response) => {
    return {
        type: UPDATE_TYPE_OF_CHARGES_SUCCESS,
        payload: response
    }
};
export const updateTypeOfChargesFail = (error) => {
    return {
        type: UPDATE_TYPE_OF_CHARGES_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTypeOfCharges = (id) => {
    return {
        type: DELETE_TYPE_OF_CHARGES,
        payload: id
    }
};
export const deleteTypeOfChargesSuccess = (response) => {
    return {
        type: DELETE_TYPE_OF_CHARGES_SUCCESS,
        payload: response
    }
};
export const deleteTypeOfChargesFail = (error) => {
    return {
        type: DELETE_TYPE_OF_CHARGES_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateTypeOfCharges = () => {
    return {
        type: RESET_TYPE_OF_CHARGES_CREATE
    }
};

export const resetTypeOfChargesDetails = () => {
    return {
        type: RESET_TYPE_OF_CHARGES_DETAILS
    }
};

export const resetTypeOfChargesDeleteData = () => {
    return {
        type: RESET_TYPE_OF_CHARGES_DELETE
    }
};