import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_EDUCATIONS, GET_EDUCATIONS_DETAILS,  EDUCATION_CREATE, EDUCATION_DELETE,
   EDUCATION_UPDATE,RESET} from "./actionTypes";

import {
  getEducationsSuccess,
  getEducationsFail,
  getEducationsDetailsSuccess,
  getEducationsDetailsFail,
  createEducationSuccess,
  createEducationFail,
  updateEducationSuccess,
  updateEducationFail,
  deleteEducationSuccess,
  deleteEducationFail,
} from "./actions";

import {  createEducation,deleteEducation,
	getEducationDetails,getEducations
	,updateEducation,
 } from "../../_helper/services/education";
function* onGetEducations({payload: {page,pageSize}}) {
  try {
    const response = yield call(getEducations,{page,pageSize});
    yield put(getEducationsSuccess(response));
  } catch (error) {
    yield put(getEducationsFail(error.response));
  }
}

function* onGetEducationDetails({ payload: id }) {
  try {
    const response = yield call(getEducationDetails, id);
    yield put(getEducationsDetailsSuccess(response));
  } catch (error) {
    yield put(getEducationsDetailsFail(error.response));
  }
}
function* onCreateEducation({ payload }) {
	try {
	  const response = yield call(createEducation, payload);
	  yield put(createEducationSuccess(response));
    ShowSucess('Education Created successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(createEducationFail(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error)
    // const { status, statusText } = error.response;
    // const errorMessage = `${status} - ${statusText}`;
    //     toast.error(errorMessage, {
    //       position: 'top-right'
    //     });
	}
  }
  function* onUpdateEducation({ payload: { id},payload }) {
	try {
	  const response = yield call(updateEducation, id, payload);
	  yield put(updateEducationSuccess(response));
    ShowSucess('Education updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateEducationFail(error.response));
    console.log(error?.response?.data?.message);
    ShowError(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
	}
  }
  function* onDeleteEducation({ payload: ID }) {
	try {
	  const response = yield call(deleteEducation, ID);
	  yield put(deleteEducationSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteEducationFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_EDUCATIONS, onGetEducations);
  yield takeLatest(GET_EDUCATIONS_DETAILS, onGetEducationDetails);
  yield takeLatest(EDUCATION_CREATE, onCreateEducation);
  yield takeLatest(EDUCATION_UPDATE, onUpdateEducation);
  yield takeLatest(EDUCATION_DELETE, onDeleteEducation);
}

export default CartSaga;