// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPaygradeDetails =(id)=> get(`${baseURL+url.PAYGRADE_BASE}/${id}`);
export const getPaygrades =({page,pageSize})=> get(baseURL+url.PAYGRADE_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
// export const updatePaygrade = (ID,payload) => put(url.PAYGRADE_BASE+'/'+ID,payload);
export const updatePaygrade = (ID, payload) => put(`${baseURL}${url.PAYGRADE_BASE}/${ID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
// export const updatePositioStatus = (ID,payload) => put(url.PAYGRADE_BASE_UPDATE+'/'+ID, payload);
// export const updatePaygradeStatusList = (payload) => put(url.PAYGRADE_BASE_UPDATE,payload);
export const createPaygrade =(payload) => post(baseURL+url.PAYGRADE_BASE,payload);
//Post
// export const deletePaygrade = (id) => remove(baseURL+url.PAYGRADE_BASE+'/'+id);
export const deletePaygrade = (ID) => remove(`${baseURL}${url.PAYGRADE_BASE}/${ID}`);
