import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { TYPE_OF_CHARGES_CREATE, DELETE_TYPE_OF_CHARGES, GET_TYPE_OF_CHARGESES, GET_TYPE_OF_CHARGES_DETAILS, RESET, RESET_TYPE_OF_CHARGES_CREATE, UPDATE_TYPE_OF_CHARGES } from "./actionsTypes";

import {
    createTypeOfChargesSuccess,
    createTypeOfChargesFail,
    reset,
    resetCreateTypeOfCharges,
    getTypeOfChargesDetailsSuccess,
    getTypeOfChargesDetailsFail,
    updateTypeOfChargesSuccess,
    deleteTypeOfChargesSuccess,
    deleteTypeOfChargesFail,
    updateTypeOfChargesFail,
    getTypeOfChargesesSuccess,
    getTypeOfChargesesFail,
} from "./action";

import {
    createTypeOfCharges, deleteTypeOfCharges, getTypeOfChargesDetails, getTypeOfChargeses, updateTypeOfCharges
} from "../../../../_helper/services/EMR/typeOfCharges";


function* onCreateTypeOfCharges({ payload }) {
    try {
        const response = yield call(createTypeOfCharges, payload);
        yield put(createTypeOfChargesSuccess(response));
        ShowSucess("CBA Status Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createTypeOfChargesFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateTypeOfCharges());
    }
}

function* onGetTypeOfChargesDetails({ payload: id }) {
    try {
        const response = yield call(getTypeOfChargesDetails, id);
        yield put(getTypeOfChargesDetailsSuccess(response));
    } catch (error) {
        yield put(getTypeOfChargesDetailsFail(error.response));
    }
}

function* onGetTypeOfChargeses() {
    try {
        const response = yield call(getTypeOfChargeses);
        yield put(getTypeOfChargesesSuccess(response));
    } catch (error) {
        yield put(getTypeOfChargesesFail(error.response));
    }
}

function* onUpdateTypeOfCharges({ payload: { id }, payload }) {
    try {
        const response = yield call(updateTypeOfCharges, id, payload);
        yield put(updateTypeOfChargesSuccess(response));
        yield put(getTypeOfChargesDetailsSuccess(payload));
        ShowSucess(`CBA Status Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateTypeOfChargesFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteTypeOfCharges({ payload: id }) {
    try {
        const response = yield call(deleteTypeOfCharges, id);
        yield put(deleteTypeOfChargesSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteTypeOfChargesFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* TypeOfChargesSaga() {
    yield takeLatest(TYPE_OF_CHARGES_CREATE, onCreateTypeOfCharges);
    yield takeLatest(GET_TYPE_OF_CHARGES_DETAILS, onGetTypeOfChargesDetails);
    yield takeLatest(GET_TYPE_OF_CHARGESES, onGetTypeOfChargeses);
    yield takeLatest(UPDATE_TYPE_OF_CHARGES, onUpdateTypeOfCharges);
    yield takeLatest(DELETE_TYPE_OF_CHARGES, onDeleteTypeOfCharges);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_TYPE_OF_CHARGES_CREATE, resetCreateTypeOfCharges);
}

export default TypeOfChargesSaga;