import {
    TYPE_OF_TRAINING_CREATE,
    TYPE_OF_TRAINING_CREATE_FAIL,
    TYPE_OF_TRAINING_CREATE_SUCCESS,
    DELETE_TYPE_OF_TRAINING,
    DELETE_TYPE_OF_TRAINING_FAIL,
    DELETE_TYPE_OF_TRAINING_SUCCESS,
    GET_TYPE_OF_TRAININGES,
    GET_TYPE_OF_TRAININGES_FAIL,
    GET_TYPE_OF_TRAININGES_SUCCESS,
    GET_TYPE_OF_TRAINING_DETAILS,
    GET_TYPE_OF_TRAINING_DETAILS_FAIL,
    GET_TYPE_OF_TRAINING_DETAILS_SUCCESS,
    RESET,
    RESET_TYPE_OF_TRAINING_CREATE,
    RESET_TYPE_OF_TRAINING_DELETE,
    RESET_TYPE_OF_TRAINING_DETAILS,
    UPDATE_TYPE_OF_TRAINING,
    UPDATE_TYPE_OF_TRAINING_FAIL,
    UPDATE_TYPE_OF_TRAINING_SUCCESS,
} from "./actionsTypes";

const initialState = {
    typeOfTrainingList: [],
    typeOfTrainingDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const TypeOfTrainingReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case TYPE_OF_TRAINING_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case TYPE_OF_TRAINING_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case TYPE_OF_TRAINING_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_TYPE_OF_TRAININGES:
            state = { ...state, loading: true };
            break;
        case GET_TYPE_OF_TRAININGES_SUCCESS:
            state = { ...state, typeOfTrainingList: action.payload, loading: false };
            break;
        case GET_TYPE_OF_TRAININGES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_TYPE_OF_TRAINING_DETAILS:
            state = { ...state, detailsLoading: true, typeOfTrainingDetails: {} };
            break;
        case GET_TYPE_OF_TRAINING_DETAILS_SUCCESS:
            state = { ...state, typeOfTrainingDetails: action.payload, detailsLoading: false };
            break;
        case GET_TYPE_OF_TRAINING_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_TYPE_OF_TRAINING:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_TYPE_OF_TRAINING_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_TYPE_OF_TRAINING_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_TYPE_OF_TRAINING:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_TYPE_OF_TRAINING_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_TYPE_OF_TRAINING_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_TYPE_OF_TRAINING_DETAILS:
            state = { ...state, typeOfTrainingDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, typeOfTrainingList: [], createResult: {} };
            break;
        case RESET_TYPE_OF_TRAINING_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_TYPE_OF_TRAINING_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default TypeOfTrainingReducer;