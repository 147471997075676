import {
    GRIEVANCE_APPEAL_STATUS_CREATE,
    GRIEVANCE_APPEAL_STATUS_CREATE_FAIL,
    GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS,
    DELETE_GRIEVANCE_APPEAL_STATUS,
    DELETE_GRIEVANCE_APPEAL_STATUS_FAIL,
    DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
    GET_GRIEVANCE_APPEAL_STATUSES,
    GET_GRIEVANCE_APPEAL_STATUSES_FAIL,
    GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL,
    GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS,
    RESET,
    RESET_GRIEVANCE_APPEAL_STATUS_CREATE,
    RESET_GRIEVANCE_APPEAL_STATUS_DELETE,
    RESET_GRIEVANCE_APPEAL_STATUS_DETAILS,
    UPDATE_GRIEVANCE_APPEAL_STATUS,
    UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL,
    UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS,
} from "./actionsTypes";

const initialState = {
    grievanceAppealStatusLists: [],
    grievanceAppealStatusDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const GrievanceAppealStatusReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case GRIEVANCE_APPEAL_STATUS_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case GRIEVANCE_APPEAL_STATUS_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case GRIEVANCE_APPEAL_STATUS_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_GRIEVANCE_APPEAL_STATUSES:
            state = { ...state, loading: true };
            break;
        case GET_GRIEVANCE_APPEAL_STATUSES_SUCCESS:
            state = { ...state, grievanceAppealStatusLists: action.payload, loading: false };
            break;
        case GET_GRIEVANCE_APPEAL_STATUSES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_GRIEVANCE_APPEAL_STATUS_DETAILS:
            state = { ...state, detailsLoading: true, grievanceAppealStatusDetails: {} };
            break;
        case GET_GRIEVANCE_APPEAL_STATUS_DETAILS_SUCCESS:
            state = { ...state, grievanceAppealStatusDetails: action.payload, detailsLoading: false };
            break;
        case GET_GRIEVANCE_APPEAL_STATUS_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_GRIEVANCE_APPEAL_STATUS:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_GRIEVANCE_APPEAL_STATUS_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_GRIEVANCE_APPEAL_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_GRIEVANCE_APPEAL_STATUS:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_GRIEVANCE_APPEAL_STATUS_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_GRIEVANCE_APPEAL_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_GRIEVANCE_APPEAL_STATUS_DETAILS:
            state = { ...state, grievanceAppealStatusDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, grievanceAppealStatusLists: [], createResult: {} };
            break;
        case RESET_GRIEVANCE_APPEAL_STATUS_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_GRIEVANCE_APPEAL_STATUS_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default GrievanceAppealStatusReducer;