// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getEthicGroupDetails =(ID)=> get(`${baseURL+url.ETHICGROUP_BASE}/${ID}`);
export const getEthicGroups =({page,pageSize})=> get(baseURL+url.ETHICGROUP_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
export const updateEthicGroup = (ID,payload) => put(baseURL+url.ETHICGROUP_BASE+'/'+ID,payload);
// export const updatePositioStatus = (ID,payload) => put(url.ETHICGROUP_BASE_UPDATE+'/'+ID, payload);
// export const updateEthicGroupStatusList = (payload) => put(url.ETHICGROUP_BASE_UPDATE,payload);
export const createEthicGroup =(payload) => post(baseURL+url.ETHICGROUP_BASE,payload);
//Post
export const deleteEthicGroup = (ID) => remove(baseURL+url.ETHICGROUP_BASE+'/'+ID);