export const CHANGE_HEADER_FILTER = "CHANGE HEADER FILTER";

export function changeHeaderFilter(data) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_HEADER_FILTER,
            payload: {
                id:
                    data === null
                        ? null
                        : data.target !== undefined
                        ? data.target.name
                        : "",
                name:
                    data === null
                        ? null
                        : data.target !== undefined
                        ? data.target.value
                        : data,
                salary: data === null ? null : data.salary ? data.salary : null,
            },
        });
    };
}

