import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { EVAL_APPEAL_CREATE, DELETE_EVAL_APPEAL, GET_EVAL_APPEAL_DETAILS, RESET, RESET_EVAL_APPEAL_CREATE, UPDATE_EVAL_APPEAL } from "./actionsTypes";

import {
    createEvalAppealSuccess,
    createEvalAppealFail,
    reset,
    resetCreateEvalAppeal,
    getEvalAppealDetailsSuccess,
    getEvalAppealDetailsFail,
    updateEvalAppealSuccess,
    deleteEvalAppealSuccess,
    deleteEvalAppealFail,
    updateEvalAppealFail,
} from "./action";

import {
    createEvalAppeal, deleteEvalAppeal, getEvalAppealDetails, updateEvalAppeal
} from "../../../_helper/services/EMR/EvalAppeal";


function* onCreateEvalAppeal({ payload }) {
    try {
        const response = yield call(createEvalAppeal, payload);
        yield put(createEvalAppealSuccess(response));
        ShowSucess("Eval Appeal Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createEvalAppealFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateEvalAppeal());
    }
}

function* onGetEvalAppealDetails({ payload: id }) {
    try {
        const response = yield call(getEvalAppealDetails, id);
        yield put(getEvalAppealDetailsSuccess(response));
    } catch (error) {
        yield put(getEvalAppealDetailsFail(error.response));
    }
}
function* onUpdateEvalAppeal({ payload: { id }, payload }) {
    try {
        const response = yield call(updateEvalAppeal, id, payload);
        yield put(updateEvalAppealSuccess(response));
        yield put(getEvalAppealDetailsSuccess(payload));
        ShowSucess(`Eval Appeal Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateEvalAppealFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteEvalAppeal({ payload: id }) {
    try {
        const response = yield call(deleteEvalAppeal, id);
        yield put(deleteEvalAppealSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteEvalAppealFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* EvalAppealSaga() {
    yield takeLatest(EVAL_APPEAL_CREATE, onCreateEvalAppeal);
    yield takeLatest(GET_EVAL_APPEAL_DETAILS, onGetEvalAppealDetails);
    yield takeLatest(UPDATE_EVAL_APPEAL, onUpdateEvalAppeal);
    yield takeLatest(DELETE_EVAL_APPEAL, onDeleteEvalAppeal);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_EVAL_APPEAL_CREATE, resetCreateEvalAppeal);
}

export default EvalAppealSaga;