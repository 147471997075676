import {
	GET_PAYSTEPS, GET_PAYSTEPS_SUCCESS, GET_PAYSTEPS_FAIL,
	GET_PAYSTEPS_DETAILS, GET_PAYSTEPS_DETAILS_SUCCESS, GET_PAYSTEPS_DETAILS_FAIL,
	PAYSTEP_CREATE,PAYSTEP_CREATE_FAIL,PAYSTEP_CREATE_SUCCESS,
	PAYSTEP_DELETE,PAYSTEP_DELETE_FAIL,PAYSTEP_DELETE_SUCCESS,
	PAYSTEP_UPDATE,PAYSTEP_UPDATE_FAIL,PAYSTEP_UPDATE_SUCCESS,RESET, GET_PAYSTEPS_SETTING_FAIL, GET_PAYSTEPS_SETTING_SUCCESS, SAVE_PAYSTEPS_SETTING_FAIL, SAVE_PAYSTEPS_SETTING_SUCCESS, SAVE_PAYSTEPS_SETTING, GET_PAYSTEPS_SETTING, PAYSTEP_COPY, PAYSTEP_COPY_SUCCESS, PAYSTEP_COPY_FAIL, PAYSTEP_STATUS_CREATE, PAYSTEP_STATUS_CREATE_SUCCESS, PAYSTEP_STATUS_CREATE_FAIL, PAYSTEP_STATUS_UPDATE, PAYSTEP_STATUS_UPDATE_SUCCESS, PAYSTEP_STATUS_UPDATE_FAIL, PAYSTEP_STATUS_DELETE, PAYSTEP_STATUS_DELETE_SUCCESS, PAYSTEP_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPaysteps = (payload) => {
	return {
	  type: GET_PAYSTEPS,
	  payload: payload
	};
  };
  
  export const getPaystepsSuccess = (posts) => {
	return {
	  type: GET_PAYSTEPS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPaystepsFail = (error) => {
	return {
	  type: GET_PAYSTEPS_FAIL,
	  payload: error,
	};
  };
  
  export const getPaystepsDetails = (id) => {
	return {
	  type: GET_PAYSTEPS_DETAILS,
	  payload: id,
	};
  };
  
  export const getPaystepsDetailsSuccess = (post) => {
	return {
	  type: GET_PAYSTEPS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPaystepsDetailsFail = (error) => {
	return {
	  type: GET_PAYSTEPS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPaystep =(payload)=>{
	return {
		type: PAYSTEP_CREATE,
		payload: payload
	}
  }
  export const createPaystepSuccess =(response)=>{
	return {
		type: PAYSTEP_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPaystepFail =(error)=>{
	return {
		type: PAYSTEP_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePaystep =(payload)=>{
	
	return {
		type: PAYSTEP_UPDATE,
		payload: payload
	}
  }
  export const updatePaystepSuccess =(response)=>{
	return {
		type: PAYSTEP_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePaystepFail =(error)=>{
	return {
		type: PAYSTEP_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePaystep =(payload)=>{
	console.log("payload:", payload);
	return {
		type: PAYSTEP_DELETE,
		payload: payload  ,}}
  export const deletePaystepSuccess =(response)=>{
	return {
		type: PAYSTEP_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePaystepFail =(error)=>{
	return {
		type: PAYSTEP_DELETE_FAIL,
		payload: error
	}
  }
  export const reset=()=>{
	return {
		type:RESET,
	}
  }

