import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { DELETE_MIS_DATA_LOG, GET_MIS_DATA_LOG_DETAILS, MIS_DATA_LOG_CREATE, RESET, RESET_MIS_DATA_LOG_CREATE, UPDATE_MIS_DATA_LOG } from "./actionsTypes";

import {
    createMISDataLogSuccess,
    createMISDataLogFail,
    resetCreateMISDataLog,
    reset,
    getMISDataLogDetailsSuccess,
    getMISDataLogDetailsFail,
    updateMISDataLogSuccess,
    updateMISDataLogFail,
    deleteMISDataLogSuccess,
    deleteMISDataLogFail,
} from "./action";

import {
    createMISDataLog, deleteMISDataLog, getMISDataLogDetails, updateMISDataLog
} from "../../../_helper/services/DrugTesting/MISDataLog";


function* onCreateMISDataLog({ payload }) {
    try {
        const response = yield call(createMISDataLog, payload);
        yield put(createMISDataLogSuccess(response));
        ShowSucess("MIS Data Log Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createMISDataLogFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateMISDataLog());
    }
}

function* onGetMISDataLogDetails({ payload: id }) {
    try {
        const response = yield call(getMISDataLogDetails, id);
        yield put(getMISDataLogDetailsSuccess(response));
    } catch (error) {
        yield put(getMISDataLogDetailsFail(error.response));
    }
}
function* onUpdateMISDataLog({ payload: { id }, payload }) {
    try {
        const response = yield call(updateMISDataLog, id, payload);
        yield put(updateMISDataLogSuccess(response));
        yield put(getMISDataLogDetailsSuccess(payload));
        ShowSucess(`MIS Data Log updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateMISDataLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteMISDataLog({ payload: id }) {
    try {
        const response = yield call(deleteMISDataLog, id);
        yield put(deleteMISDataLogSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteMISDataLogFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* MISDataLogSaga() {
    yield takeLatest(MIS_DATA_LOG_CREATE, onCreateMISDataLog);
    yield takeLatest(RESET_MIS_DATA_LOG_CREATE, resetCreateMISDataLog);
    yield takeLatest(GET_MIS_DATA_LOG_DETAILS, onGetMISDataLogDetails);
    yield takeLatest(UPDATE_MIS_DATA_LOG, onUpdateMISDataLog);
    yield takeLatest(DELETE_MIS_DATA_LOG, onDeleteMISDataLog);
    yield takeLatest(RESET, reset);
}

export default MISDataLogSaga;