export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_FAIL = "GET_VENDORS_FAIL";

export const GET_VENDORS_DETAILS = "GET_VENDORS_DETAILS";
export const GET_VENDORS_DETAILS_SUCCESS = "GET_VENDORS_DETAILS_SUCCESS";
export const GET_VENDORS_DETAILS_FAIL = "GET_VENDORS_DETAILS_FAIL";

export const GET_ALL_VENDOR_DETAILS = "GET_ALL_VENDOR_DETAILS";
export const GET_ALL_VENDOR_DETAILS_SUCCESS = "GET_ALL_VENDOR_DETAILS_SUCCESS";
export const GET_ALL_VENDOR_DETAILS_FAIL = "GET_ALL_VENDOR_DETAILS_FAIL";

export const VENDOR_UPDATE= "VENDOR_UPDATE";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAIL = "VENDOR_UPDATE_FAIL";

export const VENDOR_CREATE= "VENDOR_CREATE";
export const VENDOR_CREATE_SUCCESS = "VENDOR_CREATE_SUCCESS";
export const VENDOR_CREATE_FAIL = "VENDOR_CREATE_FAIL";

export const VENDOR_DELETE= "VENDOR_DELETE";
export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAIL = "VENDOR_DELETE_FAIL";
export const RESET = "RESET";

