import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";


import { GET_ETHICGROUPS, GET_ETHICGROUPS_DETAILS,  ETHICGROUP_CREATE, ETHICGROUP_DELETE,
   ETHICGROUP_UPDATE} from "./actionTypes";

import {
  getEthicGroupsSuccess,
  getEthicGroupsFail,
  getEthicGroupsDetailsSuccess,
  getEthicGroupsDetailsFail,
  createEthicGroupSuccess,
  createEthicGroupFail,
  updateEthicGroupSuccess,
  updateEthicGroupFail,
  deleteEthicGroupSuccess,
  deleteEthicGroupFail,
  
} from "./actions";

import {  createEthicGroup,deleteEthicGroup,
	getEthicGroupDetails,getEthicGroups
	,updateEthicGroup,
 } from "../../_helper/services/ethicgroup";
import { ToastHeader } from 'reactstrap';
import { ShowError,ShowSucess } from '../../_helper/helper';
function* onGetEthicGroups({payload: {page,pageSize}}) {
  try {
    const response = yield call(getEthicGroups,{page,pageSize});
    yield put(getEthicGroupsSuccess(response));
  } catch (error) {
    yield put(getEthicGroupsFail(error.response));
  }
}

function* onGetEthicGroupDetails({ payload: id }) {
  try {
    const response = yield call(getEthicGroupDetails, id);
    yield put(getEthicGroupsDetailsSuccess(response));
  } catch (error) {
    yield put(getEthicGroupsDetailsFail(error.response));
  }
}
function* onCreateEthicGroup({ payload }) {
	try {
	  const response = yield call(createEthicGroup, payload);
	  yield put(createEthicGroupSuccess(response));
    ShowSucess('EthicGroup Created successfully', {
      position: 'top-right'
    });
    // yield put(push('/EthicGroup-type'));
    
	} catch (error) {
	  yield put(createEthicGroupFail(error.response));
	  ShowError(error?.response?.data?.message);
  }
}
  function* onUpdateEthicGroup({ payload: { id}, payload }) {
	try {
	  const response = yield call(updateEthicGroup, id, payload);
	  yield put(updateEthicGroupSuccess(response));
    ShowSucess('EthicGroup Details updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
    
	  yield put(updateEthicGroupFail(error.response));
    
	  ShowError(error?.response?.data?.message);
	}
  }
  function* onDeleteEthicGroup({ payload: ID }) {
    try {
      const response = yield call(deleteEthicGroup, ID);
      yield put(deleteEthicGroupSuccess(response));
      ShowSucess("Deleted Successfully",{
        position:"top-right"
      })
    } catch (error) {
      yield put(deleteEthicGroupFail(error.response));
	  ShowError(error?.response?.data?.message);
    }
    }

function* CartSaga() {
  yield takeLatest(GET_ETHICGROUPS, onGetEthicGroups);
  yield takeLatest(GET_ETHICGROUPS_DETAILS, onGetEthicGroupDetails);
  yield takeLatest(ETHICGROUP_CREATE, onCreateEthicGroup);
  yield takeLatest(ETHICGROUP_UPDATE, onUpdateEthicGroup);
  yield takeLatest(ETHICGROUP_DELETE, onDeleteEthicGroup);
}

export default CartSaga;