import {
    LTD_CREATE,
    LTD_CREATE_FAIL,
    LTD_CREATE_SUCCESS,
    DELETE_LTD,
    DELETE_LTD_FAIL,
    DELETE_LTD_SUCCESS,
    GET_LTD_DETAILS,
    GET_LTD_DETAILS_FAIL,
    GET_LTD_DETAILS_SUCCESS,
    RESET,
    RESET_LTD_CREATE,
    RESET_LTD_DELETE,
    RESET_LTD_DETAILS,
    UPDATE_LTD,
    UPDATE_LTD_FAIL,
    UPDATE_LTD_SUCCESS,
} from "./actionsTypes";

const initialState = {
    LTDList: [],
    LTDDetails: [],
    createResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    deleteResult: {},
    error: {
        message: "",
    },
};

const LTDReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case LTD_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case LTD_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case LTD_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_LTD_DETAILS:
            state = { ...state, detailsLoading: true, LTDDetails: {} };
            break;
        case GET_LTD_DETAILS_SUCCESS:
            state = { ...state, LTDDetails: action.payload, detailsLoading: false };
            break;
        case GET_LTD_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_LTD:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_LTD_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_LTD_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_LTD:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_LTD_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_LTD_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_LTD_DETAILS:
            state = { ...state, LTDDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, LTDList: [], createResult: {} };
            break;
        case RESET_LTD_CREATE:
            state = { ...state, createResult: {} };
            break;
        case RESET_LTD_DELETE:
            state = { ...state, deleteResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default LTDReducer;