import {
    EVAL_APPEAL_REASON_CREATE, EVAL_APPEAL_REASON_CREATE_FAIL, EVAL_APPEAL_REASON_CREATE_SUCCESS,
    DELETE_EVAL_APPEAL_REASON,
    DELETE_EVAL_APPEAL_REASON_FAIL,
    DELETE_EVAL_APPEAL_REASON_SUCCESS,
    GET_EVAL_APPEAL_REASONES,
    GET_EVAL_APPEAL_REASONES_FAIL,
    GET_EVAL_APPEAL_REASONES_SUCCESS,
    GET_EVAL_APPEAL_REASON_DETAILS,
    GET_EVAL_APPEAL_REASON_DETAILS_FAIL,
    GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS,
    RESET,
    RESET_EVAL_APPEAL_REASON_CREATE,
    RESET_EVAL_APPEAL_REASON_DELETE,
    RESET_EVAL_APPEAL_REASON_DETAILS,
    UPDATE_EVAL_APPEAL_REASON,
    UPDATE_EVAL_APPEAL_REASON_FAIL,
    UPDATE_EVAL_APPEAL_REASON_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createEvalAppealReason = (payload) => {
    return {
        type: EVAL_APPEAL_REASON_CREATE,
        payload: payload
    }
};
export const createEvalAppealReasonSuccess = (response) => {
    return {
        type: EVAL_APPEAL_REASON_CREATE_SUCCESS,
        payload: response
    }
};
export const createEvalAppealReasonFail = (error) => {
    return {
        type: EVAL_APPEAL_REASON_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getEvalAppealReasonDetails = (id) => {
    return {
        type: GET_EVAL_APPEAL_REASON_DETAILS,
        payload: id,
    };
};
export const getEvalAppealReasonDetailsSuccess = (data) => {
    return {
        type: GET_EVAL_APPEAL_REASON_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getEvalAppealReasonDetailsFail = (error) => {
    return {
        type: GET_EVAL_APPEAL_REASON_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getEvalAppealReasones = () => {
    return {
        type: GET_EVAL_APPEAL_REASONES,
    };
};
export const getEvalAppealReasonesSuccess = (data) => {
    return {
        type: GET_EVAL_APPEAL_REASONES_SUCCESS,
        payload: data,
    };
};
export const getEvalAppealReasonesFail = (error) => {
    return {
        type: GET_EVAL_APPEAL_REASONES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateEvalAppealReason = (payload) => {

    return {
        type: UPDATE_EVAL_APPEAL_REASON,
        payload: payload
    }
};
export const updateEvalAppealReasonSuccess = (response) => {
    return {
        type: UPDATE_EVAL_APPEAL_REASON_SUCCESS,
        payload: response
    }
};
export const updateEvalAppealReasonFail = (error) => {
    return {
        type: UPDATE_EVAL_APPEAL_REASON_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteEvalAppealReason = (id) => {
    return {
        type: DELETE_EVAL_APPEAL_REASON,
        payload: id
    }
};
export const deleteEvalAppealReasonSuccess = (response) => {
    return {
        type: DELETE_EVAL_APPEAL_REASON_SUCCESS,
        payload: response
    }
};
export const deleteEvalAppealReasonFail = (error) => {
    return {
        type: DELETE_EVAL_APPEAL_REASON_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateEvalAppealReason = () => {
    return {
        type: RESET_EVAL_APPEAL_REASON_CREATE
    }
};

export const resetEvalAppealReasonDetails = () => {
    return {
        type: RESET_EVAL_APPEAL_REASON_DETAILS
    }
};

export const resetEvalAppealReasonDeleteData = () => {
    return {
        type: RESET_EVAL_APPEAL_REASON_DELETE
    }
};