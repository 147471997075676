import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import {
    GET_BILLING_INVOICES, GET_BILLING_INVOICES_DETAILS, BILLING_INVOICE_CREATE, BILLING_INVOICE_DELETE,
    BILLING_INVOICE_UPDATE, RESET, GET_BILLING_INVOICE_STATUS_LISTS, RESET_BILLING_INVOICE_CREATE, RESET_BILLING_INVOICES_DETAILS
} from "./actionsTypes";

import {
    getBillingInvoicesSuccess,
    getBillingInvoicesFail,
    getBillingInvoicesDetailsSuccess,
    getBillingInvoicesDetailsFail,
    createBillingInvoiceSuccess,
    createBillingInvoiceFail,
    updateBillingInvoiceSuccess,
    updateBillingInvoiceFail,
    deleteBillingInvoiceSuccess,
    deleteBillingInvoiceFail,
    reset,
    resetCreateBillingInvoice,

    getBillingInvoiceStatusListFail,
    getBillingInvoiceStatusListSuccess,
    resetBillingInvoicesDetails
} from "./action";

import {
    getBillingInvoiceDetails,
    createBillingInvoice,
    updateBillingInvoice,
    deleteBillingInvoice,

    getBillingInvoiceStatusLists
} from "../../../_helper/services/DrugTesting/billingInvoice";

function* onGetBillingInvoices({ payload: { page, pageSize } }) {
    try {
        const response = yield call(getBillingInvoiceDetails, { page, pageSize });
        yield put(getBillingInvoicesSuccess(response));
    } catch (error) {
        yield put(getBillingInvoicesFail(error.response));
    }
}

function* onGetBillingInvoiceDetails({ payload: id }) {
    try {
        const response = yield call(getBillingInvoiceDetails, id);
        yield put(getBillingInvoicesDetailsSuccess(response));
    } catch (error) {
        yield put(getBillingInvoicesDetailsFail(error.response));
    }
}

function* onCreateBillingInvoice({ payload }) {
    try {
        const response = yield call(createBillingInvoice, payload);
        yield put(createBillingInvoiceSuccess(response));
        ShowSucess("Billing Invoice Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createBillingInvoiceFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateBillingInvoice()); // Dispatch RESET action after handling createBillingInvoice
    }
}

function* onUpdateBillingInvoice({ payload: { id }, payload }) {
    try {
        const response = yield call(updateBillingInvoice, id, payload);
        yield put(updateBillingInvoiceSuccess(response));
        yield put(getBillingInvoicesDetailsSuccess(payload));
        ShowSucess('Billing Invoice Details updated successfully', { position: 'top-right' });
    } catch (error) {
        yield put(updateBillingInvoiceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteBillingInvoice({ payload: id }) {
    try {
        const response = yield call(deleteBillingInvoice, id);
        yield put(deleteBillingInvoiceSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteBillingInvoiceFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onGetBillingInvoiceStatusLists() {
    try {
        const response = yield call(getBillingInvoiceStatusLists);
        yield put(getBillingInvoiceStatusListSuccess(response));
    } catch (error) {
        yield put(getBillingInvoiceStatusListFail(error.response));
    }
}

function* BillingInvoiceSaga() {
    yield takeLatest(GET_BILLING_INVOICES, onGetBillingInvoices);
    yield takeLatest(GET_BILLING_INVOICES_DETAILS, onGetBillingInvoiceDetails);
    yield takeLatest(BILLING_INVOICE_CREATE, onCreateBillingInvoice);
    yield takeLatest(BILLING_INVOICE_UPDATE, onUpdateBillingInvoice);
    yield takeLatest(BILLING_INVOICE_DELETE, onDeleteBillingInvoice);

    yield takeLatest(GET_BILLING_INVOICE_STATUS_LISTS, onGetBillingInvoiceStatusLists);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_BILLING_INVOICE_CREATE, resetCreateBillingInvoice);
}

export default BillingInvoiceSaga;