export const GET_PAYGRADES = "GET_PAYGRADES";
export const GET_PAYGRADES_SUCCESS = "GET_PAYGRADES_SUCCESS";
export const GET_PAYGRADES_FAIL = "GET_PAYGRADES_FAIL";

export const GET_PAYGRADES_DETAILS = "GET_PAYGRADES_DETAILS";
export const GET_PAYGRADES_DETAILS_SUCCESS = "GET_PAYGRADES_DETAILS_SUCCESS";
export const GET_PAYGRADES_DETAILS_FAIL = "GET_PAYGRADES_DETAILS_FAIL";

export const PAYGRADE_UPDATE= "PAYGRADE_UPDATE";
export const PAYGRADE_UPDATE_SUCCESS = "PAYGRADE_UPDATE_SUCCESS";
export const PAYGRADE_UPDATE_FAIL = "PAYGRADE_UPDATE_FAIL";

export const PAYGRADE_CREATE= "PAYGRADE_CREATE";
export const PAYGRADE_CREATE_SUCCESS = "PAYGRADE_CREATE_SUCCESS";
export const PAYGRADE_CREATE_FAIL = "PAYGRADE_CREATE_FAIL";

export const PAYGRADE_DELETE= "PAYGRADE_DELETE";
export const PAYGRADE_DELETE_SUCCESS = "PAYGRADE_DELETE_SUCCESS";
export const PAYGRADE_DELETE_FAIL = "PAYGRADE_DELETE_FAIL";
export const RESET = "RESET";

