import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getHistoryDetails =(id)=> get(`${baseURL+url.HISTORY_BASE}/${id}`);
export const getHistorys =({page,pageSize})=> get(baseURL+url.HISTORY_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);

export const updateHistory = (ID, payload) => put(`${baseURL}${url.HISTORY_BASE}/${ID}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });


export const createHistory =(payload) => post(baseURL+url.HISTORY_BASE,payload);

export const deleteHistory = (id) => remove(baseURL+url.HISTORY_BASE+'/'+id);



