export const CARMENS_OWN_DT_LOG_CREATE = "CARMENS_OWN_DT_LOG_CREATE";
export const CARMENS_OWN_DT_LOG_CREATE_SUCCESS = "CARMENS_OWN_DT_LOG_CREATE_SUCCESS";
export const CARMENS_OWN_DT_LOG_CREATE_FAIL = "CARMENS_OWN_DT_LOG_CREATE_FAIL";

export const GET_CARMENS_OWN_DT_LOG_DETAILS = "GET_CARMENS_OWN_DT_LOG_DETAILS";
export const GET_CARMENS_OWN_DT_LOG_DETAILS_SUCCESS = "GET_CARMENS_OWN_DT_LOG_DETAILS_SUCCESS";
export const GET_CARMENS_OWN_DT_LOG_DETAILS_FAIL = "GET_CARMENS_OWN_DT_LOG_DETAILS_FAIL";

export const UPDATE_CARMENS_OWN_DT_LOG = "UPDATE_CARMENS_OWN_DT_LOG";
export const UPDATE_CARMENS_OWN_DT_LOG_SUCCESS = "UPDATE_CARMENS_OWN_DT_LOG_SUCCESS";
export const UPDATE_CARMENS_OWN_DT_LOG_FAIL = "UPDATE_CARMENS_OWN_DT_LOG_FAIL";

export const DELETE_CARMENS_OWN_DT_LOG = "DELETE_CARMENS_OWN_DT_LOG";
export const DELETE_CARMENS_OWN_DT_LOG_SUCCESS = "DELETE_CARMENS_OWN_DT_LOG_SUCCESS";
export const DELETE_CARMENS_OWN_DT_LOG_FAIL = "DELETE_CARMENS_OWN_DT_LOG_FAIL";

export const RESET = "RESET";
export const RESET_CARMENS_OWN_DT_LOG_CREATE = "RESET_CARMENS_OWN_DT_LOG_CREATE";
export const RESET_CARMENS_OWN_DT_LOG_DETAILS = "RESET_CARMENS_OWN_DT_LOG_DETAILS";
export const RESET_CARMENS_OWN_DT_LOG_DELETE = "RESET_CARMENS_OWN_DT_LOG_DELETE";