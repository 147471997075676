import {
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER,
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
    DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
    DEPARTMENT_JV_CONTROL_NUMBER_CREATE,
    DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL,
    DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL,
    GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS,
    RESET,
    RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE,
    RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL,
    UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS,
} from "./actionsTypes";

const initialState = {
    departmentJVControlNumberLists: [],
    departmentJVControlNumberDetails: [],
    createResult: {},
    deleteResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    error: {
        message: "",
    },
};

const DepartmentJVControlNumberReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case DEPARTMENT_JV_CONTROL_NUMBER_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case DEPARTMENT_JV_CONTROL_NUMBER_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case DEPARTMENT_JV_CONTROL_NUMBER_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;

        case GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS:
            state = { ...state, detailsLoading: true, departmentJVControlNumberDetails: {} };
            break;
        case GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_SUCCESS:
            state = { ...state, departmentJVControlNumberDetails: action.payload, detailsLoading: false };
            break;
        case GET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_DEPARTMENT_JV_CONTROL_NUMBER:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_DEPARTMENT_JV_CONTROL_NUMBER:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_DEPARTMENT_JV_CONTROL_NUMBER_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_DEPARTMENT_JV_CONTROL_NUMBER_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_DEPARTMENT_JV_CONTROL_NUMBER_DETAILS:
            state = { ...state, departmentJVControlNumberDetails: {}, detailsLoading: false };
            break;

        case RESET:
            state = { ...state, departmentJVControlNumberLists: [], createResult: {} };
            break;
        case RESET_DEPARTMENT_JV_CONTROL_NUMBER_CREATE:
            state = { ...state, createResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DepartmentJVControlNumberReducer;