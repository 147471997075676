import {
    DELETE_DOA_HR_JV_INTERNAL_LOG,
    DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL,
    DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
    DOA_HR_JV_INTERNAL_LOG_CREATE, DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL, DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL,
    GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS,
    RESET,
    RESET_DOA_HR_JV_INTERNAL_LOG_CREATE,
    RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
    UPDATE_DOA_HR_JV_INTERNAL_LOG,
    UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL,
    UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createDOAHRJVInternalLog = (payload) => {
    return {
        type: DOA_HR_JV_INTERNAL_LOG_CREATE,
        payload: payload
    }
};
export const createDOAHRJVInternalLogSuccess = (response) => {
    return {
        type: DOA_HR_JV_INTERNAL_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createDOAHRJVInternalLogFail = (error) => {
    return {
        type: DOA_HR_JV_INTERNAL_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getDOAHRJVInternalLogDetails = (id) => {
    return {
        type: GET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
        payload: id,
    };
};
export const getDOAHRJVInternalLogDetailsSuccess = (data) => {
    return {
        type: GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getDOAHRJVInternalLogDetailsFail = (error) => {
    return {
        type: GET_DOA_HR_JV_INTERNAL_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateDOAHRJVInternalLog = (payload) => {

    return {
        type: UPDATE_DOA_HR_JV_INTERNAL_LOG,
        payload: payload
    }
};
export const updateDOAHRJVInternalLogSuccess = (response) => {
    return {
        type: UPDATE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
        payload: response
    }
};
export const updateDOAHRJVInternalLogFail = (error) => {
    return {
        type: UPDATE_DOA_HR_JV_INTERNAL_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteDOAHRJVInternalLog = (id) => {
    return {
        type: DELETE_DOA_HR_JV_INTERNAL_LOG,
        payload: id
    }
};
export const deleteDOAHRJVInternalLogSuccess = (response) => {
    return {
        type: DELETE_DOA_HR_JV_INTERNAL_LOG_SUCCESS,
        payload: response
    }
};
export const deleteDOAHRJVInternalLogFail = (error) => {
    return {
        type: DELETE_DOA_HR_JV_INTERNAL_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateDOAHRJVInternalLog = () => {
    return {
        type: RESET_DOA_HR_JV_INTERNAL_LOG_CREATE
    }
};
export const resetDOAHRJVInternalLogDetails = () => {
    return {
        type: RESET_DOA_HR_JV_INTERNAL_LOG_DETAILS,
    };
};