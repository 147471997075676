export const GET_EDUCATIONS = "GET_EDUCATIONS";
export const GET_EDUCATIONS_SUCCESS = "GET_EDUCATIONS_SUCCESS";
export const GET_EDUCATIONS_FAIL = "GET_EDUCATIONS_FAIL";

export const GET_EDUCATIONS_DETAILS = "GET_EDUCATIONS_DETAILS";
export const GET_EDUCATIONS_DETAILS_SUCCESS = "GET_EDUCATIONS_DETAILS_SUCCESS";
export const GET_EDUCATIONS_DETAILS_FAIL = "GET_EDUCATIONS_DETAILS_FAIL";

export const EDUCATION_UPDATE= "EDUCATION_UPDATE";
export const EDUCATION_UPDATE_SUCCESS = "EDUCATION_UPDATE_SUCCESS";
export const EDUCATION_UPDATE_FAIL = "EDUCATION_UPDATE_FAIL";

export const EDUCATION_CREATE= "EDUCATION_CREATE";
export const EDUCATION_CREATE_SUCCESS = "EDUCATION_CREATE_SUCCESS";
export const EDUCATION_CREATE_FAIL = "EDUCATION_CREATE_FAIL";

export const EDUCATION_DELETE= "EDUCATION_DELETE";
export const EDUCATION_DELETE_SUCCESS = "EDUCATION_DELETE_SUCCESS";
export const EDUCATION_DELETE_FAIL = "EDUCATION_DELETE_FAIL";
export const RESET = "RESET";

