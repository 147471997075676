import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../../_helper/helper';

import { EVAL_APPEAL_REASON_CREATE, DELETE_EVAL_APPEAL_REASON, GET_EVAL_APPEAL_REASONES, GET_EVAL_APPEAL_REASON_DETAILS, RESET, RESET_EVAL_APPEAL_REASON_CREATE, UPDATE_EVAL_APPEAL_REASON } from "./actionsTypes";

import {
    createEvalAppealReasonSuccess,
    createEvalAppealReasonFail,
    reset,
    resetCreateEvalAppealReason,
    getEvalAppealReasonDetailsSuccess,
    getEvalAppealReasonDetailsFail,
    updateEvalAppealReasonSuccess,
    deleteEvalAppealReasonSuccess,
    deleteEvalAppealReasonFail,
    updateEvalAppealReasonFail,
    getEvalAppealReasonesSuccess,
    getEvalAppealReasonesFail,
} from "./action";

import {
    createEvalAppealReason, deleteEvalAppealReason, getEvalAppealReasonDetails, getEvalAppealReasones, updateEvalAppealReason
} from "../../../../_helper/services/EMR/EvalAppealReason";


function* onCreateEvalAppealReason({ payload }) {
    try {
        const response = yield call(createEvalAppealReason, payload);
        yield put(createEvalAppealReasonSuccess(response));
        ShowSucess("Eval Appeal Reason Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createEvalAppealReasonFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateEvalAppealReason());
    }
}

function* onGetEvalAppealReasonDetails({ payload: id }) {
    try {
        const response = yield call(getEvalAppealReasonDetails, id);
        yield put(getEvalAppealReasonDetailsSuccess(response));
    } catch (error) {
        yield put(getEvalAppealReasonDetailsFail(error.response));
    }
}

function* onGetEvalAppealReasones() {
    try {
        const response = yield call(getEvalAppealReasones);
        yield put(getEvalAppealReasonesSuccess(response));
    } catch (error) {
        yield put(getEvalAppealReasonesFail(error.response));
    }
}

function* onUpdateEvalAppealReason({ payload: { id }, payload }) {
    try {
        const response = yield call(updateEvalAppealReason, id, payload);
        yield put(updateEvalAppealReasonSuccess(response));
        yield put(getEvalAppealReasonDetailsSuccess(payload));
        ShowSucess(`Eval Appeal Reason Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateEvalAppealReasonFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteEvalAppealReason({ payload: id }) {
    try {
        const response = yield call(deleteEvalAppealReason, id);
        yield put(deleteEvalAppealReasonSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteEvalAppealReasonFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* EvalAppealReasonSaga() {
    yield takeLatest(EVAL_APPEAL_REASON_CREATE, onCreateEvalAppealReason);
    yield takeLatest(GET_EVAL_APPEAL_REASON_DETAILS, onGetEvalAppealReasonDetails);
    yield takeLatest(GET_EVAL_APPEAL_REASONES, onGetEvalAppealReasones);
    yield takeLatest(UPDATE_EVAL_APPEAL_REASON, onUpdateEvalAppealReason);
    yield takeLatest(DELETE_EVAL_APPEAL_REASON, onDeleteEvalAppealReason);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_EVAL_APPEAL_REASON_CREATE, resetCreateEvalAppealReason);
}

export default EvalAppealReasonSaga;