import {
    GRIEVANCE_REQUEST_CREATE, GRIEVANCE_REQUEST_CREATE_FAIL, GRIEVANCE_REQUEST_CREATE_SUCCESS,
    DELETE_GRIEVANCE_REQUEST,
    DELETE_GRIEVANCE_REQUEST_FAIL,
    DELETE_GRIEVANCE_REQUEST_SUCCESS,
    GET_GRIEVANCE_REQUEST_DETAILS,
    GET_GRIEVANCE_REQUEST_DETAILS_FAIL,
    GET_GRIEVANCE_REQUEST_DETAILS_SUCCESS,
    RESET,
    RESET_GRIEVANCE_REQUEST_CREATE,
    RESET_GRIEVANCE_REQUEST_DELETE,
    RESET_GRIEVANCE_REQUEST_DETAILS,
    UPDATE_GRIEVANCE_REQUEST,
    UPDATE_GRIEVANCE_REQUEST_FAIL,
    UPDATE_GRIEVANCE_REQUEST_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createGrievanceRequest = (payload) => {
    return {
        type: GRIEVANCE_REQUEST_CREATE,
        payload: payload
    }
};
export const createGrievanceRequestSuccess = (response) => {
    return {
        type: GRIEVANCE_REQUEST_CREATE_SUCCESS,
        payload: response
    }
};
export const createGrievanceRequestFail = (error) => {
    return {
        type: GRIEVANCE_REQUEST_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getGrievanceRequestDetails = (id) => {
    return {
        type: GET_GRIEVANCE_REQUEST_DETAILS,
        payload: id,
    };
};
export const getGrievanceRequestDetailsSuccess = (data) => {
    return {
        type: GET_GRIEVANCE_REQUEST_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getGrievanceRequestDetailsFail = (error) => {
    return {
        type: GET_GRIEVANCE_REQUEST_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateGrievanceRequest = (payload) => {

    return {
        type: UPDATE_GRIEVANCE_REQUEST,
        payload: payload
    }
};
export const updateGrievanceRequestSuccess = (response) => {
    return {
        type: UPDATE_GRIEVANCE_REQUEST_SUCCESS,
        payload: response
    }
};
export const updateGrievanceRequestFail = (error) => {
    return {
        type: UPDATE_GRIEVANCE_REQUEST_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteGrievanceRequest = (id) => {
    return {
        type: DELETE_GRIEVANCE_REQUEST,
        payload: id
    }
};
export const deleteGrievanceRequestSuccess = (response) => {
    return {
        type: DELETE_GRIEVANCE_REQUEST_SUCCESS,
        payload: response
    }
};
export const deleteGrievanceRequestFail = (error) => {
    return {
        type: DELETE_GRIEVANCE_REQUEST_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreateGrievanceRequest = () => {
    return {
        type: RESET_GRIEVANCE_REQUEST_CREATE
    }
};

export const resetGrievanceRequestDetails = () => {
    return {
        type: RESET_GRIEVANCE_REQUEST_DETAILS
    }
};

export const resetGrievanceRequestDeleteData = () => {
    return {
        type: RESET_GRIEVANCE_REQUEST_DELETE
    }
};