export const MEDIA_SOURCE_CREATE = "MEDIA_SOURCE_CREATE";
export const MEDIA_SOURCE_CREATE_SUCCESS = "MEDIA_SOURCE_CREATE_SUCCESS";
export const MEDIA_SOURCE_CREATE_FAIL = "MEDIA_SOURCE_CREATE_FAIL";

export const GET_MEDIA_SOURCE_DETAILS = "GET_MEDIA_SOURCE_DETAILS";
export const GET_MEDIA_SOURCE_DETAILS_SUCCESS = "GET_MEDIA_SOURCE_DETAILS_SUCCESS";
export const GET_MEDIA_SOURCE_DETAILS_FAIL = "GET_MEDIA_SOURCE_DETAILS_FAIL";

export const GET_MEDIA_SOURCES = "GET_MEDIA_SOURCES";
export const GET_MEDIA_SOURCES_SUCCESS = "GET_MEDIA_SOURCES_SUCCESS";
export const GET_MEDIA_SOURCES_FAIL = "GET_MEDIA_SOURCES_FAIL";

export const UPDATE_MEDIA_SOURCE = "UPDATE_MEDIA_SOURCE";
export const UPDATE_MEDIA_SOURCE_SUCCESS = "UPDATE_MEDIA_SOURCE_SUCCESS";
export const UPDATE_MEDIA_SOURCE_FAIL = "UPDATE_MEDIA_SOURCE_FAIL";

export const DELETE_MEDIA_SOURCE = "DELETE_MEDIA_SOURCE";
export const DELETE_MEDIA_SOURCE_SUCCESS = "DELETE_MEDIA_SOURCE_SUCCESS";
export const DELETE_MEDIA_SOURCE_FAIL = "DELETE_MEDIA_SOURCE_FAIL";

export const RESET = "RESET";
export const RESET_MEDIA_SOURCE_CREATE = "RESET_MEDIA_SOURCE_CREATE";
export const RESET_MEDIA_SOURCE_DETAILS = "RESET_MEDIA_SOURCE_DETAILS";
export const RESET_MEDIA_SOURCE_DELETE = "RESET_MEDIA_SOURCE_DELETE";