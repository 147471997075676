import * as Actions from '../actions';

const initialState = {
    deathClaimDetail: {
        currentLifeInsuranceDetail: {
            ssn:'',
            fName:'',
            lName:'',
            mi:'',
            dob:'',
            gender:'',
            relationship:'',
            benefStatus:'',
            benefPer:'',
            mailingAdd:'',
            employClass:'',
            planName:'',
            planEffectDate:'2020-12-22',
            planEndDate:'2020-12-28',
            planCode:'',
            planRemarks:'',
            activeCoverage:'30K',
            retireeCoverage:'10K'
        },
        otherInfo: {
            detail:{
                reportedBy:'',
                contactInfo:'',
                reportedDate:'2020-12-28T10:30',
                deceasedClass:'',
                deceased_ssn:'',
                deceased_fName:'',
                deceased_lName:'',
                deceased_mi:'',
                deceased_department:'',
                deceased_dod:'2020-11-20',
                deceased_dob:'1991-11-14',
                deceased_nextKin:'',
                deceased_residence:'',
                deceased_deatchPlace:'',
                checkedLaw:true,
                otherRemarks:''
            },
            familyInfo: [{
                ssn:'',
                fName:'',
                lName:'',
                mi:'',
                relationship:'',
                email:'',
                phoneNo:'',
                mailingAdd:''
            }],
            documentInfo: [{
                doc_attached:'',
                doc_description:''
            }]
        }
    }
};

const deathClaimsDetail = (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_DEATH_CLAIM_DETAIL: {
            return {
                ...state,
                deathClaimDetail: {
                    ...state.deathClaimDetail,
                    ...action.payload
                }
            }
        }

        default: {
            return state;
        }
    }
};

export default deathClaimsDetail;
