import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../../_helper/helper';

import { ADVERSE_ACTION_CREATE, DELETE_ADVERSE_ACTION, GET_ADVERSE_ACTION_DETAILS, RESET, RESET_ADVERSE_ACTION_CREATE, UPDATE_ADVERSE_ACTION } from "./actionsTypes";

import {
    createAdverseActionSuccess,
    createAdverseActionFail,
    reset,
    resetCreateAdverseAction,
    getAdverseActionDetailsSuccess,
    getAdverseActionDetailsFail,
    updateAdverseActionSuccess,
    deleteAdverseActionSuccess,
    deleteAdverseActionFail,
    updateAdverseActionFail,
} from "./action";

import {
    createAdverseAction, deleteAdverseAction, getAdverseActionDetails, updateAdverseAction
} from "../../../_helper/services/EMR/adverseAction";


function* onCreateAdverseAction({ payload }) {
    try {
        const response = yield call(createAdverseAction, payload);
        yield put(createAdverseActionSuccess(response));
        ShowSucess("Adverse Action Created Successfully", { position: "top-right" })
    }
    catch (error) {
        yield put(createAdverseActionFail(error.response));
        ShowError("Invalid data");
    } finally {
        yield put(resetCreateAdverseAction());
    }
}

function* onGetAdverseActionDetails({ payload: id }) {
    try {
        const response = yield call(getAdverseActionDetails, id);
        yield put(getAdverseActionDetailsSuccess(response));
    } catch (error) {
        yield put(getAdverseActionDetailsFail(error.response));
    }
}
function* onUpdateAdverseAction({ payload: { id }, payload }) {
    try {
        const response = yield call(updateAdverseAction, id, payload);
        yield put(updateAdverseActionSuccess(response));
        yield put(getAdverseActionDetailsSuccess(payload));
        ShowSucess(`Adverse Action Details updated successfully`, { position: 'top-right' });
    } catch (error) {
        yield put(updateAdverseActionFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onDeleteAdverseAction({ payload: id }) {
    try {
        const response = yield call(deleteAdverseAction, id);
        yield put(deleteAdverseActionSuccess(response));
        ShowSucess('Deleted successfully', { position: 'top-right' });
    } catch (error) {
        yield put(deleteAdverseActionFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* AdverseActionSaga() {
    yield takeLatest(ADVERSE_ACTION_CREATE, onCreateAdverseAction);
    yield takeLatest(GET_ADVERSE_ACTION_DETAILS, onGetAdverseActionDetails);
    yield takeLatest(UPDATE_ADVERSE_ACTION, onUpdateAdverseAction);
    yield takeLatest(DELETE_ADVERSE_ACTION, onDeleteAdverseAction);

    yield takeLatest(RESET, reset)
    yield takeLatest(RESET_ADVERSE_ACTION_CREATE, resetCreateAdverseAction);
}

export default AdverseActionSaga;