export const GET_DEDUCTIONTYPES = "GET_DEDUCTIONTYPES";
export const GET_DEDUCTIONTYPES_SUCCESS = "GET_DEDUCTIONTYPES_SUCCESS";
export const GET_DEDUCTIONTYPES_FAIL = "GET_DEDUCTIONTYPES_FAIL";

export const GET_DEDUCTIONTYPES_DETAILS = "GET_DEDUCTIONTYPES_DETAILS";
export const GET_DEDUCTIONTYPES_DETAILS_SUCCESS = "GET_DEDUCTIONTYPES_DETAILS_SUCCESS";
export const GET_DEDUCTIONTYPES_DETAILS_FAIL = "GET_DEDUCTIONTYPES_DETAILS_FAIL";

export const GET_ALL_DEDUCTIONTYPE_DETAILS = "GET_ALL_DEDUCTIONTYPE_DETAILS";
export const GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS = "GET_ALL_DEDUCTIONTYPE_DETAILS_SUCCESS";
export const GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL = "GET_ALL_DEDUCTIONTYPE_DETAILS_FAIL";

export const DEDUCTIONTYPE_UPDATE= "DEDUCTIONTYPE_UPDATE";
export const DEDUCTIONTYPE_UPDATE_SUCCESS = "DEDUCTIONTYPE_UPDATE_SUCCESS";
export const DEDUCTIONTYPE_UPDATE_FAIL = "DEDUCTIONTYPE_UPDATE_FAIL";

export const DEDUCTIONTYPE_CREATE= "DEDUCTIONTYPE_CREATE";
export const DEDUCTIONTYPE_CREATE_SUCCESS = "DEDUCTIONTYPE_CREATE_SUCCESS";
export const DEDUCTIONTYPE_CREATE_FAIL = "DEDUCTIONTYPE_CREATE_FAIL";

export const DEDUCTIONTYPE_DELETE= "DEDUCTIONTYPE_DELETE";
export const DEDUCTIONTYPE_DELETE_SUCCESS = "DEDUCTIONTYPE_DELETE_SUCCESS";
export const DEDUCTIONTYPE_DELETE_FAIL = "DEDUCTIONTYPE_DELETE_FAIL";
export const RESET = "RESET";

