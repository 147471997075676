export const GET_PMRCNATURES = "GET_PMRCNATURES";
export const GET_PMRCNATURES_SUCCESS = "GET_PMRCNATURES_SUCCESS";
export const GET_PMRCNATURES_FAIL = "GET_PMRCNATURES_FAIL";

export const GET_PMRCNATURES_DETAILS = "GET_PMRCNATURES_DETAILS";
export const GET_PMRCNATURES_DETAILS_SUCCESS = "GET_PMRCNATURES_DETAILS_SUCCESS";
export const GET_PMRCNATURES_DETAILS_FAIL = "GET_PMRCNATURES_DETAILS_FAIL";

export const PMRCNATURE_UPDATE= "PMRCNATURE_UPDATE";
export const PMRCNATURE_UPDATE_SUCCESS = "PMRCNATURE_UPDATE_SUCCESS";
export const PMRCNATURE_UPDATE_FAIL = "PMRCNATURE_UPDATE_FAIL";

export const PMRCNATURE_CREATE= "PMRCNATURE_CREATE";
export const PMRCNATURE_CREATE_SUCCESS = "PMRCNATURE_CREATE_SUCCESS";
export const PMRCNATURE_CREATE_FAIL = "PMRCNATURE_CREATE_FAIL";

export const PMRCNATURE_DELETE= "PMRCNATURE_DELETE";
export const PMRCNATURE_DELETE_SUCCESS = "PMRCNATURE_DELETE_SUCCESS";
export const PMRCNATURE_DELETE_FAIL = "PMRCNATURE_DELETE_FAIL";
export const RESET="RESET";

