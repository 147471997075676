export const SET_HEADER_FILTER = "SET HEADER FILTER";
export const CHANGE_HEADER_FILTER = "CHANGE HEADER FILTER";
export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const RESET_FILTER = "RESET_FILTER";
export const FIND_OUTSIDE_EMPLOYEEMENT = "FIND_OUTSIDE_EMPLOYEEMENT";
export const FIND_OUTSIDE_EMPLOYEEMENT_SUCCESS =
  "FIND_OUTSIDE_EMPLOYEEMENT_SUCCESS";
export const FIND_OUTSIDE_EMPLOYEEMENT_FAIL = "FIND_OUTSIDE_EMPLOYEEMENT_FAIL";
export const REQ_OUTSIDE_EMPLOYMENT = "REQ_OUTSIDE_EMPLOYMENT";
export const REQ_OUTSIDE_EMPLOYMENT_SUCCESS = "REQ_OUTSIDE_EMPLOYMENT_SUCCESS";
export const REQ_OUTSIDE_EMPLOYMENT_FAIL = "REQ_OUTSIDE_EMPLOYMENT_FAIL";
export const FETCH_OUTSIDE_EMPLOYDETAILS = "FETCH_OUTSIDE_EMPLOYDETAILS";
export const FETCH_OUTSIDE_EMPLOYDETAILS_SUCCESS =
  "FETCH_OUTSIDE_EMPLOYDETAILS_SUCCESS";
export const FETCH_OUTSIDE_EMPLOYDETAILS_FAIL =
  "FETCH_OUTSIDE_EMPLOYDETAILS_FAIL";
export const EDIT_OUTSIDE_EMPLOYEMENT = "EDIT_OUTSIDE_EMPLOYEMENT";
export const EDIT_OUTSIDE_EMPLOYEMENT_SUCCESS =
  "EDIT_OUTSIDE_EMPLOYEMENT_SUCCESS";
export const EDIT_OUTSIDE_EMPLOYEMENT_FAIL = "EDIT_OUTSIDE_EMPLOYEMENT_FAIL";
export const DELETE_OUTSIDE_EMPLOYEMENT = "DELETE_OUTSIDE_EMPLOYEMENT";
export const DELETE_OUTSIDE_EMPLOYEMENT_SUCCESS =
  "DELETE_OUTSIDE_EMPLOYEMENT_SUCCESS";
export const DELETE_OUTSIDE_EMPLOYEMENT_FAIL =
  "DELETE_OUTSIDE_EMPLOYEMENT_FAIL";
export const OUTSIDE_EMPLOYEEMENT_HISTORY = "OUTSIDE_EMPLOYEEMENT_HISTORY";
export const OUTSIDE_EMPLOYEEMENT_HISTORY_SUCCESS =
  "OUTSIDE_EMPLOYEEMENT_HISTORY_SUCCESS";
export const OUTSIDE_EMPLOYEEMENT_HISTORY_FAIL =
  "OUTSIDE_EMPLOYEEMENT_HISTORY_FAIL";
