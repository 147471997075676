// import {
// 	GET_DEPARTMENTS, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAIL,
// 	GET_DEPARTMENTS_DETAILS, GET_DEPARTMENTS_DETAILS_SUCCESS, GET_DEPARTMENTS_DETAILS_FAIL,
// 	DEPARTMENT_CREATE,DEPARTMENT_CREATE_FAIL,DEPARTMENT_CREATE_SUCCESS,
// 	DEPARTMENT_DELETE,DEPARTMENT_DELETE_FAIL,DEPARTMENT_DELETE_SUCCESS,
// 	DEPARTMENT_UPDATE,DEPARTMENT_UPDATE_FAIL,DEPARTMENT_UPDATE_SUCCESS, GET_DEPARTMENTS_SETTING_FAIL, GET_DEPARTMENTS_SETTING_SUCCESS, SAVE_DEPARTMENTS_SETTING_FAIL, SAVE_DEPARTMENTS_SETTING_SUCCESS, SAVE_DEPARTMENTS_SETTING, GET_DEPARTMENTS_SETTING, DEPARTMENT_COPY, DEPARTMENT_COPY_SUCCESS, DEPARTMENT_COPY_FAIL, DEPARTMENT_STATUS_CREATE, DEPARTMENT_STATUS_CREATE_SUCCESS, DEPARTMENT_STATUS_CREATE_FAIL, DEPARTMENT_STATUS_UPDATE, DEPARTMENT_STATUS_UPDATE_SUCCESS, DEPARTMENT_STATUS_UPDATE_FAIL, DEPARTMENT_STATUS_DELETE, DEPARTMENT_STATUS_DELETE_SUCCESS, DEPARTMENT_STATUS_DELETE_FAIL
//   } from "./actionTypes";

//   export const getDepartments = (payload) => {
// 	return {
// 	  type: GET_DEPARTMENTS,
// 	  payload: payload
// 	};
//   };
  
//   export const getDepartmentsSuccess = (posts) => {
// 	return {
// 	  type: GET_DEPARTMENTS_SUCCESS,
// 	  payload: posts,
// 	};
//   };
  
//   export const getDepartmentsFail = (error) => {
// 	return {
// 	  type: GET_DEPARTMENTS_FAIL,
// 	  payload: error,
// 	};
//   };
  
//   export const getDepartmentsDetails = (id) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS,
// 	  payload: id,
// 	};
//   };
  
//   export const getDepartmentsDetailsSuccess = (post) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS_SUCCESS,
// 	  payload: post,
// 	};
//   };
  
//   export const getDepartmentsDetailsFail = (error) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS_FAIL,
// 	  payload: error,
// 	};
//   };

//   export const createDepartment =(payload)=>{
// 	return {
// 		type: DEPARTMENT_CREATE,
// 		payload: payload
// 	}
//   }
//   export const createDepartmentSuccess =(response)=>{
// 	return {
// 		type: DEPARTMENT_CREATE_SUCCESS,
// 		payload: response
// 	}
//   }
//   export const createDepartmentFail =(error)=>{
// 	return {
// 		type: DEPARTMENT_CREATE_FAIL,
// 		payload: error
// 	}
//   }


//   export const updateDepartment =(payload)=>{
	
// 	return {
// 		type: DEPARTMENT_UPDATE,
// 		payload: payload
// 	}
//   }
//   export const updateDepartmentSuccess =(response)=>{
// 	return {
// 		type: DEPARTMENT_UPDATE_SUCCESS,
// 		payload: response
// 	}
//   }
//   export const updateDepartmentFail =(error)=>{
// 	return {
// 		type: DEPARTMENT_UPDATE_FAIL,
// 		payload: error
// 	}
//   }


//   export const deleteDepartment =(payload)=>{
// 	return {
// 		type: DEPARTMENT_DELETE,
// 		payload: payload
// 	}
//   }
//   export const deleteDepartmentSuccess =(response)=>{
// 	return {
// 		type: DEPARTMENT_DELETE_SUCCESS,
// 		payload: response
// 	}
//   }
//   export const deleteDepartmentFail =(error)=>{
// 	return {
// 		type: DEPARTMENT_DELETE_FAIL,
// 		payload: error
// 	}
//   }


import {
	GET_DEPARTMENTS, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_FAIL,
	GET_DEPARTMENTS_DETAILS, GET_DEPARTMENTS_DETAILS_SUCCESS, GET_DEPARTMENTS_DETAILS_FAIL,
	DEPARTMENT_CREATE,DEPARTMENT_CREATE_FAIL,DEPARTMENT_CREATE_SUCCESS,
	DEPARTMENT_DELETE,DEPARTMENT_DELETE_FAIL,DEPARTMENT_DELETE_SUCCESS,
	DEPARTMENT_UPDATE,DEPARTMENT_UPDATE_FAIL,DEPARTMENT_UPDATE_SUCCESS,RESET, GET_DEPARTMENTS_SETTING_FAIL, GET_DEPARTMENTS_SETTING_SUCCESS, SAVE_DEPARTMENTS_SETTING_FAIL, SAVE_DEPARTMENTS_SETTING_SUCCESS, SAVE_DEPARTMENTS_SETTING, GET_DEPARTMENTS_SETTING, DEPARTMENT_COPY, DEPARTMENT_COPY_SUCCESS, DEPARTMENT_COPY_FAIL, DEPARTMENT_STATUS_CREATE, DEPARTMENT_STATUS_CREATE_SUCCESS, DEPARTMENT_STATUS_CREATE_FAIL, DEPARTMENT_STATUS_UPDATE, DEPARTMENT_STATUS_UPDATE_SUCCESS, DEPARTMENT_STATUS_UPDATE_FAIL, DEPARTMENT_STATUS_DELETE, DEPARTMENT_STATUS_DELETE_SUCCESS, DEPARTMENT_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getDepartments = (payload) => {
	return {
	  type: GET_DEPARTMENTS,
	  payload: payload
	};
  };
  
  export const getDepartmentsSuccess = (posts) => {
	return {
	  type: GET_DEPARTMENTS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getDepartmentsFail = (error) => {
	return {
	  type: GET_DEPARTMENTS_FAIL,
	  payload: error,
	};
  };
  
  export const getDepartmentsDetails = (id) => {
	return {
	  type: GET_DEPARTMENTS_DETAILS,
	  payload: id,
	};
  };
  
  export const getDepartmentsDetailsSuccess = (post) => {
	return {
	  type: GET_DEPARTMENTS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getDepartmentsDetailsFail = (error) => {
	return {
	  type: GET_DEPARTMENTS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createDepartment =(payload)=>{
	return {
		type: DEPARTMENT_CREATE,
		payload: payload
	}
  }
  export const createDepartmentSuccess =(response)=>{
	return {
		type: DEPARTMENT_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createDepartmentFail =(error)=>{
	return {
		type: DEPARTMENT_CREATE_FAIL,
		payload: error
	}
  }


  export const updateDepartment =(payload)=>{
	
	return {
		type: DEPARTMENT_UPDATE,
		payload: payload
	}
  }
  export const updateDepartmentSuccess =(response)=>{
	return {
		type: DEPARTMENT_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateDepartmentFail =(error)=>{
	return {
		type: DEPARTMENT_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteDepartment =(payload)=>{
	return {
		type: DEPARTMENT_DELETE,
		payload: payload
	}
  }
  export const deleteDepartmentSuccess =(response)=>{
	return {
		type: DEPARTMENT_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteDepartmentFail =(error)=>{
	return {
		type: DEPARTMENT_DELETE_FAIL,
		payload: error
	}
  }

  
export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };



