import {
	GET_HISTORYS, GET_HISTORYS_SUCCESS, GET_HISTORYS_FAIL,
	GET_HISTORYS_DETAILS, GET_HISTORYS_DETAILS_SUCCESS, GET_HISTORYS_DETAILS_FAIL,
	HISTORY_CREATE,HISTORY_CREATE_FAIL,HISTORY_CREATE_SUCCESS,
	HISTORY_DELETE,HISTORY_DELETE_FAIL,HISTORY_DELETE_SUCCESS,
	HISTORY_UPDATE,HISTORY_UPDATE_FAIL,HISTORY_UPDATE_SUCCESS,RESET, GET_HISTORYS_SETTING_FAIL, GET_HISTORYS_SETTING_SUCCESS, SAVE_HISTORYS_SETTING_FAIL, SAVE_HISTORYS_SETTING_SUCCESS, SAVE_HISTORYS_SETTING, GET_HISTORYS_SETTING, HISTORY_COPY, HISTORY_COPY_SUCCESS, HISTORY_COPY_FAIL, HISTORY_STATUS_CREATE, HISTORY_STATUS_CREATE_SUCCESS, HISTORY_STATUS_CREATE_FAIL, HISTORY_STATUS_UPDATE, HISTORY_STATUS_UPDATE_SUCCESS, HISTORY_STATUS_UPDATE_FAIL, HISTORY_STATUS_DELETE, HISTORY_STATUS_DELETE_SUCCESS, HISTORY_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getHistorys = (payload) => {
	return {
	  type: GET_HISTORYS,
	  payload: payload
	};
  };
  
  export const getHistorysSuccess = (posts) => {
	return {
	  type: GET_HISTORYS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getHistorysFail = (error) => {
	return {
	  type: GET_HISTORYS_FAIL,
	  payload: error,
	};
  };
  
  export const getHistorysDetails = (id) => {
	return {
	  type: GET_HISTORYS_DETAILS,
	  payload: id,
	};
  };
  
  export const getHistorysDetailsSuccess = (post) => {
	return {
	  type: GET_HISTORYS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getHistorysDetailsFail = (error) => {
	return {
	  type: GET_HISTORYS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createHistory =(payload)=>{
	return {
		type: HISTORY_CREATE,
		payload: payload
	}
  }
  export const createHistorySuccess =(response)=>{
	return {
		type: HISTORY_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createHistoryFail =(error)=>{
	return {
		type: HISTORY_CREATE_FAIL,
		payload: error
	}
  }


  export const updateHistory =(payload)=>{
	
	return {
		type: HISTORY_UPDATE,
		payload: payload
	}
  }
  export const updateHistorySuccess =(response)=>{
	return {
		type: HISTORY_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateHistoryFail =(error)=>{
	return {
		type: HISTORY_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteHistory =(payload)=>{
	return {
		type: HISTORY_DELETE,
		payload: payload
	}
  }
  export const deleteHistorySuccess =(response)=>{
	return {
		type: HISTORY_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteHistoryFail =(error)=>{
	return {
		type: HISTORY_DELETE_FAIL,
		payload: error
	}
  }

  
export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };



