import {
    DELETE_DFWP_CONTACT_LIST,
    DELETE_DFWP_CONTACT_LIST_FAIL,
    DELETE_DFWP_CONTACT_LIST_SUCCESS,
    DFWP_CONTACT_LIST_CREATE,
    DFWP_CONTACT_LIST_CREATE_FAIL,
    DFWP_CONTACT_LIST_CREATE_SUCCESS,
    GET_DFWP_CONTACT_LIST_DETAILS,
    GET_DFWP_CONTACT_LIST_DETAILS_FAIL,
    GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS,
    RESET,
    RESET_DFWP_CONTACT_LIST_CREATE,
    RESET_DFWP_CONTACT_LIST_DETAILS,
    UPDATE_DFWP_CONTACT_LIST,
    UPDATE_DFWP_CONTACT_LIST_FAIL,
    UPDATE_DFWP_CONTACT_LIST_SUCCESS,
} from "./actionsTypes";

const initialState = {
    DFWPContactListList: [],
    DFWPContactListDetails: {},
    createResult: {},
    deleteResult: {},
    loading: false,
    detailsLoading: false,
    loadingCreate: false,
    error: {
        message: "",
    },
};

const DFWPContactListReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case DFWP_CONTACT_LIST_CREATE:
            state = { ...state, createResult: {}, loadingCreate: true };
            break;
        case DFWP_CONTACT_LIST_CREATE_SUCCESS:
            state = { ...state, createResult: action.payload, loadingCreate: false };
            break;
        case DFWP_CONTACT_LIST_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                createResult: {},
                loadingCreate: false,
            };
            break;


        case GET_DFWP_CONTACT_LIST_DETAILS:
            state = { ...state, detailsLoading: true, DFWPContactListDetails: {} };
            break;
        case GET_DFWP_CONTACT_LIST_DETAILS_SUCCESS:
            state = { ...state, DFWPContactListDetails: action.payload, detailsLoading: false };
            break;
        case GET_DFWP_CONTACT_LIST_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                detailsLoading: false,
            };
            break;

        case UPDATE_DFWP_CONTACT_LIST:
            state = { ...state, createResult: {}, loading: true };
            break;
        case UPDATE_DFWP_CONTACT_LIST_SUCCESS:
            state = { ...state, createResult: action.payload, loading: false };
            break;
        case UPDATE_DFWP_CONTACT_LIST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case DELETE_DFWP_CONTACT_LIST:
            state = { ...state, deleteResult: {}, loading: true, };
            break;
        case DELETE_DFWP_CONTACT_LIST_SUCCESS:
            state = { ...state, deleteResult: action.payload, loading: false };
            break;
        case DELETE_DFWP_CONTACT_LIST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
                deleteResult: {}
            };
            break;
        case RESET_DFWP_CONTACT_LIST_DETAILS:
            state = { ...state, DFWPContactListDetails: {}, detailsLoading: false };
            break;


        case RESET:
            state = { ...state, DFWPContactListList: [], createResult: {} };
            break;
        case RESET_DFWP_CONTACT_LIST_CREATE:
            state = { ...state, createResult: {} };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default DFWPContactListReducer;