import * as Actions from '../actions';

const initialState = {
   benefitDetail: {},
   masterDetail: {}
};

const benefitDetail = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_BENEFIT_DETAIL: {
            return {
                ...state,
                benefitDetail: {
                    ...action.payload
                }
            }
        }

        case Actions.UPDATE_BENEFIT_DETAIL: {
            return {
                ...state,
                benefitDetail: {
                    ...state.benefitDetail,
                    ...action.payload
                }
            }
        }

        case Actions.GET_GENERAL_DETAIL: {
            return {
                ...state,
                masterDetail: {
                    ...action.payload
                }
            }
        }

        default: {
            return state;
        }
    }
};

export default benefitDetail;
