import {
    PE_RATING_CREATE, PE_RATING_CREATE_FAIL, PE_RATING_CREATE_SUCCESS,
    DELETE_PE_RATING,
    DELETE_PE_RATING_FAIL,
    DELETE_PE_RATING_SUCCESS,
    GET_PE_RATINGES,
    GET_PE_RATINGES_FAIL,
    GET_PE_RATINGES_SUCCESS,
    GET_PE_RATING_DETAILS,
    GET_PE_RATING_DETAILS_FAIL,
    GET_PE_RATING_DETAILS_SUCCESS,
    RESET,
    RESET_PE_RATING_CREATE,
    RESET_PE_RATING_DELETE,
    RESET_PE_RATING_DETAILS,
    UPDATE_PE_RATING,
    UPDATE_PE_RATING_FAIL,
    UPDATE_PE_RATING_SUCCESS,
} from "./actionsTypes";

//Create Actions
export const createPERating = (payload) => {
    return {
        type: PE_RATING_CREATE,
        payload: payload
    }
};
export const createPERatingSuccess = (response) => {
    return {
        type: PE_RATING_CREATE_SUCCESS,
        payload: response
    }
};
export const createPERatingFail = (error) => {
    return {
        type: PE_RATING_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getPERatingDetails = (id) => {
    return {
        type: GET_PE_RATING_DETAILS,
        payload: id,
    };
};
export const getPERatingDetailsSuccess = (data) => {
    return {
        type: GET_PE_RATING_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getPERatingDetailsFail = (error) => {
    return {
        type: GET_PE_RATING_DETAILS_FAIL,
        payload: error,
    };
};

//Get All Actions
export const getPERatinges = () => {
    return {
        type: GET_PE_RATINGES,
    };
};
export const getPERatingesSuccess = (data) => {
    return {
        type: GET_PE_RATINGES_SUCCESS,
        payload: data,
    };
};
export const getPERatingesFail = (error) => {
    return {
        type: GET_PE_RATINGES_FAIL,
        payload: error,
    };
};

//Update Actions
export const updatePERating = (payload) => {

    return {
        type: UPDATE_PE_RATING,
        payload: payload
    }
};
export const updatePERatingSuccess = (response) => {
    return {
        type: UPDATE_PE_RATING_SUCCESS,
        payload: response
    }
};
export const updatePERatingFail = (error) => {
    return {
        type: UPDATE_PE_RATING_FAIL,
        payload: error
    }
};

//Delete Actions
export const deletePERating = (id) => {
    return {
        type: DELETE_PE_RATING,
        payload: id
    }
};
export const deletePERatingSuccess = (response) => {
    return {
        type: DELETE_PE_RATING_SUCCESS,
        payload: response
    }
};
export const deletePERatingFail = (error) => {
    return {
        type: DELETE_PE_RATING_FAIL,
        payload: error
    }
};


//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetCreatePERating = () => {
    return {
        type: RESET_PE_RATING_CREATE
    }
};

export const resetPERatingDetails = () => {
    return {
        type: RESET_PE_RATING_DETAILS
    }
};

export const resetPERatingDeleteData = () => {
    return {
        type: RESET_PE_RATING_DELETE
    }
};