import {
    TEST_DESIGNATED_POSITION_LOG_CREATE, TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL, TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS,
    RESET,
    RESET_TEST_DESIGNATED_POSITION_LOG_CREATE,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS,
    GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL,
    UPDATE_TEST_DESIGNATED_POSITION_LOG,
    UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
    UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL,
    DELETE_TEST_DESIGNATED_POSITION_LOG,
    DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
    DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL,
    RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
} from "./actionsTypes";

//Create Actions
export const createTestDesignatedPositionLog = (payload) => {
    return {
        type: TEST_DESIGNATED_POSITION_LOG_CREATE,
        payload: payload
    }
};
export const createTestDesignatedPositionLogSuccess = (response) => {
    return {
        type: TEST_DESIGNATED_POSITION_LOG_CREATE_SUCCESS,
        payload: response
    }
};
export const createTestDesignatedPositionLogFail = (error) => {
    return {
        type: TEST_DESIGNATED_POSITION_LOG_CREATE_FAIL,
        payload: error
    }
};

//Get Details Actions
export const getTestDesignatedPositionLogDetails = (id) => {
    return {
        type: GET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
        payload: id,
    };
};
export const getTestDesignatedPositionLogDetailsSuccess = (data) => {
    return {
        type: GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_SUCCESS,
        payload: data,
    };
};
export const getTestDesignatedPositionLogDetailsFail = (error) => {
    return {
        type: GET_TEST_DESIGNATED_POSITION_LOG_DETAILS_FAIL,
        payload: error,
    };
};

//Update Actions
export const updateTestDesignatedPositionLog = (payload) => {
    return {
        type: UPDATE_TEST_DESIGNATED_POSITION_LOG,
        payload: payload
    }
};
export const updateTestDesignatedPositionLogSuccess = (response) => {
    return {
        type: UPDATE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
        payload: response
    }
};
export const updateTestDesignatedPositionLogFail = (error) => {
    return {
        type: UPDATE_TEST_DESIGNATED_POSITION_LOG_FAIL,
        payload: error
    }
};

//Delete Actions
export const deleteTestDesignatedPositionLog = (id) => {
    return {
        type: DELETE_TEST_DESIGNATED_POSITION_LOG,
        payload: id
    }
};
export const deleteTestDesignatedPositionLogSuccess = (response) => {
    return {
        type: DELETE_TEST_DESIGNATED_POSITION_LOG_SUCCESS,
        payload: response
    }
};
export const deleteTestDesignatedPositionLogFail = (error) => {
    return {
        type: DELETE_TEST_DESIGNATED_POSITION_LOG_FAIL,
        payload: error
    }
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};
export const resetCreateTestDesignatedPositionLog = () => {
    return {
        type: RESET_TEST_DESIGNATED_POSITION_LOG_CREATE
    }
};
export const resetTestDesignatedPositionLogDetails = () => {
    return {
        type: RESET_TEST_DESIGNATED_POSITION_LOG_DETAILS,
    };
};