import {
	GET_PAYSTEPS, GET_PAYSTEPS_SUCCESS, GET_PAYSTEPS_FAIL,
	GET_PAYSTEPS_DETAILS, GET_PAYSTEPS_DETAILS_SUCCESS, GET_PAYSTEPS_DETAILS_FAIL,
	PAYSTEP_CREATE, PAYSTEP_CREATE_FAIL, PAYSTEP_CREATE_SUCCESS,
	PAYSTEP_DELETE, PAYSTEP_DELETE_FAIL, PAYSTEP_DELETE_SUCCESS,
	PAYSTEP_UPDATE, PAYSTEP_UPDATE_FAIL, PAYSTEP_UPDATE_SUCCESS,RESET
} from "./actionTypes";

const initialState = {
	paystepList: [],
	paystep: {},
	postingResult: {},
	loading: false,
	loadingPaystepDetails: false,
	paystepSetting:{},
	loadingpaystepSetting: false,
	// loading: false,
	error: {
		message: "",
	},
};

const PaystepReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_PAYSTEPS:
			state = { ...state, loading: false };
			break;
		case GET_PAYSTEPS_SUCCESS:
			state = { ...state, paystepList: action.payload, loading: false };
			break;
		case GET_PAYSTEPS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_PAYSTEPS_DETAILS:
			state = { ...state, loadingPaystepDetails: true };
			break;
		case GET_PAYSTEPS_DETAILS_SUCCESS:
			state = { ...state, paystep: action.payload, loadingPaystepDetails: false };
			break;
		case GET_PAYSTEPS_DETAILS_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loadingPaystepDetails: false,
			};
			break;
		case PAYSTEP_CREATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYSTEP_CREATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PAYSTEP_CREATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				postingResult: {},
				loading: false,
			};
			break;
		case PAYSTEP_UPDATE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYSTEP_UPDATE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PAYSTEP_UPDATE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case PAYSTEP_DELETE:
			state = { ...state, postingResult: {}, loading: true };
			break;
		case PAYSTEP_DELETE_SUCCESS:
			state = { ...state, postingResult: action.payload, loading: false };
			break;
		case PAYSTEP_DELETE_FAIL:
			state = {
				...state,
				error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			case RESET:{
				state={...state, paystep:{}}
			}
			
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default PaystepReducer;