export const GET_PMRCSALARIESS = "GET_PMRCSALARIESS";
export const GET_PMRCSALARIESS_SUCCESS = "GET_PMRCSALARIESS_SUCCESS";
export const GET_PMRCSALARIESS_FAIL = "GET_PMRCSALARIESS_FAIL";

export const GET_PMRCSALARIESS_DETAILS = "GET_PMRCSALARIESS_DETAILS";
export const GET_PMRCSALARIESS_DETAILS_SUCCESS = "GET_PMRCSALARIESS_DETAILS_SUCCESS";
export const GET_PMRCSALARIESS_DETAILS_FAIL = "GET_PMRCSALARIESS_DETAILS_FAIL";

export const PMRCSALARIES_UPDATE= "PMRCSALARIES_UPDATE";
export const PMRCSALARIES_UPDATE_SUCCESS = "PMRCSALARIES_UPDATE_SUCCESS";
export const PMRCSALARIES_UPDATE_FAIL = "PMRCSALARIES_UPDATE_FAIL";

export const PMRCSALARIES_CREATE= "PMRCSALARIES_CREATE";
export const PMRCSALARIES_CREATE_SUCCESS = "PMRCSALARIES_CREATE_SUCCESS";
export const PMRCSALARIES_CREATE_FAIL = "PMRCSALARIES_CREATE_FAIL";

export const PMRCSALARIES_DELETE= "PMRCSALARIES_DELETE";
export const PMRCSALARIES_DELETE_SUCCESS = "PMRCSALARIES_DELETE_SUCCESS";
export const PMRCSALARIES_DELETE_FAIL = "PMRCSALARIES_DELETE_FAIL";
export const RESET="RESET";

