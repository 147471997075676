// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getTagmasterDetails =(code)=> get(`${baseURL+url.TAGMASTER_BASE}/${code}`);
export const getTagmasters =({page,pageSize})=> get(baseURL+url.TAGMASTER_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);

export const updateTagmaster = (code, payload) => put(`${baseURL}${url.TAGMASTER_BASE}/${code}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createTagmaster =(payload) => post(baseURL+url.TAGMASTER_BASE,payload);

export const deleteTagmaster = (code) => remove(baseURL+url.TAGMASTER_BASE+'/'+code);